// alterado - revisado

const tipoFinanciamentoList = [
    {
        id: 10,
        descricao: 'Consórcio',
        ordem: 10,
        informaTabelaSAC: 'N',
        informaTemSeguro: 'S',
        grupoDespesa: 710,
    },
    {
        id: 15,
        descricao: 'Empréstimo',
        ordem: 15,
        informaTabelaSAC: 'N',
        informaTemSeguro: 'S',
        grupoDespesa: 710,
    },
    {
        id: 20,
        descricao: 'Equipamentos',
        ordem: 20,
        informaTabelaSAC: 'N',
        informaTemSeguro: 'S',
        grupoDespesa: 710,
    },
    {
        id: 30,
        descricao: 'Imóvel',
        ordem: 30,
        informaTabelaSAC: 'S',
        informaTemSeguro: 'S',
        grupoDespesa: 710,
    },
    {
        id: 40,
        descricao: 'Terreno',
        ordem: 40,
        informaTabelaSAC: 'N',
        informaTemSeguro: 'S',
        grupoDespesa: 710,
    },
    {
        id: 50,
        descricao: 'Veículo',
        ordem: 50,
        informaTabelaSAC: 'N',
        informaTemSeguro: 'S',
        grupoDespesa: 710,
    },
    {
        id: 900,
        descricao: 'Outros',
        ordem: 900,
        informaTabelaSAC: 'N',
        informaTemSeguro: 'S',
        grupoDespesa: 710,
    },
]

export default tipoFinanciamentoList