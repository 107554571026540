// alterado - revisado

const ICON = {
    home: 'home',
    menu: 'menu',
    configuracao: 'settings',
    sair: 'power_settings_new',


    dashboard: 'dashboard',
    clientes: 'people',
    pesquisa: 'search_web',
    trilhaConhecimento: 'ondemand_video',
    treinamentos: 'calendar_month',
    agenda: 'today',
    fintracks: 'attach_money',
    
    visualizarTabela: 'table_view',



    uploadFile: 'cloud_upload',
    paginaAnterior: 'arrow_back_ios_new',
    proximaPagina: 'arrow_forward_ios_new',
    zoomIn: 'zoom_in',
    zoomOut: 'zoom_out',
    download: 'cloud_download',
    print: 'print',

    verDetalhes: 'visibility',



    moverParaCima: 'north',
    moverParaBaixo: 'south',



    clienteConjuge: 'people',
    familiares: 'favorite',
    enderecos: 'place',
    objetivos: 'history',



    diaDia: 'today',
    diaDiaDespesasTemporarias: 'timer',
    educacao: 'school',
    financiamento: 'attach_money',
    projetoVidaSonho: 'flight_takeoff',



    propostaSeguroVidaMesAtualPendencia2Meses: 'assignment',
    propostaSeguroVidaPendenciaMesAtual2meses: 'assignment_return',
    propostaSeguroVidaPagasMesAtual: 'assignment_turned_in',
    receitaMensal: 'cached',
    patrimonioImobilizado: 'house',
    recursosFinanceiros: 'monetization_on',
    seguroVida: 'security',


    
    grupoCoberturaSeguroVidaMorte: 'add_alert',
    grupoCoberturaSeguroVidaInvalidezAcidente: 'airline_seat_flat',
    grupoCoberturaSeguroVidaInvalidezDoenca: 'airline_seat_individual_suite',
    grupoCoberturaSeguroVidaDoencasGraves: 'enhanced_encryption',
    grupoCoberturaSeguroVidaIncapacidadeTemporaria: 'accessible',
    grupoCoberturaSeguroVidaFuneral: 'airport_shuttle',



    sucessaoVitalicia: 'all_inclusive',
    composicaoInventario: 'view_comfy',



    total: 'done_outline',



    despesas: 'arrow_downward',
    garantias: 'arrow_upward',
    necessidadeProtecao: 'favorite',



    modoApresentacao: 'slideshow',
    modoApresentacaoPaginaAnterior: 'arrow_left',
    modoApresentacaoProximaPagina: 'arrow_right',

    

    buttonImprimir: '',
}

export default ICON