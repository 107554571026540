import LABEL from '../../business-const/Label/Label'

import formPrimeiraReuniaoInvestimento10List from "./formPrimeiraReuniaoInvestimento10List"

import formPrimeiraReuniaoInvestimento20List from "./formPrimeiraReuniaoInvestimento20List"

import formPrimeiraReuniaoInvestimento30List from "./formPrimeiraReuniaoInvestimento30List"

import formPrimeiraReuniaoInvestimento40List from "./formPrimeiraReuniaoInvestimento40List"

import formPrimeiraReuniaoPadraoList from "./formPrimeiraReuniaoPadraoList"

import {
   FormListRadioGroupPrimeiraReuniaoAprofundamento,
   FormListRadioGroupSimplificadoCompleto,
} from '../RadioGroup/FormListRadioGroup'

const formList = [
   {
      id: 10,
      descricao: 'Primeira Reunião', // Veedha
      radioGroupFormList: FormListRadioGroupPrimeiraReuniaoAprofundamento,
      perguntasFormList: formPrimeiraReuniaoInvestimento10List,
      perguntasObjetivos: 'O',
      labelTabClienteFormListConsideracoesFinais: LABEL.tabClienteFormListConsideracoesFinais,
      exibirCompleto: 'S',
      exibirMapaFinanceiro: 'N',//'S',
      exibirEstudo: 'S',
      exibirGestaoPatrimonial: 'N',
      idQuantoPossuiParaInvestir: 40,
      idPatrimonioImobilizado: 100,
      idRecursosFinanceiros: 50,
      idNecessidadeUtilizacao5anos: 90,
      idDependentesFinanceiros: 60,
      idDiaDia: 70,
      idReceitaMensal: 80,
      idRendaFalta: 135,
      informaCep: 'S',
      informaNomeCurto: 'S',
      informaCpf: 'S',
      informaOrigemCliente: 'N',
      informaTipoCarteiraInvestimentoCliente: 'N',
      informaMomentoCliente: 'N',
      informaProfissao: 'S',
      informaConjuge: 'S',
      informaMeacao: 'N',
      //informaObservacaoCliente: 'S',
      informaContatos: 'S',
      informaContasInvestimentos: 'S',
      informaFilhaos: 'S',
      informaObjetivos: 'S',
      informaPerfilCliente: 'S',
      //informaMapaFinanceiro: 'N',
      reportPrimeiraReuniaoInvestimentoSeguroVida: 'S',
      ordem: 10,
   },
   {
      id: 20,
      descricao: 'Primeira Reunião', // Fortuna
      radioGroupFormList: FormListRadioGroupPrimeiraReuniaoAprofundamento,
      perguntasFormList: formPrimeiraReuniaoInvestimento20List,
      perguntasObjetivos: 'O',
      labelTabClienteFormListConsideracoesFinais: LABEL.tabClienteFormListConsideracoesFinais,
      exibirCompleto: 'S',
      exibirMapaFinanceiro: 'N',//'S',
      exibirEstudo: 'S',
      exibirGestaoPatrimonial: 'N',
      idQuantoPossuiParaInvestir: 40,
      idPatrimonioImobilizado: 100,
      idRecursosFinanceiros: 50,
      idNecessidadeUtilizacao5anos: 90,
      idDependentesFinanceiros: 60,
      idDiaDia: 70,
      idReceitaMensal: 80,
      idRendaFalta: 135,
      informaCep: 'S',
      informaNomeCurto: 'S',
      informaCpf: 'S',
      informaOrigemCliente: 'S',
      informaTipoCarteiraInvestimentoCliente: 'S',
      informaMomentoCliente: 'S',
      informaProfissao: 'N',
      informaConjuge: 'S',
      informaMeacao: 'N',
      //informaObservacaoCliente: 'N',
      informaContatos: 'S',
      informaContasInvestimentos: 'S',
      informaFilhaos: 'S',
      informaObjetivos: 'N',
      informaPerfilCliente: 'N',
      //informaMapaFinanceiro: 'N',
      reportPrimeiraReuniaoInvestimentoSeguroVida: 'S',
      ordem: 20,
   },
   {
      id: 30,
      descricao: 'Primeira Reunião', // Guide
      radioGroupFormList: FormListRadioGroupSimplificadoCompleto,//null,
      perguntasFormList: formPrimeiraReuniaoInvestimento30List,
      perguntasObjetivos: 'P',
      labelTabClienteFormListConsideracoesFinais: LABEL.tabClienteFormListPlanoAcao,
      exibirCompleto: 'S',
      exibirMapaFinanceiro: 'N',//'S',
      exibirEstudo: 'S',
      exibirGestaoPatrimonial: 'N',
      /*
      idQuantoPossuiParaInvestir: null,
      idPatrimonioImobilizado: null,
      idRecursosFinanceiros: null,
      idNecessidadeUtilizacao5anos: null,
      idDependentesFinanceiros: null,
      idDiaDia: null,
      idReceitaMensal: null,
      idRendaFalta: null,
      informaCep: 'N',
      informaNomeCurto: 'N',
      informaCpf: 'N',
      informaOrigemCliente: 'N',
      informaTipoCarteiraInvestimentoCliente: 'N',
      informaMomentoCliente: 'N',
      informaContatos: 'N',
      informaContasInvestimentos: 'N',
      informaProfissao: 'N',
      informaConjuge: 'N',
      informaFilhaos: 'N',
      informaObjetivos: 'N',
      informaPerfilCliente: 'N',
      //informaMapaFinanceiro: 'N',
      reportPrimeiraReuniaoInvestimentoSeguroVida: 'N',
      */
      idQuantoPossuiParaInvestir: null,
      idPatrimonioImobilizado: 10,
      idRecursosFinanceiros: 20,
      idNecessidadeUtilizacao5anos: null,
      idDependentesFinanceiros: null,
      idDiaDia: 50,
      idReceitaMensal: 40,
      idRendaFalta: null,
      informaCep: 'S',
      informaNomeCurto: 'S',
      informaCpf: 'S',
      informaOrigemCliente: 'N',
      informaTipoCarteiraInvestimentoCliente: 'N',
      informaMomentoCliente: 'N',
      informaProfissao: 'S',
      informaConjuge: 'S',
      informaMeacao: 'N',
      //informaObservacaoCliente: 'N',
      informaContatos: 'S',
      informaContasInvestimentos: 'S',
      informaFilhaos: 'S',
      informaObjetivos: 'N',
      informaPerfilCliente: 'N',
      //informaMapaFinanceiro: 'S',
      reportPrimeiraReuniaoInvestimentoSeguroVida: 'N',
      ordem: 30,
   },
   {
      id: 40,
      descricao: 'Primeira Reunião', // Bradesco
      radioGroupFormList: FormListRadioGroupSimplificadoCompleto,
      perguntasFormList: formPrimeiraReuniaoInvestimento40List,
      perguntasObjetivos: 'O',
      labelTabClienteFormListConsideracoesFinais: LABEL.tabClienteFormListConsideracoesFinais,
      exibirCompleto: 'N',
      exibirMapaFinanceiro: 'N',
      exibirEstudo: 'S',
      exibirGestaoPatrimonial: 'N',
      idQuantoPossuiParaInvestir: null,
      idPatrimonioImobilizado: 90,
      idRecursosFinanceiros: 100,
      idNecessidadeUtilizacao5anos: null,
      idDependentesFinanceiros: null,
      idDiaDia: 120,
      idReceitaMensal: 110,
      idRendaFalta: null,
      informaCep: 'S',
      informaNomeCurto: 'S',
      informaCpf: 'S',
      informaOrigemCliente: 'N',
      informaTipoCarteiraInvestimentoCliente: 'N',
      informaMomentoCliente: 'N',
      informaProfissao: 'N',
      informaConjuge: 'S',
      informaMeacao: 'N',
      //informaObservacaoCliente: 'S',
      informaContatos: 'S',
      informaContasInvestimentos: 'S',
      informaFilhaos: 'S',
      informaObjetivos: 'S',
      informaPerfilCliente: 'N',
      //informaMapaFinanceiro: 'S',
      reportPrimeiraReuniaoInvestimentoSeguroVida: 'N',
      ordem: 40,
   },
   {
      id: 920, // HARDCODE.idFormListDefault
      //Foi tratado em inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient para zerar a ligação
      //das perguntas com os recursos financeiros quando idFormList = HARDCODE.idFormListDefault, que era até
      //então o único idFormList ligado a formPrimeiraReuniaoPadraoList
      descricao: 'Primeira Reunião Padrão', // Geral
      radioGroupFormList: FormListRadioGroupSimplificadoCompleto,
      perguntasFormList: formPrimeiraReuniaoPadraoList,
      perguntasObjetivos: 'O',
      labelTabClienteFormListConsideracoesFinais: LABEL.tabClienteFormListConsideracoesFinais,
      exibirCompleto: 'S',
      exibirMapaFinanceiro: 'N',//'S',
      exibirEstudo: 'S',
      exibirGestaoPatrimonial: 'N',
      idQuantoPossuiParaInvestir: null,
      idPatrimonioImobilizado: 10,
      idRecursosFinanceiros: 20,
      idNecessidadeUtilizacao5anos: null,
      idDependentesFinanceiros: null,
      idDiaDia: 50,
      idReceitaMensal: 40,
      idRendaFalta: null,
      informaCep: 'S',
      informaNomeCurto: 'S',
      informaCpf: 'S',
      informaOrigemCliente: 'N',
      informaTipoCarteiraInvestimentoCliente: 'N',
      informaMomentoCliente: 'N',
      informaProfissao: 'S',
      informaConjuge: 'S',
      informaMeacao: 'N',
      //informaObservacaoCliente: 'S',
      informaContatos: 'S',
      informaContasInvestimentos: 'S',
      informaFilhaos: 'S',
      informaObjetivos: 'S',
      informaPerfilCliente: 'N',
      //informaMapaFinanceiro: 'S',
      reportPrimeiraReuniaoInvestimentoSeguroVida: 'N',
      ordem: 920,
   },
   {
      id: 930,
      descricao: 'Gestão Patrimonial', // Blue3
      radioGroupFormList: FormListRadioGroupSimplificadoCompleto,
      perguntasFormList: formPrimeiraReuniaoPadraoList,
      perguntasObjetivos: 'O',
      labelTabClienteFormListConsideracoesFinais: LABEL.tabClienteFormListPlanoAcao,
      exibirCompleto: 'S',
      exibirMapaFinanceiro: 'N',
      exibirEstudo: 'S',
      exibirGestaoPatrimonial: 'S',//'N',//'S',
      idQuantoPossuiParaInvestir: null,
      idPatrimonioImobilizado: 10,
      idRecursosFinanceiros: 20,
      idNecessidadeUtilizacao5anos: null,
      idDependentesFinanceiros: null,
      idDiaDia: 50,
      idReceitaMensal: 40,
      idRendaFalta: null,
      informaCep: 'S',
      informaNomeCurto: 'S',
      informaCpf: 'S',
      informaOrigemCliente: 'N',
      informaTipoCarteiraInvestimentoCliente: 'N',
      informaMomentoCliente: 'N',
      informaProfissao: 'S',
      informaConjuge: 'S',
      informaMeacao: 'S',
      //informaObservacaoCliente: 'S',
      informaContatos: 'S',
      informaContasInvestimentos: 'S',
      informaFilhaos: 'S',
      informaObjetivos: 'S',
      informaPerfilCliente: 'N',
      //informaMapaFinanceiro: 'S',
      reportPrimeiraReuniaoInvestimentoSeguroVida: 'N',
      ordem: 930,
   },
   {
      id: 940,
      descricao: 'Primeira Reunião Padrão', // 920 + Mapa Financeiro
      radioGroupFormList: FormListRadioGroupSimplificadoCompleto,
      perguntasFormList: formPrimeiraReuniaoPadraoList,
      perguntasObjetivos: 'O',
      labelTabClienteFormListConsideracoesFinais: LABEL.tabClienteFormListConsideracoesFinais,
      exibirCompleto: 'S',
      exibirMapaFinanceiro: 'S',
      exibirEstudo: 'S',
      exibirGestaoPatrimonial: 'N',
      idQuantoPossuiParaInvestir: null,
      idPatrimonioImobilizado: 10,
      idRecursosFinanceiros: 20,
      idNecessidadeUtilizacao5anos: null,
      idDependentesFinanceiros: null,
      idDiaDia: 50,
      idReceitaMensal: 40,
      idRendaFalta: null,
      informaCep: 'S',
      informaNomeCurto: 'S',
      informaCpf: 'S',
      informaOrigemCliente: 'N',
      informaTipoCarteiraInvestimentoCliente: 'N',
      informaMomentoCliente: 'N',
      informaProfissao: 'S',
      informaConjuge: 'S',
      informaMeacao: 'N',
      //informaObservacaoCliente: 'S',
      informaContatos: 'S',
      informaContasInvestimentos: 'S',
      informaFilhaos: 'S',
      informaObjetivos: 'S',
      informaPerfilCliente: 'N',
      //informaMapaFinanceiro: 'S',
      reportPrimeiraReuniaoInvestimentoSeguroVida: 'N',
      ordem: 940,
   },
]
  
export default formList