const parentescoList = (listaCompleta) => {
   
   const parentescoListAux = []

   if (listaCompleta) {

      parentescoListAux.push(
         {
            id: 1, // HARDCODEBACKEND.idParentescoCliente
            descricao: 'Cliente',
            ordem: 1,
         },
         {
            id: 2, // HARDCODEBACKEND.idParentescoConjuge
            descricao: 'Cônjuge',
            ordem: 2,
         },
      )
   }
   
   parentescoListAux.push(
      {
         id: 10, // HARDCODE.idParentescoFilhao // HARDCODEBACKEND.idParentescoFilhao
         descricao: 'Filha(o)',
         ordem: 10,
      },
      {
         id: 15, // HARDCODE.idParentescoEnteadao // HARDCODEBACKEND.idParentescoEnteadao
         descricao: 'Enteada(o)',
         ordem: 15,
      },
      {
         id: 20,
         descricao: 'Pai',
         ordem: 20,
      },
      {
         id: 30,
         descricao: 'Mãe',
         ordem: 30,
      },
      {
         id: 40,
         descricao: 'Irmã(o)',
         ordem: 40,
      },
      {
         id: 50,
         descricao: 'Av(ó)ô',
         ordem: 50,
      },
      {
         id: 60,
         descricao: 'Neta(o)',
         ordem: 60,
      },
      {
         id: 70,
         descricao: 'Tia(o)',
         ordem: 70,
      },
      {
         id: 80,
         descricao: 'Sobrinha(o)',
         ordem: 80,
      },
      {
         id: 90,
         descricao: 'Madrinha',
         ordem: 90,
      },
      {
         id: 100,
         descricao: 'Padrinho',
         ordem: 100,
      },
      {
         id: 110,
         descricao: 'Sogra(o)',
         ordem: 110,
      },
      {
         id: 120,
         descricao: 'Ex-cônjuge',
         ordem: 120,
      },
      {
         id: 810,
         descricao: 'Pet',
         ordem: 810,
      },
      {
         id: 820,
         descricao: 'Sócio',
         ordem: 820,
      },
      {
         id: 900,
         descricao: 'Outros',
         ordem: 900,
      },
   )

   if (listaCompleta) {

      parentescoListAux.push(
         {
            id: 999, // HARDCODEBACKEND.idParentescoNaoInformado
            descricao: 'Não Informado',
            ordem: 999,
         },
      )
   }

   return parentescoListAux
}

export default parentescoList