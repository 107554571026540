import {
    buscarEndereco
} from '../../../business-api/LogradouroService'

import HARDCODE from '../../../business-const/HardCode/HardCode'

import TAMMAX from '../../../business-rules/TamMax/TamMax'

import {
    obtemUfItcmdEnderecoResidencial,
} from '../estudo-form/EstudoServiceSucessaoVitalicia'



export const incrementaContEndereco = ( values ) => {

    if (!values.contEndereco) {

        values.contEndereco = 0
    }
    
    values.contEndereco += 1
}



export const incluiEnderecoTipoEnderecoResidencial = ( values ) => {

    incrementaContEndereco(values)

    values.enderecos.push(
        {
            id: values.contEndereco,
            tipoEndereco: HARDCODE.idTipoEnderecoResidencial,
        }
    )
}

export const processaRegistrosDefaultIniciaisEndereco = ( values ) => {

    if (!values.enderecos) {

        values.enderecos = []
    }
    
    if (!values.processouRegistrosDefaultIniciaisEndereco) {

        values.processouRegistrosDefaultIniciaisEndereco = true

        if (values.enderecos.length === 0) {

            incluiEnderecoTipoEnderecoResidencial(values)
        }
    }
}



export const idEnderecoCepResidencial = ( values ) => {

    let idEnderecoCepResidencialAux = null

    values.enderecos?.forEach( (linhaEndereco, index) => {

        if (linhaEndereco.tipoEndereco === HARDCODE.idTipoEnderecoResidencial) {

            idEnderecoCepResidencialAux = linhaEndereco.id
        }
    })

    return idEnderecoCepResidencialAux
}

export const indexEnderecoCepResidencial = ( values ) => {

    let indexEnderecoCepResidencialAux = null

    values.enderecos?.forEach( (linhaEndereco, index) => {

        if (linhaEndereco.id === values.idEnderecoCepResidencial) {

            indexEnderecoCepResidencialAux = index
        }
    })

    return indexEnderecoCepResidencialAux
}

export const atualizaFormListCepResidencial = async ( variaveisGlobais, values ) => {

    if (!values.enderecos) {
        values.enderecos = []
    }

    if (!values.cepResidencial && values.idEnderecoCepResidencial) {

        const indexEnderecoCepResidencialAux = indexEnderecoCepResidencial(values)

        values.enderecos[indexEnderecoCepResidencialAux].cep = null
        values.enderecos[indexEnderecoCepResidencialAux].cepResidencial = null
        values.idEnderecoCepResidencial = null

    } else {

        if (values.cepResidencial) {
            
            if (!values.idEnderecoCepResidencial) {

                values.idEnderecoCepResidencial = idEnderecoCepResidencial(values)
            }
        
            if (!values.idEnderecoCepResidencial || indexEnderecoCepResidencial(values) === null) {
        
                incluiEnderecoTipoEnderecoResidencial(values)
        
                values.idEnderecoCepResidencial = idEnderecoCepResidencial(values)
            }
            
            const indexEnderecoCepResidencialAux = indexEnderecoCepResidencial(values)

            values.enderecos[indexEnderecoCepResidencialAux].cep = values.cepResidencial
            values.enderecos[indexEnderecoCepResidencialAux].cepResidencial = values.cepResidencial

            if (values.enderecos[indexEnderecoCepResidencialAux].cep.length === TAMMAX.cepSemFormatacao) {

                await buscarEndereco(variaveisGlobais, values.enderecos[indexEnderecoCepResidencialAux], false)
            }
        }
    }

    values.patrimonioImobilizado?.forEach( (linhaPatrimonioImobilizado, index) => {

        if (linhaPatrimonioImobilizado.idPergunta && !linhaPatrimonioImobilizado.ufItcmd) {

            values.patrimonioImobilizado[index].ufItcmd = obtemUfItcmdEnderecoResidencial(values)
        }
    })
}