import HARDCODE from '../../../business-const/HardCode/HardCode'



const tipoArquivoList = [
   {
      id: 10,
      descricao: 'Institucional', // HARDCODE.idTipoArquivoInstitucional
      grupoArquivo: HARDCODE.idGrupoArquivoConfiguracao,
      filtro: 'T',
      ordem: 10,
   },
   {
      id: 20,
      descricao: 'Polícita de Investimento - Cautelosa',
      grupoArquivo: HARDCODE.idGrupoArquivoConfiguracao,
      filtro: 'P',
      ordem: 20,
   },
   {
      id: 30,
      descricao: 'Polícita de Investimento - Defensiva',
      grupoArquivo: HARDCODE.idGrupoArquivoConfiguracao,
      filtro: 'P',
      ordem: 30,
   },
   {
      id: 40,
      descricao: 'Polícita de Investimento - Precavida',
      grupoArquivo: HARDCODE.idGrupoArquivoConfiguracao,
      filtro: 'P',
      ordem: 40,
   },
   {
      id: 50,
      descricao: 'Polícita de Investimento - Estrategista',
      grupoArquivo: HARDCODE.idGrupoArquivoConfiguracao,
      filtro: 'P',
      ordem: 50,
   },
   {
      id: 60,
      descricao: 'Polícita de Investimento - Visionária',
      grupoArquivo: HARDCODE.idGrupoArquivoConfiguracao,
      filtro: 'P',
      ordem: 60,
   },
   {
      id: 70,
      descricao: 'Polícita de Investimento - Energética',
      grupoArquivo: HARDCODE.idGrupoArquivoConfiguracao,
      filtro: 'P',
      ordem: 70,
   },
   {
      id: 80,
      descricao: 'Polícita de Investimento - Destemida',
      grupoArquivo: HARDCODE.idGrupoArquivoConfiguracao,
      filtro: 'P',
      ordem: 80,
   },
   {
      id: 90,
      descricao: 'Tipo da Carteira de Investimento - Conservadora',
      grupoArquivo: HARDCODE.idGrupoArquivoConfiguracao,
      filtro: 'C',
      ordem: 90,
   },
   {
      id: 100,
      descricao: 'Tipo da Carteira de Investimento - Moderada',
      grupoArquivo: HARDCODE.idGrupoArquivoConfiguracao,
      filtro: 'C',
      ordem: 100,
   },
   {
      id: 110,
      descricao: 'Tipo da Carteira de Investimento - Arrojada',
      grupoArquivo: HARDCODE.idGrupoArquivoConfiguracao,
      filtro: 'C',
      ordem: 110,
   },
   {
      id: 120, // HARDCODE.idTipoArquivoProposta
      descricao: 'Proposta',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 120,
   },
   {
      id: 121, // HARDCODE.idTipoArquivoPropostaHorizonte
      descricao: 'Proposta - Horizonte',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 121,
   },
   {
      id: 122, // HARDCODE.idTipoArquivoPropostaEssencial
      descricao: 'Proposta - Essencial',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 122,
   },
   {
      id: 123, // HARDCODE.idTipoArquivoListaPropostas
      descricao: 'Lista de Propostas',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 124,
   },
   {
      id: 124, // HARDCODE.idTipoArquivoListaPropostasImplantacao
      descricao: 'Lista de Propostas - Implantação',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 125,
   },
   {
      id: 125, // HARDCODE.idTipoArquivoListaPropostasNovoLayout
      descricao: 'Lista de Propostas - Novo Layout',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 126,
   },
   {
      id: 126, // HARDCODE.idTipoArquivoPropostaNovoLayout
      descricao: 'Proposta - Novo Layout',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 123,
   },
   {
      id: 130, // HARDCODE.idTipoArquivoApolice
      descricao: 'Apolice',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 130,
   },
   {
      id: 131, // HARDCODE.idTipoArquivoApolicePrivateSolutions
      descricao: 'Apolice - Private Solutions',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 131,
   },
   {
      id: 132, // HARDCODE.idTipoArquivoApoliceVidaToda
      descricao: 'Apolice - Vida Toda',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 132,
   },
   {
      id: 133, // HARDCODE.idTipoArquivoApoliceSiteCliente
      descricao: 'Apolice - Site Cliente',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 133,
   },
   {
      id: 134, // HARDCODE.idTipoArquivoApoliceHorizonte
      descricao: 'Apolice - Horizonte',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 134,
   },
   {
      id: 135, // HARDCODE.idTipoArquivoApoliceEssencial
      descricao: 'Apolice - Essencial',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 135,
   },
   {
      id: 140, // HARDCODE.idTipoArquivoListaApolices
      descricao: 'Lista de Apólices',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 140,
   },
   {
      id: 145, // HARDCODE.idTipoArquivoListaApolicesImplantacao
      descricao: 'Lista de Apólices - Implantação',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 145,
   },
   {
      id: 146, // HARDCODE.idTipoArquivoListaApolicesImplantacaoConsultorFinanceiro
      descricao: 'Lista de Apólices - Implantação - Consultor Financeiro',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 146,
   },
   {
      id: 147, // HARDCODE.idTipoArquivoListaApolicesImplantacaoLP
      descricao: 'Lista de Apólices - Implantação - LP',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 147,
   },
   {
      id: 148, // HARDCODE.idTipoArquivoListaApolicesImplantacaoSemAgente
      descricao: 'Lista de Apólices - Implantação - Sem Agente',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 148,
   },
   {
      id: 150, // HARDCODE.idTipoArquivoListaApolicesPlataforma
      descricao: 'Lista de Apólices - Plataforma',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 150,
   },
   {
      id: 155, // HARDCODE.idTipoArquivoListaApolicesCorretorSeguros
      descricao: 'Lista de Apólices - Corretor de Seguros',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 155,
   },
   {
      id: 200, // HARDCODE.idTipoArquivoListaCobrancas
      descricao: 'Lista de Cobranças',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 200,
   },
   {
      id: 210, // HARDCODE.idTipoArquivoListaCobrancasPlataforma
      descricao: 'Lista de Cobranças - Plataforma',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 210,
   },
   {
      id: 215, // HARDCODE.idTipoArquivoListaCobrancasCorretorSeguros
      descricao: 'Lista de Cobranças - Corretor de Seguros',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 215,
   },
   {
      id: 300, // HARDCODE.idTipoArquivoListaComissoes
      descricao: 'Lista de Comissões',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 300,
   },
   {
      id: 600, // HARDCODE.idTipoArquivoPosicaoInvestimentos
      descricao: 'Posição dos Investimentos',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 600,
   },
   {
      id: 700, // HARDCODE.idTipoArquivoListaTransferenciaClientes
      descricao: 'Lista Transferência Clientes',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 700,
   },
   {
      id: 800, // HARDCODE.idTipoArquivoConsorcio
      descricao: 'Consórcio',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 800,
   },
   {
      id: 900, // HARDCODE.idTipoArquivoNaoReconhecido
      descricao: 'Não Reconhecido',
      grupoArquivo: HARDCODE.idGrupoArquivoCliente,
      filtro: null,
      ordem: 900,
   },

]

export default tipoArquivoList