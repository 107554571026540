import _ from 'lodash'

import history from 'history.js'

import HARDCODE from '../../business-const/HardCode/HardCode'

import MESSAGE from '../../business-const/Message/Message'

import PATH from '../../business-const/Path/Path'

import AuthService from '../../business-api/AuthService'

import ConfiguracaoService from '../../business-api/ConfiguracaoService'

import UsuarioService from '../../business-api/UsuarioService'

import {
    mensagemErroErro,
    mensagemAlerta,
} from '../../business-components/Toastr/Toastr'

import {
    sortAsc
} from '../../business-components/Sort/Sort'



export const comparaConfiguracao = (values, valuesAnt) => {
    
    const comparaConfiguracao =
        _.isEqual(values.jsonConfiguracao, valuesAnt.jsonConfiguracao) &&
        _.isEqual(values.jsonImagem, valuesAnt.jsonImagem) &&
        _.isEqual(values.jsonPagina, valuesAnt.jsonPagina) &&
        _.isEqual(values.jsonGestaoPatrimonial, valuesAnt.jsonGestaoPatrimonial) &&
        _.isEqual(values.jsonUsuario, valuesAnt.jsonUsuario)

    return comparaConfiguracao
}



export const converteJsonConfiguracaoJsonImagem = (jsonConfiguracao, jsonImagem) => {

    if (!jsonImagem.logo && jsonConfiguracao.logo) {

        jsonImagem.logo = jsonConfiguracao.logo
        jsonConfiguracao.logo = null
    }
    
    if (!jsonImagem.logoLoginSidenav && jsonConfiguracao.logoLoginSidenav) {

        jsonImagem.logoLoginSidenav = jsonConfiguracao.logoLoginSidenav
        jsonConfiguracao.logoLoginSidenav = null
    }

    //if (!jsonImagem.background && jsonConfiguracao.background) {

    //    jsonImagem.background = jsonConfiguracao.background
    //    jsonConfiguracao.background = null
    //}

    if (!jsonImagem.backgroundCapaRelatorio && jsonConfiguracao.backgroundCapaRelatorio) {

        jsonImagem.backgroundCapaRelatorio = jsonConfiguracao.backgroundCapaRelatorio
        jsonConfiguracao.backgroundCapaRelatorio = null
    }
}

export const getConfiguracaoUsuario = async (variaveisGlobais, usuario, validaAssinatura, reutilizar, obterImagem) => {

    const configuracaoService = new ConfiguracaoService()

    try {

        let response

        if (
                reutilizar &&
                (!usuario || usuario === AuthService.obterUsuarioLogado(variaveisGlobais)) &&
                ConfiguracaoService.obterConfiguracaoUsuario(variaveisGlobais) !== undefined
        ) {

            response = ConfiguracaoService.obterConfiguracaoUsuario(variaveisGlobais)

        } else {

            response =
                await configuracaoService.buscarConfiguracaoUsuario(variaveisGlobais,
                    usuario ? usuario : AuthService.obterUsuarioLogado(variaveisGlobais), obterImagem)

            if (reutilizar && (!usuario || usuario === AuthService.obterUsuarioLogado(variaveisGlobais))) {

                ConfiguracaoService.setarConfiguracaoUsuario(variaveisGlobais, response)
            }
        }

        const email = response.data.email

        const perfil = response.data.perfil

        const tipoLicenca = response.data.tipoLicenca

        const fullWhiteLabel = response.data.fullWhiteLabel

        const idConfiguracao = response.data.idConfiguracao

        const idFormList = response.data.idFormList

        const escopo = response.data.escopo

        const jsonConfiguracao =
            ajustaInclusoesAlteracoesExclusoesJsonConfiguracao(JSON.parse(response.data.jsonConfiguracao))

        const jsonImagem =
            ajustaInclusoesAlteracoesExclusoesJsonImagem(JSON.parse(response.data.jsonImagem))

        const jsonPagina =
            ajustaInclusoesAlteracoesExclusoesJsonPagina(JSON.parse(response.data.jsonPagina))

        const jsonGestaoPatrimonial =
            ajustaInclusoesAlteracoesExclusoesJsonGestaoPatrimonial(JSON.parse(response.data.jsonGestaoPatrimonial))

        const contratouLicencaPlanejadorTodosUsuarios = response.data.contratouLicencaPlanejadorTodosUsuarios

        const jsonPagamento =
            ajustaInclusoesAlteracoesExclusoesJsonPagamento(JSON.parse(response.data.jsonPagamento))

        const pagamentoBoleto = response.data.pagamentoBoleto

        const jsonUsuario = 
            ajustaInclusoesAlteracoesExclusoesJsonUsuario(JSON.parse(response.data.jsonUsuario))

        const jsonUsuarioAdmin =
            ajustaInclusoesAlteracoesExclusoesJsonUsuarioAdmin(JSON.parse(response.data.jsonUsuarioAdmin))

        const dataPrecoModuloBaseAtual = response.data.dataPrecoModuloBaseAtual
	
        const precoModuloBaseAtual = response.data.precoModuloBaseAtual

        const statusAssinatura = response.data.statusAssinatura

        const integracao = response.data.integracao

        if (validaAssinatura) {

            if (statusAssinatura === HARDCODE.statusAssinaturaPendente) {

                mensagemAlerta(MESSAGE().realizarAssinatura)

                history.push(PATH.pageConfiguracaoForm)

            } else {

                if (statusAssinatura === HARDCODE.statusAssinaturaPagtoAtraso) {

                    mensagemAlerta(MESSAGE().cobrancaEmAtraso)

                } else {

                    if (statusAssinatura === HARDCODE.statusAssinaturaBloquearAcessoPagtoAtraso) {

                        mensagemAlerta(MESSAGE().acessoBloqueadoPagtoAtraso)

                        history.push(PATH.pageConfiguracaoForm)
                    }
                }
            }
        }

        return { email, perfil, tipoLicenca, fullWhiteLabel, idConfiguracao, idFormList, escopo, jsonConfiguracao, jsonImagem,
            jsonPagina, jsonGestaoPatrimonial, contratouLicencaPlanejadorTodosUsuarios, jsonPagamento, pagamentoBoleto, jsonUsuario,
                jsonUsuarioAdmin, dataPrecoModuloBaseAtual, precoModuloBaseAtual, statusAssinatura, integracao }

    } catch (erro) {

        mensagemErroErro(erro)

        const usuarioService = new UsuarioService()

        usuarioService.logout(variaveisGlobais, false);
    }
}

   

export const getEquipeList = async ( variaveisGlobais, idConfiguracao, reutilizar ) => {

    const usuarioService = new UsuarioService()

    try {

        let response

        if (
                reutilizar &&
                UsuarioService.obterEquipeList(variaveisGlobais) !== undefined
        ) {

            response = UsuarioService.obterEquipeList(variaveisGlobais)

        } else {

            response = await usuarioService.buscarEquipeList(variaveisGlobais, AuthService.obterUsuarioLogado(variaveisGlobais),
                idConfiguracao)

            response.data?.forEach( (linhaResponse, index) => {
                response.data[index].jsonUsuario = JSON.parse(linhaResponse.jsonUsuario)
                response.data[index].jsonEquipe = JSON.parse(linhaResponse.jsonEquipe)
            })

            if (reutilizar) {

                UsuarioService.setarEquipeList(variaveisGlobais, response)
            }
        }
        
        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}

export const putConfiguracaoUsuario = async ( variaveisGlobais, values ) => {

    const configuracaoService = new ConfiguracaoService()

    try {
        const configuracaoUsuario = {
            idUsuario: AuthService.obterUsuarioLogado(variaveisGlobais),
            jsonConfiguracao: JSON.stringify(values.jsonConfiguracao),
            jsonImagem: JSON.stringify(values.jsonImagem),
            jsonPagina: JSON.stringify(values.jsonPagina),
            jsonGestaoPatrimonial: JSON.stringify(values.jsonGestaoPatrimonial),
            jsonPagamento: JSON.stringify(values.jsonPagamento),
            jsonUsuario: JSON.stringify(values.jsonUsuario)
        }

        const response = await configuracaoService.atualizarConfiguracaoUsuario(variaveisGlobais, configuracaoUsuario)

        values.idConfiguracao = response.data

        return true

    } catch (erro) {

        mensagemErroErro(erro)

        return false
    }
}

export const deleteEquipe = async ( variaveisGlobais, idEquipe ) => {

    const usuarioService = new UsuarioService()

    try {
        await usuarioService.deletarEquipe(variaveisGlobais, AuthService.obterUsuarioLogado(variaveisGlobais), idEquipe)

        return true

    } catch (erro) {

        mensagemErroErro(erro)

        return false
    }
}



export const calculaPaletaCores = (cores) => {

    const paletaCores = []

    if (cores) {

        if (cores.corPadrao1) {

            paletaCores.push(
                {
                    id: HARDCODE.corPadrao1,
                    cor: cores.corPadrao1
                },
            )
        }
        
        if (cores.corPadrao2) {

            paletaCores.push(
                {
                    id: HARDCODE.corPadrao2,
                    cor: cores.corPadrao2
                },
            )
        }
          
        if (cores.corPadrao3) {

            paletaCores.push(
                {
                    id: HARDCODE.corPadrao3,
                    cor: cores.corPadrao3
                },
            )
        }

        if (cores.corPadrao4) {

            paletaCores.push(
                {
                    id: HARDCODE.corPadrao4,
                    cor: cores.corPadrao4
                },
            )
        }

        if (cores.corPadrao5) {

            paletaCores.push(
                {
                    id: HARDCODE.corPadrao5,
                    cor: cores.corPadrao5
                },
            )
        }
    }

    return paletaCores
    /*
    if (
            !cores ||
            JSON.stringify(cores) === JSON.stringify(HARDCODE.coresOld) ||
            JSON.stringify(cores) === JSON.stringify(HARDCODE.coresOld2)
    ) {

        cores = HARDCODE.cores
    }

    if (!cores.corPadrao4) {

        cores.corPadrao4 = HARDCODE.cores.corPadrao4
    }

    if (!cores.corPadrao5) {

        cores.corPadrao5 = HARDCODE.cores.corPadrao5
    }

    return [
        {
            id: HARDCODE.corPadrao1,
            cor: cores.corPadrao1
        },
        {
            id: HARDCODE.corPadrao2,
            cor: cores.corPadrao2
        },
        {
            id: HARDCODE.corPadrao3,
            cor: cores.corPadrao3
        },
        {
            id: HARDCODE.corPadrao4,
            cor: cores.corPadrao4
        },
        {
            id: HARDCODE.corPadrao5,
            cor: cores.corPadrao5
        },
        {
            id: HARDCODE.corPadrao6,
            cor: cores.corPadrao5
        },
    ]
    */
}

export const ajustaInclusoesAlteracoesExclusoesJsonConfiguracao = ( jsonConfiguracao ) => {

    if (!jsonConfiguracao.numeroCartaoCredito) {
        jsonConfiguracao.numeroCartaoCredito = ''
    }

    if (!jsonConfiguracao.mesAnoExpiracaoCartaoCredito) {
        jsonConfiguracao.mesAnoExpiracaoCartaoCredito = ''
    }
    
    if (!jsonConfiguracao.cvcCartaoCredito) {
        jsonConfiguracao.cvcCartaoCredito = ''
    }

    if (!jsonConfiguracao.nomeCartaoCredito) {
        jsonConfiguracao.nomeCartaoCredito = ''
    }
    
    if (!jsonConfiguracao.cpfCartaoCredito) {
        jsonConfiguracao.cpfCartaoCredito = ''
    }

    if (!jsonConfiguracao.historicoAssinatura) {
        jsonConfiguracao.historicoAssinatura = []
    }

    if (jsonConfiguracao.filiaisUnidades) {
        jsonConfiguracao.filiaisUnidades = sortAsc(jsonConfiguracao.filiaisUnidades)
    }

    if (!jsonConfiguracao.tamanhoLogo) {
        jsonConfiguracao.tamanhoLogo = {}
    }

    if (!jsonConfiguracao.paletaCores) {

        jsonConfiguracao.paletaCores = calculaPaletaCores(jsonConfiguracao.cores)

        if (jsonConfiguracao.cores) {

            jsonConfiguracao.cores = ''
        }
    }

    return jsonConfiguracao
}

export const ajustaInclusoesAlteracoesExclusoesJsonImagem = ( jsonImagem ) => {

    return jsonImagem
}

export const ajustaInclusoesAlteracoesExclusoesJsonPagina = ( jsonPagina ) => {

    return jsonPagina
}

export const ajustaInclusoesAlteracoesExclusoesJsonGestaoPatrimonial = ( jsonGestaoPatrimonial ) => {

    return jsonGestaoPatrimonial
}

export const ajustaInclusoesAlteracoesExclusoesJsonPagamento = ( jsonPagamento ) => {

    return jsonPagamento
}

export const ajustaInclusoesAlteracoesExclusoesJsonUsuario = ( jsonUsuario ) => {

    return jsonUsuario
}

export const ajustaInclusoesAlteracoesExclusoesJsonUsuarioAdmin = ( jsonUsuarioAdmin ) => {

    return jsonUsuarioAdmin
}