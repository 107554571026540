import HARDCODE from '../../../business-const/HardCode/HardCode'

export const aumentoLetraGarantias = (values) => {
        
    const aumentoLetraCabecalho = 2
    const aumentoLetraReportCabecalho = values.disposicaoRelatorio === HARDCODE.disposicaoRelatorioRetrato ? 1 : 2

    const aumentoLetra = 3
    const aumentoLetraReport = values.disposicaoRelatorio === HARDCODE.disposicaoRelatorioRetrato ? 2 : 3

    const aumentoLetraTotal = 1
    const aumentoLetraTotalReport = values.disposicaoRelatorio === HARDCODE.disposicaoRelatorioRetrato ? 1 : 2

    return { aumentoLetraCabecalho, aumentoLetraReportCabecalho, aumentoLetra, aumentoLetraReport,
        aumentoLetraTotal, aumentoLetraTotalReport }
}