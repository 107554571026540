// alterado - revisado

const profissaoList = [
    '', // incluído para evitar o Warning => Material-UI: The value provided to Autocomplete is invalid. None of the options match with `""`. You can use the `getOptionSelected` prop to customize the equality test. 
    'Outras', // HARDCODE.profissaoOutras
    'Abastecedor de Linha de Produção',
    'Abastecedor de Máquinas',
    'Acabador de Calçados',
    'Acabador de Embalagens',
    'Acompanhante (Idosos, Crianças)',
    'Açougueiro',
    'Acupunturista',
    'Aderecista',
    'Adesivador',
    'Adestrador de Animais - Amestrador',
    'Adestrador de Animais - Condicionador',
    'Adestrador de Animais - Domador',
    'Adestrador de Animais- Amansador',
    'Adestrador de Animais Domésticos',
    'Adestrador de Animais, Exceto Domésticos',
    'Adido Estrangeiro',
    'Adido Militar, Naval e Aeronautico',
    'Administrador',
    'Administrador de Contratos',
    'Administrador de E-Commerce',
    'Administrador de Empresas',
    'Administrador de Redes',
    'Administrador de Sistemas',
    'Administrador de Tecnologia da Informação',
    'Administrador Financeiro',
    'Administrador Hospitalar',
    'Administrador Predial',
    'Administradores do Lar (Do Lar)',
    'Administrativo de Obras',
    'Advogado Administrativo',
    'Advogado Ambientalista',
    'Advogado Cível',
    'Advogado Consumerista',
    'Advogado Criminalista',
    'Advogado da União',
    'Advogado de Contratos',
    'Advogado de Propriedade Intelectual',
    'Advogado Empresarial',
    'Advogado Imobiliário',
    'Advogado Penal',
    'Advogado Securitário',
    'Advogado Societário',
    'Advogado Trabalhista',
    'Advogado Tributarista',
    'Aeromoça',
    'Aeronauta',
    'Aeroviário',
    'Afiador',
    'Afiador de Cardas',
    'Afiador de Ferramentas',
    'Afiador de Metais Preciosos',
    'Afiadores e Polidores de Metais',
    'Agenciador',
    'Agente Administrativo',
    'Agente Ambiental',
    'Agente Comercial',
    'Agente Comunitário de Saúde',
    'Agente da Polícia Federal',
    'Agente de Aeroporto',
    'Agente de Apoio Operacional',
    'Agente de Atividade Agropecuária',
    'Agente de Bolsa de Valores',
    'Agente de Combate A Endemias',
    'Agente de Correio (Exceto Motorizado)',
    'Agente de Defesa Ambiental',
    'Agente de Defesa Civil',
    'Agente de Defesa do Patrimonio',
    'Agente de Ficalização de Imposto',
    'Agente de Fiscalização',
    'Agente de Fiscalização Financeira',
    'Agente de Higiene',
    'Agente de Identificação',
    'Agente de Investimento',
    'Agente de Manobra e Docagem',
    'Agente de Organização Escolar',
    'Agente de Policia',
    'Agente de Polícia Civil',
    'Agente de Polícia Federal',
    'Agente de Polícia Militar',
    'Agente de Portaria',
    'Agente de Proteção de Aeroporto (Vigilante de Aeroporto)',
    'Agente de Saneamento',
    'Agente de Saúde',
    'Agente de Saúde Pública',
    'Agente de Segurança',
    'Agente de Segurança Penitenciário ',
    'Agente de Seguros',
    'Agente de Serviços',
    'Agente de Serviços Escolares',
    'Agente de Telecomunicações',
    'Agente de Trânsito',
    'Agente de Transporte Maritimo',
    'Agente de Turismo',
    'Agente de Vendas',
    'Agente de Viagem',
    'Agente de Vigilancia',
    'Agente do Meio Ambiente',
    'Agente e Representante Comercial',
    'Agente em Rádio e Televisão',
    'Agente Executivo',
    'Agente Exportação',
    'Agente Fiscal',
    'Agente Fiscal de Qualidade',
    'Agente Fiscal Metrológico',
    'Agente Fiscal Têxtil',
    'Agente Florestal',
    'Agente Funerário',
    'Agente Indígena',
    'Agente Opercaional',
    'Agente Penitenciário',
    'Agente Publicitário',
    'Agente Tributário',
    'Agente Tributário Estadual',
    'Agricultor',
    'Agrônomo e Afins',
    'Agropecuarista',
    'Ajudante Administrativo',
    'Ajudante de Armador',
    'Ajudante de Bombeiro',
    'Ajudante de Caldeiraria',
    'Ajudante de Caminhão',
    'Ajudante de Carga e Descarga',
    'Ajudante de Carpinteiro',
    'Ajudante de Carvoaria',
    'Ajudante de Churrasqueiro',
    'Ajudante de Despachante',
    'Ajudante de Despachante Aduaneiro',
    'Ajudante de Eletricista',
    'Ajudante de Encanador',
    'Ajudante de Ensino',
    'Ajudante de Estamparia',
    'Ajudante de Ferreiro',
    'Ajudante de Fundição',
    'Ajudante de Funileiro',
    'Ajudante de Garçom',
    'Ajudante de Instalação',
    'Ajudante de Lanterneiro',
    'Ajudante de Lubrificação',
    'Ajudante de Maquinista',
    'Ajudante de Marceneiro',
    'Ajudante de Mecânico',
    'Ajudante de Metalúrgico',
    'Ajudante de Motorista',
    'Ajudante de Obras Civis',
    'Ajudante de Obras e Serviços',
    'Ajudante de Off-Set',
    'Ajudante de Paramentação',
    'Ajudante de Pedreiro',
    'Ajudante de Pizzaiolo',
    'Ajudante de Produção',
    'Ajudante de Serralheiro',
    'Ajudante de Tapeceiro',
    'Ajudante de Vidraceiro',
    'Ajudante de Vitrinista',
    'Ajudante Geral',
    'Ajudante Operacional',
    'Ajustador de Agulhas',
    'Ajustador Ferramenteiro',
    'Ajustador Mecanico',
    'Ajustador Naval',
    'Alfaiate',
    'Alfarrabista',
    'Alimentador de Linhas de Produção',
    'Alinhador',
    'Almirante - Marinha',
    'Almirante - Marinha Mercante',
    'Almirante de Esquadra',
    'Almoxarife',
    'Alpinista Industrial',
    'Amarrador',
    'Ambulante',
    'Amolador de Cardas',
    'Amolador de Cutelaria',
    'Amolador de Facas',
    'Amolador de Ferramentas',
    'Amolador de Serra',
    'Amostrador de Minérios',
    'Analista',
    'Analista Administrativo',
    'Analista Administrativo de Obras',
    'Analista Ambiental',
    'Analista Comercial',
    'Analista Contábil',
    'Analista de Almoxarifado',
    'Analista de Apoio',
    'Analista de Apuração de Resultados',
    'Analista de Arquitetura',
    'Analista de Assistência Técnica',
    'Analista de Assuntos Regulatórios',
    'Analista de Atendimento',
    'Analista de Ativo Fixo',
    'Analista de Auditoria',
    'Analista de Automação',
    'Analista de Back Office',
    'Analista de Banco de Dados',
    'Analista de Benefícios',
    'Analista de Business Intelligence',
    'Analista de Cadastro',
    'Analista de Câmbio',
    'Analista de Campo',
    'Analista de Captação de Recursos',
    'Analista de Cargos e Salários',
    'Analista de Comércio Exterior',
    'Analista de Comércio Exterior ',
    'Analista de Compliance',
    'Analista de Compras',
    'Analista de Comunicação',
    'Analista de Comunicação e Marketing',
    'Analista de Comunicação Interna',
    'Analista de Contas',
    'Analista de Contas A Pagar',
    'Analista de Contas A Receber',
    'Analista de Contas Médicas',
    'Analista de Conteúdo',
    'Analista de Contratos',
    'Analista de Control Desk',
    'Analista de Controladoria',
    'Analista de Controle de Qualidade',
    'Analista de Controles Internos',
    'Analista de Credenciamento',
    'Analista de Crédito',
    'Analista de Crédito e Cobrança',
    'Analista de Crédito Imobiliário',
    'Analista de Criação',
    'Analista de Crm',
    'Analista de Customer Service',
    'Analista de Custos',
    'Analista de Departamento Pessoal',
    'Analista de Desembaraço Aduaneiro',
    'Analista de Desenvolvimento Analítico',
    'Analista de Desenvolvimento Econômico',
    'Analista de Documentação',
    'Analista de E-Commerce',
    'Analista de Endomarketing',
    'Analista de Engenharia',
    'Analista de Engenharia de Produto',
    'Analista de Estoque',
    'Analista de Estratégia de Mercado',
    'Analista de Eventos',
    'Analista de Expatriados',
    'Analista de Expedição',
    'Analista de Facilities',
    'Analista de Farmacovigilância',
    'Analista de Faturamento',
    'Analista de Folha de Pagamento',
    'Analista de Franquias',
    'Analista de Frota',
    'Analista de Fusões e Aquisições',
    'Analista de Garantia da Qualidade',
    'Analista de Geoprocessamento',
    'Analista de Gestão de Incidentes',
    'Analista de Governança de Ti',
    'Analista de Help Desk',
    'Analista de Implantação',
    'Analista de Incorporação',
    'Analista de Indicadores',
    'Analista de Informações Gerenciais',
    'Analista de Infraestrutura',
    'Analista de Inteligência Comercial',
    'Analista de Inteligência de Mercado',
    'Analista de Inventário',
    'Analista de Investimentos',
    'Analista de Laboratório',
    'Analista de Legalização',
    'Analista de Licitação',
    'Analista de Logística',
    'Analista de Manutenção',
    'Analista de Marcas e Patentes',
    'Analista de Marketing',
    'Analista de Marketing Digital',
    'Analista de Melhoria Contínua',
    'Analista de Mercado',
    'Analista de Merchandising',
    'Analista de Mídia',
    'Analista de Mídia Online',
    'Analista de Mídias Sociais',
    'Analista de Monitoramento',
    'Analista de Negócios',
    'Analista de Negócios de Ti',
    'Analista de Operações',
    'Analista de Orçamento',
    'Analista de Outsourcing',
    'Analista de Ouvidoria',
    'Analista de Parcerias',
    'Analista de Patrimônio',
    'Analista de Pesquisa de Mercado',
    'Analista de Planejamento',
    'Analista de Planejamento - Contabilidade',
    'Analista de Planejamento e Desenvolvimento Organizacional',
    'Analista de Planejamento e Orçamento ',
    'Analista de Planejamento Estratégico',
    'Analista de Planejamento Financeiro',
    'Analista de Politicas Públicas',
    'Analista de Pós-Venda',
    'Analista de Prevenção de Perdas',
    'Analista de Processos',
    'Analista de Processos Gerenciais',
    'Analista de Processos Industriais',
    'Analista de Produção',
    'Analista de Produto',
    'Analista de Projetos',
    'Analista de Publicidade e Propaganda',
    'Analista de Qualidade',
    'Analista de Recrutamento e Seleção',
    'Analista de Recursos Humanos',
    'Analista de Redes',
    'Analista de Redes Sociais',
    'Analista de Relacionamento',
    'Analista de Relações Institucionais',
    'Analista de Relações Internacionais',
    'Analista de Relações Trabalhistas',
    'Analista de Relatórios',
    'Analista de Remuneração',
    'Analista de Requisitos',
    'Analista de Responsabilidade Social',
    'Analista de Retenção',
    'Analista de Risco',
    'Analista de Sac',
    'Analista de Segurança da Informação',
    'Analista de Segurança do Trabalho',
    'Analista de Segurança Patrimonial',
    'Analista de Seguros',
    'Analista de Service Desk',
    'Analista de Serviço Social',
    'Analista de Sinistro',
    'Analista de Sistema de Gestão Integrada',
    'Analista de Sistemas',
    'Analista de Subscrição',
    'Analista de Suporte Mainframe',
    'Analista de Suporte Middleware',
    'Analista de Suporte Técnico',
    'Analista de Suporte Websphere',
    'Analista de Suprimentos',
    'Analista de Sustentabilidade',
    'Analista de Tecnologia da Informação',
    'Analista de Telecomunicações',
    'Analista de Tesouraria',
    'Analista de Testes',
    'Analista de Trade Marketing',
    'Analista de Transportes',
    'Analista de Treinamento e Desenvolvimento',
    'Analista de Usabilidade',
    'Analista de Validação',
    'Analista de Vendas',
    'Analista de Viagens',
    'Analista de Visual Merchandising',
    'Analista de Web Analytics',
    'Analista de Web Design',
    'Analista Desenvolvedor Mobile',
    'Analista do Inss',
    'Analista do Tribunal Regional do Trabalho',
    'Analista Econômico Financeiro',
    'Analista em Reforma e Desenvolvimento Agrário',
    'Analista Financeiro',
    'Analista Fiscal',
    'Analista Funcional',
    'Analista Industrial',
    'Analista Judiciário',
    'Analista Jurídico',
    'Analista Pedagógico',
    'Analista Programador',
    'Analista Químico',
    'Analista Sociocultural',
    'Analista Técnico',
    'Analista Técnico Administrativo',
    'Analista Técnico Cientifico',
    'Analista Técnico de Regulação',
    'Analista Tributário',
    'Analistas de Tecnologia da Informação',
    'Animador de Festa Populares',
    'Animador de Festas',
    'Animador Digital',
    'Antiquário ',
    'Antropólogo',
    'Apicultor',
    'Aplicador de Asfalto Impermeabilizante',
    'Aplicador de Revestimentos Cerâmicos, Pastilhas, Pedras e Madeiras ',
    'Aplicador de Sinteco',
    'Apontador',
    'Apontador de Mão de Obra',
    'Apontador de Obras',
    'Apontador de Produção',
    'Aposentado Por Idade Ou Tempo de Serviço',
    'Aposentado Por Invalidez',
    'Aprendiz',
    'Aprendiz Na Área Administrativa',
    'Aprendiz Na Área Comercial',
    'Aprendiz Na Área Contábil',
    'Aprendiz Na Área de Atendimento',
    'Aprendiz Na Área de Cobrança',
    'Aprendiz Na Área de Construção Civil',
    'Aprendiz Na Área de Cozinha',
    'Aprendiz Na Área de Departamento Pessoal',
    'Aprendiz Na Área de Informática',
    'Aprendiz Na Área de Logística',
    'Aprendiz Na Área de Marketing',
    'Aprendiz Na Área de Produção',
    'Aprendiz Na Área de Recepção',
    'Aprendiz Na Área de Recursos Humanos',
    'Aprendiz Na Área de Vendas',
    'Aprendiz Na Área Elétrica',
    'Aprendiz Na Área Financeira',
    'Aprendiz Na Área Jurídica',
    'Aprendiz Na Área Mecânica',
    'Aprendiz Na Área Operacional',
    'Apresentador de Eventos',
    'Apresentador de Festas Populares',
    'Apresentador, Locutor e Comentarista de Rádio e Televisão',
    'Aquarista',
    'Arbitro Esportivo',
    'Armador',
    'Armador de Cimento',
    'Armador de Pesca',
    'Armazenista',
    'Armeiro',
    'Aromista',
    'Arqueólogo',
    'Arquiteto',
    'Arquiteto Coordenador',
    'Arquiteto de Edificações',
    'Arquiteto de Informação',
    'Arquiteto de Interiores',
    'Arquiteto de Patrimônio',
    'Arquiteto de Sistemas',
    'Arquiteto de Soluções',
    'Arquiteto Especificador',
    'Arquiteto Paisagista',
    'Arquiteto Restaurador',
    'Arquiteto Urbanista',
    'Arquiteto Vendedor',
    'Arquivista',
    'Arquivólogo',
    'Arranjador',
    'Arrematador',
    'Arrendatário',
    'Arrumador',
    'Arte-Finalista',
    'Artesão',
    'Artifice',
    'Artista Aéreo (Artista de Lira, Artista de Força Capilar, Artista de Corda)',
    'Artista da Dança',
    'Artista de Circo (Circense)',
    'Artista Pintor',
    'Artista Plástico',
    'Artista Visual ',
    'Artistas de Artes Populares e Modelos',
    'Ascensorista',
    'Assessor',
    'Assessor Científico',
    'Assessor de Comunicação',
    'Assessor de Imprensa',
    'Assessor de Investimentos',
    'Assessor de Marketing',
    'Assessor de Segurança Aérea',
    'Assessor Jurídico',
    'Assessor Postal Telegráfico',
    'Assessor Técnico',
    'Assistente',
    'Assistente Administrativo',
    'Assistente Administrativo de Manutenção',
    'Assistente Comercial',
    'Assistente Contábil',
    'Assistente de Administração',
    'Assistente de Alimentos e Bebidas',
    'Assistente de Almoxarifado',
    'Assistente de Arquitetura',
    'Assistente de Arquivo',
    'Assistente de Arte',
    'Assistente de Assuntos Regulatórios',
    'Assistente de Atendimento',
    'Assistente de Atendimento Publicitário',
    'Assistente de Ativo Fixo',
    'Assistente de Auditoria',
    'Assistente de Auditoria Contábil',
    'Assistente de Back Office',
    'Assistente de Benefícios',
    'Assistente de Cadastro',
    'Assistente de Câmbio',
    'Assistente de Cargos e Salários',
    'Assistente de Cobrança',
    'Assistente de Comércio Exterior',
    'Assistente de Compliance',
    'Assistente de Compras',
    'Assistente de Comunicação',
    'Assistente de Comunicação e Marketing',
    'Assistente de Condomínio',
    'Assistente de Contas A Pagar',
    'Assistente de Contas A Receber',
    'Assistente de Contas Médicas',
    'Assistente de Conteúdo Web',
    'Assistente de Contratos',
    'Assistente de Control Desk',
    'Assistente de Controladoria',
    'Assistente de Controle de Qualidade',
    'Assistente de Controles Internos',
    'Assistente de Coreografia',
    'Assistente de Credenciamento',
    'Assistente de Crédito e Cobrança',
    'Assistente de Crédito Imobiliário',
    'Assistente de Criação',
    'Assistente de Crm',
    'Assistente de Customer Service',
    'Assistente de Custos',
    'Assistente de Departamento Pessoal',
    'Assistente de Desembaraço Aduaneiro',
    'Assistente de Design',
    'Assistente de Design Gráfico',
    'Assistente de Diretor',
    'Assistente de Diretoria',
    'Assistente de Documentação',
    'Assistente de E-Commerce',
    'Assistente de Endomarketing',
    'Assistente de Engenharia',
    'Assistente de Estilo',
    'Assistente de Estoque',
    'Assistente de Eventos',
    'Assistente de Expedição',
    'Assistente de Facilities',
    'Assistente de Faturamento',
    'Assistente de Faturamento Hospitalar',
    'Assistente de Fotógrafo',
    'Assistente de Franquias',
    'Assistente de Frota',
    'Assistente de Garantia da Qualidade',
    'Assistente de Gestão de Politicas Públicas',
    'Assistente de Governança',
    'Assistente de Implantação',
    'Assistente de Informática',
    'Assistente de Infraestrutura',
    'Assistente de Inteligência de Mercado',
    'Assistente de Inventário',
    'Assistente de Laboratório',
    'Assistente de Legalização',
    'Assistente de Licitação',
    'Assistente de Locação',
    'Assistente de Logística',
    'Assistente de Loja',
    'Assistente de Manutenção Predial',
    'Assistente de Marketing',
    'Assistente de Marketing Digital',
    'Assistente de Meio Ambiente',
    'Assistente de Merchandising',
    'Assistente de Mídia',
    'Assistente de Mídias Sociais',
    'Assistente de Modelagem',
    'Assistente de Monitoramento',
    'Assistente de Negócios',
    'Assistente de Nutrição',
    'Assistente de Obras',
    'Assistente de Orçamento',
    'Assistente de Ouvidoria',
    'Assistente de Panificação',
    'Assistente de Patrimônio',
    'Assistente de Pesquisa',
    'Assistente de Pesquisa de Mercado',
    'Assistente de Pesquisa e Desenvolvimento',
    'Assistente de Planejamento',
    'Assistente de Planejamento Financeiro',
    'Assistente de Pós-Venda',
    'Assistente de Prevenção de Perdas',
    'Assistente de Pricing',
    'Assistente de Processamento de Dados',
    'Assistente de Processos',
    'Assistente de Produção',
    'Assistente de Produção Editorial',
    'Assistente de Produto',
    'Assistente de Programação',
    'Assistente de Projetos',
    'Assistente de Publicidade e Propaganda',
    'Assistente de Rastreamento',
    'Assistente de Recebimento Fiscal',
    'Assistente de Recrutamento e Seleção',
    'Assistente de Recursos Humanos',
    'Assistente de Relacionamento',
    'Assistente de Relações Públicas',
    'Assistente de Repartição Pública',
    'Assistente de Reservas',
    'Assistente de Risco',
    'Assistente de Sac',
    'Assistente de Secretaria',
    'Assistente de Secretaria Escolar',
    'Assistente de Segurança da Informação',
    'Assistente de Segurança do Trabalho',
    'Assistente de Segurança Patrimonial',
    'Assistente de Seguros',
    'Assistente de Service Desk',
    'Assistente de Serviçoes Gerais',
    'Assistente de Sinistro',
    'Assistente de Sistemas',
    'Assistente de Supervisão',
    'Assistente de Suporte Técnico',
    'Assistente de Suprimentos',
    'Assistente de Tecnologia da Informação',
    'Assistente de Telecomunicações',
    'Assistente de Telemarketing',
    'Assistente de Televendas',
    'Assistente de Tesouraria',
    'Assistente de Testes',
    'Assistente de Trade Marketing',
    'Assistente de Transportes',
    'Assistente de Treinamento e Desenvolvimento',
    'Assistente de Vendas',
    'Assistente de Visual Merchandising',
    'Assistente de Web Design',
    'Assistente Editorial',
    'Assistente Executivo',
    'Assistente Financeiro',
    'Assistente Fiscal',
    'Assistente Industrial',
    'Assistente Jurídico',
    'Assistente Legislativo',
    'Assistente Operacional',
    'Assistente Paralegal',
    'Assistente Pedagógico',
    'Assistente Social',
    'Assistente Societário',
    'Assistente Técnico',
    'Assistente Técnico - Administrativo',
    'Assistente Técnico de Campo',
    'Assistente Técnico de Edificações',
    'Assistente Técnico de Vendas',
    'Assistente Técnico em Eletrônica',
    'Assistente Tributário',
    'Assoalhador',
    'Astrólogo',
    'Astronauta',
    'Astrônomo',
    'Atacadista',
    'Atendente',
    'Atendente Administrativo',
    'Atendente Comercial (Agência Postal) ',
    'Atendente de Assistência Técnica',
    'Atendente de Câmbio',
    'Atendente de Delivery',
    'Atendente de E-Commerce',
    'Atendente de Enfermagem',
    'Atendente de Farmácia',
    'Atendente de Help Desk',
    'Atendente de Marketing',
    'Atendente de Nutrição',
    'Atendente de Pista',
    'Atendente de Pós-Venda',
    'Atendente de Restaurante',
    'Atendente de Sac',
    'Atendente de Service Desk',
    'Atendente de Sinistro',
    'Atendente de Suporte Técnico',
    'Atendente de Turismo',
    'Atendente Infantil',
    'Atendimento Publicitário',
    'Atividade Remunerada Pela Prática de Esporte Profissional Ou Amador',
    'Atividades Técnicas de Complexidade Intelectual',
    'Atividades Técnicas de Suporte',
    'Atleta Profissional',
    'Atleta, Desportista (Amador e Profissional)',
    'Ator',
    'Atriz',
    'Atuario',
    'Auditor',
    'Auditor Contábil',
    'Auditor da Receita Federal',
    'Auditor de Contas Médicas',
    'Auditor de Estoque',
    'Auditor de Loja',
    'Auditor de Processos',
    'Auditor de Qualidade',
    'Auditor de Sistemas',
    'Auditor Externo',
    'Auditor Financeiro',
    'Auditor Fiscal da Previdência Social',
    'Auditor Fiscal da Receita Federal',
    'Auditor Fiscal do Trabalho',
    'Auditor Fiscal Tributário',
    'Auditor Interno',
    'Auditor Municipal de Controle Interno',
    'Auditor Nacional de Controle',
    'Autor',
    'Auxiliar Acadêmico',
    'Auxiliar Administrativo',
    'Auxiliar Agrícola',
    'Auxiliar Comercial',
    'Auxiliar Contábil',
    'Auxiliar de Acabamento Gráfico',
    'Auxiliar de Açougue',
    'Auxiliar de Administração',
    'Auxiliar de Agendamento',
    'Auxiliar de Almoxarifado',
    'Auxiliar de Áreas Gráficas',
    'Auxiliar de Arquitetura',
    'Auxiliar de Arquivo',
    'Auxiliar de Assistência Técnica',
    'Auxiliar de Atendimento',
    'Auxiliar de Atendimento',
    'Auxiliar de Auditoria',
    'Auxiliar de Back Office',
    'Auxiliar de Banco de Dados',
    'Auxiliar de Banco de Sangue',
    'Auxiliar de Barman',
    'Auxiliar de Benefícios',
    'Auxiliar de Biblioteca',
    'Auxiliar de Biotério',
    'Auxiliar de Bordado',
    'Auxiliar de Borracharia',
    'Auxiliar de Cabeleireiro',
    'Auxiliar de Cadastro',
    'Auxiliar de Campo',
    'Auxiliar de Carga e Descarga',
    'Auxiliar de Cartório',
    'Auxiliar de Cirurgia',
    'Auxiliar de Classe',
    'Auxiliar de Cobrança',
    'Auxiliar de Coleta',
    'Auxiliar de Comércio',
    'Auxiliar de Comércio Exterior',
    'Auxiliar de Compras',
    'Auxiliar de Confeitaria',
    'Auxiliar de Contabilidade',
    'Auxiliar de Contas A Pagar',
    'Auxiliar de Contas A Receber',
    'Auxiliar de Contas Médicas',
    'Auxiliar de Contratos',
    'Auxiliar de Controle de Qualidade',
    'Auxiliar de Corte',
    'Auxiliar de Costura',
    'Auxiliar de Cozinha',
    'Auxiliar de Creche',
    'Auxiliar de Crédito',
    'Auxiliar de Crédito e Cobrança',
    'Auxiliar de Criação',
    'Auxiliar de Departamento Pessoal',
    'Auxiliar de Depósito',
    'Auxiliar de Desenhista',
    'Auxiliar de Desenvolvimento de Produto',
    'Auxiliar de Desenvolvimento Infantil ',
    'Auxiliar de Designer Gráfico',
    'Auxiliar de Despachante',
    'Auxiliar de Disciplina',
    'Auxiliar de E-Commerce',
    'Auxiliar de Educação',
    'Auxiliar de Eletricista',
    'Auxiliar de Enfermagem',
    'Auxiliar de Enfermagem do Trabalho',
    'Auxiliar de Engenharia',
    'Auxiliar de Engenharia Civil',
    'Auxiliar de Ensino',
    'Auxiliar de Escritório',
    'Auxiliar de Estética',
    'Auxiliar de Estilo',
    'Auxiliar de Estoque',
    'Auxiliar de Expedição',
    'Auxiliar de Expediente',
    'Auxiliar de Extrusão',
    'Auxiliar de Fabrica',
    'Auxiliar de Farmácia',
    'Auxiliar de Faturamento',
    'Auxiliar de Faturamento Hospitalar',
    'Auxiliar de Fiscalização Financeira',
    'Auxiliar de Folha de Pagamento',
    'Auxiliar de Fotografia',
    'Auxiliar de Frota',
    'Auxiliar de Garantia',
    'Auxiliar de Governança',
    'Auxiliar de Impressão',
    'Auxiliar de Informática',
    'Auxiliar de Infraestrutura',
    'Auxiliar de Instalação',
    'Auxiliar de Inventário',
    'Auxiliar de Jardinagem',
    'Auxiliar de Laboratório',
    'Auxiliar de Laboratório da Saúde',
    'Auxiliar de Lavanderia',
    'Auxiliar de Legalização',
    'Auxiliar de Limpeza',
    'Auxiliar de Limpeza Urbana',
    'Auxiliar de Locação',
    'Auxiliar de Logística',
    'Auxiliar de Loja',
    'Auxiliar de Manipulação',
    'Auxiliar de Manutenção',
    'Auxiliar de Manutenção Elétrica',
    'Auxiliar de Manutenção Hidráulica',
    'Auxiliar de Manutenção Industrial',
    'Auxiliar de Manutenção Mecânica',
    'Auxiliar de Manutenção Predial',
    'Auxiliar de Marketing',
    'Auxiliar de Mecânico',
    'Auxiliar de Merchandising',
    'Auxiliar de Meteorologista',
    'Auxiliar de Mídia',
    'Auxiliar de Modelagem',
    'Auxiliar de Modelista',
    'Auxiliar de Monitoramento',
    'Auxiliar de Montagem',
    'Auxiliar de Obras e Serviços',
    'Auxiliar de Oftalmologia',
    'Auxiliar de Orçamento',
    'Auxiliar de Ourives',
    'Auxiliar de Padeiro',
    'Auxiliar de Pátio',
    'Auxiliar de Patrimônio',
    'Auxiliar de Pedreiro',
    'Auxiliar de Perecíveis',
    'Auxiliar de Pesagem',
    'Auxiliar de Pesquisa de Mercado',
    'Auxiliar de Pintura',
    'Auxiliar de Pós-Venda',
    'Auxiliar de Processos',
    'Auxiliar de Produção',
    'Auxiliar de Professor',
    'Auxiliar de Propagandista',
    'Auxiliar de Prótese Dentária',
    'Auxiliar de Publicidade e Propaganda',
    'Auxiliar de Qualidade',
    'Auxiliar de Radiologia',
    'Auxiliar de Rastreamento',
    'Auxiliar de Recebimento',
    'Auxiliar de Recebimento Fiscal',
    'Auxiliar de Recreação',
    'Auxiliar de Recrutamento e Seleção',
    'Auxiliar de Recursos Humanos',
    'Auxiliar de Refrigeração',
    'Auxiliar de Reposição',
    'Auxiliar de Reprografia',
    'Auxiliar de Restaurante',
    'Auxiliar de Sala',
    'Auxiliar de Sanitarista',
    'Auxiliar de Saude',
    'Auxiliar de Saúde Bucal',
    'Auxiliar de Saúde Na Navegação Marítima ',
    'Auxiliar de Secretaria Escolar',
    'Auxiliar de Segurança',
    'Auxiliar de Segurança Patrimonial',
    'Auxiliar de Seguros',
    'Auxiliar de Serviços de Documentação, Informação e Pesquisa',
    'Auxiliar de Serviços Gerais',
    'Auxiliar de Serviços Jurídicos',
    'Auxiliar de Sinistro',
    'Auxiliar de Soldador',
    'Auxiliar de Sondagem',
    'Auxiliar de Supervisão',
    'Auxiliar de Suporte Técnico',
    'Auxiliar de Suprimentos',
    'Auxiliar de Sushiman',
    'Auxiliar de Tecelão',
    'Auxiliar de Tecnologia da Informação',
    'Auxiliar de Televendas',
    'Auxiliar de Tesouraria',
    'Auxiliar de Topografia',
    'Auxiliar de Topógrafo',
    'Auxiliar de Trade Marketing',
    'Auxiliar de Trafego',
    'Auxiliar de Trafego Telegrafico',
    'Auxiliar de Transportes',
    'Auxiliar de Treinamento e Desenvolvimento',
    'Auxiliar de Triagem',
    'Auxiliar de Usinagem',
    'Auxiliar de Vendas',
    'Auxiliar de Veterinário',
    'Auxiliar Editorial',
    'Auxiliar Eletrotécnico',
    'Auxiliar Financeiro',
    'Auxiliar Fiscal',
    'Auxiliar Industrial',
    'Auxiliar Institucional',
    'Auxiliar Legislativo',
    'Auxiliar Operacional',
    'Auxiliar Pedagógico',
    'Auxiliar Portuário',
    'Auxiliar Projetista',
    'Auxiliar Químico',
    'Auxiliar Técnico',
    'Auxiliar Técnico de Mecânica',
    'Auxiliar Técnico Eletrônico',
    'Auxiliar Técnico em Edificações',
    'Auxiliar Técnico em Mecatrônica',
    'Auxiliar Técnico em Segurança do Trabalho',
    'Auxiliar Tributário',
    'Avaliador Comercial',
    'Avaliador de Imóveis',
    'Avaliador de Veículos',
    'Avaliador Físico',
    'Avicultor',
    'Azulejador',
    'Azulejista',
    'Baba',
    'Back Office',
    'Back Office de Vendas',
    'Bailarino',
    'Balanceador de Motores',
    'Balanceiro',
    'Balconista',
    'Bamburista',
    'Bancário',
    'Banqueiro',
    'Barbeiro',
    'Barista',
    'Barman',
    'Barqueiro',
    'Beneficiador de Minerais',
    'Beneficiador de Minerais, Minerador e Assemelhados',
    'Berçarista',
    'Bibliotecario',
    'Bicicleteiro',
    'Bilheteiro',
    'Biologista',
    'Biólogo',
    'Biomédico',
    'Bioquimico',
    'Biotecnólogo',
    'Blogueiro',
    'Bloquista',
    'Bóia Fria',
    'Boiadeiro',
    'Boiadeiro (Comércio de Gado) - Empregador',
    'Bolsista',
    'Bombeiro',
    'Bombeiro Civil',
    'Bombeiro de Gasolina',
    'Bombeiro e Salva-Vidas - Exceto Militar',
    'Bombeiro e Salva-Vidas - Militar',
    'Bombeiro Eletricista',
    'Bombeiro Encanador',
    'Bombeiro Hidráulico',
    'Bombeiro Industrial',
    'Booker',
    'Bordador',
    'Borracheiro',
    'Botânico',
    'Boxeador',
    'Brigadeiro - Exército, Marinha e Aeronáutica',
    'Britador',
    'Broqueiro',
    'Buerista Na Conservação de Vias Permanentes',
    'Business Partner',
    'Cabeleireiro',
    'Cabista',
    'Cabo - Exército, Marinha e Aeronáutica',
    'Caçador',
    'Cadete - Exército, Marinha e Aeronáutica',
    'Caixa',
    'Caixeiro',
    'Calafate',
    'Calceteiro',
    'Caldeireiro',
    'Calheiro',
    'Camareira',
    'Camareira de Embarcações',
    'Cambista',
    'Camelo',
    'Cameraman',
    'Caminhoneiro',
    'Camiseiro',
    'Campeiro',
    'Canoeiro',
    'Cantor',
    'Capataz',
    'Capitão - Exército, Marinha e Aeronáutica',
    'Capitão de Manobra da Marinha Mercante',
    'Capoteiro',
    'Captador de Imagem em Movimento',
    'Caracterizador',
    'Carbonizador',
    'Carcereiro',
    'Caricaturista',
    'Carpinteiro',
    'Carregador',
    'Carregador de Caminhão',
    'Carregador de Malas Postais (Exceto Motorizado)',
    'Carreteiro',
    'Carroceiro',
    'Cartazeiro  - Exceto Colocador',
    'Cartazista',
    'Carteiro (Exceto Motorizado)',
    'Carteiro e Operador de Triagem de Serviços Postais - Exceto Carteiro Motorizado Com Veículos de Duas Rodas',
    'Carteiro Motorizado Com Veículo de Duas Rodas',
    'Cartógrafo',
    'Cartomante',
    'Cartonageiro A Mão ',
    'Cartorário',
    'Cartunista',
    'Carvoeiro',
    'Carvoejador',
    'Caseiro',
    'Castrador',
    'Catador de Caranguejos, Mariscos e Siris ',
    'Catador de Material Reciclável ',
    'Catador de Papel',
    'Cenarista',
    'Cenografista',
    'Cenógrafo',
    'Cenotécnico',
    'Ceramista',
    'Ceramistas - Preparação e Fabricação ',
    'Cerealista',
    'Cerimonialista',
    'Cervejeiro',
    'Chacareiro',
    'Chapeador',
    'Chapeiro',
    'Chapeleiro',
    'Chargista',
    'Charreteiro',
    'Chaveiro',
    'Chefe de Bar',
    'Chefe de Compras',
    'Chefe de Confeitaria',
    'Chefe de Costura',
    'Chefe de Cozinha',
    'Chefe de Departamento Pessoal',
    'Chefe de Expedição',
    'Chefe de Fila',
    'Chefe de Plantão Médico',
    'Chefe de Produção',
    'Chefe de Recepção',
    'Chefe de Trem',
    'Churrasqueiro',
    'Ciclista',
    'Cientista',
    'Cigarreiro, Charuteiro e Beneficiador de Fumo ',
    'Cilindrista',
    'Cineasta',
    'Cinegrafista',
    'Cinetecnico',
    'Cisterneiro',
    'Classificador de Grãos',
    'Classificador e Empilhador de Tijolos Refratários',
    'Clicherista',
    'Co Piloto de Aeronaves Não Regulares',
    'Co Piloto de Linha Aérea Regular',
    'Coach',
    'Cobrador de Transporte Coletivo - Exceto Trem',
    'Cobrador em Transporte Coletivo',
    'Cobrador Fiscal',
    'Codificador de Dados',
    'Colchoeiro',
    'Coletador de Apostas e de Jogos',
    'Coletor',
    'Coletor de Amostras',
    'Coletor de Lixo',
    'Coletor de Lixo Domiciliar ',
    'Coletor de Resíduos Sólidos de Serviços de Saúde',
    'Colocador de Andaimes',
    'Colocador de Luminosos e Letreiros',
    'Colorista de Papel',
    'Colorista Têxtil',
    'Comandante da Marinha',
    'Comandante da Marinha Mercante',
    'Comandante de Embarcações e Oficiais de Máquinas da Marinha',
    'Comandante de Embarcações e Oficiais de Máquinas da Marinha Mercante',
    'Comandante de Navio da Marinha Mercante',
    'Comandante de Vôo',
    'Comentarista (Radio e Tv)',
    'Comentarista de Rodeio',
    'Comerciante Atacadista',
    'Comerciante de Obras de Arte',
    'Comerciante Varejista ',
    'Comerciário',
    'Comissário de Avarias',
    'Comissário de Bordo',
    'Comissário de Policia',
    'Comissário de Trem',
    'Comissário de Voo',
    'Compositor',
    'Comprador',
    'Comprador (Arrematador No Comércio, Supervisor de Compras)',
    'Comprador de Medicamentos',
    'Comprador Internacional',
    'Comprador Técnico',
    'Comunicador Social',
    'Concierge',
    'Conciliador',
    'Condutor',
    'Condutor de Ambulância',
    'Condutor de Animais e de Veículos de Tração Animal',
    'Condutor de Gado',
    'Condutor de Malas',
    'Condutor de Máquinas (Bombeador)',
    'Condutor de Máquinas (Mecânico)',
    'Condutor de Processos Robotizados ',
    'Condutor de Robôs',
    'Condutor de Turismo de Aventura',
    'Condutor de Turismo de Pesca',
    'Condutor de Veículos A Pedais',
    'Condutor e Operador de Robôs',
    'Condutor e Operador de Veículos e Equipamentos de Movimentação de Carga',
    'Condutor Maquinista',
    'Confeccionador de Artefatos de Madeira, Móveis de Vime e Afins Exceto Marceneiro',
    'Confeccionador de Instrumentos Musicais',
    'Confeiteiro',
    'Conferente',
    'Conferente de Cais do Porto',
    'Conferente de Carga e Descarga',
    'Conferente de Tesouraria',
    'Conferente Líder',
    'Conferente Portuário',
    'Conselheiro Tutelar',
    'Conservador de Via Permanente (Trilhos)',
    'Conservador-Restaurador de Bens Culturais',
    'Cônsul',
    'Consultor',
    'Consultor Abap',
    'Consultor Ambiental',
    'Consultor Contábil',
    'Consultor Crm',
    'Consultor de Atendimento',
    'Consultor de Beleza',
    'Consultor de Benefícios',
    'Consultor de Comércio Exterior',
    'Consultor de Comunicação',
    'Consultor de Controladoria',
    'Consultor de Crédito Imobiliário',
    'Consultor de Estética',
    'Consultor de Eventos',
    'Consultor de Franquias',
    'Consultor de Imigração',
    'Consultor de Implantação',
    'Consultor de Informática',
    'Consultor de Infraestrutura',
    'Consultor de Inteligência de Mercado',
    'Consultor de Intercâmbio',
    'Consultor de Investimentos',
    'Consultor de Logística',
    'Consultor de Loja',
    'Consultor de Marketing',
    'Consultor de Merchandising',
    'Consultor de Moda',
    'Consultor de Negócios',
    'Consultor de Operações',
    'Consultor de Planejamento Estratégico',
    'Consultor de Pós-Venda',
    'Consultor de Processos',
    'Consultor de Projetos',
    'Consultor de Qualidade',
    'Consultor de Recrutamento e Seleção',
    'Consultor de Recursos Humanos',
    'Consultor de Redes',
    'Consultor de Relacionamento',
    'Consultor de Responsabilidade Social',
    'Consultor de Segurança da Informação',
    'Consultor de Seguros',
    'Consultor de Sistemas',
    'Consultor de Tecnologia da Informação',
    'Consultor de Trade Marketing',
    'Consultor de Treinamento e Desenvolvimento',
    'Consultor de Vendas',
    'Consultor Educacional',
    'Consultor Empresarial',
    'Consultor Erp',
    'Consultor Financeiro',
    'Consultor Fiscal',
    'Consultor Funcional',
    'Consultor Gastronômico',
    'Consultor Juridico',
    'Consultor Mastersaf',
    'Consultor Pedagógico',
    'Consultor Técnico',
    'Consultor Técnico de Veículos',
    'Consultor Técnico Industrial',
    'Consultor Tributário',
    'Contabilista',
    'Contador',
    'Contador Financeiro',
    'Contador Gerencial',
    'Contínuo (Contínuo, Office Girl, Office Boy, Estafeta, Mensageiro)',
    'Contorcionista',
    'Contra Almirante - Marinha',
    'Contra Almirante - Marinha Mercante',
    'Contra-Mestre de Embarcação - Marinha',
    'Contra-Mestre de Embarcação - Marinha Mercante',
    'Contra-Regra',
    'Controlador de Custos',
    'Controlador de Pátio',
    'Controlador de Pragas',
    'Controlador de Produção',
    'Controlador de Qualidade',
    'Controlador de Tráfego Aéreo',
    'Controlador Interno',
    'Controller',
    'Coordenador Administrativo',
    'Coordenador Administrativo de Obras',
    'Coordenador Comercial',
    'Coordenador Contábil',
    'Coordenador de Alimentos e Bebidas',
    'Coordenador de Almoxarifado',
    'Coordenador de Armazém',
    'Coordenador de Assistência Técnica',
    'Coordenador de Assuntos Regulatórios',
    'Coordenador de Atendimento Ao Cliente',
    'Coordenador de Auditoria',
    'Coordenador de Benefícios',
    'Coordenador de Biblioteca',
    'Coordenador de Business Intelligence',
    'Coordenador de Campo',
    'Coordenador de Cargos e Salários',
    'Coordenador de Cobrança',
    'Coordenador de Comércio Exterior',
    'Coordenador de Compras',
    'Coordenador de Comunicação',
    'Coordenador de Contas',
    'Coordenador de Contas A Pagar',
    'Coordenador de Contas A Receber',
    'Coordenador de Contas Médicas',
    'Coordenador de Contratos',
    'Coordenador de Controladoria',
    'Coordenador de Controles Internos',
    'Coordenador de Credenciamento',
    'Coordenador de Crédito',
    'Coordenador de Crédito e Cobrança',
    'Coordenador de Crédito Imobiliário',
    'Coordenador de Crm',
    'Coordenador de Customer Service',
    'Coordenador de Custos',
    'Coordenador de Departamento Pessoal',
    'Coordenador de Desenvolvimento de Sistemas',
    'Coordenador de E-Commerce',
    'Coordenador de Enfermagem',
    'Coordenador de Engenharia',
    'Coordenador de Estilo',
    'Coordenador de Estoque',
    'Coordenador de Eventos',
    'Coordenador de Expedição',
    'Coordenador de Facilities',
    'Coordenador de Faturamento',
    'Coordenador de Ferramentaria',
    'Coordenador de Filial',
    'Coordenador de Folha de Pagamento',
    'Coordenador de Garantia da Qualidade',
    'Coordenador de Help Desk',
    'Coordenador de Hotelaria',
    'Coordenador de Implantação',
    'Coordenador de Incorporação',
    'Coordenador de Infraestrutura',
    'Coordenador de Instalações',
    'Coordenador de Inteligência de Mercado',
    'Coordenador de Inventário',
    'Coordenador de Investimentos',
    'Coordenador de Laboratório',
    'Coordenador de Legalização',
    'Coordenador de Licitação',
    'Coordenador de Limpeza',
    'Coordenador de Logística',
    'Coordenador de Loja',
    'Coordenador de Manutenção Automotiva',
    'Coordenador de Manutenção Industrial',
    'Coordenador de Manutenção Predial',
    'Coordenador de Marketing',
    'Coordenador de Marketing Digital',
    'Coordenador de Meio Ambiente',
    'Coordenador de Melhoria Contínua',
    'Coordenador de Merchandising',
    'Coordenador de Mídia',
    'Coordenador de Mídias Sociais',
    'Coordenador de Negócios',
    'Coordenador de Obras',
    'Coordenador de Operações de Combate À Poluição No Meio Aquaviário',
    'Coordenador de Orçamento',
    'Coordenador de Patrimônio',
    'Coordenador de Pcp',
    'Coordenador de Pesquisa de Mercado',
    'Coordenador de Pesquisa e Desenvolvimento',
    'Coordenador de Planejamento',
    'Coordenador de Pós-Venda',
    'Coordenador de Prevenção de Perdas',
    'Coordenador de Pricing',
    'Coordenador de Processos',
    'Coordenador de Produção',
    'Coordenador de Produto',
    'Coordenador de Projeto Social',
    'Coordenador de Projetos',
    'Coordenador de Projetos de Construção Civil',
    'Coordenador de Qualidade',
    'Coordenador de Recepção',
    'Coordenador de Recrutamento e Seleção',
    'Coordenador de Recursos Humanos',
    'Coordenador de Redes',
    'Coordenador de Reservas',
    'Coordenador de Responsabilidade Social',
    'Coordenador de Restaurante',
    'Coordenador de Saúde, Segurança e Meio Ambiente',
    'Coordenador de Segurança da Informação',
    'Coordenador de Segurança do Trabalho',
    'Coordenador de Segurança Patrimonial',
    'Coordenador de Seguros',
    'Coordenador de Service Desk',
    'Coordenador de Serviços Gerais',
    'Coordenador de Sinistro',
    'Coordenador de Suporte Técnico',
    'Coordenador de Suprimentos',
    'Coordenador de Sustentabilidade',
    'Coordenador de Tecnologia da Informação',
    'Coordenador de Telecomunicações',
    'Coordenador de Tesouraria',
    'Coordenador de Topografia',
    'Coordenador de Trade Marketing',
    'Coordenador de Transportes',
    'Coordenador de Treinamento e Desenvolvimento',
    'Coordenador de Usinagem',
    'Coordenador de Vendas',
    'Coordenador de Visual Merchandising',
    'Coordenador de Vôo',
    'Coordenador Editorial',
    'Coordenador Eletrônico',
    'Coordenador Financeiro',
    'Coordenador Fiscal',
    'Coordenador Imobiliário',
    'Coordenador Industrial',
    'Coordenador Juridico',
    'Coordenador Jurídico',
    'Coordenador Médico',
    'Coordenador Operacional',
    'Coordenador Pedagógico',
    'Coordenador Tributário',
    'Copeiro',
    'Copiador de Chapa',
    'Copiador de Chiches Tipograficos',
    'Coreógrafo',
    'Coronel - Exército, Marinha e Aeronáutica',
    'Correspondente de Guerra',
    'Corretor',
    'Corretor de Café',
    'Corretor de Imóveis',
    'Corretor de Seguros',
    'Corretor de Valores, Ativos Financeiros, Mercadorias e Derivativos',
    'Cortador (Vidros, Cristais, Pedras, Couro, Madeiras, Etc)',
    'Cortador de Cana',
    'Cortador de Tecidos',
    'Cortador Ou Derrubador de Mato E/Ou Árvores',
    'Cosmoanalista',
    'Costureira',
    'Coveiro',
    'Cozinheiro',
    'Crepeiro',
    'Criador de Animais',
    'Criador de Animais Domésticos',
    'Criador de Camarões ',
    'Criador de Gado',
    'Criador de Jacarés ',
    'Criador de Mexilhões ',
    'Criador de Ostras ',
    'Criador de Peixes ',
    'Criador de Quelônios (Criador de Tartarugas, Quelonicultur)',
    'Criador de Rãs ',
    'Cristalomante',
    'Crítico',
    'Cronista',
    'Cronoanalista',
    'Cuidador de Animais Domésticos',
    'Cuidador de Crianças, Jovens, Adultos e Idosos',
    'Cuidador de Idoso',
    'Cumim',
    'Curador de Arte',
    'Curvador de Tubos de Vidro',
    'Dançarino',
    'Datilografo',
    'Datiloscopista',
    'Decorador',
    'Decorador de Interiores',
    'Dedetizador',
    'Defensor Público',
    'Defensor Público da União',
    'Defensor Público Estadual',
    'Defensor Público Federal',
    'Defensor Público Substituto',
    'Degustador',
    'Delegado da Polícia Civil',
    'Delegado da Polícia Federal',
    'Delegado da Polícia Militar',
    'Delegado de Policia',
    'Demolidor de Edificações',
    'Dentista',
    'Dentista Auditor',
    'Dentista Endodontista',
    'Dentista Estomatologista',
    'Dentista Implantodontista',
    'Dentista Odontopediatra',
    'Dentista Ortodontista',
    'Dentista Periodontista',
    'Dentista Protesista',
    'Dentista Traumatologista',
    'Depiladora',
    'Deputado Estadual',
    'Deputado Federal',
    'Deputado Municipal',
    'Dermoconsultor',
    'Descarnador de Couros e Peles',
    'Desembargador',
    'Desempregado',
    'Desenhista',
    'Desenhista Cadista',
    'Desenhista Copista',
    'Desenhista Elétrico',
    'Desenhista Industrial',
    'Desenhista Mecânico',
    'Desenhista Orçamentista',
    'Desenhista Projetista',
    'Desenhista Projetista Civil',
    'Desenhista Projetista da Eletrônica',
    'Desenhista Projetista da Mecânica',
    'Desenhista Projetista de Construção Civil e Arquitetura',
    'Desenhista Projetista Elétrico',
    'Desenhista Projetista Hidráulico',
    'Desenhista Projetista Mecânico',
    'Desenhista Projetistas e Modelista de Produtos e Serviços ',
    'Desenhista Técnico',
    'Desenhista Técnico da Construção Civil e Arquitetura ',
    'Desenhista Técnico da Mecânica ',
    'Desenhista Técnico de Produto e Serviços ',
    'Desenhista Técnico e Modelista',
    'Desenhista Técnico em Eletricidade, Eletrônica, Eletromecânica, Calefação, Ventilação e Refrigeração',
    'Desenhistas Industrial e Conservador',
    'Desentupidor',
    'Desenvolvedor de Software',
    'Designer',
    'Designer de Acessórios',
    'Designer de Calçados',
    'Designer de Embalagem',
    'Designer de Interação',
    'Designer de Interface',
    'Designer de Interiores',
    'Designer de Interiores, de Vitrines e Visual Merchandiser',
    'Designer de Jogos',
    'Designer de Joias',
    'Designer de Moda',
    'Designer de Produto',
    'Designer de Sobrancelha',
    'Designer Digital',
    'Designer Educacional',
    'Designer Gráfico',
    'Designer Instrucional',
    'Designer Multimídia',
    'Designer Têxtil',
    'Despachante',
    'Despachante Aduaneiro',
    'Despachante de Transportes Coletivos - Exceto Trem ',
    'Despachante do Detran',
    'Despachante Documentalista - Exceto Despachante Policial',
    'Despachante Imobiliário',
    'Despachante Operacional de Voo',
    'Despachante Policial',
    'Desportista',
    'Destroçador de Pedra ',
    'Detetive',
    'Detetive Particular ',
    'Diagramador',
    'Diarista',
    'Digitador',
    'Digitador de Laudos',
    'Digitalizador',
    'Diligenciador',
    'Diplomata e Afins',
    'Diretor',
    'Diretor Administrativo',
    'Diretor Agrícola',
    'Diretor Comercial',
    'Diretor de Arte',
    'Diretor de Cinema',
    'Diretor de Colégio',
    'Diretor de Compras',
    'Diretor de Correios',
    'Diretor de Criação',
    'Diretor de Departamento de Esportes',
    'Diretor de Empresa',
    'Diretor de Empresa e Organizações',
    'Diretor de Escola',
    'Diretor de Espetáculos',
    'Diretor de Estabelecimento de Ensino Ou de Outras Organizações',
    'Diretor de Finanças',
    'Diretor de Fotografia',
    'Diretor de Gestão Portuária',
    'Diretor de Hospital',
    'Diretor de Hotel',
    'Diretor de Instituição de Serviços Educacionais',
    'Diretor de Logística',
    'Diretor de Loja',
    'Diretor de Marketing',
    'Diretor de Negócios',
    'Diretor de Obras',
    'Diretor de Operações',
    'Diretor de Operações de Correios',
    'Diretor de Operações de Obras Pública e Civil',
    'Diretor de Operações em Empresa de Serviços de Saúde',
    'Diretor de Operações em Empresa de Serviços Pessoais, Sociais e Culturais',
    'Diretor de Organismo Não-Governamental',
    'Diretor de Organização Não Governamental',
    'Diretor de Organização Social de Interesse Público',
    'Diretor de Recursos Humanos',
    'Diretor de Teatro',
    'Diretor de Tecnologia da Informação',
    'Diretor de Transporte',
    'Diretor de Vendas',
    'Diretor Financeiro',
    'Diretor Geral',
    'Diretor Hospitalar',
    'Diretor Industrial',
    'Diretor Jurídico',
    'Diretor Pecuário',
    'Diretor Pedagógico',
    'Dirigente da Administração Pública',
    'Dirigente de Organização Patronal',
    'Dirigente de Povos Indígenas, de Quilombolas e Caiçaras ',
    'Dirigente de Sindicato',
    'Dirigente de Sindicato (Inclusive Patronal)',
    'Dirigente do Serviço Público Estadual e Distrital',
    'Dirigente do Serviço Público Federal ',
    'Dirigente do Serviço Público Municipal ',
    'Dirigente e Administrador de Entidade Religiosa ',
    'Dirigente e Administrador de Organizações da Sociedade Civil Sem Fins Lucrativos',
    'Dirigente e Líder de Partido Político',
    'Dirigente Ou Adm de Partido Politico',
    'Distribuidor de Fumo',
    'Distribuidor de Jornal',
    'Distribuidor de Produtos Químicos',
    'Divulgador',
    'Dj',
    'Do Lar',
    'Doceiro',
    'Docente',
    'Documentador',
    'Documentalista',
    'Domador de Animais (Circense)',
    'Doméstica',
    'Dublê',
    'Ecólogo',
    'Economiário',
    'Economista',
    'Economista Doméstico',
    'Editor',
    'Editor de  Livros',
    'Editor de Imagens',
    'Editor de Moda',
    'Editor de Texto',
    'Editor de Vídeo',
    'Educador',
    'Educador Ambiental',
    'Educador Físico',
    'Educador Social',
    'Eletricista',
    'Eletricista (Baixa Tensão)',
    'Eletricista de Ar Condicionado',
    'Eletricista de Empilhadeira',
    'Eletricista de Força e Controle',
    'Eletricista de Instalações',
    'Eletricista de Instalações (Aeronaves)',
    'Eletricista de Instalações (Embarcações)',
    'Eletricista de Instalações (Veículos Automotores e Máquinas Operatrizes) - Exceto Aeronaves e Embarcações',
    'Eletricista de Manutenção Eletroeletrônica (Eletricista)',
    'Eletricista de Veículos',
    'Eletricista Eletrônico',
    'Eletricista Industrial',
    'Eletricista Montador',
    'Eletricista Montador de Painel',
    'Eletricista Predial',
    'Eletricitário',
    'Eletromecânico',
    'Eletromecânico de Manutenção',
    'Eletrotecnico',
    'Eletrotécnico Na Fabricação, Montagem e Instalação de Máquinas e Equipamentos',
    'Embaixador',
    'Embalador',
    'Embalsamador',
    'Embriologista',
    'Emissor de Passagens ',
    'Empacotador',
    'Empapelador',
    'Empilhador',
    'Empregada Doméstica',
    'Empreiteiro',
    'Empreiteiro de Obras',
    'Empresário (Descrever A Ocupação)',
    'Empresário Construção Civil',
    'Empresário e Produtor de Espetáculos',
    'Encadernador',
    'Encaixotador',
    'Encanador',
    'Encanador de Agua e Esgoto',
    'Encanador e Instalador de Tubulações',
    'Encanador Industrial',
    'Encapsulador',
    'Encarregado Administrativo',
    'Encarregado Administrativo de Obras',
    'Encarregado Comercial',
    'Encarregado Contábil',
    'Encarregado de Açougue',
    'Encarregado de Almoxarifado',
    'Encarregado de Assistência Técnica',
    'Encarregado de Caldeiraria',
    'Encarregado de Carpintaria',
    'Encarregado de Cobrança',
    'Encarregado de Compras',
    'Encarregado de Elétrica',
    'Encarregado de Estacionamento',
    'Encarregado de Estamparia',
    'Encarregado de Estoque',
    'Encarregado de Expedição',
    'Encarregado de Faturamento',
    'Encarregado de Frota',
    'Encarregado de Hidráulica',
    'Encarregado de Injeção Plástica',
    'Encarregado de Instalações',
    'Encarregado de Jardinagem',
    'Encarregado de Limpeza',
    'Encarregado de Logística',
    'Encarregado de Loja',
    'Encarregado de Manutenção',
    'Encarregado de Manutenção Mecânica de Sistemas Operacionais',
    'Encarregado de Manutenção Predial',
    'Encarregado de Marcenaria',
    'Encarregado de Mecânica',
    'Encarregado de Montagem',
    'Encarregado de Obras',
    'Encarregado de Oficina',
    'Encarregado de Operações',
    'Encarregado de Padaria',
    'Encarregado de Pátio',
    'Encarregado de Peixaria',
    'Encarregado de Perecíveis',
    'Encarregado de Prevenção de Perdas',
    'Encarregado de Produção',
    'Encarregado de Serralheria',
    'Encarregado de Serviços Gerais',
    'Encarregado de Solda',
    'Encarregado de Sondagem',
    'Encarregado de Transportes',
    'Encarregado de Usinagem',
    'Encarregado Financeiro',
    'Encarregado Industrial',
    'Encarregado Operacional',
    'Enfermeiro',
    'Enfermeiro',
    'Enfermeiro Auditor',
    'Enfermeiro Chefe',
    'Enfermeiro de Centro Cirúrgico',
    'Enfermeiro de Educação Continuada',
    'Enfermeiro de Uti',
    'Enfermeiro do Trabalho',
    'Enfermeiro Estomaterapeuta',
    'Enfermeiro Nefrologista',
    'Enfermeiro Obstetra',
    'Enfermeiro Psiquiátrico',
    'Enfermeiro Responsável Técnico',
    'Enfermeiro Visitador',
    'Enfestador',
    'Engarrafador',
    'Engenheiro',
    'Engenheiro Aeronáutico',
    'Engenheiro Agrícola',
    'Engenheiro Agrimensor',
    'Engenheiro Agrônomo',
    'Engenheiro Agrossilvipecuários',
    'Engenheiro Ambiental',
    'Engenheiro Arquiteto',
    'Engenheiro Automobilístico',
    'Engenheiro Biomédico',
    'Engenheiro Calculista',
    'Engenheiro Cartógrafo',
    'Engenheiro Civil',
    'Engenheiro Clínico',
    'Engenheiro da Qualidade',
    'Engenheiro de Alimentos',
    'Engenheiro de Aplicação',
    'Engenheiro de Ar Condicionado',
    'Engenheiro de Assistência Técnica',
    'Engenheiro de Automação Industrial',
    'Engenheiro de Campo',
    'Engenheiro de Compras',
    'Engenheiro de Computação',
    'Engenheiro de Controle e Automação',
    'Engenheiro de Custos',
    'Engenheiro de Instalações',
    'Engenheiro de Instrumentação',
    'Engenheiro de Manufatura',
    'Engenheiro de Manutenção',
    'Engenheiro de Manutenção Predial',
    'Engenheiro de Materiais',
    'Engenheiro de Minas',
    'Engenheiro de Pesca',
    'Engenheiro de Petróleo',
    'Engenheiro de Planejamento',
    'Engenheiro de Processos',
    'Engenheiro de Produção',
    'Engenheiro de Produção, Qualidade, Segurança e Afins',
    'Engenheiro de Produto',
    'Engenheiro de Projetos',
    'Engenheiro de Propostas',
    'Engenheiro de Segurança do Trabalho',
    'Engenheiro de Serviços',
    'Engenheiro de Sistemas',
    'Engenheiro de Software',
    'Engenheiro de Suprimentos',
    'Engenheiro de Telecomunicações',
    'Engenheiro de Testes',
    'Engenheiro de Vendas',
    'Engenheiro de Vôo',
    'Engenheiro Eletricista',
    'Engenheiro Eletricista, Eletrônico e Afins',
    'Engenheiro Eletroeletrônico',
    'Engenheiro Eletromecânico',
    'Engenheiro Eletrônico',
    'Engenheiro em Computação',
    'Engenheiro Ferroviário',
    'Engenheiro Florestal',
    'Engenheiro Geotécnico',
    'Engenheiro Hidráulico',
    'Engenheiro Hídrico',
    'Engenheiro Industrial',
    'Engenheiro Mecânico',
    'Engenheiro Mecânico e Afins - Exceto Engenheiro Mecânico Nuclear',
    'Engenheiro Mecânico Nuclear',
    'Engenheiro Mecatrônico',
    'Engenheiro Metalúrgico',
    'Engenheiro Metalurgista',
    'Engenheiro Naval',
    'Engenheiro Nuclear',
    'Engenheiro Orçamentista',
    'Engenheiro Químico',
    'Engenheiro Químico (Ácidos e Bases)',
    'Engenheiro Químico (Indústria Química)',
    'Engenheiro Químico (Solventes e Tintas)',
    'Engenheiro Rodoviário',
    'Engenheiro Sanitarista',
    'Engenheiro Têxtil',
    'Engraxate ',
    'Enólogo',
    'Entalhador',
    'Entregador',
    'Entrevistador',
    'Envernizador',
    'Equilibrista',
    'Ergonomista',
    'Escafrandista',
    'Escorador de Minas',
    'Escovador de Fundição',
    'Escrevente - Exceto Policial',
    'Escritor',
    'Escriturário',
    'Escriturário Hospitalar',
    'Escriturários de Serviços Bancários ',
    'Escrivão - Exceto Policial',
    'Escrivão de Cartório',
    'Escrivão de Polícia',
    'Escrivão de Polícia Civil',
    'Escrivão de Polícia Federal',
    'Escrivão de Polícia Militar',
    'Escrivão Extra Judicial',
    'Escrivão Judicial',
    'Escultor',
    'Esotérico',
    'Especialista (Descrever Ocupação)',
    'Especialista Contábil',
    'Especialista de Beleza',
    'Especialista de Políticas Públicas e Gestão Governamental - Eppgg',
    'Especialista de Produto',
    'Especialista de Projetos',
    'Especialista de Suprimentos',
    'Especialista em Compras',
    'Especialista em Desenvolvimento Urbano',
    'Especialista em Geoprocessamento',
    'Especialista em Informática',
    'Especialista em Logística de Transportes',
    'Especialista em Marketing',
    'Especialista em Mídia',
    'Especialista em Recrutamento e Seleção',
    'Especialista em Recursos Hidricos',
    'Especialista em Recursos Humanos',
    'Especialista em Telecomunicações',
    'Especialista em Tesouraria',
    'Especialista em Treinamento e Desenvolvimento',
    'Especialista Financeiro',
    'Especialista Fiscal',
    'Especialista Tributário',
    'Espeleologista',
    'Espelhador',
    'Estagiario',
    'Estágio em Engenharia de Minas',
    'Estampador',
    'Estatístico',
    'Esteriotipista',
    'Esteticista',
    'Estilista',
    'Estivador',
    'Estocador de Gesso',
    'Estofador',
    'Estoquista',
    'Estudante',
    'Estudante em Intercambio',
    'Etiquetador',
    'Executivo de Contas',
    'Executivo de Vendas',
    'Exportador',
    'Extrativista e Reflorestador de Espécies Produtoras de Madeira',
    'Extrativista Florestal',
    'Extrativista Florestal de Espécies Produtoras de Alimentos Silvestres',
    'Extrativista Florestal de Espécies Produtoras de Fibras, Ceras e Óleos ',
    'Extrativista Florestal de Espécies Produtoras de Substâncias Aromáticas, Medicinais e Tóxicas ',
    'Extrusor',
    'Extrusor de Fios Ou Fibras de Vidro',
    'Extrusor de Metais',
    'Farmacêutico',
    'Farmacêutico Bioquímico',
    'Farmacêutico Homeopata',
    'Farmacêutico Hospitalar',
    'Farmacêutico Industrial',
    'Faroleiro',
    'Faturista',
    'Faturista Hospitalar',
    'Faxineiro',
    'Fazedor de Cerca',
    'Fazendeiro',
    'Feirante',
    'Ferramenteiro',
    'Ferramenteiro de Moldes Plásticos',
    'Ferramenteiro e Afins',
    'Ferreiro',
    'Ferroviário',
    'Fiador',
    'Fiandeira',
    'Figurinista',
    'Filólogo,Tradutor e Intérprete',
    'Filósofo',
    'Fiscal (Descrever Ocupação)',
    'Fiscal Agrícola',
    'Fiscal Agropecuário',
    'Fiscal de Aeroporto',
    'Fiscal de Atividades Urbanas',
    'Fiscal de Balanças',
    'Fiscal de Caixa',
    'Fiscal de Campo',
    'Fiscal de Loja',
    'Fiscal de Obras',
    'Fiscal de Prevenção de Perdas',
    'Fiscal de Rendas',
    'Fiscal de Rendas Publicas',
    'Fiscal de Saneamento',
    'Fiscal de Tráfego',
    'Fiscal de Transporte Coletivo',
    'Fiscal de Transportes Coletivos - Exceto Trem',
    'Fiscal de Tributação de Nível Médio',
    'Fiscal de Tributos Estaduais e Municipais ',
    'Fiscal Florestal',
    'Fiscal Municipal',
    'Fiscal Sanitário',
    'Físico - Exceto Físico Nuclear e Reatores',
    'Fisico Nuclear',
    'Físico Nuclear e Reatores',
    'Fisioterapeuta',
    'Fisioterapeuta Acupunturista',
    'Fisioterapeuta Dermato Funcional',
    'Fisioterapeuta do Trabalho',
    'Fisioterapeuta Esportivo',
    'Fisioterapeuta Home Care',
    'Fisioterapeuta Hospitalar',
    'Fisioterapeuta Neurofuncional',
    'Fisioterapeuta Oncofuncional',
    'Fisioterapeuta Respiratório',
    'Fisioterapeuta Rpg',
    'Fisioterapeuta Vendedor',
    'Floricultor',
    'Florista',
    'Foguista',
    'Foiceiro',
    'Fonoaudiólogo',
    'Forjador',
    'Forneiro',
    'Forneiro e Operador  de Alto Forno',
    'Forneiro e Operador  de Forno Elétrico',
    'Forneiro e Operador de Conversor A Oxigênio',
    'Forneiro e Operador de Forno de Redução Direta',
    'Forneiro e Operador de Refino de Metais Não Ferrosos',
    'Forneiro Metalúrgico',
    'Forneiro Na Fundição de Vidro',
    'Forneiro No Recozimento de Vidro',
    'Foto Impressor de Chapas',
    'Fotógrafo',
    'Fotogravador',
    'Fracionador',
    'Frenólogo',
    'Frentista',
    'Fresador',
    'Freteiro',
    'Funcionário Público Estadual (Informar A Profissão)',
    'Funcionário Público Federal (Informar A Profissão)',
    'Funcionário Público Municipal (Informar A Profissão)',
    'Fundidor',
    'Fundidor de Metais',
    'Funileiro',
    'Funileiro de Veículos',
    'Futebolista',
    'Fuzileiro Naval',
    'Galvanizador',
    'Gandula',
    'Garagista',
    'Garantista de Veículos',
    'Garçom',
    'Garçonete',
    'Garde Manger',
    'Gari',
    'Garimpeiro',
    'Garota de Programa',
    'Gastrônomo',
    'Gelador Industrial',
    'Gelador Profissional',
    'Gemólogo',
    'General - Exército, Marinha e Aeronáutica',
    'General de Brigada - Exército, Marinha e Aeronáutica',
    'General de Divisão - Exército, Marinha e Aeronáutica',
    'Geneticista',
    'Geofísico',
    'Geógrafo',
    'Geologista',
    'Geólogo',
    'Gerente Administrativo',
    'Gerente Administrativo, Financeiro e de Risco',
    'Gerente Agrícola',
    'Gerente Bancário',
    'Gerente Comercial',
    'Gerente de Aeroporto',
    'Gerente de Alimentos e Bebidas',
    'Gerente de Almoxarifado',
    'Gerente de Áreas de Apoio',
    'Gerente de Armazém',
    'Gerente de Assistência Técnica',
    'Gerente de Assuntos Regulatórios',
    'Gerente de Atendimento',
    'Gerente de Auditoria',
    'Gerente de Benefícios',
    'Gerente de Canais',
    'Gerente de Cinema',
    'Gerente de Cobrança',
    'Gerente de Comercialização, Marketing e Comunicação',
    'Gerente de Comércio Exterior',
    'Gerente de Compliance',
    'Gerente de Compras',
    'Gerente de Comunicação',
    'Gerente de Condomínio',
    'Gerente de Confecção',
    'Gerente de Contabilidade',
    'Gerente de Contas',
    'Gerente de Conteúdo',
    'Gerente de Contratos',
    'Gerente de Controladoria',
    'Gerente de Controle de Qualidade',
    'Gerente de Crédito e Cobrança',
    'Gerente de Crédito Imobiliário',
    'Gerente de Crm',
    'Gerente de Custos',
    'Gerente de Departamento de Esportes',
    'Gerente de Departamento Pessoal',
    'Gerente de E-Commerce',
    'Gerente de Empresa',
    'Gerente de Enfermagem',
    'Gerente de Engenharia',
    'Gerente de Estoque',
    'Gerente de Eventos',
    'Gerente de Expedição',
    'Gerente de Exportação',
    'Gerente de Facilities',
    'Gerente de Factoring',
    'Gerente de Farmácia',
    'Gerente de Faturamento',
    'Gerente de Fazenda',
    'Gerente de Filial',
    'Gerente de Frota',
    'Gerente de Fusões e Aquisições',
    'Gerente de Garantia da Qualidade',
    'Gerente de Hotel',
    'Gerente de Infraestrutura',
    'Gerente de Inteligência de Mercado',
    'Gerente de Inventário',
    'Gerente de Investimentos',
    'Gerente de Lavanderia',
    'Gerente de Licitação',
    'Gerente de Limpeza',
    'Gerente de Locação',
    'Gerente de Logística',
    'Gerente de Loja',
    'Gerente de Loja e Supermercado',
    'Gerente de Manutenção',
    'Gerente de Manutenção Industrial',
    'Gerente de Manutenção Mecânica',
    'Gerente de Manutenção Predial',
    'Gerente de Marketing',
    'Gerente de Marketing Digital',
    'Gerente de Merchandising',
    'Gerente de Mídias Sociais',
    'Gerente de Moda',
    'Gerente de Negócios',
    'Gerente de Nutrição',
    'Gerente de Obras',
    'Gerente de Oficina',
    'Gerente de Ong - Organização Não Governamental',
    'Gerente de Operações',
    'Gerente de Operações de Serviços em Empresa de Transporte, de Comunicação e de Logística',
    'Gerente de Operações de Serviços em Empresa de Turismo, de Alojamento, Pensão, Hotéis, Motéis e Alimentação',
    'Gerente de Operações de Serviços em Instituição de Intermediação Financeira',
    'Gerente de Operações em Empresa de Serviços de Saúde',
    'Gerente de Operações em Empresa de Serviços Pessoais, Sociais e Culturais',
    'Gerente de Orçamento',
    'Gerente de Organização Social de Interesse Público',
    'Gerente de Patrimônio',
    'Gerente de Pcp',
    'Gerente de Peças',
    'Gerente de Pesquisa e Desenvolvimento',
    'Gerente de Pesquisa, Desenvolvimento e Afins',
    'Gerente de Planejamento',
    'Gerente de Posto',
    'Gerente de Pós-Venda',
    'Gerente de Prevenção de Perdas',
    'Gerente de Pricing',
    'Gerente de Processos',
    'Gerente de Produção',
    'Gerente de Produção de Madeira',
    'Gerente de Produção e Operações  Aqüícolas',
    'Gerente de Produção e Operações  Florestais - Exceto Gerente de Produção de Madeira',
    'Gerente de Produto',
    'Gerente de Projetos',
    'Gerente de Projetos Pmo',
    'Gerente de Projetos Web',
    'Gerente de Qsms',
    'Gerente de Qualidade',
    'Gerente de Recepção',
    'Gerente de Recrutamento e Seleção',
    'Gerente de Recursos Humanos',
    'Gerente de Recursos Humanos e de Relações do Trabalho',
    'Gerente de Reservas',
    'Gerente de Responsabilidade Social',
    'Gerente de Restaurantes e Bares',
    'Gerente de Saúde, Segurança e Meio Ambiente',
    'Gerente de Segurança da Informação',
    'Gerente de Segurança do Trabalho',
    'Gerente de Segurança Patrimonial',
    'Gerente de Seguros',
    'Gerente de Sem',
    'Gerente de Seo',
    'Gerente de Serviços',
    'Gerente de Sinistros',
    'Gerente de Suporte Técnico',
    'Gerente de Suprimentos',
    'Gerente de Tecnologia da Informação',
    'Gerente de Telecomunicações',
    'Gerente de Tesouraria',
    'Gerente de Trade Marketing',
    'Gerente de Transportes',
    'Gerente de Treinamento e Desenvolvimento',
    'Gerente de Turismo',
    'Gerente de Vendas',
    'Gerente Financeiro',
    'Gerente Fiscal',
    'Gerente Geral',
    'Gerente Imobiliário',
    'Gerente Industrial',
    'Gerente Jurídico',
    'Gerente Médico',
    'Gerente Operacional',
    'Gerente Ou Coordenador de Obras em Empresa de Construção',
    'Gerente Pedagógico',
    'Gerente Predial',
    'Gerente Técnico',
    'Gerente Tributário',
    'Gerontólogo',
    'Gesseiro',
    'Gestor Ambiental',
    'Gestor em Segurança',
    'Gestor Portuário',
    'Governador',
    'Governanta',
    'Gráfico',
    'Grafiteiro',
    'Graniteiro',
    'Granjeiro',
    'Gravador À Mão ',
    'Gravador de Inscrições em Pedra',
    'Gravador de Relevos em Pedra',
    'Gravador em Máquinas Automáticas',
    'Gravador Inscrições',
    'Graxeiro',
    'Greidista',
    'Guarda',
    'Guarda Ambiental',
    'Guarda Bancário',
    'Guarda Civil Municipal',
    'Guarda Costas',
    'Guarda de Carro',
    'Guarda de Endemias',
    'Guarda de Presídio',
    'Guarda de Segurança',
    'Guarda Florestal',
    'Guarda Judiciário',
    'Guarda Noturno',
    'Guarda Vidas',
    'Guardador de Veículos',
    'Guia de Turismo',
    'Guilhotinador',
    'Guincheiro',
    'Higienista Ocupacional',
    'Historiador',
    'Hostess',
    'Iluminador de Televisão',
    'Ilustrador',
    'Imediato da Marinha',
    'Imediato da Marinha Mercante',
    'Impermeabilizador',
    'Implantador de Sistemas',
    'Importador',
    'Impregnador de Madeira',
    'Impressor de Corte e Vinco',
    'Impressor de Rotogravura',
    'Impressor Digital',
    'Impressor Flexográfico',
    'Impressor Off-Set',
    'Impressor Serigráfico',
    'Impressor Tampográfico',
    'Incorporador',
    'Industriário',
    'Inseminador de Animais',
    'Inspetor de Alunos',
    'Inspetor de Dutos',
    'Inspetor de Equipamentos',
    'Inspetor de Fabricação',
    'Inspetor de Frota',
    'Inspetor de Pintura',
    'Inspetor de Policia',
    'Inspetor de Polícia Rodoviária Federal',
    'Inspetor de Presídio',
    'Inspetor de Qualidade',
    'Inspetor de Riscos',
    'Inspetor de Segurança',
    'Inspetor de Solda',
    'Inspetor de Terminal',
    'Inspetor de Via Permanente (Trilhos)',
    'Inspetor Naval',
    'Inspetor Veicular',
    'Inspetores e Revisor de Produção Têxtil ',
    'Instal. Gás, Agua e Esgoto e Assemelhados',
    'Instalador de Acessórios',
    'Instalador de Alarmes',
    'Instalador de Ar Condicionado',
    'Instalador de Comunicação Visual',
    'Instalador de Equipamentos de Refrigeração e Ventilação',
    'Instalador de Equipamentos Eletrônicos',
    'Instalador de Insulfilm',
    'Instalador de Isolantes Acústicos ',
    'Instalador de Isolantes Térmicos ',
    'Instalador de Isolantes Térmicos de Caldeira e Tubulações ',
    'Instalador de Linhas Elétricas de Alta e Baixa Tensão ',
    'Instalador de Material Isolante',
    'Instalador de Outdoor',
    'Instalador de Painéis e Cartazes',
    'Instalador de Produtos e Acessórios',
    'Instalador de Rastreador Veicular',
    'Instalador de Telecomunicações',
    'Instalador de Tubalações',
    'Instalador de Tv A Cabo',
    'Instalador de Tv A Cabo, Cabista',
    'Instalador de Vidros',
    'Instalador de Vidros Automotivos',
    'Instalador e Mantenedor de Sistemas Eletroeletrônicos de Segurança ',
    'Instalador e Mantenedor Eletromecânicos de Elevadores, Escadas e Portas Automáticas',
    'Instalador e Manutenção de Alarmes Residenciais e Empresariais',
    'Instalador e Reparador de Linhas e Cabos Elétricos, Telefônicos e de Comunicação de Dados ',
    'Instalador Mantenedor Antenas, Telhados e Assemelhados',
    'Instalador Mantenedor de Elevadores',
    'Instalador Reparador de  Linhas e Equipamentos de Telecomunicações',
    'Instrumentador Cirúrgico',
    'Instrumentista',
    'Instrutor de Auto Escola',
    'Instrutor de Aviação',
    'Instrutor de Cabeleireiro',
    'Instrutor de Curso',
    'Instrutor de Equitação Para Equoterapia',
    'Instrutor de Excel',
    'Instrutor de Informática',
    'Instrutor de Mecânico',
    'Instrutor de Motorista',
    'Instrutor de Musculação',
    'Instrutor de Pilates',
    'Instrutor de Qualidade',
    'Instrutor de Treinamento',
    'Instrutor de Vôo',
    'Instrutor e Professor de Escolas Livres',
    'Insufilmador de Veículo',
    'Intérprete',
    'Intérprete de Libras',
    'Investigador em Seguros',
    'Investigador Particular',
    'Investigador Policial',
    'Isolador Térmico',
    'Jardineiro',
    'Jatista',
    'Joalheiro',
    'Joalheiro (Comércio Varejista)',
    'Joalheiro (Fabricação - Empregador)',
    'Joalheiro e Lapidador de Gemas ',
    'Jogador de Futebol',
    'Jóquei',
    'Jornaleiro',
    'Jornalista',
    'Juiz',
    'Juiz Criminal',
    'Juiz de Direito - Exceto Juiz de Direito Criminal',
    'Juiz de Direito Criminal',
    'Juiz de Futebol',
    'Juiz do Trabalho',
    'Juiz Estadual',
    'Juiz Federal',
    'Juiz Leigo',
    'Juiz Substituto',
    'Jurista',
    'Laboratorista de Concreto',
    'Laboratorista de Solos',
    'Laboratorista Fotográfico',
    'Laboratorista Industrial Auxiliar',
    'Lactarista',
    'Ladrilheiro',
    'Laminador',
    'Lancheiro',
    'Lanterneiro',
    'Lapidador de Gemas',
    'Lapidador de Vidros',
    'Lapidário',
    'Laqueador',
    'Lavadeira',
    'Lavador de Automóveis',
    'Lavador de Fachadas de Edifícos',
    'Lavador de Garrafas, Vidros e Outros Utensílios',
    'Lavador de Veículos',
    'Lavourista',
    'Lavrador',
    'Layoutista',
    'Leiloeiro',
    'Leiloeiros e Avaliadores ',
    'Leiteiro',
    'Leitor de Oráculos',
    'Leiturista',
    'Lenhador',
    'Lenheiro (Comércio Varejista)',
    'Letrista',
    'Líder Comercial',
    'Líder de Atendimento',
    'Líder de Caixa',
    'Líder de Cobrança',
    'Líder de Controle de Qualidade',
    'Líder de Equipe',
    'Líder de Expedição',
    'Líder de Extrusão',
    'Líder de Help Desk',
    'Líder de Logística',
    'Líder de Loja',
    'Líder de Manutenção',
    'Líder de Manutenção Elétrica',
    'Líder de Montagem',
    'Líder de Pintura',
    'Líder de Portaria',
    'Líder de Produção',
    'Líder de Recepção',
    'Líder de Refrigeração',
    'Líder de Testes',
    'Líder de Usinagem',
    'Líder de Vendas',
    'Líder de Visual Merchandising',
    'Limpador de Fachadas ',
    'Limpador de Pára-Brisa',
    'Limpador de Piscinas',
    'Limpador de Vidros',
    'Lingotador',
    'Livreiro',
    'Lixador',
    'Lixeiro',
    'Locutor',
    'Locutor de Rodeio',
    'Lubrificador de Embarcações',
    'Lubrificador de Veículos Automotores (Exceto Embarcações)',
    'Lubrificador Industrial (Lubrificador de Máquinas)',
    'Lustrador',
    'Lustrador de Móveis',
    'Lutador',
    'Maçariqueiro',
    'Madeireiro',
    'Madeireiro (Comércio Atacadista e Varejista)',
    'Maestro',
    'Magarefe',
    'Mágico',
    'Magistério',
    'Magistrado',
    'Maître',
    'Major - Exército, Marinha e Aeronáutica',
    'Major Brigadeiro - Exército, Marinha e Aeronáutica',
    'Malabarista',
    'Mandrilhador',
    'Manequim',
    'Manicure',
    'Manipulador de Cosméticos',
    'Manipulador de Farmácia',
    'Manipulador Produto Químico',
    'Manobreiro',
    'Manobrista',
    'Mantenedor de Edificações',
    'Mantenedor de Equipamentos de Parques de Diversões e Similares',
    'Maqueiro',
    'Maquetista',
    'Maquiador',
    'Maquinista',
    'Maquinista de Bordo',
    'Maquinista de Trem',
    'Marceneiro',
    'Marchante (Comércio de Gado)',
    'Marechal - Exército, Marinha e Aeronáutica',
    'Marechal do Ar',
    'Marinheiro - Marinha',
    'Marinheiro - Marinha Mercante',
    'Marinheiro Auxiliar de Convés - Marinha',
    'Marinheiro Auxiliar de Convés - Marinha Mercante',
    'Marinheiro Auxiliar de Máquinas - Marinha',
    'Marinheiro Auxiliar de Máquinas - Marinha Mercante',
    'Marinheiro de Convés - Marinha',
    'Marinheiro de Convés - Marinha Mercante',
    'Mariscador',
    'Marítimo - Marinha',
    'Marítimo - Marinha Mercante',
    'Marmorista',
    'Marteleteiro',
    'Mascate',
    'Massagista',
    'Masseiro',
    'Massoterapeuta',
    'Matemático',
    'Mecânico',
    'Mecânico Compressor',
    'Mecânico de Aeronave',
    'Mecânico de Ar Condicionado',
    'Mecanico de Automóveis',
    'Mecânico de Bicicletas',
    'Mecânico de Eletrônica',
    'Mecânico de Empilhadeira',
    'Mecânico de Instrumentos de Precisão ',
    'Mecânico de Manutenção',
    'Mecânico de Manutenção de Aeronaves',
    'Mecânico de Manutenção de Automóveis, Motocicletas e Veículos Similares',
    'Mecânico de Manutenção de Bicicletas e Equipamentos Esportivos e de Ginástica (Montador de Bicicletas)',
    'Mecânico de Manutenção de Bomba Injetora (Exceto de Veículos Automotores)',
    'Mecânico de Manutenção de Bombas',
    'Mecânico de Manutenção de Compressores de Ar',
    'Mecânico de Manutenção de Empilhadeiras e Outros Veículos de Cargas Leves',
    'Mecânico de Manutenção de Máquinas Cortadoras de Grama, Roçadeiras, Motosserras e Similares',
    'Mecânico de Manutenção de Máquinas Industriais',
    'Mecânico de Manutenção de Máquinas Pesadas e Equipamentos Agrícolas ',
    'Mecânico de Manutenção de Motocicletas',
    'Mecânico de Manutenção de Motores Diesel - Exceto de Veículos Automotores',
    'Mecânico de Manutenção de Motores e Equipamentos Navais',
    'Mecânico de Manutenção de Redutores',
    'Mecânico de Manutenção de Sistema Hidráulico de Aeronaves',
    'Mecânico de Manutenção de Tratores',
    'Mecânico de Manutenção de Turbinas - Exceto de Aeronaves',
    'Mecânico de Manutenção de Turbocompressores',
    'Mecânico de Manutenção de Veículos Ferroviários',
    'Mecânico de Manutenção e Instalação de Aparelhos de Climatização e  Refrigeração',
    'Mecânico de Manutenção Hidráulica',
    'Mecânico de Manutenção Predial',
    'Mecânico de Máquinas',
    'Mecanico de Maquinas em Geral',
    'Mecanico de Motor Diesel (Exceto Veiculos Automotores)',
    'Mecânico de Refrigeração',
    'Mecanico de Sistema Hidráulico',
    'Mecânico de Sopro',
    'Mecânico de Usinagem',
    'Mecânico de Veículos',
    'Mecânico de Veículos Automotores A Diesel - Exceto Tratores',
    'Mecanico de Veiculos Ferroviários',
    'Mecânico de Voo',
    'Mecanico do Sistema Hidraulico',
    'Mecânico Eletricista',
    'Mecânico Hidráulico',
    'Mecânico Industrial',
    'Mecânico Lubrificador',
    'Mecanico Manutenção Veiculos Automotores, Aeronaves',
    'Mecanico Manutenção, Montador, Prepar, Operad Máquinas',
    'Mecânico Montador',
    'Mecânico Montador de Motores e Turboalimentadores',
    'Mecânico Operador',
    'Mecanico Para Manutenção de Edificios',
    'Mecanico Reparador',
    'Médico',
    'Médico Acupunturista',
    'Médico Alergologista',
    'Médico Ambulatorial',
    'Médico Anestesista',
    'Médico Auditor',
    'Médico Cardiologista',
    'Médico Cirurgião',
    'Médico Cirurgião Plástico',
    'Médico Cirurgião Torácico',
    'Médico Cirurgião Vascular',
    'Médico Clínico Geral',
    'Médico Colonoscopista',
    'Médico Dermatologista',
    'Médico do Esporte',
    'Médico do Trabalho',
    'Médico do Tráfego',
    'Médico e Cirurgião',
    'Médico Ecocardiografista',
    'Médico em Medicina Nuclear',
    'Médico Emergencista',
    'Médico Endocrinologista',
    'Médico Esteticista',
    'Médico Fisiatra',
    'Médico Gastroenterologista',
    'Médico Gastropediatra',
    'Médico Geneticista',
    'Médico Geriatra',
    'Médico Ginecologista',
    'Médico Hebiatra',
    'Médico Hematologista',
    'Médico Hemoterapeuta',
    'Médico Homeopata',
    'Médico Imunologista',
    'Médico Infectologista',
    'Médico Intensivista',
    'Médico Legista',
    'Médico Mamografista',
    'Médico Mastologista',
    'Médico Nefrologista',
    'Médico Neurocirurgião',
    'Médico Neurologista',
    'Médico Nutrólogo',
    'Médico Obstetra',
    'Médico Oftalmologista',
    'Médico Oncologista',
    'Médico Ortomolecular',
    'Médico Ortopedista',
    'Médico Otorrinolaringologista',
    'Médico Patologista',
    'Médico Pediatra',
    'Médico Perito',
    'Médico Plantonista',
    'Médico Pneumologista',
    'Médico Proctologista',
    'Médico Psiquiatra',
    'Médico Radiologista',
    'Médico Regulador',
    'Médico Reumatologista',
    'Médico Traumatologista',
    'Médico Ultrassonografista',
    'Médico Urologista',
    'Médico Veterinário (Zootecnista)',
    'Médico Visitador',
    'Medidor de Obras',
    'Meio Oficial Ajustador Mecânico',
    'Meio Oficial Carpinteiro',
    'Meio Oficial de Caldeiraria',
    'Meio Oficial de Ferramentaria',
    'Meio Oficial de Impressão Flexográfica',
    'Meio Oficial de Manutenção',
    'Meio Oficial de Manutenção Predial',
    'Meio Oficial de Pintura',
    'Meio Oficial de Refrigeração',
    'Meio Oficial Eletricista',
    'Meio Oficial Fresador',
    'Meio Oficial Funileiro',
    'Meio Oficial Hidráulico',
    'Meio Oficial Marceneiro',
    'Meio Oficial Mecânico',
    'Meio Oficial Mecânico de Manutenção',
    'Meio Oficial Montador',
    'Meio Oficial Serralheiro',
    'Meio Oficial Soldador',
    'Meio Oficial Torneiro Mecânico',
    'Membro de Ordens Ou Seitas Religiosas',
    'Membro do Ministério Público (Informar A Profissão)',
    'Membro Superior do Poder Executivo (Informar A Profissão)',
    'Merendeira',
    'Mergulhador',
    'Mergulhador - Pescador de Lagosta',
    'Mergulhador Profissional ',
    'Mestre de Cabotagem',
    'Mestre de Linhas (Ferrovias)',
    'Mestre de Obras',
    'Mestre de Panificação',
    'Mestre Hotel',
    'Meteorologista',
    'Metrologista',
    'Microbiologista',
    'Militar Ativo',
    'Militar da Aeronáutica',
    'Militar da Marinha',
    'Militar da Reserva',
    'Militar do Exercito',
    'Militar Estrangeiro',
    'Militar Inativo',
    'Militar Reformado',
    'Mineiro (Minerador)',
    'Ministro',
    'Ministro de Culto Religioso',
    'Ministro de Estado',
    'Ministro de Estado e da Diplomacia',
    'Ministro de Tribunal',
    'Missionário',
    'Mobilizador Social',
    'Modelista',
    'Modelo',
    'Modista',
    'Moedor de Café',
    'Moedor de Sal',
    'Moldador',
    'Moldador de Abrasivos Na Fabricação de Cerâmica, Vidro e Porcelana',
    'Moldureiro',
    'Moleiro',
    'Moleiro de Cereais - Exceto Arroz ',
    'Moleiro de Especiarias ',
    'Moleiro de Minérios',
    'Monitor de Alunos',
    'Monitor de Aparelhos e Instrumentos de Precisão e Musicais',
    'Monitor de Informática',
    'Monitor de Qualidade',
    'Montador de Andaimes',
    'Montador de Aparelhos de Telecomunicações ',
    'Montador de Calçados',
    'Montador de Centrais Elétricas',
    'Montador de Elevador',
    'Montador de Equipamentos',
    'Montador de Equipamentos Eletroeletrônicos ',
    'Montador de Esquadrias',
    'Montador de Estruturas de Concreto Armado ',
    'Montador de Estruturas Metálicas',
    'Montador de Máquinas',
    'Montador de Motores de Aeronaves, Embarcações, Motores de Explosão, Sistema de Combustão de Aeronaves',
    'Montador de Móveis e Artefatos de Madeira',
    'Montador de Painéis',
    'Montador de Sistemas e Estruturas de Aeronaves',
    'Montador de Tubulação de Gás',
    'Montador de Veículos',
    'Montador de Veículos Automotores - Linha de Montagem',
    'Montador Elétrico',
    'Montador Eletrônico',
    'Montador Hidráulico',
    'Mordomo',
    'Motoboy',
    'Motociclista e Ciclistas de Entregas Rápidas',
    'Motorista',
    'Motorista Administrativo',
    'Motorista de Ambulancia',
    'Motorista de Automóveis',
    'Motorista de Caminhão',
    'Motorista de Carro Forte',
    'Motorista de Guincho',
    'Motorista de Ônibus',
    'Motorista de Transporte de Carga',
    'Motorista de Transporte Escolar',
    'Motorista de Transportes Não Regulares',
    'Motorista de Utilitários',
    'Motorista Entregador',
    'Motorista Escolar',
    'Motorista Executivo',
    'Motorista Operador',
    'Motorista Operador de Betoneira',
    'Motorista Particular',
    'Motorista Profissional',
    'Motoristas de Veículos Leves',
    'Motorneiro',
    'Mototaxista',
    'Museólogo',
    'Músico',
    'Musicoterapeuta',
    'Naturólogo',
    'Neuropsicólogo',
    'Nivelador',
    'Numerólogo',
    'Nutricionista',
    'Nutricionista Clínico',
    'Nutricionista Comercial',
    'Nutricionista de Produção',
    'Nutricionista de Qualidade',
    'Nutricionista do Esporte',
    'Oceanógrafo',
    'Odontólogo',
    'Office Boy',
    'Oficiais do Exército',
    'Oficial da Aeronáutica',
    'Oficial da Marinha',
    'Oficial de Cartório',
    'Oficial de Inteligência da Abin',
    'Oficial de Justiça',
    'Oficial de Manutenção',
    'Oficial de Manutenção Predial',
    'Oficial de Máquinas da Marinha Mercante',
    'Oficial de Nautica',
    'Oficial de Promotoria',
    'Oficial de Quarto de Navegação da Marinha Mercante',
    'Oficial de Redes',
    'Oficial de Refrigeração',
    'Oficial de Registo de Contratos Marítimos',
    'Oficial do Exército',
    'Oficial do Registo de Distribuições de Imóveis, de Títulos e Documentos',
    'Oficial do Registro Civil de Pessoas Jurídicas e de Pessoais Naturais',
    'Oficial Eletricista',
    'Oficial General da Aeronáutica',
    'Oficial General da Marinha',
    'Oficial General do Exército',
    'Oficial Intermediário do Corpo de Bombeiro Militar (Capitão)',
    'Oficial Legislativo',
    'Oficial Mecânico',
    'Oficial Serralheiro',
    'Oficial Superior da Polícia Militar',
    'Oficial Superior do Corpo de Bombeiro Militar',
    'Oficineiro',
    'Oleiro - Fabricação de Telhas e Tijolos',
    'Operador Audiovisual',
    'Operador Cinematográfico',
    'Operador da Fiação ',
    'Operador de Acabamento (Indústria Gráfica)',
    'Operador de Aciaria (Basculamento de Convertedor)',
    'Operador de Aciaria (Dessulfuração de Gusa)',
    'Operador de Aciaria (Recebimento de Gusa)',
    'Operador de Alambique de Func Continuo',
    'Operador de Aparelho de Filtragem/Destilação/Reação/Conversão/Autoclave (Tratamento Quimico)',
    'Operador de Aparelho de Flotação (Operador de Flotação)',
    'Operador de Aparelho de Precipitação ',
    'Operador de Área de Corrida Controlador de Carro Torpedo, Operador de Conservação de Canais)',
    'Operador de Áudio',
    'Operador de Balanças Rodoviárias',
    'Operador de Banho Metálico de Vidro Por Flutuação',
    'Operador de Bate-Estacas',
    'Operador de Betoneira',
    'Operador de Bomba de Concreto',
    'Operador de Britador de Mandíbulas',
    'Operador de Caixa',
    'Operador de Calandra',
    'Operador de Calcinação No Tratamento Químico e Afins',
    'Operador de Caldeira',
    'Operador de Call Center',
    'Operador de Câmaras Frias',
    'Operador de Câmbio',
    'Operador de Câmera de Televisão',
    'Operador de Caminhão em Minas e Pedreiras',
    'Operador de Carregadeira',
    'Operador de Ceifadeira Na Conservação de Vias Permanentes',
    'Operador de Central de Concreto',
    'Operador de Central Hidrelétrica',
    'Operador de Central Termoelétrica',
    'Operador de Centro de Usinagem',
    'Operador de Cftv',
    'Operador de Cobrança',
    'Operador de Coladeira',
    'Operador de Compactadora de Solos',
    'Operador de Computador',
    'Operador de Controle Mestre',
    'Operador de Cortadeira de Papel',
    'Operador de Corte e Dobra',
    'Operador de Corte e Solda',
    'Operador de Corte e Vinco',
    'Operador de Cristalização Na Refinação de Açúcar ',
    'Operador de Desgaseificação',
    'Operador de Dobradeira',
    'Operador de Draga',
    'Operador de Eletroerosão',
    'Operador de Empilhadeira',
    'Operador de Engenho',
    'Operador de Equipamento',
    'Operador de Equipamento de Laminação',
    'Operador de Equipamento de Moagem e Mistura de Materiais  No Tratamento Químico e Afins',
    'Operador de Equipamento de Refinação de Açúcar - Processo Contínuo',
    'Operador de Equipamentos de Acabamento de Chapas e Metais',
    'Operador de Equipamentos de Coqueificação',
    'Operador de Equipamentos de Destilação, Evaporação e Reação - Exceto Petróleo',
    'Operador de Equipamentos de Entrada e Transmissão de Dados ',
    'Operador de Equipamentos de Filtragem e Separação',
    'Operador de Equipamentos de Movimentação de Cargas',
    'Operador de Escavadeira',
    'Operador de Espessador',
    'Operador de Estação de Captação, Tratamento e Distribuição de Água',
    'Operador de Estação de Tratamento de Águas e Efluentes',
    'Operador de Estacionamento',
    'Operador de Extrusora',
    'Operador de Factoring',
    'Operador de Forno',
    'Operador de Forno - Fabricação de Pães, Biscoitos e Similares',
    'Operador de Forno de Incineração No Tratamento de Água, Efluentes e Resíduos Industriais',
    'Operador de Furadeira',
    'Operador de Gás',
    'Operador de Granitadeira',
    'Operador de Guilhotina',
    'Operador de Guincho',
    'Operador de Guindaste',
    'Operador de Injetora',
    'Operador de Instalação de Extração, Processamento, Envasamento e Distribuição de Gases ',
    'Operador de Instalação de Refrigeração e Ar Condicionado',
    'Operador de Instalações de Produção e Distribuição de Energia',
    'Operador de Instalações de Sinterização ',
    'Operador de Instalações e Máquinas de Produtos Plásticos, de Borracha e Moldadores de Parafinas ',
    'Operador de Jig (Minas)',
    'Operador de Laminação',
    'Operador de Lixadeira',
    'Operador de Logística',
    'Operador de Máquina',
    'Operador de Máquina - Ferramentas Convencionais',
    'Operador de Máquina Agropecuária',
    'Operador de Máquina Copiadora',
    'Operador de Máquina Cortadora',
    'Operador de Máquina de Abrir Valas',
    'Operador de Máquina de Corte',
    'Operador de Máquina de Eletroerosão',
    'Operador de Máquina de Extração Contínua',
    'Operador de Máquina de Soprar Vidro',
    'Operador de Máquina de Sopro',
    'Operador de Máquina de Usinar Madeira - Produção em Série',
    'Operador de Máquina Extrusora de Varetas e Tubos de Vidro ',
    'Operador de Máquina Perfuradora',
    'Operador de Máquina Perfuratriz',
    'Operador de Máquinas A Vapor e Utilidades ',
    'Operador de Maquinas Agricolas',
    'Operador de Máquinas de Aglomeração e Prensagem de Chapas ',
    'Operador de Máquinas de Conformação de Metais',
    'Operador de Máquinas de Construção Civil e Mineração',
    'Operador de Máquinas de Costurar e Montar Calçados ',
    'Operador de Máquinas de Desdobramento da Madeira',
    'Operador de Máquinas de Fabricação de Chocolates e Achocolatados',
    'Operador de Máquinas de Fabricação de Doces, Salgados e Massas Alimentícias',
    'Operador de Máquinas de Fabricar Papel e Papelão ',
    'Operador de Máquinas de Usinagem Com Comando Numérico ',
    'Operador de Máquinas e Equipamentos de Elevação ',
    'Operador de Máquinas e Instalações de Produtos Farmacêuticos, Cosméticos e Afins ',
    'Operador de Máquinas Especiais em Conservação de Via Permanente (Trilhos)',
    'Operador de Máquinas Fixas, em Geral',
    'Operador de Máquinas Na Confecção de Artefatos de  Couro - Exceto Roupas e Calçados',
    'Operador de Máquinas Na Fabricação de Produtos de Papel e Papelão',
    'Operador de Máquinas Operatrizes',
    'Operador de Máquinas Para Bordado e Acabamento de Roupas ',
    'Operador de Maquinas Pesadas',
    'Operador de Martelete',
    'Operador de Mesa',
    'Operador de Moenda Na Fabricação de Açúcar ',
    'Operador de Monitoramento',
    'Operador de Montagem',
    'Operador de Moto Serra',
    'Operador de Motoniveladora',
    'Operador de Motosserra',
    'Operador de Negócios',
    'Operador de Onduladeira',
    'Operador de Pá Carregadeira',
    'Operador de Painel de Controle Na Refinação de Petróleo',
    'Operador de Pavimentadora',
    'Operador de Peneiras Hidráulicas',
    'Operador de Perfiladeira',
    'Operador de Photoshop',
    'Operador de Plotter',
    'Operador de Ponte Rolante',
    'Operador de Prensa',
    'Operador de Prensa de Moldar Vidro ',
    'Operador de Processo de Moagem ',
    'Operador de Processos Das Indústrias de Transformação de Produtos Químicos, Petroquímicos e Afins ',
    'Operador de Processos Químicos',
    'Operador de Puncionadeira',
    'Operador de Quadro de Distribuição de Energia Elétrica',
    'Operador de Radio',
    'Operador de Rama',
    'Operador de Rastreamento',
    'Operador de Reatores Nucleares',
    'Operador de Rebocador',
    'Operador de Rede de Teleprocessamento',
    'Operador de Redes',
    'Operador de Refrigeração',
    'Operador de Retificadora',
    'Operador de Retroescavadeira',
    'Operador de Robôs',
    'Operador de Rolo Compactador',
    'Operador de Sala de Máquinas',
    'Operador de Salina',
    'Operador de Serra',
    'Operador de Sonda de Percussão',
    'Operador de Sonda Rotativa',
    'Operador de Subestação',
    'Operador de Tear e Máquinas Similares ',
    'Operador de Telefone',
    'Operador de Telefonia',
    'Operador de Telemarketing',
    'Operador de Televendas',
    'Operador de Torno',
    'Operador de Torno Automático',
    'Operador de Tráfego',
    'Operador de Transferência e Estocagem A Refinação do Petróleo',
    'Operador de Transmissão',
    'Operador de Tratamento de Calda Na Refinação de Açúcar',
    'Operador de Tratamento Químico de Materiais Radioativos',
    'Operador de Trator',
    'Operador de Trator de Lâmina',
    'Operador de Triagem e Transbordo',
    'Operador de Turbina (Usina de Açucar)',
    'Operador de Turismo',
    'Operador de Tv',
    'Operador de Usina Nuclear',
    'Operador de Usinagem Convencional de Madeira ',
    'Operador de Usinagem Convencional Por Abrasão',
    'Operador de Utilidades',
    'Operador de Veículos Sobre Trilhos e Cabos Aéreos ',
    'Operador de Veículos Subaquáticos Controlados Remotamente',
    'Operador de Xerox',
    'Operador de Zincagem',
    'Operador do Comércio em Lojas e Mercados',
    'Operador Eletromecânico',
    'Operador Mantenedor',
    'Operador Na Preparação de Massas Para Abrasivo, Vidro, Cerâmica, Porcelana e Materiais de Construção',
    'Operador Polivalente da Indústria Têxtil',
    'Operador Polivalente de Equipamentos em Indústrias Químicas, Petroquímicas e Afins',
    'Orçamentista',
    'Orçamentista Civil',
    'Órgão Federal',
    'Órgão Rodoviário Federal',
    'Orientador Educacional',
    'Orientador Social',
    'Orientador Sócio Educativo',
    'Ótico',
    'Ourives',
    'Oxicortadores',
    'Padeiro, Confeiteiro e Afins',
    'Padre',
    'Pailoscopista Exceto Policial',
    'Paisagista',
    'Paleontólogo',
    'Palestrante',
    'Palhaço',
    'Panfleteiro',
    'Panificador',
    'Papiloscopista Policial',
    'Paramédico',
    'Paranormal',
    'Parapsicólogo',
    'Paraquedista',
    'Parlamentar',
    'Parteira',
    'Partner (Circo)',
    'Passador',
    'Pasteleiro',
    'Pastor',
    'Patinador',
    'Pavimentador',
    'Peão',
    'Peão de Boiadeiro',
    'Peão de Chácara',
    'Peão de Rodeio',
    'Pecuarista',
    'Pedagogo',
    'Pedagogo Empresarial',
    'Pedicure',
    'Pedreiro',
    'Peixeiro',
    'Pensionista',
    'Perfumista',
    'Perfurador',
    'Perfurador de Poços',
    'Perfurador de Poços Petróleo',
    'Perito - Exceto Policial, Criminal e Judiciário',
    'Perito Contábil',
    'Perito em Identificação',
    'Perito Judicial',
    'Perito Policial',
    'Perito Securitário',
    'Personal Stylist',
    'Personal Trainer',
    'Pesador',
    'Pescador',
    'Pescador Artesanal de Água Doce ',
    'Pescador Artesanal de Lagostas - Exceto Mergulhador',
    'Pescador Artesanal de Peixes e Camarões',
    'Pescadores de Água Costeira e Alto Mar ',
    'Pesquisador',
    'Pesquisador de Mercado',
    'Pesquisador em Biologia Ambiental ',
    'Pesquisador em Biologia Animal e Biologia de Microorganismos e Parasistas ',
    'Pesquisador em Biologia Humana (Fisiologista) - Exceto Médico',
    'Pesquisador em Biologia Vegetal',
    'Piloteiro (Confecção Textil)',
    'Piloto Agrícola',
    'Piloto Automobilistico',
    'Piloto Comercial de Helicóptero ',
    'Piloto de Aviação Executiva, Táxi Aéreo e Assemelhados',
    'Piloto de Competição Automobilística',
    'Piloto de Helicóptero',
    'Piloto de Lancha',
    'Piloto de Linha Aérea Não Regular',
    'Piloto de Linha Aérea Regular',
    'Piloto de Navio',
    'Piloto de Testes',
    'Pintor Artístico',
    'Pintor de Auto',
    'Pintor de Construção Civil',
    'Pintor de Estruturas Metálicas',
    'Pintor de Fachadas de Edifícios',
    'Pintor de Letreiros',
    'Pintor de Móveis',
    'Pintor de Obras',
    'Pintor de Obras e Revestidores de Interiores',
    'Pintor de Quadros',
    'Pintor Eletrostático',
    'Pintores de Paredes de Edificios',
    'Pizzaiolo',
    'Plainador',
    'Planejador de Produção',
    'Planejador de Projetos',
    'Plataformista (Petróleo)',
    'Poceiro',
    'Podador de Árvores Na Conservação de Vias Permanentes',
    'Podólogo',
    'Polícia Florestal e de Mananciais',
    'Policial',
    'Policial Civil',
    'Policial Federal',
    'Policial Federal e Rodoviário',
    'Policial Inativo',
    'Policial Militar',
    'Policial Rodoviário Estadual',
    'Policial Rodoviário Federal',
    'Polidor',
    'Polidor de Metais',
    'Polidor de Pedras',
    'Polidor de Veículos',
    'Político',
    'Porteiro',
    'Porteiro (Hotel)',
    'Porteiro de Edifícios',
    'Porteiro de Local de Diversão ',
    'Praça da Aeronáutica ',
    'Praça da Marinha',
    'Praça do Exército',
    'Praticantes Profissionais Ou Amadores de Esportes Radicais',
    'Prático de Portos da Marinha Mercante',
    'Prefeito',
    'Prensador',
    'Prensador de Frutas',
    'Prensista',
    'Preparador de Autos',
    'Preparador de Cnc',
    'Preparador de Máquina Injetora',
    'Preparador de Máquinas',
    'Preparador de Máquinas - Ferramenta ',
    'Preparador de Matrizes de Corte e Vinco',
    'Preparador de Pasta Para Fabricação de Papel ',
    'Preparador de Pintura',
    'Preparador de Tintas',
    'Preparador de Torno',
    'Preparador Físico',
    'Presidente da República',
    'Prestador de Serviços do Comércio',
    'Primeiro Sargento - Exército, Marinha e Aeronáutica',
    'Primeiro Tenente - Exército, Marinha e Aeronáutica',
    'Procurador da Fazenda Nacional',
    'Procurador da Republica',
    'Procurador de Estado',
    'Procurador do Banco Central',
    'Procurador do Inss',
    'Procurador do Mínistério Público',
    'Procurador do Trabalho',
    'Procurador e Advogado Público',
    'Procurador Geral',
    'Procurador Juridico',
    'Produtor Agricola e Agropecuario',
    'Produtor Agrícola Na Cultura de Gramíneas ',
    'Produtor Agrícola Na Cultura de Plantas Estimulantes ',
    'Produtor Agrícola Na Cultura de Plantas Fibrosas ',
    'Produtor Agrícola Na Cultura de Plantas Oleaginosas ',
    'Produtor Agrícola Na Fruticultura ',
    'Produtor Agrícola Na Olericultura ',
    'Produtor Agrícola No Cultivo de Flores e Plantas Ornamentais ',
    'Produtor Agropecuário',
    'Produtor Artístico',
    'Produtor Artístico e Cultural',
    'Produtor Cultural',
    'Produtor da Avicultura e Cunicultura',
    'Produtor de Animais e Insetos Úteis ',
    'Produtor de Casting',
    'Produtor de Cinema',
    'Produtor de Especiarias e de Plantas Aromáticas e Medicinais ',
    'Produtor de Espetáculos',
    'Produtor de Eventos',
    'Produtor de Moda',
    'Produtor de Televisão',
    'Produtor de Vídeo',
    'Produtor Editorial',
    'Produtor em Pecuária de Animais de Grande Porte ',
    'Produtor em Pecuária de Animais de Médio Porte ',
    'Produtor em Pecuária Polivalente',
    'Produtor Gráfico',
    'Produtor Musical',
    'Produtor Na Exploração Agropecuária',
    'Proeiro (Vigia de Proa)',
    'Professor',
    'Professor Auxiliar',
    'Professor de Artes Cênicas',
    'Professor de Artes Marciais',
    'Professor de Artesanato',
    'Professor de Canto',
    'Professor de Circo',
    'Professor de Costura',
    'Professor de Dança',
    'Professor de Educação Especial',
    'Professor de Educação Fisica',
    'Professor de Expressão Corporal',
    'Professor de Futebol',
    'Professor de Ginástica',
    'Professor de Ginástica Laboral',
    'Professor de Manicure',
    'Professor de Moda',
    'Professor de Musculação',
    'Professor de Música',
    'Professor de Natação',
    'Professor de Petróleo e Gás',
    'Professor de Tênis',
    'Professor de Yoga',
    'Professor de Zootecnia',
    'Professor Universitário',
    'Profissionais do Espetáculo (Informar Profissão)',
    'Profissional da Biotecnologia',
    'Profissional da Educação Física',
    'Profissional da Educação Física',
    'Profissional da Escrita',
    'Profissional da Informação',
    'Profissional da Matemática e Estatística ',
    'Profissional da Metrologia ',
    'Profissional Das Ciências Atmosféricas e Espaciais e de Astronomia ',
    'Profissional de Atletismo ',
    'Profissional de Comercializacão e Consultoria de Serviços Bancários',
    'Profissional de Direitos Autorais e de Avaliacão de Produtos Dos Meios de Comunicação ',
    'Profissional de Marketing',
    'Profissional de Relações Com Investidores',
    'Profissional do Jornalismo',
    'Profissional do Sexo',
    'Profissional em Pesquisa e Análise Antropológica Sociológica ',
    'Profissional Polivalentes da Confecção de Roupas',
    'Programador',
    'Programador de Banco de Dados',
    'Programador de Bordado',
    'Programador de Business Intelligence',
    'Programador de Centro de Usinagem',
    'Programador de Jogos',
    'Programador de Manutenção',
    'Programador de Materiais',
    'Programador de Produção',
    'Programador de Software',
    'Projetista',
    'Projetista 3D',
    'Projetista Cadista',
    'Projetista Civil',
    'Projetista de Ar Condicionado',
    'Projetista de Automação',
    'Projetista de Estruturas Metálicas',
    'Projetista de Ferramentas',
    'Projetista de Iluminação',
    'Projetista de Instalações',
    'Projetista de Máquinas',
    'Projetista de Moldes',
    'Projetista de Móveis',
    'Projetista de Produto',
    'Projetista de Telecomunicações',
    'Projetista Elétrico',
    'Projetista Eletrônico',
    'Projetista Hidráulico',
    'Projetista Mecânico',
    'Projetista Orçamentista',
    'Promotor de Crédito',
    'Promotor de Eventos',
    'Promotor de Financiamento',
    'Promotor de Justiça',
    'Promotor de Merchandising',
    'Promotor de Vendas',
    'Promotor Jurídico',
    'Promotor Público',
    'Promotor Técnico',
    'Propagandista',
    'Proprietário Banca Jornais e Revistas',
    'Proprietário de Lojas de Veículos Ou Concessionárias',
    'Proprietário Editora e Livraria',
    'Proprietário Empr. Importação e Exportação',
    'Proprietário Estabelecimento Agrícola e Pecuário',
    'Proprietário Estabelecimento Comercial, Lojista',
    'Proprietário Estabelecimento Escolar Educacional',
    'Proprietário Estabelecimento Industrial',
    'Proprietário Estabelecimento Informática',
    'Proprietário Hotel, Motel, Pousada, Resort',
    'Proprietário Padaria, Bar e Restaurante',
    'Proprietário Posto de Gasolina',
    'Proprietário Transportadora',
    'Protético',
    'Psicanalista',
    'Psicólogo',
    'Psicólogo Clínico',
    'Psicólogo do Trânsito',
    'Psicólogo Escolar',
    'Psicólogo Hospitalar',
    'Psicólogo Organizacional',
    'Psicólogo Social',
    'Psicomotricista',
    'Psicopedagogo',
    'Psiquiatra',
    'Publicitário',
    'Pugilista',
    'Químico - Exceto Químico Nuclear e Químico Industrial',
    'Químico Industrial',
    'Químico Nuclear',
    'Químico Responsável',
    'Quiromante',
    'Quiropraxista',
    'Quitandeiro',
    'Radialista',
    'Rádio Operador',
    'Radioestesista',
    'Radiologista',
    'Radiotecnico',
    'Radioterapeuta',
    'Rasqueateador de Metais',
    'Rebarbador de Metais',
    'Rebobinador',
    'Recebedor',
    'Recenseador',
    'Recepcionista',
    'Recepcionista de Casas de Espetáculos',
    'Recepcionista de Hotel',
    'Recepcionista Hospitalar',
    'Recreador',
    'Recuperador de Crédito',
    'Redator',
    'Redator de Publicidade',
    'Redator Publicitário',
    'Redator Web',
    'Redeiro (Pesca)',
    'Refinador de Sal',
    'Regente de Coral',
    'Regente de Orquestra',
    'Regulador de Sinistros',
    'Reitor',
    'Relações Públicas',
    'Relojoeiro',
    'Reparador de Aparelhos Eletrodomésticos ',
    'Reparador de Carrocerias de Veículos ',
    'Reparador de Equipamentos de Escritório',
    'Reparador de Equipamentos Fotográficos ',
    'Reparador de Instrumentos Musicais',
    'Reparador de Rádio, Tv e Som ',
    'Repórter',
    'Repórter - Exceto Repórter Cinematográfico',
    'Repórter Cinematográfico',
    'Repositor',
    'Representante Comercial',
    'Representante Comercial',
    'Representante Comercial Alimentos',
    'Representante Comercial Autônomo',
    'Restaurador de Bens Culturais',
    'Restaurador de Livros e Documentos',
    'Retificador',
    'Retocador de Cliches (Fotogravura)',
    'Revestidor de Concreto',
    'Revisor de Tecidos',
    'Revisor de Texto',
    'Rigger',
    'Robotista',
    'Roteirista',
    'Roteirizador',
    'Sacerdote',
    'Saladeiro',
    'Salgadeiro',
    'Saltimbanco',
    'Salva Vidas',
    'Sanitarista',
    'Sapateiro',
    'Sargento - Exército, Marinha e Aeronáutica',
    'Scouter',
    'Scrum Master',
    'Secretaria',
    'Secretária Comercial',
    'Secretária Escolar',
    'Secretária Executiva',
    'Secretária Financeira',
    'Securitário',
    'Securitário',
    'Segudo Tenente - Exército, Marinha e Aeronáutica',
    'Segundo Sargento - Exército, Marinha e Aeronáutica',
    'Segundo Tenente - Exército, Marinha e Aeronáutica',
    'Segurança',
    'Segurança Comunitário',
    'Segurança de Evento',
    'Segurança Particular',
    'Segurança Patrimonial',
    'Segurança Pessoal',
    'Segurança/Vigia Não Armado (Museu, Escritorio, Portaria, Shopping)',
    'Selecionador',
    'Senador',
    'Separador de Mercadorias',
    'Sericultor',
    'Serigrafista',
    'Seringueiro',
    'Serrador',
    'Serralheiro',
    'Servente',
    'Servente (Limpeza)',
    'Servente de Obras',
    'Serventuário da Justiça - Exceto Escrivão de Polícia',
    'Serventuários de Justiça (Informar A Profissão)',
    'Servidor Público (Informar Profissão)',
    'Servidor Público Estadual (Informar Profissão)',
    'Servidor Público Federal (Informar Profissão)',
    'Servidor Público Municipal (Informar Profissão)',
    'Sinaleiro',
    'Síndico',
    'Sociologo',
    'Socorrista',
    'Soldado - Exército, Marinha e Aeronáutica',
    'Soldado Fuzileiro Naval',
    'Soldador',
    'Soldador Aluminotérmico em Conservação de Trilhos',
    'Soldador de Veículos',
    'Soldador Montador',
    'Sommelier',
    'Sondador',
    'Sondador de Poços (Exceto de Petróleo e Gás)',
    'Sonoplasta',
    'Soprador de Convertedor ',
    'Soprador, Moldador e Modelador de Vidros e Afins ',
    'Sorveteiro',
    'Steward',
    'Suboficial - Exército, Marinha e Aeronáutica',
    'Subtenente - Exército, Marinha e Aeronáutica',
    'Superintendente Comercial',
    'Superintendente de Operações',
    'Supervisor',
    'Supervisor Administrativo',
    'Supervisor Agrícola',
    'Supervisor Comercial',
    'Supervisor Contábil',
    'Supervisor da Confecção de Artefatos de Tecidos, Couros e Afins',
    'Supervisor da Construção Civil',
    'Supervisor da Extração Mineral ',
    'Supervisor da Fabricação de Alimentos, Bebidas e Fumo ',
    'Supervisor da Fabricação de Celulose e Papel ',
    'Supervisor da Fabricação e Montagem Metalmecânica ',
    'Supervisor da Indústria Têxtil',
    'Supervisor da Manutenção e Reparação de Veículos Leves e Pesados',
    'Supervisor da Mecânica de Precisão e Instrumentos Musicais ',
    'Supervisor Das Artes Gráficas - Indústria Editorial e Gráfica ',
    'Supervisor de Alimentos e Bebidas',
    'Supervisor de Almoxarifado',
    'Supervisor de Arquivo',
    'Supervisor de Assistência Técnica',
    'Supervisor de Atendimento Ao Cliente',
    'Supervisor de Atendimento Ao Público e de Pesquisa',
    'Supervisor de Auditoria',
    'Supervisor de Automação',
    'Supervisor de Back Office',
    'Supervisor de Bombeiro',
    'Supervisor de Caixa',
    'Supervisor de Caldeiraria',
    'Supervisor de Call Center',
    'Supervisor de Câmbio',
    'Supervisor de Campo',
    'Supervisor de Cobrança',
    'Supervisor de Comércio Exterior',
    'Supervisor de Compras',
    'Supervisor de Condomínio',
    'Supervisor de Contas',
    'Supervisor de Contas A Pagar',
    'Supervisor de Contas A Receber',
    'Supervisor de Contratos',
    'Supervisor de Controladoria',
    'Supervisor de Controle de Qualidade',
    'Supervisor de Costura',
    'Supervisor de Crédito',
    'Supervisor de Crédito e Cobrança',
    'Supervisor de Crédito Imobiliário',
    'Supervisor de Curtimento',
    'Supervisor de Custos',
    'Supervisor de Departamento Pessoal',
    'Supervisor de E-Commerce',
    'Supervisor de Elétrica',
    'Supervisor de Embalagem e Etiquetagem ',
    'Supervisor de Empresa',
    'Supervisor de Engenharia',
    'Supervisor de Ensino',
    'Supervisor de Estamparia',
    'Supervisor de Eventos',
    'Supervisor de Expedição',
    'Supervisor de Facilities',
    'Supervisor de Farmácia',
    'Supervisor de Faturamento',
    'Supervisor de Ferramentaria',
    'Supervisor de Filial',
    'Supervisor de Folha de Pagamento',
    'Supervisor de Frota',
    'Supervisor de Fundição',
    'Supervisor de Garantia da Qualidade',
    'Supervisor de Hotelaria',
    'Supervisor de Infraestrutura',
    'Supervisor de Injeção Plástica',
    'Supervisor de Instalação',
    'Supervisor de Inteligência de Mercado',
    'Supervisor de Joalheria',
    'Supervisor de Laboratório',
    'Supervisor de Lavanderia',
    'Supervisor de Lavanderia ',
    'Supervisor de Limpeza',
    'Supervisor de Locação',
    'Supervisor de Logística',
    'Supervisor de Loja',
    'Supervisor de Manutenção',
    'Supervisor de Manutenção Automotiva',
    'Supervisor de Manutenção de Aparelhos Térmicos, de Climatização e de Refrigeração',
    'Supervisor de Manutenção de Bombas, Motores, Compressores e Equipamentos de Transmissão',
    'Supervisor de Manutenção de Máquinas Gráficas',
    'Supervisor de Manutenção de Máquinas Industriais Têxteis',
    'Supervisor de Manutenção de Máquinas Operatrizes e de Usinagem',
    'Supervisor de Manutenção Elétrica',
    'Supervisor de Manutenção Elétrica de Alta Tensão Industrial',
    'Supervisor de Manutenção Eletroeletrônica Veicular',
    'Supervisor de Manutenção Eletromecânica',
    'Supervisor de Manutenção Eletromecânica (Utilidades)',
    'Supervisor de Manutenção Eletromecânica Industrial, Comercial e Predial',
    'Supervisor de Manutenção Industrial',
    'Supervisor de Manutenção Mecânica',
    'Supervisor de Manutenção Predial',
    'Supervisor de Marketing',
    'Supervisor de Meio Ambiente',
    'Supervisor de Merchandising',
    'Supervisor de Mineração',
    'Supervisor de Monitoramento',
    'Supervisor de Monitoria',
    'Supervisor de Montagem',
    'Supervisor de Montagens e Instalações Eletroeletrônicas',
    'Supervisor de Negócios',
    'Supervisor de Nutrição',
    'Supervisor de Obras',
    'Supervisor de Operação de Fluidos ',
    'Supervisor de Operação Elétrica',
    'Supervisor de Operações',
    'Supervisor de Outros Trabalhadores de Serviços de Reparação, Conservação e Manutenção',
    'Supervisor de Ouvidoria',
    'Supervisor de Patrimônio',
    'Supervisor de Pcp',
    'Supervisor de Peças',
    'Supervisor de Pintura',
    'Supervisor de Planejamento',
    'Supervisor de Pós-Venda',
    'Supervisor de Prevenção de Perdas',
    'Supervisor de Processos',
    'Supervisor de Produção',
    'Supervisor de Produção em Indústrias de Produtos Farmacêuticos, Cosméticos e Afins',
    'Supervisor de Produção em Indústrias de Transformação de Plásticos e Borrachas',
    'Supervisor de Produção em Indústrias Químicas, Petroquímicas e Afins ',
    'Supervisor de Produção em Indústrias Siderúrgicas ',
    'Supervisor de Produtos',
    'Supervisor de Projeto Social',
    'Supervisor de Projetos',
    'Supervisor de Qualidade',
    'Supervisor de Radiologia',
    'Supervisor de Recepção',
    'Supervisor de Recrutamento e Seleção',
    'Supervisor de Recursos Humanos',
    'Supervisor de Refrigeração',
    'Supervisor de Relacionamento',
    'Supervisor de Reservas',
    'Supervisor de Restaurante',
    'Supervisor de Sac',
    'Supervisor de Segurança do Trabalho',
    'Supervisor de Segurança Patrimonial',
    'Supervisor de Seguros',
    'Supervisor de Serviços Gerais',
    'Supervisor de Suprimentos',
    'Supervisor de Tecnologia da Informação',
    'Supervisor de Tesouraria',
    'Supervisor de Trade Marketing',
    'Supervisor de Transportes',
    'Supervisor de Treinamento e Desenvolvimento',
    'Supervisor de Usinagem',
    'Supervisor de Usinagem, Conformação e Tratamento de Metais ',
    'Supervisor de Vendas',
    'Supervisor de Vendas e de Prestação de Serviços ',
    'Supervisor de Vendas e Prestação de Serviços do Comércio',
    'Supervisor de Vidraria, Cerâmica e Afins ',
    'Supervisor de Vigilante ',
    'Supervisor Dos Serviços de Transporte, Turismo, Hotelaria e Administração de Edifícios',
    'Supervisor Dos Serviços e do Comércio',
    'Supervisor em Indústria de Madeira, Mobiliário e da Carpintaria Veicular',
    'Supervisor Financeiro',
    'Supervisor Florestal',
    'Supervisor Industrial',
    'Supervisor Na Área Florestal e Aqüicultura',
    'Supervisor Na Confecção de Calçados ',
    'Supervisor Na Confecção do Vestuário',
    'Supervisor Na Exploração Agropecuária ',
    'Supervisor Na Fabricação de Materiais Para Construção - Vidros e Cerâmicas',
    'Supervisor Pedagógico',
    'Supervisor Técnico',
    'Supervisor Tributário',
    'Surfista',
    'Sushiman',
    'Tabelião',
    'Tabelião de Notas',
    'Tabelião de Notas e de Protesto',
    'Tabelião de Protestos de Títulos',
    'Tabelião de Registro de Contratos Marítimos',
    'Taifeiro',
    'Taifeiro (Exceto Militares)',
    'Taifeiro Mor',
    'Tapeceiro',
    'Tapeceiro de Veículos',
    'Taquigrafo',
    'Tarólogo',
    'Tatuador',
    'Taxista',
    'Tecelão',
    'Técnico Administrativo',
    'Técnico Agrícola',
    'Tecnico Ativ Rodoviario',
    'Técnico Auxiliar de Regulação Médica',
    'Técnico Bancário',
    'Técnico Comercial',
    'Técnico Contábil',
    'Técnico da Biotecnologia',
    'Técnico da Ciência da Saúde Animal',
    'Técnico da Ciência da Saúde Humana',
    'Técnico da Conservação',
    'Técnico da Qualidade',
    'Técnico Das Ciências Administrativas e Contabéis',
    'Técnico de Apoio À Bioengenharia',
    'Técnico de Apoio À Biotecnologia',
    'Técnico de Apoio em Pesquisa e Desenvolvimento',
    'Técnico de Apoio em Pesquisa e Desenvolvimento Agropecuário Florestal',
    'Técnico de Arquivo',
    'Técnico de Assistência Técnica',
    'Técnico de Atendimento',
    'Técnico de Bioquímica',
    'Técnico de Campo',
    'Técnico de Celular',
    'Técnico de Celulose e Papel',
    'Técnico de Contabilidade',
    'Técnico de Controle de Meio Ambiente',
    'Técnico de Controle de Qualidade',
    'Técnico de Coordenação Administrativa',
    'Técnico de Desenvolvimento de Sistemas e Aplicações ',
    'Técnico de Desporto Individual e Coletivo (Exceto Futebol)',
    'Técnico de Dissecação',
    'Técnico de Enfermagem',
    'Técnico de Enfermagem Psiquiátrica',
    'Técnico de Equipamentos',
    'Técnico de Equipamentos Médicos',
    'Técnico de Eventos',
    'Técnico de Fiscalização',
    'Técnico de Gasoterapia',
    'Técnico de Gesso',
    'Técnico de Help Desk',
    'Técnico de Iluminação',
    'Técnico de Implantação',
    'Técnico de Impressora',
    'Técnico de Infraestrutura',
    'Técnico de Inspeção',
    'Técnico de Instalação',
    'Técnico de Laboratório',
    'Técnico de Laboratório de Análises Físico-Químicas',
    'Técnico de Laboratório e Fiscalização Desportiva',
    'Técnico de Laboratório Industrial',
    'Técnico de Manutenção de Computadores',
    'Técnico de Manutenção Elétrica',
    'Técnico de Manutenção Elétrica ',
    'Técnico de Manutenção Industrial',
    'Técnico de Manutenção Máquinas, Sistemas e Instrumentos',
    'Técnico de Manutenção Predial',
    'Técnico de Materiais',
    'Técnico de Meteorologia',
    'Técnico de Monitoramento',
    'Técnico de Montagem',
    'Técnico de Nível Médio Das Ciências Físicas',
    'Técnico de Nível Médio Das Ciências Químicas',
    'Técnico de Odontologia ',
    'Técnico de Operação de Emissoras de Rádio ',
    'Técnico de Planejamento',
    'Técnico de Planejamento de Manutenção',
    'Técnico de Planejamento e Controle de Produção',
    'Técnico de Planejamento e Pesquisa',
    'Técnico de Processos',
    'Técnico de Produção',
    'Técnico de Produto',
    'Técnico de Raios-X',
    'Técnico de Redes',
    'Técnico de Seguros',
    'Técnico de Service Desk',
    'Técnico de Serviços Culturais',
    'Técnico de Sinistro',
    'Técnico de Som',
    'Técnico de Suporte',
    'Técnico de Tacógrafo',
    'Técnico de Testes',
    'Técnico de Utilidade',
    'Técnico de Vendas',
    'Técnico de Vendas Especializadas',
    'Tecnico Desportivo',
    'Técnico do Mobiliário',
    'Técnico do Vestuário',
    'Técnico e Auxiliares Técnicos em Patologia Clínica',
    'Técnico e Inspetor de Soldagem',
    'Técnico Eletricista',
    'Técnico Elétro-Eletrônica',
    'Técnico Eletroeletrônico',
    'Técnico Eletromecânico',
    'Técnico em Administração',
    'Técnico em Agrimensura',
    'Técnico em Agropecuária',
    'Técnico em Alimentos',
    'Técnico em Análises Clínicas',
    'Técnico em Aqüicultura ',
    'Técnico em Artes Gráficas ',
    'Técnico em Assuntos Educacionais',
    'Técnico em Áudio',
    'Técnico em Áudio e Vídeo',
    'Técnico em Automação Bancária',
    'Técnico em Automação Industrial',
    'Técnico em Automação Predial',
    'Técnico em Biblioteconomia',
    'Técnico em Biologia',
    'Técnico em Cabeamento',
    'Técnico em Caldeiraria',
    'Técnico em Calibração e Instrumentação ',
    'Técnico em Cenografia ',
    'Técnico em Cftv',
    'Técnico em Ciências Físicas e Químicas',
    'Técnico em Construção Civil',
    'Técnico em Contabilidade',
    'Técnico em Curtimento',
    'Técnico em Documentação',
    'Técnico em Edificações',
    'Técnico em Eletrocardiograma',
    'Técnico em Eletroencefalograma',
    'Técnico em Eletromecânica',
    'Técnico em Eletrônica',
    'Técnico em Eletrotécnica',
    'Técnico em Enfermagem',
    'Técnico em Enfermagem do Trabalho',
    'Técnico em Equipamentos Médicos e Odontológicos ',
    'Técnico em Espirometria',
    'Técnico em Esquadrias de Alumínio',
    'Técnico em Estradas',
    'Técnico em Estruturas Metálicas',
    'Técnico em Fabricação de Produtos Plásticos e de Borracha',
    'Técnico em Farmácia',
    'Técnico em Farmácia e em Manipulação Farmacêutica ',
    'Técnico em Fibras Ópticas',
    'Técnico em Fotônica ',
    'Técnico em Gastronomia',
    'Técnico em Geologia',
    'Técnico em Geomática ',
    'Técnico em Geoprocessamento',
    'Técnico em Hemoterapia',
    'Técnico em Higiene Ocupacional',
    'Técnico em Imobilização Ortopédica',
    'Técnico em Informática',
    'Técnico em Inspeção de Equipamentos',
    'Técnico em Instrumentação',
    'Técnico em Licitação',
    'Técnico em Logística',
    'Técnico em Lubrificação',
    'Técnico em Manutenção',
    'Técnico em Manutenção Automotiva',
    'Técnico em Manutenção de Balanças',
    'Técnico em Manutenção de Elevadores',
    'Técnico em Manutenção de Equipamentos e Instrumentos Médico-Hospitalares',
    'Técnico em Manutenção de Hidrômetros',
    'Técnico em Manutenção de Instrumentos de Medição e Precisão',
    'Técnico em Materiais, Produtos Cerâmicos e Vidros',
    'Técnico em Mecânica',
    'Técnico em Mecânica Veicular',
    'Técnico em Mecatrônica',
    'Técnico em Mecatrônica (Robôtica)',
    'Técnico em Medição',
    'Técnico em Medicina Nuclear',
    'Técnico em Meio Ambiente',
    'Técnico em Metalmecânica',
    'Técnico em Metalurgia',
    'Técnico em Metrologia',
    'Técnico em Mineração',
    'Técnico em Mineralogia',
    'Técnico em Montagem, Edição e Finalização de Filme e Vídeo',
    'Técnico em Museologia ',
    'Técnico em Navegação Aérea',
    'Técnico em Navegação Marítima',
    'Técnico em Necropsia',
    'Técnico em Necrópsia e Taxidermistas',
    'Técnico em Nutrição',
    'Técnico em Operação de Aparelhos de Projeção ',
    'Técnico em Operação de Aparelhos de Sonorização',
    'Técnico em Operação de Estações de Rádio e Televisão',
    'Técnico em Operação de Sistemas de Televisão e de Produtoras de Vídeo ',
    'Técnico em Operação e Monitoração de Computadores',
    'Técnico em Operações de Máquina',
    'Técnico em Operações e Serviços Bancários',
    'Técnico em Óptica',
    'Técnico em Óptica e Optometria',
    'Técnico em Optometria',
    'Técnico em Orientação e Mobilidade',
    'Técnico em Panificação',
    'Técnico em Pecuária',
    'Técnico em Petroquímica',
    'Técnico em Plásticos',
    'Técnico em Polissonografia',
    'Técnico em Processos Industriais',
    'Técnico em Produção Agropecuária',
    'Técnico em Produção, Conservação  e de Qualidade de Alimentos',
    'Técnico em Projetos',
    'Técnico em Próteses Ortopédicas',
    'Técnico em Química',
    'Técnico em Radiologia',
    'Técnico em Recursos Humanos',
    'Técnico em Refrigeração',
    'Técnico em Saneamento',
    'Técnico em Saúde Bucal',
    'Técnico em Secretariado',
    'Técnico em Secretariado, Taquígrafos e Estenotipistas',
    'Técnico em Segurança do Trabalho',
    'Técnico em Segurança e Meio Ambiente',
    'Técnico em Segurança Eletrônica',
    'Técnico em Segurança No Trabalho',
    'Técnico em Seguros',
    'Técnico em Serviço de Turismo e Organização de Eventos ',
    'Técnico em Siderurgia',
    'Técnico em Tecnologia da Informação',
    'Técnico em Telecomunicações',
    'Técnico em Terapias Complementares',
    'Técnico em Transportes',
    'Técnico em Transportes Aéreos',
    'Técnico em Transportes Metroferroviários - Ferrovia e Metrô ',
    'Técnico em Transportes Por Vias Navegáveis e Operações Portuárias ',
    'Técnico em Transportes Rodoviários',
    'Técnico em Tratamento de Efluentes',
    'Técnico em Turismo',
    'Técnico Empalhamento de Corpos',
    'Técnico Financeiro',
    'Técnico Físico Nuclear',
    'Técnico Florestal',
    'Técnico Fluvial',
    'Técnico Hidráulico',
    'Técnico Industrial',
    'Técnico Judiciário',
    'Técnico Marítimo e Fluviário de Máquinas ',
    'Técnico Marítimo, Fluviário e Pescador de Convés ',
    'Técnico Mecânico (Energia Nuclear)',
    'Técnico Mecânico (Ferramentas)',
    'Técnico Mecânico de Manutenção',
    'Técnico Mecânico e em Mecânica de Precisão - Exceto Técnico Mecânico Energia Nuclear',
    'Técnico Metroviário',
    'Tecnico Mineiração',
    'Técnico Nacional de Controle',
    'Técnico Operacional',
    'Técnico Orçamentista',
    'Técnico Projetista',
    'Técnico Químico ',
    'Técnico Químico de Petróleo',
    'Tecnico Refrigeraçao',
    'Tecnico Telecomunicações',
    'Técnico Têxtil',
    'Técnico Tráfego em Aeroportos',
    'Tecnólogo em Alimentos',
    'Tecnólogo em Construção Civil',
    'Tecnólogo em Gastronomia ',
    'Tecnólogo em Mecânica',
    'Tecnólogo em Petróleo e Gás e em Rochas Ornamentais',
    'Tecnólogo em Produção Industrial Ou em Segurança do Trabalho',
    'Tecnólogo em Radiologia',
    'Tecnólogo em Redes de Computadores',
    'Tecnólogo em Saneamento Ambiental',
    'Telefonista',
    'Telefonista Recepcionista',
    'Telegrafista',
    'Telhadista',
    'Telhador',
    'Temperador de Metais e Vidros',
    'Temperador de Vidro',
    'Tenente Brigadeiro - Exército, Marinha e Aeronáutica',
    'Tenente Coronel - Exército, Marinha e Aeronáutica',
    'Tenente do Corpo de Bombeiros Militar',
    'Teólogo ',
    'Terapeuta Ocupacional',
    'Terceiro Sargento - Exército, Marinha e Aeronáutica',
    'Tesoureiro',
    'Tintureiro',
    'Tintureiro e Lavadeiro A Máquina e A Mão ',
    'Tipógrafo',
    'Titeriteiro',
    'Topógrafo',
    'Torneiro (Lavra de Pedra)',
    'Torneiro Ferramenteiro',
    'Torneiro Mecânico',
    'Torrista (Petróleo)',
    'Tosador',
    'Trabalhador Agrícola Na Cultura de Gramíneas ',
    'Trabalhador Agrícola Na Cultura de Plantas Fibrosas',
    'Trabalhador Agrícola Na Cultura de Plantas Oleaginosas',
    'Trabalhador Agrícola Na Olericultura',
    'Trabalhador Agrícola Nas Culturas de Plantas Estimulantes',
    'Trabalhador Agrícola No Cultivo de Flores e Plantas Ornamentais ',
    'Trabalhador Agropecuário',
    'Trabalhador Aquaviário ',
    'Trabalhador Artes Gráficas',
    'Trabalhador Artesanal da Confecção de Calçados e Artefatos de Couros e Peles ',
    'Trabalhador Artesanal da Confecção de Peças e Tecidos ',
    'Trabalhador Artesanal Na Conservação de Alimentos ',
    'Trabalhador Artesanal Na Pasteurização do Leite e Na Fabricação de Laticínios e Afins',
    'Trabalhador Conservador em Areas Publicas',
    'Trabalhador da Caprinocultura',
    'Trabalhador da Classificação de Fibras Têxteis e Lavagem de Lã ',
    'Trabalhador da Confecção de Artefatos de Tecidos e Couros',
    'Trabalhador da Construção Civil',
    'Trabalhador da Construção Naval',
    'Trabalhador da Cultura de Especiarias',
    'Trabalhador da Exploração de Espécies Produtoras de Gomas Não Elásticas',
    'Trabalhador da Exploração de Resinas',
    'Trabalhador da Fabricação de Alimentos',
    'Trabalhador da Fabricação de Bebidas',
    'Trabalhador da Fabricação de Munição e Explosivos Químicos',
    'Trabalhador da Fabricação de Pedras Artificiais',
    'Trabalhador da Impressão Gráfica',
    'Trabalhador da Industria Extrativista',
    'Trabalhador da Irrigação e Drenagem',
    'Trabalhador da Mecanização Agrícola ',
    'Trabalhador da Mecanização Florestal ',
    'Trabalhador da Ovinocultura',
    'Trabalhador da Pintura de Equipamentos, Veículos e de Compósitos',
    'Trabalhador da Pré-Impressão Gráfica',
    'Trabalhador da Preparação da Confecção de Calçados',
    'Trabalhador da Preparação da Confecção de Roupas ',
    'Trabalhador da Preparação de Artefatos de Tecidos, Couros e Tapeçaria - Exceto Roupas e Calçados ',
    'Trabalhador da Preparação do Curtimento de Couros e Peles ',
    'Trabalhador da Suinocultura',
    'Trabalhador da Transformação de Metais e Compostos',
    'Trabalhador da Transformação de Vidros Planos',
    'Trabalhador Das Indústrias de Madeira',
    'Trabalhador Das Indústrias Têxteis',
    'Trabalhador de Acabamento, Tingimento e Estamparia Das Indústrias Têxteis',
    'Trabalhador de Apoio À Agricultura',
    'Trabalhador de Arte e  do Acabamento em Madeira do Mobiliário ',
    'Trabalhador de Atenção, Defesa e Proteção A Pessoas em Situação de Risco ',
    'Trabalhador de Atendimento Ao Publico',
    'Trabalhador de Caldeiraria e Serralheria ',
    'Trabalhador de Cargas e Descargas de Mercadorias ',
    'Trabalhador de Conservação e Manutenção',
    'Trabalhador de Embalagem e de Etiquetagem ',
    'Trabalhador de Estruturas de Alvenaria',
    'Trabalhador de Fabricação de Fumo',
    'Trabalhador de Floricultura',
    'Trabalhador de Forjamento de Metais ',
    'Trabalhador de Fundição de Metais Puros e de Ligas Metálicas',
    'Trabalhador de Instalação Eletro-Eletrônica',
    'Trabalhador de Instalações e Máquinas de Fabricação de Celulose',
    'Trabalhador de Instalações Elétricas ',
    'Trabalhador de Instalações Siderúrgicas',
    'Trabalhador de Laboratório Fotográfico',
    'Trabalhador de Manobras de Transportes Sobre Trilhos ',
    'Trabalhador de Materiais de Construção',
    'Trabalhador de Moldagem de Metais e de Ligas Metálicas ',
    'Trabalhador de Outras Instalações Agroindustriais',
    'Trabalhador de Pecuária Polivalente, Asininos, Muares, Bovinos Corte e Leite, Bubalinos, Equinos',
    'Trabalhador de Preparação de Pescados (Limpeza)',
    'Trabalhador de Reparação e Manutenção',
    'Trabalhador de Serviços de Limpeza e Conservação de Áreas Públicas',
    'Trabalhador de Serviços Veterinários, de Higiene e Estética de Animais Domésticos',
    'Trabalhador de Soldagem e Corte de Ligas Metálicas ',
    'Trabalhador de Tecelagem Manual, Tricô, Crochê, Rendas e Afins',
    'Trabalhador de Traçagem e Montagem de Estruturas Metálicas e de Compósitos',
    'Trabalhador de Tratamento de Superfícies de Metais e de Compósitos - Termoquímicos ',
    'Trabalhador de Tratamento e Preparação da Madeira',
    'Trabalhador de Tratamento Térmico de Metais ',
    'Trabalhador de Trefilação e Estiramento de Metais Puros e Ligas Metálicas ',
    'Trabalhador do Acabamento de Artefatos de Tecidos e Couros',
    'Trabalhador do Acabamento de Couros e Peles',
    'Trabalhador do Beneficiamento de Fumo',
    'Trabalhador do Curtimento',
    'Trabalhador do Curtimento de Couros e Peles ',
    'Trabalhador do Mobiliário',
    'Trabalhador do Vestuário',
    'Trabalhador Dos Serviços de Administração',
    'Trabalhador Dos Serviços de Embelezamento e Cuidados Pessoais',
    'Trabalhador Dos Serviços de Hotelaria e Alimentação',
    'Trabalhador Dos Serviços de Proteção e Segurança',
    'Trabalhador Dos Serviços de Saúde',
    'Trabalhador Dos Serviços Domésticos em Geral',
    'Trabalhador e Auxiliares Dos Serviços Funerários ',
    'Trabalhador Elementar de Serviços de Manutenção Veicular ',
    'Trabalhador em Criatórios de Animais Produtores de Veneno ',
    'Trabalhador Florestal Polivalente',
    'Trabalhador Na Apicultura',
    'Trabalhador Na Avicultura e Cunicultura',
    'Trabalhador Na Degustação e Classificação de Grãos e Afins ',
    'Trabalhador Na Exploração Agropecuária',
    'Trabalhador Na Fabricação de Cachaça, Cerveja, Vinhos e Outras Bebidas ',
    'Trabalhador Na Fabricação de Produtos Abrasivos',
    'Trabalhador Na Industrialização de Café, Cacau, Mate e de Produtos Afins ',
    'Trabalhador Na Minhocultura ',
    'Trabalhador Na Operação de Máquinas de Concreto Usinado ',
    'Trabalhador Na Pasteurização do Leite e Fabricação de Laticínios e Afins',
    'Trabalhador Na Sericicultura',
    'Trabalhador No Cultivo de Árvores Frutíferas',
    'Trabalhador Nos Serviços de Administração de Edifícios ',
    'Trabalhador Nos Serviços de Embelezamento e Higiene ',
    'Trabalhador Operacional de Conservação de Vias Permanentes (Exceto Trilhos)',
    'Trabalhador Polivalente da Confecção de Artefatos de Tecidos e Couros - Exceto Sapatos ',
    'Trabalhador Polivalente da Confecção de Calçados',
    'Trabalhador Polivalente do Curtimento de Couros e Peles',
    'Trabalhador Submarino',
    'Trabalhadores Com Produtos Explosivos Ou Substâncias Químicas Corrosivas',
    'Trabalhadores em Instalação Ou Manutenção de Linhas de Alta Tensão',
    'Trabalhor Das Industrias Química, Petroquímica',
    'Traçador de Caldeiraria',
    'Traçador de Pedras',
    'Trader',
    'Tradutor',
    'Trainee',
    'Trançador e Laceiro de Cabos de Aço',
    'Trapezista',
    'Tratador de Animais (Vacinador)',
    'Tratador de Piscina',
    'Tratorista',
    'Trefilador',
    'Treinador de Animais (Circense) ',
    'Treinador Profissional de Futebol ',
    'Trocador de Moldes',
    'Turismólogo',
    'Urbanista ',
    'Vaqueiro',
    'Vendedor',
    'Vendedor  Permissionário ',
    'Vendedor Ambulante',
    'Vendedor Autonomo',
    'Vendedor de Consórcio',
    'Vendedor de E-Commerce',
    'Vendedor de Franquias',
    'Vendedor de Frete',
    'Vendedor de Informática',
    'Vendedor de Loja',
    'Vendedor de Máquinas',
    'Vendedor de Seguros',
    'Vendedor de Telecomunicações',
    'Vendedor de Veículos',
    'Vendedor em Domicílio',
    'Vendedor Externo',
    'Vendedor Industrial',
    'Vendedor Ou Representante Comercial Banca de Jornais e Revistas',
    'Vendedor Ou Representante Comercial da Construção Civil',
    'Vendedor Ou Representante Comercial de Produtos Agrícolas',
    'Vendedor Ou Representante Comercial de Produtos Alimentícios',
    'Vendedor Ou Representante Comercial Emp. Importação e Exportação',
    'Vendedor Ou Representante Comercial Esportivo',
    'Vendedor Projetista',
    'Vendedor Técnico',
    'Ventríloquo',
    'Vereador',
    'Veterinario',
    'Veterinário Patologista',
    'Vibradorista',
    'Vice Almirante - Marinha',
    'Vice Almirante - Marinha Mercante',
    'Vice-Governador de Estado',
    'Vice-Governador do Distrito Federal',
    'Vice-Prefeito',
    'Vice-Presidente da República',
    'Vidente',
    'Videografista',
    'Vidraceiro',
    'Vidreiro e Ceramista - Arte e Decoração ',
    'Vigia',
    'Vigia (Vigia Noturno)',
    'Vigia Florestal',
    'Vigia Portuário',
    'Vigilante',
    'Vigilante de Escolta',
    'Vigilante de Saúde',
    'Vigilante Líder',
    'Visitador de Navios',
    'Visitador Sanitário',
    'Vistoriador de Empresas',
    'Vistoriador de Imóveis',
    'Vistoriador de Veículos',
    'Vistoriador Naval',
    'Visual Merchandiser',
    'Vitrinista',
    'Web Designer',
    'Web Developer',
    'Webmaster',
    'Zelador',
    'Zootecnista',
]
 
 export default profissaoList