import LABEL from '../../business-const/Label/Label'



const tipoReceitaMensalList = [
    {
        id: 10, // HARDCODE.idTipoReceitaMensalProfissionalLiberal
        descricao: 'Profissional Liberal',
        ordem: 10,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "I",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "S",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'AA',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
    {
        id: 20, // HARDCODE.idTipoReceitaMensalCLT
        descricao: 'CLT',
        ordem: 20,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'S',
        informaInssPensao: "I",
        informaSeguroVidaEmGrupo: 'S',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "S",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "S",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'AA',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
    {
        id: 30,
        descricao: 'Bônus',
        ordem: 30,
        tipoPeriodicidadeRecebtoDefault: 'A',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "S",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'AA',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
    {
        id: 40,
        descricao: 'Funcionário Público',
        ordem: 40,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "P",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "S",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'AA',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
    {
        id: 50,
        descricao: 'Pró-labore',
        ordem: 50,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "I",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "S",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'AA',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
    {
        id: 60,
        descricao: 'Distribuição de Lucro',
        ordem: 60,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "S",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'AA',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
    {
        id: 63,
        descricao: 'Dividendos',
        ordem: 63,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "S",
        cobreMorteSolteiroSemDependente: "S",
        cobreInvalidez: "S",
        cobreMorteInvalidezClienteEConjuge: "S",
        cobreMorteInvalidezClienteOuConjuge: "N",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'VT',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
    {
        id: 65, // HARDCODE.idTipoReceitaMensalRendaMensal // Integração
        descricao: 'Renda Mensal',
        ordem: 65,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "S",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "N",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'AA',
        permiteAlterarTipoCalculoAcumulado: 'N',
    },
    {
        id: 70, // HARDCODE.idTipoReceitaMensalAluguel
        descricao: 'Aluguel',
        ordem: 70,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "S",
        cobreMorteSolteiroSemDependente: "S",
        cobreInvalidez: "S",
        cobreMorteInvalidezClienteEConjuge: "S",
        cobreMorteInvalidezClienteOuConjuge: "N",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'VT',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
    {
        id: 80,
        descricao: 'Pensão Pessoal',
        ordem: 80,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "S",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'VT',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
    {
        id: 85,
        descricao: 'Pensão Família',
        ordem: 85,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "S",
        cobreMorteSolteiroSemDependente: "S",
        cobreInvalidez: "S",
        cobreMorteInvalidezClienteEConjuge: "S",
        cobreMorteInvalidezClienteOuConjuge: "N",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'VT',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
    {
        id: 90,
        descricao: 'INSS - Benefício Atual',
        ordem: 90,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "S",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "S",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "N",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'VT',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
    {
        id: 100, // HARDCODE.idTipoReceitaMensalInssRendaInvalidez
        descricao: 'INSS - Renda p/ Invalidez',
        ordem: 100,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "S",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "N",
        cobreSobrevivencia: "N",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "N",
        totalizaEntradaDados: 'N',
        tipoCalculoAcumulado: 'VT',
        permiteAlterarTipoCalculoAcumulado: 'N',
    },
    {
        id: 110, // HARDCODE.idTipoReceitaMensalInssRendaMorte
        descricao: 'INSS - Renda p/ Morte',
        ordem: 110,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "S",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "N",
        cobreSobrevivencia: "N",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "N",
        totalizaEntradaDados: 'N',
        tipoCalculoAcumulado: 'VT',
        permiteAlterarTipoCalculoAcumulado: 'N',
    },
    {
        id: 120, // HARDCODE.idTipoReceitaMensalInssRendaAposentadoria
        descricao: 'INSS - Renda ' + LABEL.aposentadoria,
        ordem: 120,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "S",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "N",
        totalizaEntradaDados: 'N',
        tipoCalculoAcumulado: 'II',
        permiteAlterarTipoCalculoAcumulado: 'N',
    },
    {
        id: 140, // HARDCODE.idTipoReceitaMensalPensaoRendaInvalidez
        descricao: 'Pensão - Renda p/ Invalidez',
        ordem: 140,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "S",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "N",
        cobreSobrevivencia: "N",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "N",
        totalizaEntradaDados: 'N',
        tipoCalculoAcumulado: 'VT',
        permiteAlterarTipoCalculoAcumulado: 'N',
    },
    {
        id: 150, // HARDCODE.idTipoReceitaMensalPensaoRendaMorte
        descricao: 'Pensão - Renda p/ Morte',
        ordem: 150,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "S",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "N",
        cobreSobrevivencia: "N",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "N",
        totalizaEntradaDados: 'N',
        tipoCalculoAcumulado: 'VT',
        permiteAlterarTipoCalculoAcumulado: 'N',
    },
    {
        id: 160, // HARDCODE.idTipoReceitaMensalPensaoRendaAposentadoria
        descricao: 'Pensão - Renda ' + LABEL.aposentadoria,
        ordem: 160,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "S",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "N",
        totalizaEntradaDados: 'N',
        tipoCalculoAcumulado: 'PA',
        permiteAlterarTipoCalculoAcumulado: 'N',
    },
    {
        id: 900, // HARDCODE.idTipoReceitaMensalOutrasAteAposentadoria
        descricao: 'Outras - Até a ' + LABEL.aposentadoria,
        ordem: 900,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "S",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'AA',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
    {
        id: 905,
        descricao: 'Outras - Após a ' + LABEL.aposentadoria + ' Vitalícia',
        ordem: 905,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "S",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'PA',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
    {
        id: 910,
        descricao: 'Outras - Até a Expectativa de Vida',
        ordem: 910,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "N",
        cobreMorteSolteiroSemDependente: "N",
        cobreInvalidez: "N",
        cobreMorteInvalidezClienteEConjuge: "N",
        cobreMorteInvalidezClienteOuConjuge: "S",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'VT',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
    {
        id: 920,
        descricao: 'Outros - Renda Passiva',
        ordem: 920,
        tipoPeriodicidadeRecebtoDefault: 'M',
        informaValorBruto: 'N',
        informaInssPensao: "N",
        informaSeguroVidaEmGrupo: 'N',
        cobreMorte: "S",
        cobreMorteSolteiroSemDependente: "S",
        cobreInvalidez: "S",
        cobreMorteInvalidezClienteEConjuge: "S",
        cobreMorteInvalidezClienteOuConjuge: "N",
        cobreSobrevivencia: "S",
        calculaFGTSContribuicao: "N",
        permiteEntradaDados: "S",
        totalizaEntradaDados: 'S',
        tipoCalculoAcumulado: 'VT',
        permiteAlterarTipoCalculoAcumulado: 'S',
    },
]

export default tipoReceitaMensalList