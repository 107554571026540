import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import AppContext from './contexts/AppContext'
import history from 'history.js'
import routes from './RootRoutes'
import { GlobalCss, MatxSuspense, MatxTheme, MatxLayout } from 'app/components'
import sessionRoutes from './views/sessions/SessionRoutes'
import { SettingsProvider } from 'app/contexts/SettingsContext'

import 'toastr/build/toastr.min.js'
import 'toastr/build/toastr.css'

import {
    primeiroDiaMesAtual,
    ultimoDiaMesAtual,
} from './business-components/Date/Date'

import camposConsorcioList from './business-rules/List/Consorcio/camposConsorcioList'

import camposPropostaList from './business-rules/List/SeguroVida/camposPropostaList'

import camposApoliceList from './business-rules/List/SeguroVida/camposApoliceList'

import camposApoliceCobrancaList from './business-rules/List/SeguroVida/camposApoliceCobrancaList'

import camposApoliceComissaoList from './business-rules/List/SeguroVida/camposApoliceComissaoList'

import camposClienteAgenteList from './business-rules/List/camposClienteAgenteList'

import {
    inicializaControleDashboard,
} from './views/dashboard/dashboardComponents/DashboardService'



const App = () => {

    let variaveisGlobais = {
        configuracaoUsuario: undefined,
        equipeList: undefined,
        acessoCliente: false,
        acessoClienteChaves: {},
        clienteSelecionado: undefined,
        ultimaVersaoClienteSelecionado: undefined,
        jsonComparacaoEstudosClienteSelecionado: undefined,
        versaoSelecionada: undefined,
        usuarioClienteSelecionado: undefined,
        usuarioClienteSelecionadoIdentificacao: undefined,
        exibirListaAgenda: true,
        exibirListaAniversario: true,
        exibirListaAporte: true,
        dashboardFamilia: inicializaControleDashboard(null, null, false, ''),
        dashboardConsorcio: inicializaControleDashboard('dataAssembleia', camposConsorcioList, false, ''),
        dashboardProjetoVidaSonho: inicializaControleDashboard(null, null, false, ''),
        dashboardProposta: inicializaControleDashboard('dataAssinatura', camposPropostaList, false, ''),
        dashboardApolice: inicializaControleDashboard('dataEmissao', camposApoliceList, false, 'producaoPropria'),
        dashboardApoliceCobranca: inicializaControleDashboard('dataVencimento', camposApoliceCobrancaList, true, 'producaoPropria'),
        dashboardApoliceComissao: inicializaControleDashboard('dataGeracaoComissao', camposApoliceComissaoList, false, ''),
        dashboardClienteAgente: inicializaControleDashboard(null, camposClienteAgenteList, false, ''),
        dataInicialAporte: primeiroDiaMesAtual(),
        dataFinalAporte: ultimoDiaMesAtual(),
        exibirStatusAportePendente: true,
        exibirStatusAporteNaoRealizado: false,
        exibirStatusAporteRealizado: false,
    }

    return (
        <AppContext.Provider value={{ variaveisGlobais, routes }}>
            <SettingsProvider>
                <MatxTheme>
                    <GlobalCss />
                    <Router history={history}>
                        <MatxSuspense>
                            <Switch>
                                {sessionRoutes.map((item, i) => (
                                    <Route
                                        key={i}
                                        path={item.path}
                                        component={item.component}
                                    />
                                ))}
                                <MatxLayout
                                    variaveisGlobais={variaveisGlobais}
                                />{' '}
                            </Switch>
                        </MatxSuspense>
                    </Router>
                </MatxTheme>
            </SettingsProvider>
        </AppContext.Provider>
    )
}

export default App