import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    nvl,
    formataTableCellBodyExibeDadosTexto,
    formataTableCellBodyExibeDadosValor,
} from '../../../business-components/Formata/Formata'

import {
    mes,
    ano,
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    calculaJurosMensal,
} from '../cliente-form/ClienteService'

import {
    ajustaValorNegativo,
    calculaValorPresente,
    calculaIndexGraficoAnosIdades,
    calculaJaAposentou,
    calculaAnosDepoisAposentar,
    calculaMesesDepoisAposentar,
    calculaMesesAteAposentar,
} from '../estudo-form/EstudoServiceCalculo'

import {
    calculaTaxaRetornoLiquida,
    calculaValorPresenteValorFuturo,
} from '../estudo-form/EstudoServiceMatematicaFinanceira'

import {
    existeTabelaEvolucaoValorResgate,
} from '../seguroVidaCotacao-form/SeguroVidaCotacaoServiceCotacao'



export const copiaArray = ( array ) => {

    let novoArray = []

    array?.forEach( ( itemArray, index ) => {

        novoArray.push(itemArray)
    })

    return novoArray
}



export const copiaValores = ( array ) => {

    let novoArray = []

    array?.forEach( ( itemArray, index ) => {

        novoArray.push(itemArray.recursosFinanceirosFinal)
    })

    return novoArray
}



export const calculaSimulacaoFinanceiraEvolucaoReservaFinanceiraGraficosPlanilha = ( values, estudo, report,
    origem ) => {

    const evolucaoReservaFinanceira = copiaArray(estudo.current.evolucaoReservaFinanceira)

    let valorAporteAnt
    let valorRetiradaAnt

    if (
            origem === HARDCODE.origemCalculoEstudoPrimeiraReuniaoPlanilha ||
            origem === HARDCODE.origemCalculoEstudoPrimeiraReuniaoReport
    ) {

        calculaSimulacaoFinanceiraEvolucaoReservaFinanceira(values, estudo, true)

        const aporteMensalAdicionalConsumirReservaAposentadoria =
            values.aporteMensalAdicionalConsumirReservaAposentadoria

        const retiradaMensalMaximaPreservarReservaAposentadoria =
            values.retiradaMensalMaximaPreservarReservaAposentadoria
            
        const retiradaMensalMaximaConsumirReservaAposentadoria =
            values.retiradaMensalMaximaConsumirReservaAposentadoria



        if (values.aporteMensalAdicionalPreservarReservaAposentadoria !== 0) {

            valorAporteAnt = values.aporteMensalAntesAposentarCliente

            if (values.aporteMensalAntesAposentarCliente) {

                values.aporteMensalAntesAposentarCliente =
                    round(values.aporteMensalAntesAposentarCliente, 2) + 
                        values.aporteMensalAdicionalPreservarReservaAposentadoria
                
            } else {

                values.aporteMensalAntesAposentarCliente = values.aporteMensalAdicionalPreservarReservaAposentadoria
            }

            estudo.current.evolucaoReservaFinanceira = copiaArray(evolucaoReservaFinanceira)

            calculaSimulacaoFinanceiraEvolucaoReservaFinanceira(values, estudo, true)

            estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoViverRentabilidade =
                copiaValores(estudo.current.evolucaoReservaFinanceira)

            values.depositoViverRentabilidadeSaldoInicioAposentadoria =
                estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoViverRentabilidade[
                    nvl(calculaIndexGraficoAnosIdades(estudo.current.graficoIdades,
                        values.idadeVaiAposentarCliente), 0)]

            values.aporteMensalAntesAposentarCliente = valorAporteAnt

            values.reservaInicioAposentadoriaPrimeiraReuniaoViverRentabilidade = 
                estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoViverRentabilidade[
                    calculaIndexGraficoAnosIdades(estudo.current.graficoIdades, values.idadeVaiAposentarCliente)]
        } else {

            values.reservaInicioAposentadoriaPrimeiraReuniaoViverRentabilidade = 0
        }



        if (aporteMensalAdicionalConsumirReservaAposentadoria !== 0) {

            valorAporteAnt = values.aporteMensalAntesAposentarCliente

            if (values.aporteMensalAntesAposentarCliente) {

                values.aporteMensalAntesAposentarCliente = 
                    round(values.aporteMensalAntesAposentarCliente, 2) +
                        aporteMensalAdicionalConsumirReservaAposentadoria
                
            } else {

                values.aporteMensalAntesAposentarCliente = aporteMensalAdicionalConsumirReservaAposentadoria
            }

            estudo.current.evolucaoReservaFinanceira = copiaArray(evolucaoReservaFinanceira)

            calculaSimulacaoFinanceiraEvolucaoReservaFinanceira(values, estudo, true)

            estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoIdeal =
                copiaValores(estudo.current.evolucaoReservaFinanceira)

            values.aporteMensalAntesAposentarCliente = valorAporteAnt

            values.reservaInicioAposentadoriaPrimeiraReuniaoSituacaoIdeal = 
                estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoIdeal[
                    calculaIndexGraficoAnosIdades(estudo.current.graficoIdades, values.idadeVaiAposentarCliente)]

        } else {

            values.reservaInicioAposentadoriaPrimeiraReuniaoSituacaoIdeal = 0
        }



        valorAporteAnt = values.aporteMensalDepoisAposentarCliente
        valorRetiradaAnt = values.retiradaMensalDepoisAposentarCliente

        values.aporteMensalDepoisAposentarCliente = null
        values.retiradaMensalDepoisAposentarCliente = retiradaMensalMaximaPreservarReservaAposentadoria

        estudo.current.evolucaoReservaFinanceira = copiaArray(evolucaoReservaFinanceira)

        calculaSimulacaoFinanceiraEvolucaoReservaFinanceira(values, estudo, false)
        
        estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoPreservarRecursosFinanceiros =
            copiaValores(estudo.current.evolucaoReservaFinanceira)

        values.retiradaMaximaPreservarRecursosFinanceirosSaldoInicioAposentadoria =
            estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoPreservarRecursosFinanceiros[
                nvl(calculaIndexGraficoAnosIdades(estudo.current.graficoIdades,
                    values.idadeVaiAposentarCliente), 0)]

        values.aporteMensalDepoisAposentarCliente = valorAporteAnt
        values.retiradaMensalDepoisAposentarCliente = valorRetiradaAnt



        valorAporteAnt = values.aporteMensalDepoisAposentarCliente
        valorRetiradaAnt = values.retiradaMensalDepoisAposentarCliente

        values.aporteMensalDepoisAposentarCliente = null
        values.retiradaMensalDepoisAposentarCliente = retiradaMensalMaximaConsumirReservaAposentadoria

        estudo.current.evolucaoReservaFinanceira = copiaArray(evolucaoReservaFinanceira)

        calculaSimulacaoFinanceiraEvolucaoReservaFinanceira(values, estudo, false)

        estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoConsumirRecursosFinanceiros =
            copiaValores(estudo.current.evolucaoReservaFinanceira)

        values.aporteMensalDepoisAposentarCliente = valorAporteAnt
        values.retiradaMensalDepoisAposentarCliente = valorRetiradaAnt

        estudo.current.graficoAnos?.forEach( (linhaGraficoAnos, index) => {

            if (linhaGraficoAnos < values.anoFinalVaiAposentarCliente) {

                estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoPreservarRecursosFinanceiros[index] = null
                estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoConsumirRecursosFinanceiros[index] = null
            }
        })
    }



    estudo.current.evolucaoReservaFinanceira = copiaArray(evolucaoReservaFinanceira)

    calculaSimulacaoFinanceiraEvolucaoReservaFinanceira(values, estudo, true)

    estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoReal = 
        copiaValores(estudo.current.evolucaoReservaFinanceira)

    if (values.reservaInicioAposentadoriaPrimeiraReuniaoViverRentabilidade === 0) {

        values.reservaInicioAposentadoriaPrimeiraReuniaoViverRentabilidade =
            estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoReal[
                nvl(calculaIndexGraficoAnosIdades(estudo.current.graficoIdades,
                    values.idadeVaiAposentarCliente), 0)]
    }

    if (values.reservaInicioAposentadoriaPrimeiraReuniaoSituacaoIdeal === 0) {

        values.reservaInicioAposentadoriaPrimeiraReuniaoSituacaoIdeal =
            estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoReal[
                nvl(calculaIndexGraficoAnosIdades(estudo.current.graficoIdades,
                    values.idadeVaiAposentarCliente), 0)]
    }

    values.reservaInicial = estudo.current.evolucaoReservaFinanceira[0].recursosFinanceirosInicio
    values.reservaInicioAposentadoria = estudo.current.evolucaoReservaFinanceira[
        nvl(calculaIndexGraficoAnosIdades(estudo.current.graficoIdades,
            values.idadeVaiAposentarCliente), 0)].recursosFinanceirosFinal
            
    let primeiro = true

    estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoReal?.forEach(
        (linhaGraficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoReal, index) => {

            if (linhaGraficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoReal >= 0) {

                estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoRealSuperavit.push(
                    linhaGraficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoReal
                )

                estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoRealDeficit.push(
                    null
                )

            } else {

                if (primeiro) {

                    if (index > 0) {

                        estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoRealDeficit[index -1] =
                            estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoReal[index - 1]
                    }

                    primeiro = false
                }

                estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoRealSuperavit.push(
                    null
                )

                estudo.current.graficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoRealDeficit.push(
                    linhaGraficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoReal
                )
            }

            //values.saldoFinalSituacaoAtual = linhaGraficoIndependenciaFinanceiraPrimeiraReuniaoSituacaoReal
    })

    formataEvolucaoReservaFinanceira(values, estudo, report)

    estudo.current.evolucaoReservaFinanceiraReport = []

    estudo.current.evolucaoReservaFinanceira?.forEach( (linhaEvolucaoReservaFinanceira, index) => {

        if (
                index % values.anosExibicaoRelatorioEvolucaoReservaFinanceira === 0 ||
                linhaEvolucaoReservaFinanceira.boldAposentadoria ||
                index === estudo.current.evolucaoReservaFinanceira.length - 1
        ) {

            estudo.current.evolucaoReservaFinanceiraReport.push(
                linhaEvolucaoReservaFinanceira
            )
        }
    })
}


const calculaValorAnualCorrespondenteMesInformado = (linhaEvolucaoReservaFinanceira, linhaAporteRetiradaAnual, mesAtual,
    anoAtual, anoFinalAposentar, fatorTaxaJurosMensal, fatorTaxaJurosMensalAposAposentadoria, aporteRetiradaAnual) => {

    if (linhaAporteRetiradaAnual.mes) {

        let taxaJurosMensalValorPresenteValorFuturo

        if (linhaEvolucaoReservaFinanceira.ano > anoFinalAposentar) {

            taxaJurosMensalValorPresenteValorFuturo = fatorTaxaJurosMensalAposAposentadoria * 100

        } else {

            taxaJurosMensalValorPresenteValorFuturo = fatorTaxaJurosMensal * 100
        }

        if (linhaEvolucaoReservaFinanceira.ano === anoAtual) {

            if (linhaAporteRetiradaAnual.mes > mesAtual) {

                aporteRetiradaAnual = calculaValorPresenteValorFuturo(aporteRetiradaAnual,
                    taxaJurosMensalValorPresenteValorFuturo, linhaAporteRetiradaAnual.mes - mesAtual)
            }

        } else {

            aporteRetiradaAnual = calculaValorPresenteValorFuturo(aporteRetiradaAnual,
                taxaJurosMensalValorPresenteValorFuturo, linhaAporteRetiradaAnual.mes - 1)
        }
    }

    return aporteRetiradaAnual
}



const calculaSimulacaoFinanceiraEvolucaoReservaFinanceira = ( values, estudo, consideraAporteRetidadaDepoisAposentar ) => {

    const anoAtual = ano(values.dataSimulacaoEstudo)
    const mesAtual = mes(values.dataSimulacaoEstudo)

    const anoFinalAposentar = values.anoFinalVaiAposentarCliente < anoAtual ? anoAtual - 1 : values.anoFinalVaiAposentarCliente
    const anoFinalSimulacao = values.anoFinalVitalicioClienteConjuge

    const jaAposentou = calculaJaAposentou(values, anoAtual)

    let fatorTaxaJurosAnual
    //let fatorTaxaJurosAnualAporte
    let fatorTaxaInflacaoAnual
    let fatorTaxaInflacaoAnualAporteEvolucaoInflacao
    let fatorTaxaJurosAnualAposAposentadoria
    //let fatorTaxaJurosAnualAporteAposAposentadoria
    let fatorTaxaInflacaoAnualAposAposentadoria

    if (values.tipoRetornoFinanceiro === HARDCODE.tipoRetornoFinanceiroNominal) {

        fatorTaxaJurosAnual = /*values.taxaJurosAnual / 100*/
            calculaTaxaRetornoLiquida(values.taxaJurosAnual, 0/*values.taxaInflacaoAnual*/,
                values.tipoMomentoTributacaoIR, values.percIR, values.tempoMinimoEntreAportesResgatesCalculoIRAnos
            ) / 100
        //fatorTaxaJurosAnualAporte = fatorTaxaJurosAnual
        fatorTaxaInflacaoAnual = values.taxaInflacaoAnual / 100
        fatorTaxaInflacaoAnualAporteEvolucaoInflacao = fatorTaxaInflacaoAnual
        fatorTaxaJurosAnualAposAposentadoria = /*values.taxaJurosAnualAposAposentadoria / 100*/
            calculaTaxaRetornoLiquida(values.taxaJurosAnualAposAposentadoria,
                0/*values.taxaInflacaoAnualAposAposentadoria*/, values.tipoMomentoTributacaoIR, values.percIR,
                    values.tempoMinimoEntreAportesResgatesCalculoIRAnos
            ) / 100
        //fatorTaxaJurosAnualAporteAposAposentadoria = fatorTaxaJurosAnualAposAposentadoria
        fatorTaxaInflacaoAnualAposAposentadoria = values.taxaInflacaoAnualAposAposentadoria / 100

    } else {

        fatorTaxaJurosAnual = values.taxaRetornoAnualReal / 100
        //fatorTaxaJurosAnualAporte = 0
        fatorTaxaInflacaoAnual = 0
        fatorTaxaInflacaoAnualAporteEvolucaoInflacao = values.taxaInflacaoAnual / 100
        fatorTaxaJurosAnualAposAposentadoria = values.taxaRetornoAnualRealAposAposentadoria / 100
        //fatorTaxaJurosAnualAporteAposAposentadoria = 0
        fatorTaxaInflacaoAnualAposAposentadoria = 0
    }

    const fatorTaxaJurosMensal = Math.pow(1 + fatorTaxaJurosAnual, 1 / 12) - 1
    const fatorTaxaJurosMensalAposAposentadoria = Math.pow(1 + fatorTaxaJurosAnualAposAposentadoria, 1 / 12) - 1
    const anosDepoisAposentar = calculaAnosDepoisAposentar(values, anoAtual, jaAposentou)//anoFinalSimulacao - anoFinalAposentar
    const mesesDepoisAposentar = calculaMesesDepoisAposentar(mesAtual, jaAposentou, anosDepoisAposentar)//anosDepoisAposentar * 12

    const mesesAteAposentar = calculaMesesAteAposentar(values, anoAtual, mesAtual, jaAposentou)//((anoFinalAposentar - anoAtual) * 12) + 12 - mes(values.dataSimulacaoEstudo) + 1

    //let recursosInicioAposentaria
    //let deficitDepoisAposentar = 0

    let recursosFinaneceirosInicioAposentadoria
    let necessidadesInicioAposentadoria
    let retiradaAposentadoriaTotal

    values.valorRecursosFinanceirosInicioAposentadoria = ''
    values.idadeLimiteReservaAposentadoria = ''
    values.valorRecursosFinanceirosFinalAposentadoria = ''

    values.totalAportesAteAposentadoria = 0



    const aporteRetiradaAnual = values.aporteRetiradaAnual ? [...values.aporteRetiradaAnual] : []

    if (
            existeTabelaEvolucaoValorResgate(values) && 
            (
                values.premioVidaInteiraOriundoCarteiraInvestimentos ||
                values.idadeSimularResgateVidaInteiraCliente ||
                values.idadeSimularResgateVidaInteiraConjuge
            )
    ) {

        aporteRetiradaAnual.push(
            ...values.simulacaoFinanceiraPremioVidaInteiraOriundoCarteiraInvestimentos
        )
    }



    estudo.current.evolucaoReservaFinanceira?.forEach( (linhaEvolucaoReservaFinanceira, indexEvolucaoReservaFinanceira) => {

        if (linhaEvolucaoReservaFinanceira.ano <= anoFinalAposentar) {

            estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensal =
                values.aporteMensalAntesAposentarCliente ? round(values.aporteMensalAntesAposentarCliente, 2) : 0

            values.totalAportesAteAposentadoria += round(values.aporteMensalAntesAposentarCliente *
                (
                    linhaEvolucaoReservaFinanceira.ano === anoAtual
                        ?
                            12 - mesAtual + 1
                        :
                            12
                ), 2)

            estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensal =
                values.retiradaMensalAntesAposentarCliente ? round(values.retiradaMensalAntesAposentarCliente, 2) : 0

        } else {

            estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensal =
                values.aporteMensalDepoisAposentarCliente ? round(values.aporteMensalDepoisAposentarCliente, 2) : 0

            estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensal =
                values.retiradaMensalDepoisAposentarCliente ? round(values.retiradaMensalDepoisAposentarCliente, 2) : 0

            //deficitDepoisAposentar += calculaJurosMensalSaldo(
            //    estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensal -
            //        estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensal, null, 0,
            //            null, anoAtual, null, anoFinalSimulacao, linhaEvolucaoReservaFinanceira.ano, values, estudo, 0, 0,
            //                null, null, null, null)
        }

        estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensalEvolucaoInflacao =
            estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensal

        estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensalEvolucaoInflacao =
            estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensal

        values.aporteRetiradaMensal?.forEach( (linhaAporteRetiradaMensal, indexAporteRetiradaMensal) => {

            if (
                    linhaEvolucaoReservaFinanceira.ano <= anoFinalAposentar ||
                    consideraAporteRetidadaDepoisAposentar
            ) {

                if (
                    linhaEvolucaoReservaFinanceira.idade >= linhaAporteRetiradaMensal.idadeInicial &&
                    linhaEvolucaoReservaFinanceira.idade <= linhaAporteRetiradaMensal.idadeFinal
                ) {

                    if (linhaAporteRetiradaMensal.aporteMensal) {

                        let aporteMensal = round(linhaAporteRetiradaMensal.aporteMensal, 2)

                        if (
                                (
                                    linhaAporteRetiradaMensal.percReajusteAporteMensal ||
                                    linhaAporteRetiradaMensal.aumentoAporteMensal
                                ) && 
                                linhaAporteRetiradaMensal.anosReajusteAumentoAporteMensal
                        ) {

                            const anosReajusteAumentoAporteMensal =
                                Math.trunc((linhaEvolucaoReservaFinanceira.idade - linhaAporteRetiradaMensal.idadeInicial) /
                                    linhaAporteRetiradaMensal.anosReajusteAumentoAporteMensal)

                            if (linhaAporteRetiradaMensal.percReajusteAporteMensal) {

                                aporteMensal = round(aporteMensal *
                                    Math.pow(1 + (linhaAporteRetiradaMensal.percReajusteAporteMensal / 100), anosReajusteAumentoAporteMensal), 2)

                            } else {

                                aporteMensal += round(linhaAporteRetiradaMensal.aumentoAporteMensal * anosReajusteAumentoAporteMensal, 2)
                            }
                        }

                        estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensal += aporteMensal
                            
                        estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensalEvolucaoInflacao += aporteMensal

                        values.totalAportesAteAposentadoria += round(aporteMensal *
                            (
                                linhaEvolucaoReservaFinanceira.ano === anoAtual
                                    ?
                                        12 - mesAtual + 1
                                    :
                                        12
                            ), 2)

                        //if (linhaEvolucaoReservaFinanceira.ano > anoFinalAposentar) {
                        //    deficitDepoisAposentar += calculaJurosMensalSaldo(
                        //        round(linhaAporteRetiradaMensal.aporteMensal, 2), null, 0,
                        //            null, anoAtual, null, anoFinalSimulacao, linhaEvolucaoReservaFinanceira.ano,
                        //                values, estudo, 0, 0, null, null, null, null)
                        //}
                    }
                    
                    if (linhaAporteRetiradaMensal.retiradaMensal) {
                        
                        let retiradaMensal = round(linhaAporteRetiradaMensal.retiradaMensal, 2)

                        if (
                                (
                                    linhaAporteRetiradaMensal.percReajusteRetiradaMensal ||
                                    linhaAporteRetiradaMensal.aumentoRetiradaMensal
                                ) && 
                                linhaAporteRetiradaMensal.anosReajusteAumentoRetiradaMensal
                        ) {

                            const anosReajusteAumentoRetiradaMensal =
                                Math.trunc((linhaEvolucaoReservaFinanceira.idade - linhaAporteRetiradaMensal.idadeInicial) /
                                    linhaAporteRetiradaMensal.anosReajusteAumentoRetiradaMensal)

                            if (linhaAporteRetiradaMensal.percReajusteRetiradaMensal) {

                                retiradaMensal = round(retiradaMensal *
                                    Math.pow(1 + (linhaAporteRetiradaMensal.percReajusteRetiradaMensal / 100), anosReajusteAumentoRetiradaMensal), 2)

                            } else {

                                 retiradaMensal += round(linhaAporteRetiradaMensal.aumentoRetiradaMensal * anosReajusteAumentoRetiradaMensal, 2)
                            }
                        }

                        estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensal += retiradaMensal

                        estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensalEvolucaoInflacao += retiradaMensal

                        //if (linhaEvolucaoReservaFinanceira.ano > anoFinalAposentar) {
                        //    
                        //    deficitDepoisAposentar -= calculaJurosMensalSaldo(
                        //        round(linhaAporteRetiradaMensal.retiradaMensal, 2), null, 0,
                        //            null, anoAtual, null, anoFinalSimulacao, linhaEvolucaoReservaFinanceira.ano,
                        //                values, estudo, 0, 0, null, null, null, null)
                        //}
                    }
                }
            }
        })


        
        if (linhaEvolucaoReservaFinanceira.ano <= anoFinalAposentar) {

            estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensal =
                round(estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensal *
                    Math.pow(1 + fatorTaxaInflacaoAnual/*fatorTaxaJurosAnualAporte*/, (indexEvolucaoReservaFinanceira * 12) / 12), 2)

            estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensal =
                round(estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensal *
                    Math.pow(1 + fatorTaxaInflacaoAnual, (indexEvolucaoReservaFinanceira * 12) / 12), 2)

        } else {

            if (!jaAposentou) {
            
                estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensal =
                    round(estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensal *
                        Math.pow(1 + fatorTaxaInflacaoAnual/*fatorTaxaJurosAnualAporte*/, ((anoFinalAposentar - anoAtual) * 12) / 12), 2)
            }

            estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensal =
                round(estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensal *
                    Math.pow(1 + fatorTaxaInflacaoAnualAposAposentadoria/*fatorTaxaJurosAnualAporteAposAposentadoria*/,
                        ((indexEvolucaoReservaFinanceira - (jaAposentou ? 0 : anoFinalAposentar - anoAtual)) * 12) / 12), 2)

            if (!jaAposentou) {
            
                estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensal =
                    round(estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensal *
                        Math.pow(1 + fatorTaxaInflacaoAnual, ((anoFinalAposentar - anoAtual) * 12) / 12), 2)
            }

            estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensal =
                round(estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensal *
                    Math.pow(1 + fatorTaxaInflacaoAnualAposAposentadoria,
                        ((indexEvolucaoReservaFinanceira - (jaAposentou ? 0 : anoFinalAposentar - anoAtual)) * 12) / 12), 2)
        }

        estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensalEvolucaoInflacao =
            round(estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensalEvolucaoInflacao *
                Math.pow(1 + fatorTaxaInflacaoAnualAporteEvolucaoInflacao, (indexEvolucaoReservaFinanceira * 12) / 12), 2)

        estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensalEvolucaoInflacao =
            round(estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensalEvolucaoInflacao *
                Math.pow(1 + fatorTaxaInflacaoAnualAporteEvolucaoInflacao, (indexEvolucaoReservaFinanceira * 12) / 12), 2)

        if (indexEvolucaoReservaFinanceira === 0) {

            let valorAcumuladoPainel = round(estudo.current.panelGarantiasAtuais[
                HARDCODE.indexEstudoRecursosFinanceiros].valorAcumuladoPainel, 2)

            if (values.tipoMomentoTributacaoIR === HARDCODE.tipoMomentoTributacaoIRPGBL && values.percIR) {

                valorAcumuladoPainel = round(valorAcumuladoPainel - (valorAcumuladoPainel * values.percIR / 100), 2)
            }

            estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].recursosFinanceirosInicio =
                valorAcumuladoPainel

        } else {

            estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].recursosFinanceirosInicio =
                estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira -1].recursosFinanceirosFinal
        }



        if (linhaEvolucaoReservaFinanceira.ano === anoFinalAposentar + 1) {

            if (values.tipoRetornoFinanceiro === HARDCODE.tipoRetornoFinanceiroReal) {

                recursosFinaneceirosInicioAposentadoria =
                    estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].recursosFinanceirosInicio
                
                necessidadesInicioAposentadoria = 0

                retiradaAposentadoriaTotal = 0

            }

            /*
            if (values.tipoRetornoFinanceiro === HARDCODE.tipoRetornoFinanceiroNominal) {

                values.retiradaMensalMaximaPreservarReservaAposentadoria = 0
                values.retiradaMensalMaximaConsumirReservaAposentadoria = 0

            } else {

                //recursosInicioAposentaria =
                //    estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira]
                //        .recursosFinanceirosInicio

                values.retiradaMensalMaximaPreservarReservaAposentadoria =
                    round(estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira]
                        .recursosFinanceirosInicio -
                            (estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira]
                                .recursosFinanceirosInicio / 
                                    Math.pow(1 + fatorTaxaJurosAnualAposAposentadoria, 1 / 12)), 2)

                if (values.retiradaMensalMaximaPreservarReservaAposentadoria < 0) {

                    values.retiradaMensalMaximaPreservarReservaAposentadoria = 0
                }

                if (fatorTaxaJurosMensalAposAposentadoria === 0) {

                    values.retiradaMensalMaximaConsumirReservaAposentadoria =
                        round(estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira]
                            .recursosFinanceirosInicio / mesesDepoisAposentar, 2)
                
                } else {
                
                    values.retiradaMensalMaximaConsumirReservaAposentadoria =
                        round((estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira]
                            .recursosFinanceirosInicio * Math.pow(1 + fatorTaxaJurosMensalAposAposentadoria,
                                mesesDepoisAposentar - 1) * fatorTaxaJurosMensalAposAposentadoria) /
                                    (Math.pow(1 + fatorTaxaJurosMensalAposAposentadoria, mesesDepoisAposentar) - 1), 2)
                }

                if (values.retiradaMensalMaximaConsumirReservaAposentadoria < 0) {

                    values.retiradaMensalMaximaConsumirReservaAposentadoria = 0
                }
            }
            */
        }



        let saldo =
            estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].recursosFinanceirosInicio

        //if (
        //    linhaEvolucaoReservaFinanceira.ano <= anoFinalAposentar ||
        //    consideraAporteRetidadaDepoisAposentar
        //) {

            estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteRetiradaAnual = 0

            aporteRetiradaAnual?.forEach( (linhaAporteRetiradaAnual, indexAporteRetiradaAnual) => {

                if (
                        (
                            linhaAporteRetiradaAnual.idade === linhaEvolucaoReservaFinanceira.idade &&
                            !linhaAporteRetiradaAnual.idadeFinal
                        ) ||
                        (
                            linhaAporteRetiradaAnual.idade &&
                            linhaAporteRetiradaAnual.idadeFinal &&
                            linhaEvolucaoReservaFinanceira.idade >= linhaAporteRetiradaAnual.idade &&
                            linhaEvolucaoReservaFinanceira.idade <= linhaAporteRetiradaAnual.idadeFinal
                        )
                ) {

                    if (linhaAporteRetiradaAnual.aporteAnual) {

                        let aporteAnual =
                            linhaAporteRetiradaAnual.naoCorrigirInflacao
                                ?
                                    round(linhaAporteRetiradaAnual.aporteAnual, 2)
                                :
                                    round(linhaAporteRetiradaAnual.aporteAnual *
                                        Math.pow(1 + fatorTaxaInflacaoAnual/*fatorTaxaJurosAnualAporte*/,
                                            indexEvolucaoReservaFinanceira), 2)

                        estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteRetiradaAnual += 
                            linhaAporteRetiradaAnual.naoCorrigirInflacao
                                ?
                                    linhaAporteRetiradaAnual.aporteAnualResgateVidaInteira
                                :
                                    aporteAnual//round(linhaAporteRetiradaAnual.aporteAnual, 2)

                        values.totalAportesAteAposentadoria += aporteAnual
                            
                        aporteAnual = calculaValorAnualCorrespondenteMesInformado(linhaEvolucaoReservaFinanceira,
                            linhaAporteRetiradaAnual, mesAtual, anoAtual, anoFinalAposentar,
                                fatorTaxaJurosMensal, fatorTaxaJurosMensalAposAposentadoria, aporteAnual)

                        if (values.tipoMomentoTributacaoIR === HARDCODE.tipoMomentoTributacaoIRPGBL && values.percIR) {

                            aporteAnual = round(aporteAnual - (aporteAnual * values.percIR / 100), 2)
                        }

                        saldo += aporteAnual//round(linhaAporteRetiradaAnual.aporteAnual, 2)

                        if (linhaEvolucaoReservaFinanceira.ano > anoFinalAposentar) {

                            if (linhaEvolucaoReservaFinanceira.ano === anoFinalAposentar + 1) {

                                recursosFinaneceirosInicioAposentadoria += aporteAnual//round(linhaAporteRetiradaAnual.aporteAnual, 2)

                            } else {

                                recursosFinaneceirosInicioAposentadoria += round(aporteAnual/*linhaAporteRetiradaAnual.aporteAnual*/ /
                                    Math.pow(1 + fatorTaxaJurosMensalAposAposentadoria,
                                        calculaMesesDepoisAposentar(mesAtual, jaAposentou,
                                            linhaEvolucaoReservaFinanceira.ano - (anoFinalAposentar + 1))), 2)
                            }
                        }
                    }
                    
                    if (linhaAporteRetiradaAnual.retiradaAnual) {

                        let retiradaAnual = round(linhaAporteRetiradaAnual.retiradaAnual *
                            Math.pow(1 + fatorTaxaInflacaoAnual, indexEvolucaoReservaFinanceira), 2)

                        estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteRetiradaAnual -= 
                            retiradaAnual//round(linhaAporteRetiradaAnual.retiradaAnual, 2)

                        retiradaAnual = calculaValorAnualCorrespondenteMesInformado(linhaEvolucaoReservaFinanceira,
                            linhaAporteRetiradaAnual, mesAtual, anoAtual, anoFinalAposentar,
                                fatorTaxaJurosMensal, fatorTaxaJurosMensalAposAposentadoria, retiradaAnual)
                        
                        saldo -= retiradaAnual//round(linhaAporteRetiradaAnual.retiradaAnual, 2)

                        if (linhaEvolucaoReservaFinanceira.ano > anoFinalAposentar) {

                            if (linhaEvolucaoReservaFinanceira.ano === anoFinalAposentar + 1) {

                                necessidadesInicioAposentadoria += retiradaAnual//round(linhaAporteRetiradaAnual.retiradaAnual, 2)
                                recursosFinaneceirosInicioAposentadoria -= retiradaAnual//round(linhaAporteRetiradaAnual.aporteAnual, 2)
                                
                                retiradaAposentadoriaTotal += retiradaAnual/*round(linhaAporteRetiradaAnual.retiradaAnual, 2)*/

                            } else {

                                const retiradaAnualAux = round(retiradaAnual/*linhaAporteRetiradaAnual.retiradaAnual*/ /
                                    Math.pow(1 + fatorTaxaJurosMensalAposAposentadoria,
                                        calculaMesesDepoisAposentar(mesAtual, jaAposentou,
                                            linhaEvolucaoReservaFinanceira.ano - (anoFinalAposentar + 1))), 2)

                                necessidadesInicioAposentadoria += retiradaAnualAux
                                recursosFinaneceirosInicioAposentadoria -= retiradaAnualAux

                                retiradaAposentadoriaTotal += retiradaAnualAux/*round(linhaAporteRetiradaAnual.retiradaAnual, 2)*/
                            }
                        }
                    }

                    //if (linhaEvolucaoReservaFinanceira.ano > anoFinalAposentar) {
                    //
                    //    deficitDepoisAposentar +=
                    //        estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteRetiradaAnual
                    //}
                }
            })
        //}



        let aporteMensal = estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensal

        if (values.tipoMomentoTributacaoIR === HARDCODE.tipoMomentoTributacaoIRPGBL && values.percIR) {

            aporteMensal = round(aporteMensal - (aporteMensal * values.percIR / 100), 2)
        }
        
        if (linhaEvolucaoReservaFinanceira.ano > anoFinalAposentar) {

            recursosFinaneceirosInicioAposentadoria +=
                calculaValorPresente(aporteMensal/*estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensal*/, null, 
                    null, anoFinalAposentar + 1, null, linhaEvolucaoReservaFinanceira.ano,
                        jaAposentou ? mesAtual : 1, anoFinalAposentar + 1, linhaEvolucaoReservaFinanceira.ano, values, fatorTaxaJurosAnual * 100,
                        fatorTaxaJurosAnualAposAposentadoria * 100, null, null)
            
            necessidadesInicioAposentadoria +=
                calculaValorPresente(estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensal, null, 
                    null, anoFinalAposentar + 1, null, linhaEvolucaoReservaFinanceira.ano,
                        jaAposentou ? mesAtual : 1, anoFinalAposentar + 1, linhaEvolucaoReservaFinanceira.ano, values, fatorTaxaJurosAnual * 100,
                            fatorTaxaJurosAnualAposAposentadoria * 100, null, null)

            retiradaAposentadoriaTotal += calculaValorPresente(estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensal, null, 
                null, anoFinalAposentar + 1, null, linhaEvolucaoReservaFinanceira.ano,
                    jaAposentou ? mesAtual : 1, anoFinalAposentar + 1, linhaEvolucaoReservaFinanceira.ano, values, 0, 0, null, null)
        }



        const { rendimentoMensalTotal, saldoAux } = calculaJurosMensal(
            aporteMensal/*estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].aporteMensal*/ -
                estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].retiradaMensal, null,
                    saldo, null, anoAtual, null, anoFinalSimulacao, linhaEvolucaoReservaFinanceira.ano, values, estudo,
                        fatorTaxaJurosAnual * 100, fatorTaxaJurosAnualAposAposentadoria * 100, null, null, null, null)

        saldo = saldoAux

        saldo = ajustaValorNegativo(saldo, estudo.current.evolucaoReservaFinanceira.length, indexEvolucaoReservaFinanceira)

        

        estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].rendimentoMensal =
            round(rendimentoMensalTotal /
                (12 - (indexEvolucaoReservaFinanceira === 0 ? mes(values.dataSimulacaoEstudo) : 1) + 1), 2)

        estudo.current.evolucaoReservaFinanceira[indexEvolucaoReservaFinanceira].recursosFinanceirosFinal = saldo

        if (linhaEvolucaoReservaFinanceira.ano === anoFinalAposentar) {

            values.valorRecursosFinanceirosInicioAposentadoria = saldo
        }

        if (!values.idadeLimiteReservaAposentadoria && saldo < 0) {

            values.idadeLimiteReservaAposentadoria = linhaEvolucaoReservaFinanceira.idade
        }

        values.valorRecursosFinanceirosFinalAposentadoria = saldo

    })


    
    values.valorPresenteReservaInicioAposentadoriaPrimeiraReuniao = recursosFinaneceirosInicioAposentadoria
    values.valorPresenteNecessidadesInicioAposentadoriaPrimeiraReuniao = necessidadesInicioAposentadoria



    if (values.tipoRetornoFinanceiro === HARDCODE.tipoRetornoFinanceiroNominal) {

        values.aporteMensalAdicionalPreservarReservaAposentadoria = 0
        values.aporteMensalAdicionalConsumirReservaAposentadoria = 0

        values.retiradaMensalMaximaPreservarReservaAposentadoria = 0
        values.retiradaMensalMaximaConsumirReservaAposentadoria = 0

        values.aporteUnicoAdicionalPreservarReservaAposentadoria = 0
        values.aporteUnicoAdicionalConsumirReservaAposentadoria = 0

    } else {

        if (jaAposentou) {
        
            values.aporteMensalAdicionalPreservarReservaAposentadoria = 0
            values.aporteMensalAdicionalConsumirReservaAposentadoria = 0
    
        } else {
    
            values.aporteMensalAdicionalPreservarReservaAposentadoria =
                round((((retiradaAposentadoriaTotal / mesesDepoisAposentar) /
                    (1 - (1 / (Math.pow(1 + fatorTaxaJurosAnualAposAposentadoria, 1 / 12))))) -
                        recursosFinaneceirosInicioAposentadoria) *
                            (fatorTaxaJurosMensal / (Math.pow(1 + fatorTaxaJurosMensal, mesesAteAposentar) - 1)) *
                                (1 / (1 + fatorTaxaJurosMensal)), 2)
            //values.aporteMensalAdicionalPreservarReservaAposentadoria =
            //    round(((((deficitDepoisAposentar * -1) / mesesDepoisAposentar) /
            //        (1 - (1 / (Math.pow(1 + fatorTaxaJurosAnualAposAposentadoria, 1 / 12))))) -
            //            recursosInicioAposentaria) *
            //                (fatorTaxaJurosMensal / (Math.pow(1 + fatorTaxaJurosMensal, mesesAteAposentar) - 1)) *
            //                    (1 / (1 + fatorTaxaJurosMensal)), 2)

            if (!values.aporteMensalAdicionalPreservarReservaAposentadoria || values.aporteMensalAdicionalPreservarReservaAposentadoria < 0) {

                values.aporteMensalAdicionalPreservarReservaAposentadoria = 0

            } else {

                if (values.tipoMomentoTributacaoIR === HARDCODE.tipoMomentoTributacaoIRPGBL && values.percIR) {

                    values.aporteMensalAdicionalPreservarReservaAposentadoria =
                        round(values.aporteMensalAdicionalPreservarReservaAposentadoria /
                            ((100 - values.percIR) / 100), 2)
                }
            }
    
            if (fatorTaxaJurosMensal === 0) {
    
                values.aporteMensalAdicionalConsumirReservaAposentadoria =
                    round((values.valorRecursosFinanceirosFinalAposentadoria * -1) / mesesAteAposentar, 2)
    
            } else {

                values.aporteMensalAdicionalConsumirReservaAposentadoria =
                    round(round((values.valorRecursosFinanceirosFinalAposentadoria * -1) / 
                        Math.pow(1 + fatorTaxaJurosAnualAposAposentadoria, anosDepoisAposentar), 2) *
                            (fatorTaxaJurosMensal / (Math.pow(1 + fatorTaxaJurosMensal, mesesAteAposentar) - 1)) *
                                (1 / (1 + fatorTaxaJurosMensal)), 2)
            }
    
            if (values.aporteMensalAdicionalConsumirReservaAposentadoria < 0) {
    
                values.aporteMensalAdicionalConsumirReservaAposentadoria = 0

            } else {

                if (values.tipoMomentoTributacaoIR === HARDCODE.tipoMomentoTributacaoIRPGBL && values.percIR) {

                    values.aporteMensalAdicionalConsumirReservaAposentadoria =
                        round(values.aporteMensalAdicionalConsumirReservaAposentadoria /
                            ((100 - values.percIR) / 100), 2)
                }
            }
        }



        values.retiradaMensalMaximaPreservarReservaAposentadoria =
            round(recursosFinaneceirosInicioAposentadoria -
                (recursosFinaneceirosInicioAposentadoria / 
                    Math.pow(1 + fatorTaxaJurosAnualAposAposentadoria, 1 / 12)), 2)

        if (values.retiradaMensalMaximaPreservarReservaAposentadoria < 0) {

            values.retiradaMensalMaximaPreservarReservaAposentadoria = 0
        }

        if (fatorTaxaJurosMensalAposAposentadoria === 0) {

            values.retiradaMensalMaximaConsumirReservaAposentadoria =
                round(recursosFinaneceirosInicioAposentadoria / mesesDepoisAposentar, 2)
        
        } else {
        
            values.retiradaMensalMaximaConsumirReservaAposentadoria =
                round((recursosFinaneceirosInicioAposentadoria * Math.pow(1 + fatorTaxaJurosMensalAposAposentadoria,
                    mesesDepoisAposentar - 1) * fatorTaxaJurosMensalAposAposentadoria) /
                        (Math.pow(1 + fatorTaxaJurosMensalAposAposentadoria, mesesDepoisAposentar) - 1), 2)
        }

        if (values.retiradaMensalMaximaConsumirReservaAposentadoria < 0) {

            values.retiradaMensalMaximaConsumirReservaAposentadoria = 0
        }



        values.aporteUnicoAdicionalPreservarReservaAposentadoria =
            round((((retiradaAposentadoriaTotal / mesesDepoisAposentar) /
                (1 - (1 / (Math.pow(1 + fatorTaxaJurosAnualAposAposentadoria, 1 / 12))))) -
                    recursosFinaneceirosInicioAposentadoria) /
                        Math.pow(1 + fatorTaxaJurosAnual, mesesAteAposentar / 12), 2)
        //values.aporteUnicoAdicionalPreservarReservaAposentadoria =
        //    round(((((deficitDepoisAposentar * -1) / mesesDepoisAposentar) /
        //        (1 - (1 / (Math.pow(1 + fatorTaxaJurosAnualAposAposentadoria, 1 / 12))))) -
        //           recursosInicioAposentaria) /
        //                Math.pow(1 + fatorTaxaJurosAnual, mesesAteAposentar / 12), 2)

        if (!values.aporteUnicoAdicionalPreservarReservaAposentadoria || values.aporteUnicoAdicionalPreservarReservaAposentadoria <= 0.01) {

            values.aporteUnicoAdicionalPreservarReservaAposentadoria = 0

        } else {
            
            if (values.tipoMomentoTributacaoIR === HARDCODE.tipoMomentoTributacaoIRPGBL && values.percIR) {

                values.aporteUnicoAdicionalPreservarReservaAposentadoria =
                    round(values.aporteUnicoAdicionalPreservarReservaAposentadoria /
                        ((100 - values.percIR) / 100), 2)
            }
        }


        values.aporteUnicoAdicionalConsumirReservaAposentadoria = round((necessidadesInicioAposentadoria - recursosFinaneceirosInicioAposentadoria) /
            Math.pow(1 + fatorTaxaJurosMensal, mesesAteAposentar), 2)
        //values.aporteUnicoAdicionalConsumirReservaAposentadoria =
        //    round(((values.valorRecursosFinanceirosFinalAposentadoria * -1) /
        //        Math.pow(1 + fatorTaxaJurosAnualAposAposentadoria, mesesDepoisAposentar / 12)) /
        //            Math.pow(1 + fatorTaxaJurosAnual, mesesAteAposentar / 12), 2)

        if (!values.aporteUnicoAdicionalConsumirReservaAposentadoria || values.aporteUnicoAdicionalConsumirReservaAposentadoria < 0) {

            values.aporteUnicoAdicionalConsumirReservaAposentadoria = 0

        } else {

            if (values.tipoMomentoTributacaoIR === HARDCODE.tipoMomentoTributacaoIRPGBL && values.percIR) {

                values.aporteUnicoAdicionalConsumirReservaAposentadoria =
                    round(values.aporteUnicoAdicionalConsumirReservaAposentadoria /
                        ((100 - values.percIR) / 100), 2)
            }
        }
    }
}



const formataEvolucaoReservaFinanceira = (values, estudo, report) => {

    const api = values.api

    estudo.current.evolucaoReservaFinanceira?.forEach( (linhaEvolucaoReservaFinanceira, index) => {

        const boldAposentadoria = linhaEvolucaoReservaFinanceira.idade === values.idadeVaiAposentarCliente

        estudo.current.evolucaoReservaFinanceira[index].ano =
            formataTableCellBodyExibeDadosTexto(linhaEvolucaoReservaFinanceira.ano, report, api, boldAposentadoria)

        estudo.current.evolucaoReservaFinanceira[index].idade =
            formataTableCellBodyExibeDadosTexto(linhaEvolucaoReservaFinanceira.idade, report, api, boldAposentadoria)

        estudo.current.evolucaoReservaFinanceira[index].recursosFinanceirosInicio =
            formataTableCellBodyExibeDadosValor(linhaEvolucaoReservaFinanceira.recursosFinanceirosInicio, report,
                api, boldAposentadoria, true)

        estudo.current.evolucaoReservaFinanceira[index].aporteMensal =
            formataTableCellBodyExibeDadosValor(linhaEvolucaoReservaFinanceira.aporteMensal, report,
                api, boldAposentadoria, true)

        estudo.current.evolucaoReservaFinanceira[index].aporteMensalEvolucaoInflacao =
            formataTableCellBodyExibeDadosValor(linhaEvolucaoReservaFinanceira.aporteMensalEvolucaoInflacao, report,
                api, boldAposentadoria, true)
            
        estudo.current.evolucaoReservaFinanceira[index].retiradaMensal =
            formataTableCellBodyExibeDadosValor(linhaEvolucaoReservaFinanceira.retiradaMensal, report,
                api, boldAposentadoria, true)

        estudo.current.evolucaoReservaFinanceira[index].retiradaMensalEvolucaoInflacao =
            formataTableCellBodyExibeDadosValor(linhaEvolucaoReservaFinanceira.retiradaMensalEvolucaoInflacao, report,
                api, boldAposentadoria, true)

        estudo.current.evolucaoReservaFinanceira[index].aporteRetiradaAnual =
            formataTableCellBodyExibeDadosValor(linhaEvolucaoReservaFinanceira.aporteRetiradaAnual, report,
                api, boldAposentadoria, true)
                
        estudo.current.evolucaoReservaFinanceira[index].rendimentoMensal =
            formataTableCellBodyExibeDadosValor(linhaEvolucaoReservaFinanceira.rendimentoMensal, report,
                api, boldAposentadoria, true)
            
        estudo.current.evolucaoReservaFinanceira[index].recursosFinanceirosFinal =
            formataTableCellBodyExibeDadosValor(linhaEvolucaoReservaFinanceira.recursosFinanceirosFinal, report,
                api, boldAposentadoria, true)

        estudo.current.evolucaoReservaFinanceira[index].boldAposentadoria = boldAposentadoria
    })
}