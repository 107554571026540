import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    ano,
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    formataTableCellBodyExibeDadosTitulo,
    formataTableCellBodyExibeDadosTexto,
    formataTableCellBodyExibeDadosValor,
    formataTableCellBodyExibeDadosSubTotalTitulo,
    formataTableCellBodyExibeDadosSubTotalValor,
    formataTableCellBodyExibeDadosTotalTitulo,
    formataTableCellBodyExibeDadosTotalValor,
} from '../../../business-components/Formata/Formata'

import {
    pesquisaList,
} from '../../../business-rules/List/ListService'

import tipoEducacaoList from '../../../business-rules/List/tipoEducacaoList'

import familiaresList from '../../../business-rules/List/familiaresList'

import {
    calculaValorMensal,
    calculaAnoIdadeInformada,
    calculaIntervaloTempoAnos,
} from '../cliente-form/ClienteService'

import {
    dadosCompletosLinhaEducacao,
} from '../despesas-form/DespesasServiceEducacao'

import {
    formataValorTipoPeriodicidade,
} from './EstudoService'

import {
    inicializaSerieGraficoDespesa,
    inicializaSerieGrafico,
    incluiDespesaMensalDetalhadaTemp,
    calculaGraficoValorPresente,
    calculaGraficoAcumulacaoMensal,
} from './EstudoServiceCalculo'

export const calculaDespesasEducacao = (values, estudo, report) => {
    let educacaoTemp = []
    let educacao = []
    let panelDespesasTotaisEducacao

    if (values.educacao) {
        const api = values.api
        
        let linhaTipoEducacao
        let descricaoTipoEducacao = null

        let familiaresListAux = familiaresList(values)
        let linhaFamiliaresList = ''
        let nomeCurtoFamiliar = null

        let indexGraficoDespesaAnual
        let indexGraficoDespesaAnualMedia /**/

        let valor = 0 /**/
        let valorMensal = 0
        let anoInicial = ''
        let anoFinal = ''
        let maiorAnoFinal = ''
        let valorAcumulado = 0
        let valorResponsabilidadeCliente = 0
        let valorGarantiaCliente = 0
        let valorResponsabilidadeConjuge = 0
        let valorGarantiaConjuge = 0

        let valorAcumuladoTotal = 0
        let valorResponsabilidadeClienteTotal = 0
        let valorGarantiaClienteTotal = 0
        let valorResponsabilidadeConjugeTotal = 0
        let valorGarantiaConjugeTotal = 0
        
        values.educacao.forEach( (linhaEducacao, index) => {
            
            linhaFamiliaresList = 
                pesquisaList(familiaresListAux, linhaEducacao.familiar)

            if (
                    dadosCompletosLinhaEducacao(linhaEducacao, false) && 
                    (
                        linhaFamiliaresList.idade || 
                        linhaFamiliaresList.idade === 0
                    ) &&
                    !pesquisaList(values.exclusaoFamiliares, linhaEducacao.familiar).id &&
                    !pesquisaList(values.exclusaoDespesasGarantias,
                        LABEL.educacao + '/' + linhaEducacao.id).id
                ) {

                linhaTipoEducacao = pesquisaList(tipoEducacaoList, linhaEducacao.tipoEducacao)
                
                descricaoTipoEducacao = linhaTipoEducacao.descricao

                nomeCurtoFamiliar = linhaFamiliaresList.descricao

                valor = round(linhaEducacao.valor, 2) /**/
                valorMensal = calculaValorMensal (linhaEducacao.valor, linhaEducacao.tipoPeriodicidadePagto)
                
                anoInicial = Math.max(calculaAnoIdadeInformada(values, linhaEducacao.idadeInicial, linhaFamiliaresList.idadeDataSimulacao),
                    ano(values.dataSimulacaoEstudo))

                anoFinal = calculaAnoIdadeInformada(values, linhaEducacao.idadeFinal, linhaFamiliaresList.idadeDataSimulacao)

                valorAcumulado = estudo.current.graficoEducacao[0]

                calculaGraficoValorPresente(null, anoInicial, null, anoFinal, valor/*valorMensal*/, linhaEducacao.tipoPeriodicidadePagto/*null*/,
                    estudo.current.graficoAnos, estudo.current.graficoEducacao,
                        estudo.current.graficoDespesasAcumuladas, values, estudo,
                            values.naoAplicarTaxaJurosSeguroVida ? 0 : values.taxaRetornoAnualReal,
                                values.naoAplicarTaxaJurosSeguroVida ? 0 :
                                    values.taxaRetornoAnualRealAposAposentadoria, null, null, true
                )

                //calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinal, 0,
                //    valorMensal, null, estudo.current.graficoAnos,
                //        estudo.current.graficoDespesaAnualTotal, null, values, estudo, 0, 0, false, null, null, null)

                indexGraficoDespesaAnual =
                    inicializaSerieGrafico(values, estudo.current.graficoDespesaAnual,
                        inicializaSerieGraficoDespesa(linhaEducacao, linhaEducacao.familiar, nomeCurtoFamiliar), null, null)

                calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinal, 0,
                    valor/*valorMensal*/, linhaEducacao.tipoPeriodicidadePagto/*null*/, estudo.current.graficoAnos,
                        estudo.current.graficoDespesaAnual[indexGraficoDespesaAnual].serie,
                            estudo.current.graficoDespesaAnualTotal/*null*/, values, estudo, 0, 0, false, null, null, null)

                incluiDespesaMensalDetalhadaTemp(values, estudo, linhaEducacao, nomeCurtoFamiliar + LABEL.traco + descricaoTipoEducacao,
                    valor, linhaEducacao.tipoPeriodicidadePagto, valorMensal, null, estudo.current.mesAtual, anoInicial, 12, anoFinal)

                /**/
                indexGraficoDespesaAnualMedia =
                    inicializaSerieGrafico(values, estudo.current.graficoDespesaAnualMedia,
                        inicializaSerieGraficoDespesa(linhaEducacao, linhaEducacao.familiar, nomeCurtoFamiliar), null, null)

                calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinal, 0,
                    valorMensal, null, estudo.current.graficoAnos,
                        estudo.current.graficoDespesaAnualMedia[indexGraficoDespesaAnualMedia].serie,
                            estudo.current.graficoDespesaAnualTotalMedia,
                                values, estudo, 0, 0, false, null, null, null)
                /**/

                if (!maiorAnoFinal || anoFinal > maiorAnoFinal) {
                    maiorAnoFinal = anoFinal
                }

                valorAcumulado = round(estudo.current.graficoEducacao[0] - valorAcumulado, 2)

                if (
                        (
                            values.morteInvalidezEstudo === "A" ||
                            values.morteInvalidezEstudo === "M"
                        ) &&
                        linhaEducacao.familiar === HARDCODE.idFamiliarCliente
                ) {

                    valorResponsabilidadeCliente = 0
                    valorGarantiaCliente = 0
                } else {

                    valorResponsabilidadeCliente = valorAcumulado

                    if (linhaEducacao.temSeguro === "S" &&
                        linhaEducacao.tipoSegurado === "C"
                    ) {

                        valorGarantiaCliente = valorAcumulado
                    } else {
                        
                        valorGarantiaCliente = 0
                    }
                }

                if (
                        (
                            values.morteInvalidezEstudo === "A" ||
                            values.morteInvalidezEstudo === "M"
                        ) &&
                        linhaEducacao.familiar === HARDCODE.idFamiliarConjuge
                ) {

                    valorResponsabilidadeConjuge = 0
                    valorGarantiaConjuge = 0
                } else {

                    valorResponsabilidadeConjuge = valorAcumulado

                    if (linhaEducacao.temSeguro === "S" &&
                        linhaEducacao.tipoSegurado === "O"
                    ) {

                        valorGarantiaConjuge = valorAcumulado
                    } else {
                        
                        valorGarantiaConjuge = 0
                    }
                }

                educacaoTemp.push({
                    ordem: ("0000" + linhaEducacao.familiar).slice(-4) + 
                    ("000" + linhaEducacao.idadeFinal).slice(-3),
                    familiar: linhaEducacao.familiar,
                    nomeCurtoFamiliar: nomeCurtoFamiliar, 
                    descricaoTipoEducacao: descricaoTipoEducacao,
                    valor: valor,/**/
                    tipoPeriodicidadePagto: linhaEducacao.tipoPeriodicidadePagto,/**/
                    valorMensal: valorMensal,
                    idadeInicial: linhaEducacao.idadeInicial,
                    idadeFinal: linhaEducacao.idadeFinal,
                    anoFinal: anoFinal,
                    valorAcumulado: valorAcumulado,
                    valorResponsabilidadeCliente: valorResponsabilidadeCliente,
                    valorGarantiaCliente: valorGarantiaCliente,
                    valorResponsabilidadeConjuge: valorResponsabilidadeConjuge,
                    valorGarantiaConjuge: valorGarantiaConjuge,
                })

                valorAcumuladoTotal =
                    round(valorAcumuladoTotal + valorAcumulado, 2)
                valorResponsabilidadeClienteTotal =
                    round(valorResponsabilidadeClienteTotal + valorResponsabilidadeCliente, 2)
                valorGarantiaClienteTotal =
                    round(valorGarantiaClienteTotal + valorGarantiaCliente, 2)
                valorResponsabilidadeConjugeTotal =
                    round(valorResponsabilidadeConjugeTotal + valorResponsabilidadeConjuge, 2)
                valorGarantiaConjugeTotal =
                    round(valorGarantiaConjugeTotal + valorGarantiaConjuge, 2)
                
            }
        })

        educacaoTemp = sortAsc(educacaoTemp)

        let familiarAnt = null
        let anoFinalFamiliar

        educacaoTemp.forEach( (linhaEducacao, index) => {
            if (linhaEducacao.familiar !== familiarAnt) {
                familiarAnt = linhaEducacao.familiar

                anoFinalFamiliar = ''

                valorAcumulado = 0
                valorResponsabilidadeCliente = 0
                valorGarantiaCliente = 0
                valorResponsabilidadeConjuge = 0
                valorGarantiaConjuge = 0

                educacaoTemp.forEach( (linhaEducacaoTotal, index) => {
                    if (linhaEducacaoTotal.familiar === linhaEducacao.familiar) {

                        if (!anoFinalFamiliar || linhaEducacaoTotal.anoFinal > anoFinalFamiliar) {
                            anoFinalFamiliar = linhaEducacaoTotal.anoFinal
                        }

                        valorAcumulado = valorAcumulado + 
                            linhaEducacaoTotal.valorAcumulado
                        valorResponsabilidadeCliente = valorResponsabilidadeCliente + 
                            linhaEducacaoTotal.valorResponsabilidadeCliente
                        valorGarantiaCliente = valorGarantiaCliente + 
                            linhaEducacaoTotal.valorGarantiaCliente
                        valorResponsabilidadeConjuge = valorResponsabilidadeConjuge + 
                            linhaEducacaoTotal.valorResponsabilidadeConjuge
                        valorGarantiaConjuge = valorGarantiaConjuge + 
                            linhaEducacaoTotal.valorGarantiaConjuge
                    }
                })

                educacao.push({
                    tipo:
                        "S",
                    familiar: linhaEducacao.familiar,
                    descricao: 
                        formataTableCellBodyExibeDadosSubTotalTitulo(linhaEducacao.nomeCurtoFamiliar, report, api),
                    valorAcumulado: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorAcumulado, report, api),
                    valorResponsabilidadeCliente: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorResponsabilidadeCliente, report, api),
                    valorGarantiaCliente: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorGarantiaCliente, report, api),
                    valorResponsabilidadeConjuge: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorResponsabilidadeConjuge, report, api),
                    valorGarantiaConjuge: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorGarantiaConjuge, report, api),
                    nomeCurtoFamiliar:
                        linhaEducacao.nomeCurtoFamiliar,
                    anosProtegerProtegido:
                        calculaIntervaloTempoAnos(values, anoFinalFamiliar),
                    valorDespesaCliente:
                        valorResponsabilidadeCliente - valorGarantiaCliente,
                    valorDespesaConjuge:
                        valorResponsabilidadeConjuge - valorGarantiaConjuge,
                })
            }

            educacao.push({
                tipo:
                    "D",
                descricao: 
                    formataTableCellBodyExibeDadosTitulo("          " + 
                        linhaEducacao.descricaoTipoEducacao, report, api),
                valorMensal: 
                    //formataTableCellBodyExibeDadosValor(linhaEducacao.valorMensal, report, api),
                    linhaEducacao.valor === linhaEducacao.valorMensal
                        ?
                            formataTableCellBodyExibeDadosValor(linhaEducacao.valorMensal, report, api)
                        :
                            formataValorTipoPeriodicidade(linhaEducacao.valor, 2, false, false,
                                linhaEducacao.tipoPeriodicidadePagto, false, report, api),
                idadeInicial: 
                    formataTableCellBodyExibeDadosTexto(linhaEducacao.idadeInicial, report, api),
                idadeFinal: 
                    formataTableCellBodyExibeDadosTexto(linhaEducacao.idadeFinal, report, api),
                valorAcumulado: 
                    formataTableCellBodyExibeDadosValor(linhaEducacao.valorAcumulado, report, api),
                valorResponsabilidadeCliente: 
                    formataTableCellBodyExibeDadosValor(linhaEducacao.valorResponsabilidadeCliente, report, api),
                valorGarantiaCliente: 
                    formataTableCellBodyExibeDadosValor(linhaEducacao.valorGarantiaCliente, report, api),
                valorResponsabilidadeConjuge: 
                    formataTableCellBodyExibeDadosValor(linhaEducacao.valorResponsabilidadeConjuge, report, api),
                valorGarantiaConjuge: 
                    formataTableCellBodyExibeDadosValor(linhaEducacao.valorGarantiaConjuge, report, api),
                nomeCurtoFamiliar:
                    linhaEducacao.nomeCurtoFamiliar,
                anosProtegerProtegido:
                    calculaIntervaloTempoAnos(values, linhaEducacao.anoFinal),
                valorDespesaCliente:
                    linhaEducacao.valorResponsabilidadeCliente - linhaEducacao.valorGarantiaCliente,
                valorDespesaConjuge:
                    linhaEducacao.valorResponsabilidadeConjuge - linhaEducacao.valorGarantiaConjuge,
            })
        })

        educacao.push({
            tipo:
                "T",
            descricao: 
                formataTableCellBodyExibeDadosTotalTitulo(LABEL.total, report, api),
            valorAcumulado: 
                formataTableCellBodyExibeDadosTotalValor(valorAcumuladoTotal, report, api),
            valorResponsabilidadeCliente: 
                formataTableCellBodyExibeDadosTotalValor(valorResponsabilidadeClienteTotal, report, api),
            valorGarantiaCliente: 
                formataTableCellBodyExibeDadosTotalValor(valorGarantiaClienteTotal, report, api),
            valorResponsabilidadeConjuge: 
                formataTableCellBodyExibeDadosTotalValor(valorResponsabilidadeConjugeTotal, report, api),
            valorGarantiaConjuge: 
                formataTableCellBodyExibeDadosTotalValor(valorGarantiaConjugeTotal, report, api),
            anosProtegerProtegido:
                calculaIntervaloTempoAnos(values, maiorAnoFinal),
            valorDespesaCliente:
                valorResponsabilidadeClienteTotal - valorGarantiaClienteTotal,
            valorDespesaConjuge:
                valorResponsabilidadeConjugeTotal - valorGarantiaConjugeTotal,
        })

        panelDespesasTotaisEducacao = {
            descricao: LABEL.educacao,
            valorAcumuladoPainel: valorAcumuladoTotal,
            valorDespesaClientePainel: valorResponsabilidadeClienteTotal - valorGarantiaClienteTotal,
            valorReceitaClientePainel: 0,
            valorDespesaConjugePainel: valorResponsabilidadeConjugeTotal - valorGarantiaConjugeTotal,
            valorReceitaConjugePainel: 0,
            anosProtegerProtegido: calculaIntervaloTempoAnos(values, maiorAnoFinal),
        }

    } else {

        panelDespesasTotaisEducacao = {
            descricao: LABEL.educacao,
            valorAcumuladoPainel: 0,
            valorDespesaClientePainel: 0,
            valorReceitaClientePainel: 0,
            valorDespesaConjugePainel: 0,
            valorReceitaConjugePainel: 0,
            anosProtegerProtegido: '',
        }
    }

    estudo.current.educacao = educacao

    estudo.current.panelDespesasTotais[HARDCODE.indexEstudoEducacao] = panelDespesasTotaisEducacao
}