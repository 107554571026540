import HARDCODE from '../../business-const/HardCode/HardCode'

import TAMMAX from '../TamMax/TamMax'

import sexoList from "./sexoList"

import profissaoList from "./profissaoList"

import ufList from "./ufList"

import tipoFormaPagtoPremioList from './tipoFormaPagtoPremioList'

import bancoList from './bancoList'

import statusParcelaList from './SeguroVida/statusParcelaList'

import tipoBaixaParcelaList from './SeguroVida/tipoBaixaParcelaList'

import tipoPeriodicidadeList from "./tipoPeriodicidadeList"

import pessoaFisicaJuridicaList from './Consorcio/pessoaFisicaJuridicaList'

import statusGrupoConsorcioList from './Consorcio/statusGrupoConsorcioList'

    

export const camposClienteXLSXPDFList = [
    {
        id: HARDCODE.importacaoLinhaDados,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoCodigoAgente,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoNumeroApolice,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoNumeroCotacao,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoNumeroProposta,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoDataAssinatura,
        type: HARDCODE.typeData,
    },
    {
        id: HARDCODE.importacaoDataUltimaAlteracao,
        type: HARDCODE.typeData,
    },
    {
        id: HARDCODE.importacaoDataEmissao,
        type: HARDCODE.typeData,
    },
    {
        id: HARDCODE.importacaoDataCancelamento,
        type: HARDCODE.typeData,
    },
    {
        id: HARDCODE.importacaoMotivoCancelamento,
        type: HARDCODE.typeString,
        tammax: TAMMAX.motivoCancelamento,
    },
    {
        id: HARDCODE.importacaoCpfSeguradoCliente,
        type: HARDCODE.typeCpf,
    },
    {
        id: HARDCODE.importacaoNomeSeguradoCliente,
        type: HARDCODE.typeString,
        tammax: TAMMAX.nome,
    },
    {
        id: HARDCODE.importacaoNascimentoSeguradoCliente,
        type: HARDCODE.typeData,
    },
    {
        id: HARDCODE.importacaoSexoSeguradoCliente,
        type: HARDCODE.typeList,
        list: sexoList(false),
        substrList: 1,
    },
    {
        id: HARDCODE.importacaoProfissaoSegurado,
        type: HARDCODE.typeList,
        list: profissaoList,
        autoComplete: 'S',
        valorNaoEncontrouList: 'Outras',
        campoNaoEncontrouList: 'profissaoSeguradoOutras',
    },
    {
        id: HARDCODE.importacaoEnderecoSeguradoCliente,
        type: HARDCODE.typeString,
        tammax: TAMMAX.endereco,
    },
    {
        id: HARDCODE.importacaoNumeroEnderecoSeguradoCliente,
        type: HARDCODE.typeString,
        tammax: TAMMAX.numeroEndereco,
    },
    {
        id: HARDCODE.importacaoComplementoEnderecoSeguradoCliente,
        type: HARDCODE.typeString,
        tammax: TAMMAX.complementoEndereco,
    },
    {
        id: HARDCODE.importacaoBairroSeguradoCliente,
        type: HARDCODE.typeString,
        tammax: TAMMAX.bairro,
    },
    {
        id: HARDCODE.importacaoCidadeSeguradoCliente,
        type: HARDCODE.typeString,
        tammax: TAMMAX.cidade,
    },
    {
        id: HARDCODE.importacaoUfSeguradoCliente,
        type: HARDCODE.typeList,
        list: ufList,
    },
    {
        id: HARDCODE.importacaoCepSeguradoCliente,
        type: HARDCODE.typeCep,
    },
    {
        id: HARDCODE.importacaoTelefoneSegurado,
        type: HARDCODE.typeTelefone,
    },
    {
        id: HARDCODE.importacaoTelefoneComercialSegurado,
        type: HARDCODE.typeTelefone,
    },
    {
        id: HARDCODE.importacaoCelularSeguradoCliente,
        type: HARDCODE.typeTelefone,
    },
    {
        id: HARDCODE.importacaoEmailSeguradoCliente,
        type: HARDCODE.typeEmail,
    },
    {
        id: HARDCODE.importacaoCpfCnpjRespPagto,
        type: HARDCODE.typeCpfCnpj,
    },
    {
        id: HARDCODE.importacaoNomeRespPagto,
        type: HARDCODE.typeString,
        tammax: TAMMAX.nome,
    },
    {
        id: HARDCODE.importacaoSexoRespPagto,
        type: HARDCODE.typeList,
        list: sexoList(false),
        substrList: 1,
    },
    {
        id: HARDCODE.importacaoRendaMensal,
        type: HARDCODE.typeValor,
    },
    {
        id: HARDCODE.importacaoStatusProposta,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoStatusApolice,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoObservacao,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoCoberturas,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoPropostaCoberturas,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoDescricaoCobertura,
        type: HARDCODE.typeString,
        tammax: TAMMAX.descricaoCobertura,
    },
    {
        id: HARDCODE.importacaoValorCS,
        type: HARDCODE.typeValor,
    },
    {
        id: HARDCODE.importacaoValorPremioLiquido,
        type: HARDCODE.typeValor,
    },
    {
        id: HARDCODE.importacaoValorIof,
        type: HARDCODE.typeValor,
    },
    {
        id: HARDCODE.importacaoValorPremio,
        type: HARDCODE.typeValor,
    },
    {
        id: HARDCODE.importacaoValorPremioAnualizado,
        type: HARDCODE.typeValor,
    },
    {
        id: HARDCODE.importacaoDataTerminoCoberturaString,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoPrazoCobertura,
        type: HARDCODE.typeInteiro,
    },
    {
        id: HARDCODE.importacaoClasseAjusteRisco,
        type: HARDCODE.typeString,
        tammax: TAMMAX.classeAjusteRisco,
    },
    {
        id: HARDCODE.importacaoStatusCobertura,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoTipoFormaPagtoPremio,
        type: HARDCODE.typeList,
        list: tipoFormaPagtoPremioList,
        substrList: 1,
    },
    {
        id: HARDCODE.importacaoTipoFormaPagtoPremioConversao,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoBanco,
        type: HARDCODE.typeList,
        list: bancoList(false),
    },
    {
        id: HARDCODE.importacaoAgencia,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoConta,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoDiaVencimento,
        type: HARDCODE.typeInteiro,
    },
    {
        id: HARDCODE.importacaoNumeroParcela,
        type: HARDCODE.typeInteiro,
    },
    {
        id: HARDCODE.importacaoStatusParcela,
        type: HARDCODE.typeList,
        list: statusParcelaList,
        pesquisarChave: true,
    },
    {
        id: HARDCODE.importacaoTipoBaixaParcela,
        type: HARDCODE.typeList,
        list: tipoBaixaParcelaList,
        pesquisarChave: true,
    },
    {
        id: HARDCODE.importacaoDataVencimento,
        type: HARDCODE.typeData,
    },
    {
        id: HARDCODE.importacaoDataCompetencia,
        type: HARDCODE.typeData,
    },
    {
        id: HARDCODE.importacaoDataInicioVigencia,
        type: HARDCODE.typeData,
    },
    {
        id: HARDCODE.importacaoDataFimVigencia,
        type: HARDCODE.typeData,
    },
    {
        id: HARDCODE.importacaoDataPagamento,
        type: HARDCODE.typeData,
    },
    {
        id: HARDCODE.importacaoDataCorte,
        type: HARDCODE.typeData,
    },
    {
        id: HARDCODE.importacaoValorAPagar,
        type: HARDCODE.typeValor,
    },
    {
        id: HARDCODE.importacaoValorPago,
        type: HARDCODE.typeValor,
    },
    {
        id: HARDCODE.importacaoTipoPeriodicidadePagtoPremio,
        type: HARDCODE.typeList,
        list: tipoPeriodicidadeList(false, true),
        substrList: 1,
    },
    {
        id: HARDCODE.importacaoTipoPeriodicidadePagtoPremioInteiro,
        type: HARDCODE.typeInteiro,
    },
    {
        id: HARDCODE.importacaoTipoPeriodicidadePagtoPremioValor,
        type: HARDCODE.typeValor,
    },
    {
        id: HARDCODE.importacaoDataGeracaoComissao,
        type: HARDCODE.typeData,
    },
    {
        id: HARDCODE.importacaoValorBaseComissao,
        type: HARDCODE.typeValor,
    },
    {
        id: HARDCODE.importacaoPercComissao,
        type: HARDCODE.typePercentual,
    },
    {
        id: HARDCODE.importacaoValorComissao,
        type: HARDCODE.typePercentual,
    },

    {
        id: HARDCODE.importacaoPercComissaoCoCorretagem,
        type: HARDCODE.typePercentual,
    },
    {
        id: HARDCODE.importacaoValorComissaoCoCorretagem,
        type: HARDCODE.typePercentual,
    },
    {
        id: HARDCODE.importacaoValorComissaoTotal,
        type: HARDCODE.typePercentual,
    },
    {
        id: HARDCODE.importacaoCodigoCobertura,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoDataCotacao,
        type: HARDCODE.typeData,
    },
    {
        id: HARDCODE.importacaoDataCotacaoExtenso,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoIdadeCotacao,
        type: HARDCODE.typeInteiro,
    },
    {
        id: HARDCODE.importacaoTabelaEvolucaoValorResgate,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoNumeroConta,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoTaxaCDI12Meses,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoPosicaoInvestimentos,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoNumeroConsorcio,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoValorCreditoConsorcio,
        type: HARDCODE.typeValor,
    },
    {
        id: HARDCODE.importacaoGrupoConsorcio,
        type: HARDCODE.typeString,
    },
    {
        id: HARDCODE.importacaoCotaConsorcio,
        type: HARDCODE.typeInteiro,
    },
    {
        id: HARDCODE.importacaoPessoaFisicaJuridica,
        type: HARDCODE.typeList,
        list: pessoaFisicaJuridicaList,
    },
    {
        id: HARDCODE.importacaoStatusGrupoConsorcio,
        type: HARDCODE.typeList,
        list: statusGrupoConsorcioList,
        substrList: 1,
    },
    {
        id: HARDCODE.importacaoDataAssembleia,
        type: HARDCODE.typeData,
    },
    {
        id: HARDCODE.importacaoNumeroAssembleiaParticipacao,
        type: HARDCODE.typeInteiro,
    },
    {
        id: HARDCODE.importacaoValorParcelaInicial,
        type: HARDCODE.typeValor,
    },
    {
        id: HARDCODE.importacaoTipoFormaPagtoConsorcio,
        type: HARDCODE.typeList,
        list: tipoFormaPagtoPremioList,
        substrList: 1,
    },
    {
        id: HARDCODE.importacaoPrazoTotalGrupoConsorcio,
        type: HARDCODE.typeInteiro,
    },
    {
        id: HARDCODE.importacaoNumeroTotalParticipantesGrupoConsorcio,
        type: HARDCODE.typeInteiro,
    },
    {
        id: HARDCODE.importacaoTaxaAdministracaoTotal,
        type: HARDCODE.typePercentual,
    },
    {
        id: HARDCODE.importacaoPercFundoReserva,
        type: HARDCODE.typePercentual,
    },
    {
        id: HARDCODE.importacaoPercSeguroVida,
        type: HARDCODE.typePercentual,
    },
    {
        id: HARDCODE.importacaoPercLanceEmbutido,
        type: HARDCODE.typePercentual,
    },
    {
        id: HARDCODE.importacaoPercLanceFixo,
        type: HARDCODE.typePercentual,
    },
    {
        id: HARDCODE.importacaoDataVenctoBoleto,
        type: HARDCODE.typeData,
    },
]

export default camposClienteXLSXPDFList