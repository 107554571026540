import HARDCODE from '../../../business-const/HardCode/HardCode'

import MESSAGE from '../../../business-const/Message/Message'



export const obtemPagina = (pagina) => {
    pagina.current++

    return pagina.current
}



export const paginas = ( array, tamPagina, primPagina ) => {

    let paginasAux = []

    if (!tamPagina) {

        tamPagina = HARDCODE.reportLinhasSemGraficoRetrato
    }

    if (!primPagina) {
        
        primPagina = tamPagina
    }

    let qtdePaginas = 1
    
    if (array.length > primPagina) {

        qtdePaginas += Math.floor((array.length - primPagina) / tamPagina)

        if ((array.length - primPagina) % tamPagina !== 0) {

            qtdePaginas++
        }
    }

    let ind = 0

    while (qtdePaginas > 0) {

        const tamPaginaAux = ind === 0 ? primPagina : tamPagina

        paginasAux.push(
            {
                indInicial: ind,
                indFinal: ind +  tamPaginaAux - 1,
            }
        )

        ind += tamPaginaAux
        qtdePaginas--
    }

    return paginasAux
}



export const calcEstudoReportCapaTitulo1 = (jsonConfiguracao) => {

    return (
        jsonConfiguracao && jsonConfiguracao.estudoReportCapaTitulo1
            ?
                jsonConfiguracao.estudoReportCapaTitulo1
            :
                MESSAGE().estudoReportCapaTitulo1
    )
}

export const calcProfissaoQualificacao = (jsonUsuario) => {

    return (
        jsonUsuario && jsonUsuario.profissaoQualificacao
            ?
                jsonUsuario.profissaoQualificacao
            :
                MESSAGE().profissaoQualificacao
    )
}



export const reportLinhasSemGrafico = (values) => {

    return values.disposicaoRelatorio === HARDCODE.disposicaoRelatorioRetrato
        ?
            HARDCODE.reportLinhasSemGraficoRetrato
        :
            HARDCODE.reportLinhasSemGraficoPaisagem
}

export const reportLinhasComGrafico = (values) => {

    return values.disposicaoRelatorio === HARDCODE.disposicaoRelatorioRetrato
        ?
            HARDCODE.reportLinhasComGraficoRetrato
        :
            HARDCODE.reportLinhasComGraficoPaisagem
}