import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    trataValorTexto,
} from '../cliente-form/ClienteServiceImportacao'

import {
    espaco,
    fimLinha,
    indexAtualJsonRegistros,
    obtemDadoPDF,
    jsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosInicializacao,
    incluiTabelaEvolucaoValorResgateCoberturaVidaInteira,
} from './IntegracaoClienteArquivoService'



export const importaCotacaoBradescoTabelaEvolucaoValorResgate = (values, item, PDFTxt, nomeArquivo, erros) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)


    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nº da cotação:' + espaco, fimLinha,
        HARDCODE.importacaoNumeroCotacao, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data da cotação:' + espaco, fimLinha,
        HARDCODE.importacaoDataCotacao, true, null, erros, nomeArquivo)
        
    obtemDadoPDF(PDFTxt, jsonRegistros, ',' + espaco, espaco, HARDCODE.importacaoIdadeCotacao, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Coberturas Valor Capital (R$) Prêmio Líquido (R$) IOF (R$) Prêmio Total (R$)' +
        fimLinha + 'Morte' + espaco,
        espaco, HARDCODE.importacaoValorCS, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Franquia Carência Valor Capital (R$) Prêmio Líquido (R$) IOF (R$) Prêmio Total (R$)' +
            fimLinha + 'Morte' + espaco,
            espaco, HARDCODE.importacaoValorCS, true, null, erros, nomeArquivo)
    }


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Simulação de Resgates' + fimLinha + 'Mês/Ano Prêmio Acumulado Resgate' + fimLinha,
        fimLinha + 'Informações Importantes' + fimLinha,
            HARDCODE.importacaoTabelaEvolucaoValorResgate, true, null, erros, nomeArquivo)


    if (jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate) {

        const linhasTabelaEvolucaoValorResgateAux =
            jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate.split(fimLinha)
        let indexLinhasTabelaEvolucaoValorResgateAux = 0

        const linhasTabelaEvolucaoValorResgate = []
        let indexLinhasTabelaEvolucaoValorResgate = 0

        let numeroCotacao = jsonRegistros.jsonRegistros[indexJsonRegistros].numeroCotacao
        let dataCotacao = jsonRegistros.jsonRegistros[indexJsonRegistros].dataCotacao
        let ano = 0
        let idade = jsonRegistros.jsonRegistros[indexJsonRegistros].idadeCotacao
        let valorCS = jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS
        let valorPremioAnualizadoAcumulado
        let valorResgate
        let valorSaldado
        let beneficioProlongado

        item.tabelaEvolucaoValorResgate = []

        let dadosTabelaEvolucaoValorResgate

        while (indexLinhasTabelaEvolucaoValorResgateAux < linhasTabelaEvolucaoValorResgateAux.length) {

            if (linhasTabelaEvolucaoValorResgateAux[indexLinhasTabelaEvolucaoValorResgateAux].indexOf('.') !== -1) {

                linhasTabelaEvolucaoValorResgate.push(
                    linhasTabelaEvolucaoValorResgateAux[indexLinhasTabelaEvolucaoValorResgateAux]
                )
            }

            indexLinhasTabelaEvolucaoValorResgateAux++
        }

        while (indexLinhasTabelaEvolucaoValorResgate < linhasTabelaEvolucaoValorResgate.length) {

            dadosTabelaEvolucaoValorResgate = linhasTabelaEvolucaoValorResgate[indexLinhasTabelaEvolucaoValorResgate].split(espaco)

            ano++
            idade++
            valorPremioAnualizadoAcumulado = trataValorTexto(dadosTabelaEvolucaoValorResgate[1])
            valorResgate = trataValorTexto(dadosTabelaEvolucaoValorResgate[2])
            valorSaldado = ''
            beneficioProlongado = ''

            incluiTabelaEvolucaoValorResgateCoberturaVidaInteira(values, item, numeroCotacao, dataCotacao, ano, idade, valorCS,
                valorPremioAnualizadoAcumulado, valorResgate, valorSaldado, beneficioProlongado)
            
            indexLinhasTabelaEvolucaoValorResgate++
        }
    }



    
    //jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    //return jsonRegistros.jsonRegistros
}