import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import MESSAGE from '../../../business-const/Message/Message'

import {
    round
} from '../../../business-components/round/round'

import {
    ano,
    mes,
    formataDataEmMesAnoSemBarra,
} from '../../../business-components/Date/Date'

//import {
//    sortAscId
//} from '../../../business-components/Sort/Sort'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import familiaresList from '../../../business-rules/List/familiaresList'

import tipoReceitaMensalList from '../../../business-rules/List/tipoReceitaMensalList'

import tipoMultiploSalarioSeguroVidaEmGrupo from '../../../business-rules/List/tipoMultiploSalarioSeguroVidaEmGrupo'

import {
    dadosCompletosConjuge,
    calculaValorPerc,
    calculaValorMensal,
} from '../cliente-form/ClienteService'

import {
    possuiFilhao,
} from '../cadastro-form/CadastroServiceFamiliares'

import {
    dadosIcompletosAlertaCamposObrigatorios
} from '../estudo-form/EstudoServiceAlertaCamposObrigatorios'

import {
    calculaAnoInicialReceitaMensalClienteConjuge,
    calculaAnoFinalReceitaMensalClienteConjuge,
} from '../estudo-form/EstudoServiceGarantiasReceitaMensal'

import {
    dadosCompletosLinhaPatrimonioImobilizado,
} from './GarantiasServicePatrimonioImobilizado'



export const incrementaContReceitaMensal = ( values ) => {
    if (!values.contReceitaMensal) {
        values.contReceitaMensal = 0
    }
    values.contReceitaMensal = values.contReceitaMensal + 1
}

export const obtemContReceitaMensal = ( values ) => {

    let contReceitaMensal = ''

    if (!values.contReceitaMensal) {
        incrementaContReceitaMensal(values)
        contReceitaMensal = values.contReceitaMensal

    } else {
        contReceitaMensal = 1

        while (contReceitaMensal <= values.contReceitaMensal) {
            if (!pesquisaList(values.receitaMensal, contReceitaMensal).id) {
                break
            }

            contReceitaMensal++
        }

        if (contReceitaMensal > values.contReceitaMensal) {
            incrementaContReceitaMensal(values)
            contReceitaMensal = values.contReceitaMensal
        }  
    }

    return contReceitaMensal
}

const alterouReceitaMensalInssPensao = ( values ) => {

    let inssPensao = ''

    let alterou = false

    values.receitaMensal?.forEach( (linhaReceitaMensal, index) => {
            
        if (
                dadosCompletosLinhaReceitaMensal(linhaReceitaMensal) &&
                pesquisaList(tipoReceitaMensalList, linhaReceitaMensal.tipoReceitaMensal).informaInssPensao !== "N"
        ) {

            inssPensao +=
                linhaReceitaMensal.id + "|" +
                linhaReceitaMensal.valorInssPensaoInvalidez + "|" +
                linhaReceitaMensal.valorInssPensaoMorte + "|" +
                linhaReceitaMensal.valorInssPensaoAposentadoria + "|" +
                linhaReceitaMensal.naoConsiderarGarantiaMorteCliente + "|" +
                linhaReceitaMensal.naoConsiderarGarantiaMorteConjuge + "|" +
                linhaReceitaMensal.naoConsiderarGarantiaInvalidezCliente + "|" +
                linhaReceitaMensal.naoConsiderarGarantiaInvalidezConjuge + ","
        }
    })

    if (values.inssPensaoAnt !== inssPensao) {
        alterou = true
    }

    values.inssPensaoAnt = inssPensao

    return alterou
}

const calculaReceitaMensalAluguelPatrimonioImobilizadoAluguelObservacao = ( linhaPatrimonioImobilizado ) => {
    return MESSAGE().aluguelIncluidoPatrimonioImobilizado + (linhaPatrimonioImobilizado.observacao ? " (" + linhaPatrimonioImobilizado.observacao + ")" : '')
}

const incluiReceitaMensalInssPensao = ( values, linhaReceitaMensal, idTipoReceitaMensalInssPensao,
    valorInssPensao ) => {

    values.receitaMensal.push(
        {
            id: obtemContReceitaMensal(values),
            familiar: linhaReceitaMensal.familiar,
            tipoReceitaMensal: idTipoReceitaMensalInssPensao,
            valor: valorInssPensao,
            tipoPeriodicidadeRecebto: HARDCODE.idTipoPeriodicidadeMensal,
            naoConsiderarGarantiaMorteCliente: linhaReceitaMensal.naoConsiderarGarantiaMorteCliente,
            naoConsiderarGarantiaMorteConjuge: linhaReceitaMensal.naoConsiderarGarantiaMorteConjuge,
            naoConsiderarGarantiaInvalidezCliente: linhaReceitaMensal.naoConsiderarGarantiaInvalidezCliente,
            naoConsiderarGarantiaInvalidezConjuge: linhaReceitaMensal.naoConsiderarGarantiaInvalidezConjuge,
            observacao: '',
            idReceitaMensal: linhaReceitaMensal.id,
        }
    )
}

export const incluiReceitaMensalInssPensaoAluguelPatrimonioImobilizadoAluguel = ( values ) => {

    if (
            values.processarIncluiReceitaMensalInssPensaoAluguelPatrimonioImobilizadoAluguel ||
            alterouReceitaMensalInssPensao(values)
    ) {
        
        if (values.receitaMensal) {

            let index = values.receitaMensal.length - 1

            while (index >= 0) {

                if (
                        values.receitaMensal[index].idPatrimonioImobilizado ||
                        values.receitaMensal[index].idReceitaMensal
                ) {
                    values.receitaMensal.splice(index, 1)
                }

                index -= 1
            }
        }

        values.patrimonioImobilizado?.forEach( (linhaPatrimonioImobilizado, index) => {

            if (
                    dadosCompletosLinhaPatrimonioImobilizado(linhaPatrimonioImobilizado) &&
                    linhaPatrimonioImobilizado.valorAluguel
            ) {

                if (!values.receitaMensal) {
                    values.receitaMensal = []
                }

                if (linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarCasal) {

                    values.receitaMensal.push(
                        {
                            id: obtemContReceitaMensal(values),
                            familiar: HARDCODE.idFamiliarCliente,
                            tipoReceitaMensal: HARDCODE.idTipoReceitaMensalAluguel,
                            valor:
                                calculaValorPerc(round(linhaPatrimonioImobilizado.valorAluguel, 2), 
                                    linhaPatrimonioImobilizado.percPatrimonioImobilizadoCliente),
                            tipoPeriodicidadeRecebto: HARDCODE.idTipoPeriodicidadeMensal,
                            observacao:
                                calculaReceitaMensalAluguelPatrimonioImobilizadoAluguelObservacao(linhaPatrimonioImobilizado),
                            idPatrimonioImobilizado: linhaPatrimonioImobilizado.id,
                        }
                    )

                    values.receitaMensal.push(
                        {
                            id: obtemContReceitaMensal(values),
                            familiar: HARDCODE.idFamiliarConjuge,
                            tipoReceitaMensal: HARDCODE.idTipoReceitaMensalAluguel,
                            valor:
                                linhaPatrimonioImobilizado.valorAluguel -
                                    calculaValorPerc(round(linhaPatrimonioImobilizado.valorAluguel, 2), 
                                        linhaPatrimonioImobilizado.percPatrimonioImobilizadoCliente),
                            tipoPeriodicidadeRecebto: HARDCODE.idTipoPeriodicidadeMensal,
                            observacao:
                                calculaReceitaMensalAluguelPatrimonioImobilizadoAluguelObservacao(linhaPatrimonioImobilizado),
                            idPatrimonioImobilizado: linhaPatrimonioImobilizado.id,
                        }
                    )

                } else {

                    values.receitaMensal.push(
                        {
                            id: obtemContReceitaMensal(values),
                            familiar: linhaPatrimonioImobilizado.familiar,
                            tipoReceitaMensal: HARDCODE.idTipoReceitaMensalAluguel,
                            valor: linhaPatrimonioImobilizado.valorAluguel,
                            tipoPeriodicidadeRecebto: HARDCODE.idTipoPeriodicidadeMensal,
                            observacao:
                                calculaReceitaMensalAluguelPatrimonioImobilizadoAluguelObservacao(linhaPatrimonioImobilizado),
                            idPatrimonioImobilizado: linhaPatrimonioImobilizado.id,
                        }
                    )
                }
            }
        })

        values.receitaMensal?.forEach( (linhaReceitaMensal, index) => {

            if (
                    dadosCompletosLinhaReceitaMensal(linhaReceitaMensal) &&
                    (
                        linhaReceitaMensal.valorInssPensaoInvalidez ||
                        linhaReceitaMensal.valorInssPensaoMorte ||
                        linhaReceitaMensal.valorInssPensaoAposentadoria
                    )
            ) {

                const informaInssPensao =
                    pesquisaList(tipoReceitaMensalList, linhaReceitaMensal.tipoReceitaMensal).informaInssPensao

                if (!values.receitaMensal) {
                    values.receitaMensal = []
                }

                if (linhaReceitaMensal.valorInssPensaoInvalidez) {
                    incluiReceitaMensalInssPensao(values, linhaReceitaMensal,
                        informaInssPensao === "I"
                            ?
                                HARDCODE.idTipoReceitaMensalInssRendaInvalidez
                            : 
                                HARDCODE.idTipoReceitaMensalPensaoRendaInvalidez,
                            linhaReceitaMensal.valorInssPensaoInvalidez)
                }

                if (linhaReceitaMensal.valorInssPensaoMorte) {
                    incluiReceitaMensalInssPensao(values, linhaReceitaMensal,
                        informaInssPensao === "I"
                            ?
                                HARDCODE.idTipoReceitaMensalInssRendaMorte
                            : 
                                HARDCODE.idTipoReceitaMensalPensaoRendaMorte,
                            linhaReceitaMensal.valorInssPensaoMorte)
                }

                if (linhaReceitaMensal.valorInssPensaoAposentadoria) {
                    incluiReceitaMensalInssPensao(values, linhaReceitaMensal,
                        informaInssPensao === "I"
                            ?
                                HARDCODE.idTipoReceitaMensalInssRendaAposentadoria
                            : 
                                HARDCODE.idTipoReceitaMensalPensaoRendaAposentadoria,
                            linhaReceitaMensal.valorInssPensaoAposentadoria)
                }
            }
        })

        //if (values.receitaMensal) { Essa ordenação não é mais necessária pois os lançamentos automáticos não são mais exibidos no input de dados
        //    values.receitaMensal = sortAscId(values.receitaMensal)
        //}

        calculaTotaisReceitaMensal(values)

        values.processarIncluiReceitaMensalInssPensaoAluguelPatrimonioImobilizadoAluguel = false
    }
}



export const processaRegistrosDefaultIniciaisReceitaMensal = ( values, familiar ) => {

    if (familiar) {
        if (!values.familiaresProcessouRegistrosDefaultIniciaisReceitaMensal) {
            values.familiaresProcessouRegistrosDefaultIniciaisReceitaMensal = []
        }

        if (!values.receitaMensal) {
            values.receitaMensal = []
        }
        
        if (!pesquisaList(values.familiaresProcessouRegistrosDefaultIniciaisReceitaMensal, familiar).id) {

            values.familiaresProcessouRegistrosDefaultIniciaisReceitaMensal.push(
                {
                    id: familiar
                }
            )

            if (values.receitaMensal.filter(receitaMensal => receitaMensal.familiar === familiar).length === 0) {

                incrementaContReceitaMensal(values)
                values.receitaMensal.push(
                    {
                        id: values.contReceitaMensal,
                        familiar: familiar,
                    }
                )
            }
        }

        values.processarIncluiRecursosFinanceirosFGTSContribuicaoReceitaMensalCLT = true

        values.processarIncluiSeguroVidaSeguroVidaEmGrupo = true
    }
}

export const atualizaFormListReceitaMensal = ( values, idPergunta, valor, idReceitaMensal, familiar ) => {

    if (valor > 0) {
        
        if (!idReceitaMensal) {
            
            if (!values.receitaMensal) {
                values.receitaMensal = []
            }

            incrementaContReceitaMensal(values)
            values.receitaMensal.push(
                {
                    id: values.contReceitaMensal,
                    familiar: familiar,
                    tipoReceitaMensal: HARDCODE.idTipoReceitaMensalOutrasAteAposentadoria,
                    valor: valor,
                    tipoPeriodicidadeRecebto: HARDCODE.idTipoPeriodicidadeMensal,
                    observacao: MESSAGE().incluidoPerguntas,
                    idPergunta: idPergunta,
                }
            )

            idReceitaMensal = values.contReceitaMensal

        } else {

            values.receitaMensal?.forEach( (linhaReceitaMensal, index) => {

                if (linhaReceitaMensal.idPergunta === idPergunta && linhaReceitaMensal.familiar === familiar) {
    
                    values.receitaMensal[index].valor = valor
                }
            })
        }

        calculaTotaisReceitaMensal(values)

    } else {

        if (idReceitaMensal) {

            let index = values.receitaMensal.length - 1

            while (index >= 0) {

                if (
                        values.receitaMensal[index].idPergunta === idPergunta && 
                        values.receitaMensal[index].familiar === familiar
                ) {
                    values.receitaMensal.splice(index, 1)
                }

                index -= 1
            }

            idReceitaMensal = null

            calculaTotaisReceitaMensal(values)
        }
    }

    return idReceitaMensal
}



export const processaValoresDefaultIniciaisReceitaMensal = ( item, ind ) => {

    if (ind === 0 && !item.tipoReceitaMensal) {

        item.tipoReceitaMensal = HARDCODE.tipoReceitaMensalListDefault
    }
}



const calculaReceitaMensalTipoPeriodicidadeRecebto = ( item ) => {

    if (!item.tipoPeriodicidadeRecebto) {

        item.tipoPeriodicidadeRecebto = pesquisaList(tipoReceitaMensalList,
            item.tipoReceitaMensal).tipoPeriodicidadeRecebtoDefault
    }
}

const calculaReceitaMensalZeraCamposNaoInformados = ( item, values ) => {
    
    const linhaTipoReceitaMensalList = pesquisaList(tipoReceitaMensalList, item.tipoReceitaMensal)

    if (linhaTipoReceitaMensalList.informaValorBruto !== "S") {
        item.valorBruto = ''
    }

    if (linhaTipoReceitaMensalList.informaInssPensao === "N") {
        item.valorInssPensaoInvalidez = ''
        item.valorInssPensaoMorte = ''
        item.valorInssPensaoAposentadoria = ''
        item.alterarValorInssPensaoMorteAposentadoria = null
    }

    if (linhaTipoReceitaMensalList.informaSeguroVidaEmGrupo !== "S") {
        item.temSeguroVidaEmGrupo = null
        zeraCamposNaoUtilizadosReceitaMensalNaoTemSeguroVidaEmGrupo(item, values)
    }
}

export const processaAlteracaoReceitaMensalTipoReceitaMensal = ( item, values ) => {
    calculaReceitaMensalTipoPeriodicidadeRecebto(item)
    calculaReceitaMensalZeraCamposNaoInformados(item, values)
}



export const processaAlteracaoReceitaMensalValorInssPensaoInvalidez = ( item ) => {

    if (!item.alterarValorInssPensaoMorteAposentadoria) {

        item.valorInssPensaoMorte = item.valorInssPensaoInvalidez
        item.valorInssPensaoAposentadoria = item.valorInssPensaoInvalidez
    }
}

export const processaAlteracaoReceitaMensalAlterarValorInssPensaoMorteAposentadoria = ( item ) => {

    processaAlteracaoReceitaMensalValorInssPensaoInvalidez(item)
}



export const processaAlteracaoReceitaMensalAlterarTipoCalculoAcumulado = ( item, values,
    tipoReceitaMensalSelecionada ) => {

    if (!item.alterarTipoCalculoAcumulado) {

        item.mesAnoInicial = null
        item.mesAnoFinal = null
        item.receitaMensalAteAposentadoria = false

    } else {

        if (!item.mesAnoInicial && !item.mesAnoFinal) {

            if (
                    item.familiar === HARDCODE.idFamiliarCliente ||
                    item.familiar === HARDCODE.idFamiliarConjuge
            ) {

                const anoInicial =
                    calculaAnoInicialReceitaMensalClienteConjuge(values, item, tipoReceitaMensalSelecionada) 

                const anoFinal =
                    calculaAnoFinalReceitaMensalClienteConjuge(values, item, tipoReceitaMensalSelecionada)

                item.mesAnoInicial =
                    (anoInicial === ano(values.dataSimulacaoEstudo)
                        ?
                            ("0" + mes(values.dataSimulacaoEstudo)).slice(-2)
                        :
                            '01'
                    ) +
                        anoInicial.toString()

                if (!item.receitaMensalAteAposentadoria) {

                    item.mesAnoFinal = '12' + anoFinal
                }
                
            } else {

                item.mesAnoInicial = formataDataEmMesAnoSemBarra(values.dataSimulacaoEstudo)
            }

        } else {

            if (item.receitaMensalAteAposentadoria) {

                item.mesAnoFinal = null
            }
        }
    }
}



export const zeraCamposNaoUtilizadosReceitaMensalNaoTemSeguroVidaEmGrupo = ( item, values ) => {

    if (!item.temSeguroVidaEmGrupo) {
        item.tipoMultiploSalarioSeguroVidaEmGrupo = ''
        item.valorCSSeguroVidaEmGrupoMorte = ''
        item.valorCSSeguroVidaEmGrupoMorteAcidental = ''
        item.valorCSSeguroVidaEmGrupoInvalidezPorAcidente = ''
        item.valorCSSeguroVidaEmGrupoInvalidezFuncionalPorDoenca = ''
        item.valorCSSeguroVidaEmGrupoFuneral = ''
        item.seguradora = ''
    }

    if (
            !item.temSeguroVidaEmGrupo ||
            !dadosCompletosConjuge(values, true) ||
            (
                item.familiar !== HARDCODE.idFamiliarCliente &&
                item.familiar !== HARDCODE.idFamiliarConjuge
            )
    ) {
        item.extensivelClienteConjuge = null
        calculaReceitaMensalPercExtensivelClienteConjuge(item)
    }

    if (
            !item.temSeguroVidaEmGrupo ||
            !possuiFilhao(values) || 
            (
                item.familiar !== HARDCODE.idFamiliarCliente &&
                item.familiar !== HARDCODE.idFamiliarConjuge
            )
    ) {
        item.extensivelFilhao = null
        calculaReceitaMensalPercExtensivelFilhao(item)
    }
}

export const processaAlteracaoReceitaMensalTemSeguroVidaEmGrupo = ( item, values ) => {

    zeraCamposNaoUtilizadosReceitaMensalNaoTemSeguroVidaEmGrupo(item, values)
}



export const calculaReceitaMensalValorCSSeguroVidaEmGrupo = ( item ) => {

    const multiploSalarioBruto =
        pesquisaList(tipoMultiploSalarioSeguroVidaEmGrupo,
            item.tipoMultiploSalarioSeguroVidaEmGrupo).multiploSalarioBruto
    
    if (multiploSalarioBruto) {
        item.valorCSSeguroVidaEmGrupoMorte = item.valorBruto * multiploSalarioBruto
        item.valorCSSeguroVidaEmGrupoMorteAcidental = item.valorCSSeguroVidaEmGrupoMorte
        item.valorCSSeguroVidaEmGrupoInvalidezPorAcidente = item.valorCSSeguroVidaEmGrupoMorte
        item.valorCSSeguroVidaEmGrupoInvalidezFuncionalPorDoenca = item.valorCSSeguroVidaEmGrupoMorte
    }

}

export const processaAlteracaoReceitaMensalTipoMultiploSalarioSeguroVidaEmGrupo = ( item ) => {
    calculaReceitaMensalValorCSSeguroVidaEmGrupo(item)
}



export const calculaReceitaMensalPercExtensivelClienteConjuge = ( item ) => {
    if (!item.extensivelClienteConjuge) {
        item.percExtensivelClienteConjuge = ''
    }
}

export const processaAlteracaoReceitaMensalExtensivelClienteConjuge = ( item ) => {
    calculaReceitaMensalPercExtensivelClienteConjuge(item)
}



export const calculaReceitaMensalPercExtensivelFilhao = ( item ) => {
    if (!item.extensivelFilhao) {
        item.percExtensivelFilhao = ''
    }
}

export const processaAlteracaoReceitaMensalExtensivelFilhao = ( item ) => {
    calculaReceitaMensalPercExtensivelFilhao(item)
}



export const calculaReceitaMensalSeguradoraOutras = ( item ) => {
    if (item.seguradora !== HARDCODE.idSeguradoraOutras) {
        item.seguradoraOutras = ''
    }
}

export const processaAlteracaoReceitaMensalSeguradora = ( item ) => {
    calculaReceitaMensalSeguradoraOutras(item)
}



export const dadosCompletosLinhaReceitaMensal = ( linhaReceitaMensal ) => {
    return  linhaReceitaMensal.valor &&
            linhaReceitaMensal.tipoPeriodicidadeRecebto &&
            (
                pesquisaList(tipoReceitaMensalList, linhaReceitaMensal.tipoReceitaMensal).informaValorBruto !== "S" ||
                linhaReceitaMensal.valorBruto
            ) &&
            (
                !linhaReceitaMensal.alterarTipoCalculoAcumulado ||
                (
                    linhaReceitaMensal.alterarTipoCalculoAcumulado &&
                    linhaReceitaMensal.mesAnoInicial &&
                    (
                        linhaReceitaMensal.mesAnoFinal ||
                        linhaReceitaMensal.receitaMensalAteAposentadoria
                    )
                ) ||
                (
                    linhaReceitaMensal.alterarTipoCalculoAcumulado &&
                    !linhaReceitaMensal.mesAnoInicial &&
                    !linhaReceitaMensal.mesAnoFinal
                )
            )
}

export const calculaTotaisReceitaMensal = ( values ) => {

    const indexStepsValorFluxoCaixa = HARDCODE.indexFluxoCaixaReceitaMensal

    values.stepsValorFluxoCaixa[indexStepsValorFluxoCaixa] = 0

    values.totalReceitaMensal = []

    let valorMensalMedio = 0

    let valorMensalMedioTotal = 0

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {

        valorMensalMedioTotal = 0

        values.receitaMensal?.forEach( (linhaReceitaMensal, index) => {

            const linhaTipoReceitaMensalList = pesquisaList(tipoReceitaMensalList, linhaReceitaMensal.tipoReceitaMensal)
            
            if (
                    linhaReceitaMensal.familiar === linhaFamiliaresList.id &&
                    dadosCompletosLinhaReceitaMensal(linhaReceitaMensal) &&
                    linhaTipoReceitaMensalList.totalizaEntradaDados === "S" &&
                    (
                        !linhaReceitaMensal.alterarTipoCalculoAcumulado ||
                        (
                            linhaReceitaMensal.alterarTipoCalculoAcumulado &&
                            linhaReceitaMensal.mesAnoInicial === formataDataEmMesAnoSemBarra(values.dataSimulacaoEstudo)
                        )
                    ) && (
                        linhaTipoReceitaMensalList.tipoCalculoAcumulado !== "PA" ||
                        (
                            linhaTipoReceitaMensalList.tipoCalculoAcumulado === "PA" &&
                            linhaFamiliaresList.idade > linhaFamiliaresList.idadeVaiAposentar
                        )
                    )
            ) {

                valorMensalMedio = calculaValorMensal(linhaReceitaMensal.valor, linhaReceitaMensal.tipoPeriodicidadeRecebto)

                valorMensalMedioTotal = valorMensalMedioTotal + valorMensalMedio
            }
        })

        if (
                valorMensalMedioTotal !== 0 ||
                linhaFamiliaresList.id === HARDCODE.idFamiliarCliente ||
                linhaFamiliaresList.id === HARDCODE.idFamiliarConjuge
        ) {
        
            values.totalReceitaMensal.push(
                {
                    id: linhaFamiliaresList.id,
                    descricao: linhaFamiliaresList.descricao,
                    valor: valorMensalMedioTotal,
                }
            )

            values.stepsValorFluxoCaixa[indexStepsValorFluxoCaixa] =
                values.stepsValorFluxoCaixa[indexStepsValorFluxoCaixa] + valorMensalMedioTotal
        }
    })
}



export const dadosCompletosReceitaMensal = ( values ) => {

    let dadosIncompletos = ''
    let familiares = []

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {
        
        values.receitaMensal?.forEach( (linhaReceitaMensal, index) => {

            if (
                    linhaReceitaMensal.familiar === linhaFamiliaresList.id &&
                    linhaReceitaMensal.tipoReceitaMensal &&
                    !dadosCompletosLinhaReceitaMensal(linhaReceitaMensal)
            ) {

                dadosIncompletos = dadosIcompletosAlertaCamposObrigatorios(familiares, linhaFamiliaresList,
                    dadosIncompletos, pesquisaDescricaoList(tipoReceitaMensalList,
                        linhaReceitaMensal.tipoReceitaMensal))
            }
        })
    })

    return dadosIncompletos
}


export const familiarPossuiReceitaMensal = ( values, idFamiliar, tipoReceitaMensal ) => {

    let possuiReceitaMensal = false

    values.receitaMensal?.forEach( (linhaReceitaMensal, index) => {

        if (
                linhaReceitaMensal.familiar === idFamiliar &&
                dadosCompletosLinhaReceitaMensal(linhaReceitaMensal) &&
                (
                    !linhaReceitaMensal.alterarTipoCalculoAcumulado ||
                    (
                        linhaReceitaMensal.alterarTipoCalculoAcumulado &&
                        linhaReceitaMensal.mesAnoInicial === formataDataEmMesAnoSemBarra(values.dataSimulacaoEstudo)
                    )
                ) &&
                linhaReceitaMensal.tipoReceitaMensal === tipoReceitaMensal &&
                !pesquisaList(values.exclusaoFamiliares, linhaReceitaMensal.familiar).id &&
                !pesquisaList(values.exclusaoDespesasGarantias,
                    LABEL.receitaMensal + '/' + linhaReceitaMensal.id).id
        ) {

            possuiReceitaMensal = true
        }
    })

    return possuiReceitaMensal
}



export const calculaReceitaMensalFamiliarTipoReceitaMensal = ( values, idFamiliar, tipoReceitaMensal ) => {

    let valor = 0

    values.receitaMensal?.forEach( (linhaReceitaMensal, index) => {
            
        if (
                linhaReceitaMensal.familiar === idFamiliar &&
                dadosCompletosLinhaReceitaMensal(linhaReceitaMensal) &&
                (
                    !linhaReceitaMensal.alterarTipoCalculoAcumulado ||
                    (
                        linhaReceitaMensal.alterarTipoCalculoAcumulado &&
                        linhaReceitaMensal.mesAnoInicial === formataDataEmMesAnoSemBarra(values.dataSimulacaoEstudo)
                    )
                ) &&
                linhaReceitaMensal.tipoReceitaMensal === tipoReceitaMensal &&
                !pesquisaList(values.exclusaoFamiliares, linhaReceitaMensal.familiar).id &&
                !pesquisaList(values.exclusaoDespesasGarantias,
                    LABEL.receitaMensal + '/' + linhaReceitaMensal.id).id
        ) {

            valor += round(linhaReceitaMensal.valor, 2)
        }
    })

    return valor
}