// alterado - revisado

import HARDCODE from '../../../business-const/HardCode/HardCode'

import MESSAGE from '../../../business-const/Message/Message'

import {
    round
} from '../../../business-components/round/round'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import titularList from '../../../business-rules/List/titularList'

import tipoPatrimonioImobilizadoList from '../../../business-rules/List/tipoPatrimonioImobilizadoList'

import {
    calcDifPerc100,
    calculaValorPerc,
} from '../cliente-form/ClienteService'

import {
    dadosIcompletosAlertaCamposObrigatorios
} from '../estudo-form/EstudoServiceAlertaCamposObrigatorios'

import {
    obtemUfItcmdEnderecoResidencial,
} from '../estudo-form/EstudoServiceSucessaoVitalicia'

import {
    atualizaPerguntaPerc,
} from '../form-form/FormServicePerguntas'



export const incrementaContPatrimonioImobilizado = ( values ) => {
    if (!values.contPatrimonioImobilizado) {
        values.contPatrimonioImobilizado = 0
    }
    values.contPatrimonioImobilizado = values.contPatrimonioImobilizado + 1
}



export const processaRegistrosDefaultIniciaisPatrimonioImobilizado = ( values, familiar ) => {

    if (familiar) {
        if (!values.familiaresProcessouRegistrosDefaultIniciaisPatrimonioImobilizado) {
            values.familiaresProcessouRegistrosDefaultIniciaisPatrimonioImobilizado = []
        }

        if (!values.patrimonioImobilizado) {
            values.patrimonioImobilizado = []
        }
        
        if (!pesquisaList(values.familiaresProcessouRegistrosDefaultIniciaisPatrimonioImobilizado, familiar).id) {

            values.familiaresProcessouRegistrosDefaultIniciaisPatrimonioImobilizado.push(
                {
                    id: familiar
                }
            )

            incrementaContPatrimonioImobilizado(values)
            values.patrimonioImobilizado.push(
                {
                    id: values.contPatrimonioImobilizado,
                    familiar: familiar,
                }
            )
        }

        values.processarIncluiReceitaMensalInssPensaoAluguelPatrimonioImobilizadoAluguel = true
    }
}

export const atualizaFormListPatrimonioImobilizado = ( values, idPergunta, valor, perc, idPatrimonioImobilizado ) => {

    if (valor > 0) {

        if (!idPatrimonioImobilizado) {
            
            if (!values.patrimonioImobilizado) {
                values.patrimonioImobilizado = []
            }

            incrementaContPatrimonioImobilizado(values)
            values.patrimonioImobilizado.push(
                {
                    id: values.contPatrimonioImobilizado,
                    familiar: perc || perc === 0 ? HARDCODE.idFamiliarCasal : HARDCODE.idFamiliarCliente,
                    tipoPatrimonioImobilizado: HARDCODE.idTipoPatrimonioImobilizadoOutros,
                    valor: valor,
                    percPatrimonioImobilizadoCliente: perc || perc === 0 ? perc : '',
                    percPatrimonioImobilizadoConjuge: perc || perc === 0 ? calcDifPerc100(perc) : '',
                    ufItcmd: obtemUfItcmdEnderecoResidencial(values),
                    observacao: MESSAGE().incluidoPerguntas,
                    idPergunta: idPergunta,
                }
            )

            idPatrimonioImobilizado = values.contPatrimonioImobilizado

        } else {

            values.patrimonioImobilizado?.forEach( (linhaPatrimonioImobilizado, index) => {

                if (linhaPatrimonioImobilizado.idPergunta === idPergunta) {
    
                    values.patrimonioImobilizado[index].familiar =
                        perc || perc === 0 ? HARDCODE.idFamiliarCasal : HARDCODE.idFamiliarCliente
                    values.patrimonioImobilizado[index].valor =
                        valor
                    values.patrimonioImobilizado[index].percPatrimonioImobilizadoCliente =
                        perc || perc === 0 ? perc : ''
                    values.patrimonioImobilizado[index].percPatrimonioImobilizadoConjuge =
                        perc || perc === 0 ? calcDifPerc100(perc) : ''
                    values.patrimonioImobilizado[index].ufItcmd =
                        values.patrimonioImobilizado[index].ufItcmd
                            ? 
                                values.patrimonioImobilizado[index].ufItcmd
                            :
                                obtemUfItcmdEnderecoResidencial(values)
                }
            })
        }

        calculaTotaisPatrimonioImobilizado(values)

    } else {

        if (idPatrimonioImobilizado) {

            let index = values.patrimonioImobilizado.length - 1

            while (index >= 0) {

                if (values.patrimonioImobilizado[index].idPergunta === idPergunta) {
                    values.patrimonioImobilizado.splice(index, 1)
                }

                index -= 1
            }

            idPatrimonioImobilizado = null

            calculaTotaisPatrimonioImobilizado(values)
        }
    }

    return idPatrimonioImobilizado
}



export const processaValoresDefaultIniciaisPatrimonioImobilizado = ( item, values ) => {

    if (item.tipoPatrimonioImobilizado) {
        
        if (!item.ufItcmd) {

            item.ufItcmd = obtemUfItcmdEnderecoResidencial(values)
        }

        if (item.familiar === HARDCODE.idFamiliarCasal &&
            !item.percPatrimonioImobilizadoCliente && 
            item.percPatrimonioImobilizadoCliente !==0) {

            item.percPatrimonioImobilizadoCliente =
                values.percMeacaoCliente
                    ?
                        values.percMeacaoCliente
                    :
                        HARDCODE.percPatrimonioImobilizadoClienteDefault
        }

        if (!item.valorCompra || !item.dataCompra) {

            item.dataCompra = null
        }
    }
}



const calculaPatrimonioImobilizadoZeraCamposNaoInformados = ( item ) => {
    const linhaTipoPatrimonioImobilizadoList = pesquisaList(tipoPatrimonioImobilizadoList, item.tipoPatrimonioImobilizado)

    if (linhaTipoPatrimonioImobilizadoList.informaAluguel !== "S") {
        item.alugado = null
        item.valorAluguel = ''
    }

    if (linhaTipoPatrimonioImobilizadoList.informaTipoImovel !== "S") {
        item.tipoImovel = ''
    }

    if (item.familiar !== HARDCODE.idFamiliarCasal) {
        item.percPatrimonioImobilizadoCliente = ''
        item.percPatrimonioImobilizadoConjuge = ''
    }
}

export const processaAlteracaoPatrimonioImobilizadoTipoPatrimonioImobilizado = ( item ) => {
    calculaPatrimonioImobilizadoZeraCamposNaoInformados(item)
}



export const processaAlteracaoPatrimonioImobilizadoAlugado = ( item ) => {
    if (!item.alugado) {
        item.valorAluguel = ''
    }
}



const calculaPatrimonioImobilizadoPercPatrimonioImobilizadoConjuge = ( item ) => {
    if (item.percPatrimonioImobilizadoCliente === 0 || item.percPatrimonioImobilizadoCliente) {
        item.percPatrimonioImobilizadoConjuge = calcDifPerc100(item.percPatrimonioImobilizadoCliente)
    } else {
        item.percPatrimonioImobilizadoConjuge = ''
    }
}

export const processaAlteracaoPatrimonioImobilizadoPercPatrimonioImobilizadoCliente = ( values, item ) => {

    calculaPatrimonioImobilizadoPercPatrimonioImobilizadoConjuge(item)

    atualizaPerguntaPerc(values, item.idPergunta, item.percPatrimonioImobilizadoCliente)
}



export const dadosCompletosLinhaPatrimonioImobilizado = ( linhaPatrimonioImobilizado ) => {
    return  linhaPatrimonioImobilizado.valor &&
            (
                !linhaPatrimonioImobilizado.alugado ||
                linhaPatrimonioImobilizado.valorAluguel
            ) &&
            (
                linhaPatrimonioImobilizado.familiar !== HARDCODE.idFamiliarCasal || 
                linhaPatrimonioImobilizado.percPatrimonioImobilizadoCliente || 
                linhaPatrimonioImobilizado.percPatrimonioImobilizadoCliente === 0
            ) &&
            (
                !linhaPatrimonioImobilizado.valorCompra ||
                linhaPatrimonioImobilizado.dataCompra
            )
}

export const calculaTotaisPatrimonioImobilizado = ( values ) => {

    const indexStepsValorPatrimonio = HARDCODE.indexPatrimonioPatrimonioImobilizado

    values.stepsValorPatrimonio[indexStepsValorPatrimonio] = 0

    values.totalPatrimonioImobilizado = []

    let valorTotal = 0

    titularList(values, 'completo')?.forEach( (linhaTitularList, index) => {
        valorTotal = 0

        values.patrimonioImobilizado?.forEach( (linhaPatrimonioImobilizado, index) => {
            if (
                    (
                        linhaPatrimonioImobilizado.familiar === linhaTitularList.id ||
                        (
                            linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarCasal &&
                            (
                                linhaTitularList.id === HARDCODE.idFamiliarCliente ||
                                linhaTitularList.id === HARDCODE.idFamiliarConjuge
                            )
                        ) 
                    ) &&
                    dadosCompletosLinhaPatrimonioImobilizado(linhaPatrimonioImobilizado) &&
                    linhaTitularList.id !== HARDCODE.idFamiliarCasal
            ) {
                if (linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarCasal) {

                    if (linhaTitularList.id === HARDCODE.idFamiliarCliente) {

                        valorTotal = valorTotal +
                            calculaValorPerc(round(linhaPatrimonioImobilizado.valor, 2), linhaPatrimonioImobilizado.percPatrimonioImobilizadoCliente)
                    } else {
                        if (linhaTitularList.id === HARDCODE.idFamiliarConjuge) {

                            valorTotal = valorTotal + round(linhaPatrimonioImobilizado.valor, 2) -
                                calculaValorPerc(round(linhaPatrimonioImobilizado.valor, 2), linhaPatrimonioImobilizado.percPatrimonioImobilizadoCliente)
                        }
                    }
                } else {

                    valorTotal = valorTotal + round(linhaPatrimonioImobilizado.valor, 2)
                }
            }
        })

        if (
                valorTotal !== 0 ||
                linhaTitularList.id === HARDCODE.idFamiliarCasal ||
                linhaTitularList.id === HARDCODE.idFamiliarCliente ||
                linhaTitularList.id === HARDCODE.idFamiliarConjuge
        ) {

            values.totalPatrimonioImobilizado.push(
                {
                    id: linhaTitularList.id,
                    descricao: linhaTitularList.descricao,
                    valor: valorTotal,
                }
            )
            values.stepsValorPatrimonio[indexStepsValorPatrimonio] += valorTotal
        }
    })
}



export const dadosCompletosPatrimonioImobilizado = ( values ) => {
    let dadosIncompletos = ''
    let familiares = []

    titularList(values, 'completo')?.forEach( (linhaTitularList, index) => {
        
        values.patrimonioImobilizado?.forEach( (linhaPatrimonioImobilizado, index) => {

            if (
                    linhaPatrimonioImobilizado.familiar === linhaTitularList.id &&
                    linhaPatrimonioImobilizado.tipoPatrimonioImobilizado &&
                    (
                        !dadosCompletosLinhaPatrimonioImobilizado(linhaPatrimonioImobilizado) ||
                        !(
                            pesquisaList(tipoPatrimonioImobilizadoList, linhaPatrimonioImobilizado.tipoPatrimonioImobilizado).informaUfItcmd !== "S" ||
                            linhaPatrimonioImobilizado.ufItcmd ||
                            linhaPatrimonioImobilizado.naoInventariar
                        )
                    )
            ) {

                dadosIncompletos = dadosIcompletosAlertaCamposObrigatorios(familiares, linhaTitularList,
                    dadosIncompletos, pesquisaDescricaoList(tipoPatrimonioImobilizadoList, linhaPatrimonioImobilizado.tipoPatrimonioImobilizado))
            }
        })
    })

    return dadosIncompletos
}