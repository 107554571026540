import HARDCODE from '../../business-const/HardCode/HardCode'

import AuthService from '../../business-api/AuthService'

import ConfiguracaoService from '../../business-api/ConfiguracaoService'

import RespostaSellFluxService from '../../business-api/RespostaSellFluxService'

import UsuarioService from '../../business-api/UsuarioService'

import {
    mensagemErroErro,
} from '../../business-components/Toastr/Toastr'

//import {
//    pesquisaList
//} from '../../business-rules/List/ListService'

import whiteLabelList from '../../business-rules/List/whiteLabelList'

import {
    converteJsonConfiguracaoJsonImagem,
    calculaPaletaCores,
    ajustaInclusoesAlteracoesExclusoesJsonConfiguracao,
    ajustaInclusoesAlteracoesExclusoesJsonImagem,
} from '../configuracao/ConfiguracaoService'



export const idConfiguracaoHTTPS = () => {

    const https = window.location.href

    ////const URL = https.substr(8, https.indexOf(HARDCODE.fintracksDotCom) + 14 - 8)
    //const URL = 'www.desenvolvimento.fin-tracks.com'

    ////const idConfiguracao = pesquisaList(whiteLabelList, URL).idConfiguracao

    let idConfiguracao

    whiteLabelList?.forEach( (linha, ind) => {

        if (https.indexOf(linha) !== -1) {

            idConfiguracao = linha.idConfiguracao
        }
    })

    return idConfiguracao
}



export const getConfiguracaoURL = async (variaveisGlobais) => {

    const idConfiguracao = idConfiguracaoHTTPS()

    if (idConfiguracao) {

        const configuracaoService = new ConfiguracaoService()

        try {
            const response =
                await configuracaoService.buscarConfiguracaoURL(variaveisGlobais, idConfiguracao)

            const jsonConfiguracao = ajustaInclusoesAlteracoesExclusoesJsonConfiguracao(JSON.parse(response.data.jsonConfiguracao))

            const jsonImagem = ajustaInclusoesAlteracoesExclusoesJsonImagem(JSON.parse(response.data.jsonImagem))

            converteJsonConfiguracaoJsonImagem(jsonConfiguracao, jsonImagem)

            const paletaCores =
                jsonConfiguracao.paletaCores
                    ?
                        jsonConfiguracao.paletaCores
                    :
                        calculaPaletaCores(jsonConfiguracao.cores)

            if (
                    JSON.stringify(jsonImagem.logo ? jsonImagem.logo : null) !==
                        JSON.stringify(AuthService.obterLogo(variaveisGlobais)) ||
                    JSON.stringify(jsonConfiguracao.tamanhoLogo ? jsonConfiguracao.tamanhoLogo : null) !==
                        JSON.stringify(AuthService.obterTamanhoLogo(variaveisGlobais)) ||
                    JSON.stringify(jsonImagem.logoLoginSidenav ? jsonImagem.logoLoginSidenav : null) !==
                        JSON.stringify(AuthService.obterLogoLoginSidenav(variaveisGlobais)) ||
                    //JSON.stringify(jsonImagem.background ? jsonImagem.background : null) !==
                    //    JSON.stringify(AuthService.obterBackground(variaveisGlobais)) ||
                    JSON.stringify(jsonImagem.backgroundCapaRelatorio ? jsonImagem.backgroundCapaRelatorio : null) !==
                        JSON.stringify(AuthService.obterBackgroundCapaRelatorio(variaveisGlobais)) ||
                    JSON.stringify(jsonImagem.backgroundCapaRelatorioPaisagem ? jsonImagem.backgroundCapaRelatorioPaisagem : null) !==
                        JSON.stringify(AuthService.obterBackgroundCapaRelatorioPaisagem(variaveisGlobais)) ||
                    JSON.stringify(jsonImagem.backgroundPaginaRelatorio ? jsonImagem.backgroundPaginaRelatorio : null) !==
                        JSON.stringify(AuthService.obterBackgroundPaginaRelatorio(variaveisGlobais)) ||
                    JSON.stringify(jsonImagem.backgroundPaginaRelatorioPaisagem ? jsonImagem.backgroundPaginaRelatorioPaisagem : null) !==
                        JSON.stringify(AuthService.obterBackgroundPaginaRelatorioPaisagem(variaveisGlobais)) ||
                    JSON.stringify(paletaCores) !== JSON.stringify(AuthService.obterPaletaCores())
            ) {

                AuthService.setarLogo(jsonImagem.logo ? jsonImagem.logo : null)

                AuthService.setarTamanhoLogo(jsonConfiguracao.tamanhoLogo ? jsonConfiguracao.tamanhoLogo : null)

                AuthService.setarLogoLoginSidenav(jsonImagem.logoLoginSidenav ? jsonImagem.logoLoginSidenav : null)

                //AuthService.setarBackground(jsonImagem.background ? jsonImagem.background : null)
                
                AuthService.setarBackgroundCapaRelatorio(jsonImagem.backgroundCapaRelatorio ? jsonImagem.backgroundCapaRelatorio : null)
                
                AuthService.setarBackgroundCapaRelatorioPaisagem(jsonImagem.backgroundCapaRelatorioPaisagem ? jsonImagem.backgroundCapaRelatorioPaisagem : null)
                
                AuthService.setarBackgroundPaginaRelatorio(jsonImagem.backgroundPaginaRelatorio ? jsonImagem.backgroundPaginaRelatorio : null)
                
                AuthService.setarBackgroundPaginaRelatorioPaisagem(jsonImagem.backgroundPaginaRelatorioPaisagem ? jsonImagem.backgroundPaginaRelatorioPaisagem : null)

                AuthService.setarPaletaCores(paletaCores)

                window.location.reload()
            }

            return true

        } catch (erro) {

            mensagemErroErro(erro)

            return false
        }

    } else {

        return true
    }
}



export const postRespostaSellFlux = async (variaveisGlobais, idSellFlux, email, nome, celular) => {

    const respostaSellFluxService = new RespostaSellFluxService()

    let respostaOk = false

    try {

        if (idSellFlux) {

            respostaOk = await respostaSellFluxService.respostaSellFlux(variaveisGlobais, idSellFlux, email, nome, celular)
        }

    } catch (erro) {

        mensagemErroErro(erro)
    }

    return respostaOk
}

export const postReiniciarFreetrack = async (variaveisGlobais, email) => {

    const usuarioService = new UsuarioService()

    let respostaOk = false

    try {

        await usuarioService.reiniciarFreetrack(variaveisGlobais, email)

        respostaOk = true

    } catch (erro) {

        mensagemErroErro(erro)
    }

    return respostaOk
}



export const senhaOK = (senha) => {

    const regex = new RegExp(HARDCODE.regExpValidaSenha)

    return regex.test(senha)
}