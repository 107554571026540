// alterado - revisado

const LABELESPECIAL = {

    morte: 'Morte',

    ou: ' ou ',

    
    
    moedaReal: 'R$',
    moedaEuro: '€',
    moedaDolarAmericano: 'US$',
    moedaDolarCanadense: 'C$',
}

export default LABELESPECIAL