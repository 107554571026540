import readXlsxFile from 'read-excel-file'

import LABEL from '../../../business-const/Label/Label'

import HARDCODE from '../../../business-const/HardCode/HardCode'

import MESSAGE from '../../../business-const/Message/Message'

import AuthService from '../../../business-api/AuthService'

import ClienteService from '../../../business-api/ClienteService'

import {
    buscarEndereco
} from '../../../business-api/LogradouroService'

import {
    comparaArraysJSON
} from '../../../business-components/Array/Array'

import {
    dataAtual,
    ano,
    mes,
    dia,
    dataAnoMesDia,
    formataDataHora,
} from '../../../business-components/Date/Date'

import {
    formataCpf,
} from '../../../business-components/Formata/Formata'

import {
    mensagemErroErro
} from '../../../business-components/Toastr/Toastr'

import {
    getBase64,
    getBlobFromBase64,
} from '../../../business-components/UploadFile/UploadFile'

import {
    pesquisaList
} from '../../../business-rules/List/ListService'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import camposClienteXLSXPDFList from '../../../business-rules/List/camposClienteXLSXPDFList'

import familiaresList from '../../../business-rules/List/familiaresList'

import instituicaoFinanceiraList from '../../../business-rules/List/instituicaoFinanceiraList'

import tipoArquivoList from '../../../business-rules/List/Arquivo/tipoArquivoList'

import tipoCoberturaSeguroVidaList from '../../../business-rules/List/SeguroVida/tipoCoberturaSeguroVidaList'

import {
    getArquivoConteudo,
    putArquivoArquivoAcesso,
    putArquivoStatusProcessamento,
    putConverter,
} from '../../arquivo/ArquivoService'

import {
    putPropostaList
} from '../../dashboard/dashboard-proposta-form/DashboardServiceProposta'

import {
    putApoliceCobrancaList
} from '../../dashboard/dashboard-apoliceCobranca-form/DashboardServiceApoliceCobranca'

import {
    putApoliceComissaoList
} from '../../dashboard/dashboard-apoliceComissao-form/DashboardServiceApoliceComissao'

import {
    getFamiliaClienteList,
    obtemJsonClienteAlteracao,
    putClienteInfoclient,
    getIdentificaSexo,
    putTransferirCliente,
} from '../cliente-form/ClienteService'

import {
    processaValor,
    ajustaClienteJson,
} from '../cliente-form/ClienteServiceImportacao'

import {
    incluirContato,
} from '../cadastro-form/CadastroServiceContato'

import {
    getContaInvestimentos,
} from '../cadastro-form/CadastroServiceContaInvestimentos'

import {
    incrementaContEndereco,
} from '../cadastro-form/CadastroServiceEndereco'

import {
    identificaFamiliarSemCpf,
    pesquisaIndexFamiliar,
} from '../cadastro-form/CadastroServiceFamiliares'

import {
    obtemContReceitaMensal,
} from '../garantias-form/GarantiasServiceReceitaMensal'

import {
    obtemContRecursosFinanceiros,
    processaRegistrosDefaultIniciaisRecursosFinanceiros,
} from '../garantias-form/GarantiasServiceRecursosFinanceiros'

import {
    obtemContConsorcio,
    processaRegistrosDefaultIniciaisConsorcio,
} from '../consorcio-form/ConsorcioServiceConsorcio'

import {
    processaRegistrosDefaultIniciaisSeguroVidaProposta,
} from '../seguroVidaProposta-form/SeguroVidaPropostaService'

import {
    obtemContSeguroVidaProposta,
} from '../seguroVidaProposta-form/SeguroVidaPropostaServiceProposta'

import {
    obtemContSeguroVidaPropostaCobertura,
    excluirPropostaCoberturas,
} from '../seguroVidaProposta-form/SeguroVidaPropostaServiceCobertura'

import {
    processaRegistrosDefaultIniciaisSeguroVida,
} from '../seguroVida-form/SeguroVidaService'

import {
    obtemContSeguroVidaApolice,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaApolice'

import {
    obtemContSeguroVidaCobertura,
    excluirCoberturas,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaCobertura'



const analisaXLSXPDFTxt = (XLSXPDFTxt, XLSXPDF) => {

    let tipoArquivo = null
    let instituicaoFinanceira = null
    let processou = false

    instituicaoFinanceiraList?.forEach( (linhaInstituicaoFinanceira, indexInstituicaoFinanceira) => {

        if (linhaInstituicaoFinanceira.integracao && !processou) {

            linhaInstituicaoFinanceira.integracao?.forEach( (linhaIntegracao, indexIntegracao) => {

                if (!processou) {

                    if (XLSXPDF === HARDCODE.PDF && linhaIntegracao.XLSXPDF === HARDCODE.PDF) {

                        let matchChaveIntegracao = 0

                        linhaIntegracao.chaveIntegracao?.forEach((linhaChaveIntegracao, indexChaveIntegracao) => {

                            if (XLSXPDFTxt.indexOf(linhaChaveIntegracao) !== -1) {

                                matchChaveIntegracao++
                            }
                        })

                        if (matchChaveIntegracao > 0 && matchChaveIntegracao === linhaIntegracao.chaveIntegracao.length) {

                            tipoArquivo = linhaIntegracao.tipoArquivo
                            instituicaoFinanceira = linhaInstituicaoFinanceira.id
                            processou = true
                        }

                    } else {

                        if (XLSXPDF === HARDCODE.XLSX && linhaIntegracao.XLSXPDF === HARDCODE.XLSX) {

                            const cabecalho = XLSXPDFTxt[linhaIntegracao.cabecalho]

                            //console.log(cabecalho)

                            if (comparaArraysJSON(linhaIntegracao.chaveIntegracao, cabecalho)) {

                                tipoArquivo = linhaIntegracao.tipoArquivo
                                instituicaoFinanceira = linhaInstituicaoFinanceira.id
                                processou = true
                            }
                        }
                    }
                }
            })
        }
    })

    return { tipoArquivo, instituicaoFinanceira }
}



const obtemIdentificacao = (linhaJsonRegistros, nomeArquivo) => {

    return (
        linhaJsonRegistros.numeroApolice
                ?
                    LABEL.numeroApolice + ': ' + linhaJsonRegistros.numeroApolice
                :
                    linhaJsonRegistros.numeroProposta
                        ?
                            LABEL.numeroProposta + ': ' + linhaJsonRegistros.numeroProposta
                        : 
                            linhaJsonRegistros.numeroCotacao
                                ?
                                    LABEL.numeroCotacao + ': ' + linhaJsonRegistros.numeroCotacao
                                : 
                                    LABEL.nomeArquivo + ':  ' + nomeArquivo
    )
}


export const adicionaErro = (listaErros, erro, linhaArquivo) => {

    return (listaErros ? '<br>' : '') + HARDCODE.htmlMarcadorSquare + ' ' + linhaArquivo.name + LABEL.traco + erro
}



const salvaUpload = async (variaveisGlobais, arquivos, indexArquivos, idConfiguracao, tipoArquivo, instituicaoFinanceira,
    extensaoArquivo, conteudo, processados, erros, listaErros, naoProcessados) => {

    let processadosAux = processados
    let errosAux = erros
    let listaErrosAux = listaErros
    let naoProcessadosAux = naoProcessados

    if (tipoArquivo) {

        const arquivoArquivoAcesso = {
            idConfiguracaoOwner: idConfiguracao,
            idUsuarioOwner: ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais),
            grupoArquivo: HARDCODE.idGrupoArquivoCliente,
            tipoArquivo: tipoArquivo,
            instituicaoFinanceira: instituicaoFinanceira,
            nomeArquivo: arquivos[indexArquivos].name,
            extensaoArquivo: extensaoArquivo,
            statusProcessamento: HARDCODE.idStatusProcessamentoPendenteProcessamento,
            idUsuarioAcesso:  ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais),
        }

        try {

            await putArquivoArquivoAcesso(variaveisGlobais, ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais),
                arquivoArquivoAcesso, conteudo)

            processadosAux++

        } catch (erro) {

            const textoErro = mensagemErroErro(erro)
            
            errosAux++

            listaErrosAux = adicionaErro(listaErros, textoErro, arquivos[indexArquivos])
        }

    } else {

        const arquivoArquivoAcesso = {
            idConfiguracaoOwner: idConfiguracao,
            idUsuarioOwner: ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais),
            grupoArquivo: HARDCODE.idGrupoArquivoCliente,
            tipoArquivo: HARDCODE.idTipoArquivoNaoReconhecido,
            instituicaoFinanceira: HARDCODE.idSeguradoraOutras,
            nomeArquivo: arquivos[indexArquivos].name,
            extensaoArquivo: extensaoArquivo,
            statusProcessamento: HARDCODE.idStatusProcessamentoLayoutNaoReconhecido,
            idUsuarioAcesso:  ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais),
        }

        try {

            await putArquivoArquivoAcesso(variaveisGlobais, ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais),
                arquivoArquivoAcesso, conteudo)

            naoProcessadosAux++

        } catch (erro) {

            const textoErro = mensagemErroErro(erro)
            
            errosAux++

            listaErrosAux = adicionaErro(listaErros, textoErro, arquivos[indexArquivos])
        }
    }

    return {processadosAux, errosAux, listaErrosAux, naoProcessadosAux}
}

export const processaUploadArquivos = async (variaveisGlobais, arquivos, idConfiguracao) => {

    let processados = 0
    let naoProcessados = 0
    let erros = 0
    let listaErros = ''

    let indexArquivos = 0

    while (indexArquivos < arquivos.length) {

        try {

            const conteudo = await getBase64(arquivos[indexArquivos])

            if (arquivos[indexArquivos].type === HARDCODE.PDFApplication) {

                const { PDFTxt, mensagemErro } = await putConverter(variaveisGlobais, conteudo)

                if (PDFTxt) {

                    //console.log(PDFTxt)
                    //console.log(PDFTxt.replaceAll('\u0000', 'fi'))

                    const { tipoArquivo, instituicaoFinanceira } = analisaXLSXPDFTxt(PDFTxt, HARDCODE.PDF)

                    const {processadosAux, errosAux, listaErrosAux, naoProcessadosAux} = await salvaUpload(variaveisGlobais, arquivos,
                        indexArquivos, idConfiguracao, tipoArquivo, instituicaoFinanceira, HARDCODE.PDF, conteudo, processados,
                            erros, listaErros, naoProcessados)

                    processados = processadosAux
                    erros = errosAux
                    listaErros = listaErrosAux
                    naoProcessados = naoProcessadosAux

                } else {

                    erros++

                    listaErros = adicionaErro(listaErros, mensagemErro, arquivos[indexArquivos])
                }

            } else {

                const XLSXBlob = getBlobFromBase64(conteudo)
                
                const XLSX = await readXlsxFile(XLSXBlob)

                const { tipoArquivo, instituicaoFinanceira } = analisaXLSXPDFTxt(XLSX, HARDCODE.XLSX)

                const {processadosAux, errosAux, listaErrosAux, naoProcessadosAux} = await salvaUpload(variaveisGlobais, arquivos,
                    indexArquivos, idConfiguracao, tipoArquivo, instituicaoFinanceira, HARDCODE.XLSX, conteudo, processados,
                        erros, listaErros, naoProcessados)

                processados = processadosAux
                erros = errosAux
                listaErros = listaErrosAux
                naoProcessados = naoProcessadosAux
            }

        } catch (erro) {

            const textoErro = mensagemErroErro(erro)
            
            erros++

            listaErros = adicionaErro(listaErros, textoErro, arquivos[indexArquivos])
        }

        indexArquivos++
    }

    return { processados, naoProcessados, erros, listaErros }
}



export const incluiErroTipoCoberturaSeguroVida = (descricaoCobertura, linhaJsonRegistros, nomeArquivo, erros) => {
    
    erros.push(
        MESSAGE().tipoCoberturaSeguroVidaNaoEncontrado
            .replace("$identificacao$", obtemIdentificacao(linhaJsonRegistros, nomeArquivo))
                .replace("$descricaoCobertura$", descricaoCobertura)
                    .replace("$cpfSegurado$", formataCpf(linhaJsonRegistros.cpfSeguradoCliente))
                        .replace("$nomeSegurado$", linhaJsonRegistros.nomeSeguradoCliente)
    )
}

export const identificaTipoCoberturaSeguroVida = (descricaoCobertura, linhaIntegracao, linhaJsonRegistros, nomeArquivo, erros) => {

    let indexTipoCoberturaSeguroVida = 0

    let tipoCoberturaSeguroVida = ''

    while (indexTipoCoberturaSeguroVida < linhaIntegracao.tipoCoberturaSeguroVida.length && !tipoCoberturaSeguroVida) {
    
        if (descricaoCobertura.indexOf(linhaIntegracao.tipoCoberturaSeguroVida[indexTipoCoberturaSeguroVida].id) !== -1) {

            tipoCoberturaSeguroVida = linhaIntegracao.tipoCoberturaSeguroVida[indexTipoCoberturaSeguroVida].tipoCoberturaSeguroVida
        }

        indexTipoCoberturaSeguroVida++
    }

    if (!tipoCoberturaSeguroVida) {

        incluiErroTipoCoberturaSeguroVida(descricaoCobertura, linhaJsonRegistros, nomeArquivo, erros)
    }

    return tipoCoberturaSeguroVida
}

export const identificaStatusCobertura = (descricaoStatusCobertura, linhaIntegracao, linhaJsonRegistros, nomeArquivo, erros) => {

    let indexStatusCobertura = 0

    let statusCobertura = ''

    while (indexStatusCobertura < linhaIntegracao.statusCobertura.length && !statusCobertura) {
    
        if (descricaoStatusCobertura.indexOf(linhaIntegracao.statusCobertura[indexStatusCobertura].id) !== -1) {

            statusCobertura = linhaIntegracao.statusCobertura[indexStatusCobertura].idConversao
        }

        indexStatusCobertura++
    }

    if (!statusCobertura) {

        erros.push(
            MESSAGE().statusCoberturaNaoEncontrado
                .replace("$identificacao$", obtemIdentificacao(linhaJsonRegistros, nomeArquivo))
                    .replace("$descricaoStatusCobertura$", descricaoStatusCobertura)
                        .replace("$cpfSegurado$", formataCpf(linhaJsonRegistros.cpfSeguradoCliente))
                            .replace("$nomeSegurado$", linhaJsonRegistros.nomeSeguradoCliente)
        )
    }

    return statusCobertura
}

export const calculaDataTerminoCobertura = (jsonRegistros, indexJsonRegistros, campoData, prazo) => {

    let dataTerminoCobertura

    if (prazo === 'VITALÍCIO') {

        dataTerminoCobertura =
            dataAnoMesDia(
                ano(jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente) + 100/* - 1*/,
                mes(jsonRegistros.jsonRegistros[indexJsonRegistros][campoData]),
                dia(jsonRegistros.jsonRegistros[indexJsonRegistros][campoData])
            )

    } else {

        dataTerminoCobertura =
            dataAnoMesDia(
                ano(jsonRegistros.jsonRegistros[indexJsonRegistros][campoData]) +
                    Number.parseInt(('' + prazo).replace('ANOS', '')),
                mes(jsonRegistros.jsonRegistros[indexJsonRegistros][campoData]),
                dia(jsonRegistros.jsonRegistros[indexJsonRegistros][campoData])
            )
    }

    return dataTerminoCobertura
}

export const indexAtualJsonRegistros = (jsonRegistros) => {

    return jsonRegistros.jsonRegistros.length - 1
}

export const obtemDadoPDF = (PDFTxt, jsonRegistros, chaveInicio, chaveFim, campo, obrigatorio, conversaoIntegracao, erros, nomeArquivo) => {

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    jsonRegistros.jsonRegistros[indexJsonRegistros][campo] = ''

    const identificacao = obtemIdentificacao(jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo)

    let indexInicio = PDFTxt.indexOf(chaveInicio, jsonRegistros.indexInicial)/* + chaveInicio.length*/

    if (indexInicio !== -1 && indexInicio >= jsonRegistros.indexInicial) {

        indexInicio += chaveInicio.length

        let indexFim

        if (chaveFim >= 1 && chaveFim <= 100) {

            indexFim = indexInicio + chaveFim

        } else {
        
            indexFim = PDFTxt.indexOf(chaveFim, indexInicio)
        }

        if (indexFim !== -1 && indexFim >= indexInicio) {

            const valor = PDFTxt.substring(indexInicio, indexFim)

            jsonRegistros.indexInicial = indexFim

            processaValor(jsonRegistros.jsonRegistros[indexJsonRegistros], campo, valor, camposClienteXLSXPDFList, erros, identificacao,
                HARDCODE.PDF)

            if (conversaoIntegracao) {

                const linhaConversaoIntegracao = pesquisaList(conversaoIntegracao, jsonRegistros.jsonRegistros[indexJsonRegistros][campo])

                if (linhaConversaoIntegracao.idConversao) {

                    jsonRegistros.jsonRegistros[indexJsonRegistros][campo] = linhaConversaoIntegracao.idConversao

                } else {

                    erros.push(MESSAGE().campoNaoConvertido
                        .replace("$identificacao$", identificacao)
                            .replace("$campo$", campo)
                                .replace("$valor$", jsonRegistros.jsonRegistros[indexJsonRegistros][campo])
                    )
                }
            }

        } else {

            if (obrigatorio) {

                erros.push(MESSAGE().campoNaoEncontradoPDFFim
                    .replace("$identificacao$", identificacao)
                        .replace("$campo$", campo)
                )
            }
        }

    } else {

        if (obrigatorio) {

            erros.push(MESSAGE().campoNaoEncontradoPDFInicio
                .replace("$identificacao$", identificacao)
                    .replace("$campo$", campo)
            )
        }
    }
}

export const obtemDadoPDFAnterior = (PDFTxt, jsonRegistros, chaveInicio, chaveFim, campo, obrigatorio, conversaoIntegracao, erros, nomeArquivo) => {

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    jsonRegistros.jsonRegistros[indexJsonRegistros][campo] = ''

    const identificacao = obtemIdentificacao(jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo)

    let indexFim = PDFTxt.indexOf(chaveInicio, jsonRegistros.indexInicial)/* + chaveInicio.length*/

    if (indexFim !== -1 && indexFim >= jsonRegistros.indexInicial) {

        let indexInicio = indexFim

        while (PDFTxt.substring(indexInicio - chaveFim.length, indexInicio) !== chaveFim) {

            indexInicio--
        }

        if (indexFim >= indexInicio) {

            const valor = PDFTxt.substring(indexInicio, indexFim)

            jsonRegistros.indexInicial = indexFim

            processaValor(jsonRegistros.jsonRegistros[indexJsonRegistros], campo, valor, camposClienteXLSXPDFList, erros, identificacao,
                HARDCODE.PDF)

            if (conversaoIntegracao) {

                const linhaConversaoIntegracao = pesquisaList(conversaoIntegracao, jsonRegistros.jsonRegistros[indexJsonRegistros][campo])

                if (linhaConversaoIntegracao.idConversao) {

                    jsonRegistros.jsonRegistros[indexJsonRegistros][campo] = linhaConversaoIntegracao.idConversao

                } else {

                    erros.push(MESSAGE().campoNaoConvertido
                        .replace("$identificacao$", identificacao)
                            .replace("$campo$", campo)
                                .replace("$valor$", jsonRegistros.jsonRegistros[indexJsonRegistros][campo])
                    )
                }
            }

        } else {

            if (obrigatorio) {

                erros.push(MESSAGE().campoNaoEncontradoPDFFim
                    .replace("$identificacao$", identificacao)
                        .replace("$campo$", campo)
                )
            }
        }

    } else {

        if (obrigatorio) {

            erros.push(MESSAGE().campoNaoEncontradoPDFInicio
                .replace("$identificacao$", identificacao)
                    .replace("$campo$", campo)
            )
        }
    }
}



export const obtemDadoXLSX = (XLSX, jsonRegistros, linha, coluna, campo, obrigatorio, conversaoIntegracao, erros, nomeArquivo) => {

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    jsonRegistros.jsonRegistros[indexJsonRegistros][campo] = ''

    const identificacao = obtemIdentificacao(jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo)

    const valor = XLSX[linha][coluna]

    if (valor || valor === 0) {

        processaValor(jsonRegistros.jsonRegistros[indexJsonRegistros], campo, valor, camposClienteXLSXPDFList, erros, identificacao,
            HARDCODE.XLSX)

        if (conversaoIntegracao) {

            const linhaConversaoIntegracao = pesquisaList(conversaoIntegracao, jsonRegistros.jsonRegistros[indexJsonRegistros][campo])

            if (linhaConversaoIntegracao.idConversao) {

                jsonRegistros.jsonRegistros[indexJsonRegistros][campo] = linhaConversaoIntegracao.idConversao

            } else {

                erros.push(MESSAGE().campoNaoConvertido
                    .replace("$identificacao$", identificacao)
                        .replace("$campo$", campo)
                            .replace("$valor$", jsonRegistros.jsonRegistros[indexJsonRegistros][campo])
                )
            }
        }

    } else {
        
        if (obrigatorio) {

            erros.push(MESSAGE().campoNaoEncontradoXLSX
                .replace("$identificacao$", identificacao)
                    .replace("$campo$", campo)
            )
        }
    }
}



export const obtemDadoPDFAvulso = (jsonRegistros, dado, campo, obrigatorio, conversaoIntegracao, erros, nomeArquivo) => {

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    jsonRegistros.jsonRegistros[indexJsonRegistros][campo] = ''

    const identificacao = obtemIdentificacao(jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo)

    const valor = dado

    if (valor || valor === 0) {

        processaValor(jsonRegistros.jsonRegistros[indexJsonRegistros], campo, valor, camposClienteXLSXPDFList, erros, identificacao,
            HARDCODE.PDF)

        if (conversaoIntegracao) {

            const linhaConversaoIntegracao = pesquisaList(conversaoIntegracao, jsonRegistros.jsonRegistros[indexJsonRegistros][campo])

            if (linhaConversaoIntegracao.idConversao) {

                jsonRegistros.jsonRegistros[indexJsonRegistros][campo] = linhaConversaoIntegracao.idConversao

            } else {

                erros.push(MESSAGE().campoNaoConvertido
                    .replace("$identificacao$", identificacao)
                        .replace("$campo$", campo)
                            .replace("$valor$", jsonRegistros.jsonRegistros[indexJsonRegistros][campo])
                )
            }
        }

    } else {
        
        if (obrigatorio) {

            erros.push(MESSAGE().campoNaoEncontradoPDF
                .replace("$identificacao$", identificacao)
                    .replace("$campo$", campo)
            )
        }
    }
}

export const indexPDFAvulso = (linhasPDFTxt, texto, indexInicial) => {

    let index = null

    linhasPDFTxt?.forEach( (linhaPDFTxt, indexLinhaPDFTxt) => {

        if (!index && indexLinhaPDFTxt >= indexInicial && linhaPDFTxt === texto) {

            index = indexLinhaPDFTxt
        }
    })

    return index
}

export const encontrarData = (texto) => {

    let data = null

    const arrayTexto = texto.split(espaco)

    let index = 0

    while (index < arrayTexto.length) {

        const padrao = '0123456789'

        arrayTexto[index] += ''

        if (padrao.indexOf(arrayTexto[index].substring(0, 1)) !== -1) {

            if (padrao.indexOf(arrayTexto[index].substring(1, 2)) !== -1) {

                if (arrayTexto[index].substring(2, 3) === '/') {

                    data = arrayTexto[index]

                    break
                }
            }
        }

        index ++
    }

    return data
}

export const agentesSeguradora = (jsonUsuario, seguradora) => {

    const agentesSeguradoraAux = []

    jsonUsuario.integracaoSeguradoras?.forEach( (linhaIntegracaoSeguradoras, indexIntegracaoSeguradora) => {

        if (linhaIntegracaoSeguradoras.id === seguradora) {

            agentesSeguradoraAux.push(
                {
                    id: linhaIntegracaoSeguradoras.codigoAgente
                }
            )
        }
    })

    return agentesSeguradoraAux
}

export const timeSeguradora = (idUsuarioLogado, equipeList, seguradora) => {

    const timeSeguradoraAux = []

    const linhaUsuarioLogado = pesquisaList(equipeList, idUsuarioLogado)

    equipeList?.forEach( (linhaEquipeList, indexEquipeList) => {

        if (
                (
                    linhaEquipeList.id === idUsuarioLogado ||
                    (
                        linhaUsuarioLogado.jsonEquipe && pesquisaList(linhaUsuarioLogado.jsonEquipe, linhaEquipeList.id).id
                    )
                ) &&
                linhaEquipeList.jsonUsuario
            ) {

            const agentesSeguradoraAux = agentesSeguradora(linhaEquipeList.jsonUsuario, seguradora)

            if (agentesSeguradoraAux.length > 0) {

                timeSeguradoraAux.push({
                    id: linhaEquipeList.id,
                    agentesSeguradora: agentesSeguradoraAux
                })
            }
        }
    })

    return timeSeguradoraAux
}

export const agentePertenceSeguradora = (agentesSeguradora, codigoAgente) => {

    let agenteOK = false

    agentesSeguradora?.forEach( (linhaAgentesSeguradora, indexAgentesSeguradora) => {

        if (linhaAgentesSeguradora.id === ('' + codigoAgente).toUpperCase()) {

            agenteOK = true
        }
    })

    return agenteOK
}



export const idAgenteTimeSeguradora = (timeSeguradoraAux, codigoAgente) => {

    let id = ''

    timeSeguradoraAux?.forEach( (linhaTimeSeguradoraAux, indexTimeSeguradoraAux) => {

        if (!id && agentePertenceSeguradora(linhaTimeSeguradoraAux.agentesSeguradora, codigoAgente)) {

            id = linhaTimeSeguradoraAux.id
        }
    })

    return id
}



export const espaco = ' '
export const fimLinha = HARDCODE.conexao.fimLinha
export const barraData = '/'
export const virgula = ','

export const jsonRegistrosInicializacao = () => {

    return {
        indexInicial: 0,
        jsonRegistros: []
    }
}

export const jsonRegistrosJsonRegistrosInicializacao = (erros) => {

    return {
        qtdeErros: erros.length,
    }
}

export const jsonRegistrosJsonRegistrosAtualizaErros = (jsonRegistros, erros) => {

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    jsonRegistros.jsonRegistros[indexJsonRegistros].qtdeErros = erros.length - jsonRegistros.jsonRegistros[indexJsonRegistros].qtdeErros
}



export const incluiCoberturas = (coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
    statusCobertura, valorCS, valorPremioLiquido, valorIof, valorPremio, classeAjusteRisco, valorPremioExtra, duracaoPremioExtra,
        dataTerminoCobertura, valorRendaMensal) => {

    const grupoCoberturaSeguroVida = pesquisaList(tipoCoberturaSeguroVidaList, tipoCoberturaSeguroVida).grupoCoberturaSeguroVida

    coberturas.push({
        numeroCobertura: numeroCobertura,
        codigoCobertura: codigoCobertura,
        descricaoCobertura: descricaoCobertura.trim(),
        grupoCoberturaSeguroVida: grupoCoberturaSeguroVida,
        tipoCoberturaSeguroVida: tipoCoberturaSeguroVida,
        statusCobertura: statusCobertura,
        valorCS: valorCS,
        valorPremioLiquido: valorPremioLiquido,
        valorIof: valorIof,
        valorPremio: valorPremio,
        classeAjusteRisco: classeAjusteRisco,
        valorPremioExtra: valorPremioExtra,
        duracaoPremioExtra: duracaoPremioExtra,
        dataTerminoCobertura: dataTerminoCobertura,
        valorRendaMensal: valorRendaMensal,
    })
}

export const incluiPosicaoInvestimentos = (posicaoInvestimentos, tipoRecursoFinanceiro, descricao, valor,
    percRentabilidadeAnualRecursoFinanceiro) => {

    posicaoInvestimentos.push({
        tipoRecursoFinanceiro: tipoRecursoFinanceiro,
        descricao: descricao,
        valor: valor,
        percRentabilidadeAnualRecursoFinanceiro: percRentabilidadeAnualRecursoFinanceiro,
    })
}

export const numeroCotacaoAutomatico = () => {

    return formataDataHora(dataAtual()).replace(' ', '-').replace(':', '')
}

export const incluiTabelaEvolucaoValorResgateCoberturaVidaInteira = (values, item, numeroCotacao, dataCotacao, anoTabela, idade,
    valorCS, valorPremioAnualizadoAcumulado, valorResgate, valorSaldado, beneficioProlongado) => {

    item.tabelaEvolucaoValorResgate.push(
        {
            ano: anoTabela,
            idade: idade,
            valorCS: valorCS,
            valorPremioAnualizadoAcumulado: valorPremioAnualizadoAcumulado,
            valorResgate: valorResgate,
            valorSaldado: valorSaldado,
            beneficioProlongado: beneficioProlongado,
        }
    )

    if (anoTabela === 1) {

        valorCS = item.tabelaEvolucaoValorResgate.length === 1 ? valorCS : item.tabelaEvolucaoValorResgate[0].valorCS

        values.seguroVidaCotacaoCobertura?.forEach( (linhaSeguroVidaCotacaoCobertura, indexSeguroVidaCotacaoCobertura) => {

            if (
                    linhaSeguroVidaCotacaoCobertura.idSeguroVidaCotacao === item.id &&
                    linhaSeguroVidaCotacaoCobertura.grupoCoberturaSeguroVida === HARDCODE.idGrupoCoberturaSeguroVidaMorte &&
                    (
                        !linhaSeguroVidaCotacaoCobertura.tipoCoberturaSeguroVida ||
                        linhaSeguroVidaCotacaoCobertura.tipoCoberturaSeguroVida ===
                            HARDCODE.idTipoCoberturaSeguroVidaVidaInteira
                    )
            ) {

                item.numeroCotacao = numeroCotacao
                item.dataCotacao = dataCotacao
                item.tipoPeriodicidadePagtoPremio = HARDCODE.idTipoPeriodicidadeAnual

                values.seguroVidaCotacaoCobertura[indexSeguroVidaCotacaoCobertura].tipoCoberturaSeguroVida =
                    HARDCODE.idTipoCoberturaSeguroVidaVidaInteira
                values.seguroVidaCotacaoCobertura[indexSeguroVidaCotacaoCobertura].valorCS = valorCS
                values.seguroVidaCotacaoCobertura[indexSeguroVidaCotacaoCobertura].valorPremio =
                    valorPremioAnualizadoAcumulado
                    values.seguroVidaCotacaoCobertura[indexSeguroVidaCotacaoCobertura].dataTerminoCobertura =
                    dataAnoMesDia(
                        ano(pesquisaList(familiaresList(values), item.familiar).nascimento) + 100 - 1,
                        mes(values.dataSimulacaoEstudo),
                        dia(values.dataSimulacaoEstudo)
                    )
            }
        })
    }
}

const atualizarJsonDadosCadastrais = (json, familiar, linhaJsonRegistros) => {

    if (familiar === HARDCODE.idFamiliarCliente) {

        if (!json.nascimentoCliente && linhaJsonRegistros.nascimentoSeguradoCliente) {

            json.nascimentoCliente = linhaJsonRegistros.nascimentoSeguradoCliente
        }

        if (!json.sexoCliente && linhaJsonRegistros.sexoSeguradoCliente) {

            json.sexoCliente = linhaJsonRegistros.sexoSeguradoCliente
        }

        if (!json.cpfCliente && linhaJsonRegistros.cpfSeguradoCliente) {

            json.cpfCliente = linhaJsonRegistros.cpfSeguradoCliente
        }

        if (linhaJsonRegistros.profissaoSegurado) {

            json.profissaoCliente = linhaJsonRegistros.profissaoSegurado
            json.profissaoClienteOutras = linhaJsonRegistros.profissaoSeguradoOutras
        }

    } else {
        
        if (familiar === HARDCODE.idFamiliarConjuge) {

            if (!json.nascimentoConjuge && linhaJsonRegistros.nascimentoSeguradoCliente) {

                json.nascimentoConjuge = linhaJsonRegistros.nascimentoSeguradoCliente
            }

            if (!json.sexoConjuge && linhaJsonRegistros.sexoSeguradoCliente) {

                json.sexoConjuge = linhaJsonRegistros.sexoSeguradoCliente
            }

            if (!json.cpfConjuge && linhaJsonRegistros.cpfSeguradoCliente) {

                json.cpfConjuge = linhaJsonRegistros.cpfSeguradoCliente
            }
    
            if (linhaJsonRegistros.profissaoSegurado) {
            
                json.profissaoConjuge = linhaJsonRegistros.profissaoSegurado
                json.profissaoConjugeOutras = linhaJsonRegistros.profissaoSeguradoOutras
            }
        }
    }
}

const atualizarJsonContatos = (json, familiar, linhaJsonRegistros) => {

    if (!json.contatos) {

        json.contatos = []
    }

    if (linhaJsonRegistros.telefoneSegurado) {

        incluirContato(json, familiar,
            linhaJsonRegistros.telefoneSegurado.length <= 10
                ?
                    HARDCODE.idTipoContatoTelefoneFixo
                :
                    HARDCODE.idTipoContatoCelular,
            linhaJsonRegistros.telefoneSegurado
        )
    }

    if (linhaJsonRegistros.telefoneComercialSegurado) {

        incluirContato(json, familiar,
            linhaJsonRegistros.telefoneComercialSegurado.length <= 10
                ?
                    HARDCODE.idTipoContatoTelefoneComercial
                :
                    HARDCODE.idTipoContatoCelularComercial,
            linhaJsonRegistros.telefoneComercialSegurado
        )
    }

    if (linhaJsonRegistros.celularSeguradoCliente) {

        incluirContato(json, familiar, HARDCODE.idTipoContatoCelular, linhaJsonRegistros.celularSeguradoCliente)
    }

    if (linhaJsonRegistros.emailSeguradoCliente) {

        incluirContato(json, familiar, HARDCODE.idTipoContatoEmail, linhaJsonRegistros.emailSeguradoCliente)
    }
}

const atualizarJsonEndereco = async (variaveisGlobais, json, idTipoEnderecoResidencial, cepSeguradoCliente, enderecoSeguradoCliente,
    numeroEnderecoSeguradoCliente, complementoEnderecoSeguradoCliente, bairroSeguradoCliente, cidadeSeguradoCliente,
        ufSeguradoCliente, integracaoSeguradora) => {

    if (!json.enderecos) {

        json.enderecos = []
    }

    if (
            cepSeguradoCliente &&
            (
                !enderecoSeguradoCliente ||
                !bairroSeguradoCliente ||
                !cidadeSeguradoCliente ||
                !ufSeguradoCliente
            )
    ) {

        const item =
            {
                cep: cepSeguradoCliente
            }

        await buscarEndereco(variaveisGlobais, item, true)

        if (!enderecoSeguradoCliente) {

            enderecoSeguradoCliente = item.endereco
        }

        if (!bairroSeguradoCliente) {

            bairroSeguradoCliente = item.bairro
        }

        if (!cidadeSeguradoCliente) {

            cidadeSeguradoCliente = item.cidade
        }

        if (!ufSeguradoCliente) {

            ufSeguradoCliente = item.uf
        }
    }

    if (cepSeguradoCliente && enderecoSeguradoCliente && cidadeSeguradoCliente && ufSeguradoCliente) {

        let incluir = true

        json.enderecos?.forEach( (linhaEndereco, indexEnderedo) => {

            if (linhaEndereco.tipoEndereco === idTipoEnderecoResidencial) {

                incluir = false

                if (
                        integracaoSeguradora ||
                        (
                            !linhaEndereco.cep &&
                            !linhaEndereco.endereco &&
                            !linhaEndereco.numeroEndereco &&
                            !linhaEndereco.complementoEndereco &&
                            !linhaEndereco.bairro &&
                            !linhaEndereco.cidade &&
                            !linhaEndereco.uf
                        ) ||
                        (
                            !linhaEndereco.cep &&
                            !linhaEndereco.endereco &&
                            !linhaEndereco.numeroEndereco &&
                            !linhaEndereco.complementoEndereco &&
                            !linhaEndereco.bairro &&
                            !linhaEndereco.cidade &&
                            linhaEndereco.uf &&
                            linhaEndereco.uf === ufSeguradoCliente
                        )
                ) {

                    json.enderecos[indexEnderedo].cep = cepSeguradoCliente
                    json.enderecos[indexEnderedo].endereco = enderecoSeguradoCliente
                    json.enderecos[indexEnderedo].numeroEndereco = numeroEnderecoSeguradoCliente
                    json.enderecos[indexEnderedo].complementoEndereco = complementoEnderecoSeguradoCliente
                    json.enderecos[indexEnderedo].bairro = bairroSeguradoCliente
                    json.enderecos[indexEnderedo].cidade = cidadeSeguradoCliente
                    json.enderecos[indexEnderedo].uf = ufSeguradoCliente
                }
            }
        })

        if (incluir) {

            incrementaContEndereco(json)

            json.enderecos.push({
                id: json.contEndereco,
                tipoEndereco: idTipoEnderecoResidencial,
                cep: cepSeguradoCliente,
                endereco: enderecoSeguradoCliente,
                numeroEndereco: numeroEnderecoSeguradoCliente,
                complementoEndereco: complementoEnderecoSeguradoCliente,
                bairro: bairroSeguradoCliente,
                cidade: cidadeSeguradoCliente,
                uf: ufSeguradoCliente,
            })
        }

    } else {

        if (
                json.enderecos.length === 0 &&
                (
                    cepSeguradoCliente ||
                    bairroSeguradoCliente ||
                    cidadeSeguradoCliente ||
                    ufSeguradoCliente
                )
         ) {

            incrementaContEndereco(json)

            json.enderecos.push({
                id: json.contEndereco,
                tipoEndereco: idTipoEnderecoResidencial,
                cep: cepSeguradoCliente,
                bairro: bairroSeguradoCliente,
                cidade: cidadeSeguradoCliente,
                uf: ufSeguradoCliente,
            })
        }
    }
}

const atualizarJsonReceitaMensal = async (json, linhaJsonRegistros, familiar) => {

    if (!json.receitaMensal && linhaJsonRegistros.rendaMensal) {

        json.receitaMensal = []

        json.receitaMensal.push(
            {
                id: obtemContReceitaMensal(json),
                familiar: familiar,
                tipoReceitaMensal: HARDCODE.idTipoReceitaMensalRendaMensal,
                valor: linhaJsonRegistros.rendaMensal,
                tipoPeriodicidadeRecebto: HARDCODE.idTipoPeriodicidadeMensal,
                observacao: MESSAGE().incluidoIntegracao,
                valorIntegracao: linhaJsonRegistros.rendaMensal,
            }
        )
    }
}

const atualizarJsonRecursosFinanceiros = async (json, linhaJsonRegistros, familiar) => {

    if (linhaJsonRegistros.posicaoInvestimentos) {

        processaRegistrosDefaultIniciaisRecursosFinanceiros(json, familiar)

        const chaveIntegracao1 = familiar + '|' + linhaJsonRegistros.instituicaoFinanceira

        json.recursosFinanceiros?.forEach( (linhaRecursosFinanceiros, indRecursosFinanceiros) => {

            if (linhaRecursosFinanceiros.chaveIntegracao1 === chaveIntegracao1) {

                json.recursosFinanceiros[indRecursosFinanceiros].atualizouIntegracao = 'N'
            }
        })

        linhaJsonRegistros.posicaoInvestimentos?.forEach( (linhaPosicaoInvestimentos, indPosicaoInvestimentos) => {

            let index = -1

            const chaveIntegracao2 = linhaPosicaoInvestimentos.tipoRecursoFinanceiro + '|' + linhaPosicaoInvestimentos.descricao

            json.recursosFinanceiros?.forEach( (linhaRecursosFinanceiros, indRecursosFinanceiros) => {

                if (
                        chaveIntegracao1 === linhaRecursosFinanceiros.chaveIntegracao1 &&
                        chaveIntegracao2 === linhaRecursosFinanceiros.chaveIntegracao2
                ) {

                    index = indRecursosFinanceiros
                }
            })

            if (index === -1) {

                json.recursosFinanceiros.push(
                    {
                        id: obtemContRecursosFinanceiros(json),
                        familiar: familiar,
                        tipoRecursoFinanceiro: linhaPosicaoInvestimentos.tipoRecursoFinanceiro,
                        valor: linhaPosicaoInvestimentos.valor,
                        percRentabilidadeAnualRecursoFinanceiro:
                            linhaPosicaoInvestimentos.percRentabilidadeAnualRecursoFinanceiro,
                        instituicaoFinanceira: linhaJsonRegistros.instituicaoFinanceira,
                        descricaoOutros: linhaPosicaoInvestimentos.descricao,
                        observacao: MESSAGE().incluidoIntegracao,
                        chaveIntegracao1: chaveIntegracao1,
                        chaveIntegracao2: chaveIntegracao2,
                        atualizouIntegracao: 'S',
                    }
                )

            } else {

                json.recursosFinanceiros[index].valor = linhaPosicaoInvestimentos.valor
                json.recursosFinanceiros[index].percRentabilidadeAnualRecursoFinanceiro =
                    linhaPosicaoInvestimentos.percRentabilidadeAnualRecursoFinanceiro

                if (!json.recursosFinanceiros[index].instituicaoFinanceira) {
                    
                    json.recursosFinanceiros[index].instituicaoFinanceira = linhaJsonRegistros.instituicaoFinanceira
                }
                
                json.recursosFinanceiros[index].atualizouIntegracao = 'S'
            }
        })

        let index = json.recursosFinanceiros.length - 1

        while (index >= 0) {

            if (
                    json.recursosFinanceiros[index].chaveIntegracao1 === chaveIntegracao1 && 
                    json.recursosFinanceiros[index].atualizouIntegracao === 'N'
            ) {
    
                json.recursosFinanceiros.splice(index, 1)
            }
    
            index -= 1
        }
    }
}

const atualizarJsonPropostaCoberturas = (json, linhaJsonRegistros, idFamiliar, idSeguradora, tipoAcessoArquivo) => {

    if (tipoAcessoArquivo === HARDCODE.tipoAcessoArquivoProposta && linhaJsonRegistros.numeroProposta) {

        let indexProposta = -1

        processaRegistrosDefaultIniciaisSeguroVidaProposta(json, idFamiliar)

        let index = json.seguroVidaProposta.length - 1

        while (index >= 0) {

            if (
                    json.seguroVidaProposta[index].familiar === idFamiliar &&
                    json.seguroVidaProposta[index].numeroProposta === linhaJsonRegistros.numeroProposta &&
                    json.seguroVidaProposta[index].seguradora === idSeguradora
            ) {
                
                indexProposta = index

            } else {

                if (
                        !json.seguroVidaProposta[index].numeroProposta &&
                        !json.seguroVidaProposta[index].seguradora &&
                        !json.seguroVidaProposta[index].dataAssinatura &&
                        !json.seguroVidaProposta[index].statusProposta &&
                        !json.seguroVidaProposta[index].numeroCotacao &&
                        !json.seguroVidaProposta[index].observacao
                ) {

                    let excluir = true

                    const linhaSeguroVidaProposta = json.seguroVidaProposta[index]

                    json.seguroVidaPropostaCobertura?.forEach( (linhaSeguroVidaPropostaCobertura, indexSeguroVidaPropostaCobertura) => {

                        if (
                                linhaSeguroVidaPropostaCobertura.idSeguroVidaProposta === linhaSeguroVidaProposta.id &&
                                linhaSeguroVidaPropostaCobertura.tipoCoberturaSeguroVida
                        ) {

                            excluir = false
                        }
                    })

                    if (excluir) {

                        excluirPropostaCoberturas(json, linhaSeguroVidaProposta.id)

                        json.seguroVidaProposta.splice(index, 1)
                    }
                }
            }

            index -= 1
        }

        if (indexProposta === -1 ) {

            json.seguroVidaProposta.push(
                {
                    id: obtemContSeguroVidaProposta(json),
                    familiar: idFamiliar,
                    numeroProposta: linhaJsonRegistros.numeroProposta,
                    seguradora: idSeguradora,
                    dataAssinatura: linhaJsonRegistros.dataAssinatura,
                    tipoPeriodicidadePagtoPremio: linhaJsonRegistros.tipoPeriodicidadePagtoPremio,
                    tipoFormaPagtoPremio: linhaJsonRegistros.tipoFormaPagtoPremio,
                    valorPremioAnualizado: linhaJsonRegistros.valorPremioAnualizado,
                    statusProposta: linhaJsonRegistros.statusProposta,
                    dataUltimaAlteracao: linhaJsonRegistros.dataUltimaAlteracao,
                    numeroCotacao: linhaJsonRegistros.numeroCotacao,
                    observacao: linhaJsonRegistros.observacao,
                    integracao: 'S',
                    integracaoSeguradora: linhaJsonRegistros.integracaoSeguradora ? 'S' : 'N',
                }
            )

            indexProposta = json.seguroVidaProposta.length - 1

        } else {

            json.seguroVidaProposta[indexProposta].dataAssinatura = linhaJsonRegistros.dataAssinatura
            json.seguroVidaProposta[indexProposta].tipoPeriodicidadePagtoPremio = linhaJsonRegistros.tipoPeriodicidadePagtoPremio
            json.seguroVidaProposta[indexProposta].tipoFormaPagtoPremio = linhaJsonRegistros.tipoFormaPagtoPremio
            json.seguroVidaProposta[indexProposta].valorPremioAnualizado = linhaJsonRegistros.valorPremioAnualizado
            json.seguroVidaProposta[indexProposta].statusProposta = linhaJsonRegistros.statusProposta
            json.seguroVidaProposta[indexProposta].dataUltimaAlteracao = linhaJsonRegistros.dataUltimaAlteracao
            json.seguroVidaProposta[indexProposta].numeroCotacao = linhaJsonRegistros.numeroCotacao
            json.seguroVidaProposta[indexProposta].observacao = linhaJsonRegistros.observacao
            json.seguroVidaProposta[indexProposta].integracao = 'S'
            json.seguroVidaProposta[indexProposta].integracaoSeguradora = linhaJsonRegistros.integracaoSeguradora ? 'S' : 'N'

            if (linhaJsonRegistros.propostaCoberturas && linhaJsonRegistros.propostaCoberturas.length > 0) {

                excluirPropostaCoberturas(json, json.seguroVidaProposta[indexProposta].id)
            }
        }

        linhaJsonRegistros.propostaCoberturas?.forEach( (linhaPropostaCoberturas, indexPropostaCoberturas) => {
            
            json.seguroVidaPropostaCobertura.push(
                {
                    id: obtemContSeguroVidaPropostaCobertura(json),
                    idSeguroVidaProposta:  json.seguroVidaProposta[indexProposta].id,
                    grupoCoberturaSeguroVida: linhaPropostaCoberturas.grupoCoberturaSeguroVida,
                    tipoCoberturaSeguroVida: linhaPropostaCoberturas.tipoCoberturaSeguroVida,
                    valorCS: linhaPropostaCoberturas.valorCS,
                    valorPremio: linhaPropostaCoberturas.valorPremio,
                    dataTerminoCobertura: linhaPropostaCoberturas.dataTerminoCobertura,
                    valorCSSobrevivencia:
                        pesquisaList(tipoCoberturaSeguroVidaList, linhaPropostaCoberturas.tipoCoberturaSeguroVida).informaCSSobrevivencia === "S"
                            ?
                                linhaPropostaCoberturas.valorCSSobrevivencia
                                    ?
                                        linhaPropostaCoberturas.valorCSSobrevivencia
                                    :
                                        linhaPropostaCoberturas.valorCS
                            :
                                '',
                    descricaoCobertura: linhaPropostaCoberturas.descricaoCobertura,
                    valorPremioLiquido: linhaPropostaCoberturas.valorPremioLiquido,
                    valorIof: linhaPropostaCoberturas.valorIof,
                    classeAjusteRisco: linhaPropostaCoberturas.classeAjusteRisco,
                    valorPremioExtra: linhaPropostaCoberturas.valorPremioExtra,
                    duracaoPremioExtra: linhaPropostaCoberturas.duracaoPremioExtra,
                    valorRendaMensal: linhaPropostaCoberturas.valorRendaMensal,
                }
            )
        })
    }
}

const atualizarJsonApoliceCoberturas = (json, linhaJsonRegistros, idFamiliar, idSeguradora, tipoAcessoArquivo) => {

    if (tipoAcessoArquivo === HARDCODE.tipoAcessoArquivoApolice && linhaJsonRegistros.numeroApolice) {

        let indexApolice = -1

        processaRegistrosDefaultIniciaisSeguroVida(json, idFamiliar)

        let index = json.seguroVidaApolice.length - 1

        while (index >= 0) {

            if (
                    json.seguroVidaApolice[index].familiar === idFamiliar &&
                    (
                        json.seguroVidaApolice[index].numeroApolice === linhaJsonRegistros.numeroApolice ||
                        (
                            linhaJsonRegistros.numeroProposta === linhaJsonRegistros.numeroApolice &&
                            json.seguroVidaApolice[index].numeroProposta === linhaJsonRegistros.numeroProposta
                        )
                     ) &&
                    json.seguroVidaApolice[index].seguradora === idSeguradora
            ) {
                
                indexApolice = index

            } else {

                if (
                        !json.seguroVidaApolice[index].numeroApolice &&
                        !json.seguroVidaApolice[index].seguradora &&
                        !json.seguroVidaApolice[index].dataEmissao &&
                        !json.seguroVidaApolice[index].tipoFormaPagtoPremio &&
                        !json.seguroVidaApolice[index].statusApolice &&
                        !json.seguroVidaApolice[index].numeroCotacao &&
                        !json.seguroVidaApolice[index].numeroProposta &&
                        !json.seguroVidaApolice[index].dataCancelamento &&
                        !json.seguroVidaApolice[index].motivoCancelamento &&
                        !json.seguroVidaApolice[index].observacao &&
                        !json.seguroVidaApolice[index].idReceitaMensal
                ) {

                    let excluir = true

                    const linhaSeguroVidaApolice = json.seguroVidaApolice[index]

                    json.seguroVidaCobertura?.forEach( (linhaSeguroVidaCobertura, indexSeguroVidaCobertura) => {

                        if (
                                linhaSeguroVidaCobertura.idSeguroVidaApolice === linhaSeguroVidaApolice.id &&
                                linhaSeguroVidaCobertura.tipoCoberturaSeguroVida
                        ) {

                            excluir = false
                        }
                    })

                    if (excluir) {

                        excluirCoberturas(json, linhaSeguroVidaApolice.id)

                        json.seguroVidaApolice.splice(index, 1)
                    }
                }
            }

            index -= 1
        }

        if (indexApolice === -1 ) {

            json.seguroVidaApolice.push(
                {
                    id: obtemContSeguroVidaApolice(json),
                    familiar: idFamiliar,
                    numeroApolice: linhaJsonRegistros.numeroApolice,
                    seguradora: idSeguradora,
                    dataEmissao: linhaJsonRegistros.dataEmissao,
                    tipoPeriodicidadePagtoPremio: linhaJsonRegistros.tipoPeriodicidadePagtoPremio,
                    tipoFormaPagtoPremio: linhaJsonRegistros.tipoFormaPagtoPremio,
                    valorPremioAnualizado: linhaJsonRegistros.valorPremioAnualizado,
                    statusApolice: linhaJsonRegistros.statusApolice,
                    numeroCotacao: linhaJsonRegistros.numeroCotacao,
                    numeroProposta: linhaJsonRegistros.numeroProposta,
                    dataCancelamento: linhaJsonRegistros.dataCancelamento,
                    motivoCancelamento: linhaJsonRegistros.motivoCancelamento,
                    integracao: 'S',
                    integracaoSeguradora: linhaJsonRegistros.integracaoSeguradora ? 'S' : 'N',
                    producaoPropria: 'S',
                }
            )

            indexApolice = json.seguroVidaApolice.length - 1

        } else {

            json.seguroVidaApolice[indexApolice].numeroApolice = linhaJsonRegistros.numeroApolice
            json.seguroVidaApolice[indexApolice].dataEmissao = linhaJsonRegistros.dataEmissao
            json.seguroVidaApolice[indexApolice].tipoPeriodicidadePagtoPremio = linhaJsonRegistros.tipoPeriodicidadePagtoPremio
            json.seguroVidaApolice[indexApolice].tipoFormaPagtoPremio = linhaJsonRegistros.tipoFormaPagtoPremio
            json.seguroVidaApolice[indexApolice].valorPremioAnualizado = linhaJsonRegistros.valorPremioAnualizado
            json.seguroVidaApolice[indexApolice].statusApolice = linhaJsonRegistros.statusApolice
            json.seguroVidaApolice[indexApolice].numeroCotacao = linhaJsonRegistros.numeroCotacao
            json.seguroVidaApolice[indexApolice].numeroProposta = linhaJsonRegistros.numeroProposta
            json.seguroVidaApolice[indexApolice].dataCancelamento = linhaJsonRegistros.dataCancelamento
            json.seguroVidaApolice[indexApolice].motivoCancelamento = linhaJsonRegistros.motivoCancelamento
            json.seguroVidaApolice[indexApolice].integracao = 'S'
            json.seguroVidaApolice[indexApolice].integracaoSeguradora = linhaJsonRegistros.integracaoSeguradora ? 'S' : 'N'
            json.seguroVidaApolice[indexApolice].producaoPropria = 'S'
            
            if (linhaJsonRegistros.coberturas && linhaJsonRegistros.coberturas.length > 0) {

                excluirCoberturas(json, json.seguroVidaApolice[indexApolice].id)
            }
        }

        linhaJsonRegistros.coberturas?.forEach( (linhaCoberturas, indexCoberturas) => {
            
            json.seguroVidaCobertura.push(
                {
                    id: obtemContSeguroVidaCobertura(json),
                    idSeguroVidaApolice:  json.seguroVidaApolice[indexApolice].id,
                    grupoCoberturaSeguroVida: linhaCoberturas.grupoCoberturaSeguroVida,
                    tipoCoberturaSeguroVida: linhaCoberturas.tipoCoberturaSeguroVida,
                    valorCS: linhaCoberturas.valorCS,
                    valorPremio: linhaCoberturas.valorPremio,
                    dataTerminoCobertura: linhaCoberturas.dataTerminoCobertura,
                    valorCSSobrevivencia:
                        pesquisaList(tipoCoberturaSeguroVidaList, linhaCoberturas.tipoCoberturaSeguroVida).informaCSSobrevivencia === "S"
                            ?
                                linhaCoberturas.valorCSSobrevivencia
                                    ?
                                        linhaCoberturas.valorCSSobrevivencia
                                    :
                                        linhaCoberturas.valorCS
                            :
                                '',
                    statusCobertura: linhaCoberturas.statusCobertura,
                    numeroCobertura: linhaCoberturas.numeroCobertura,
                    codigoCobertura: linhaCoberturas.codigoCobertura,
                    descricaoCobertura: linhaCoberturas.descricaoCobertura,
                    valorPremioLiquido: linhaCoberturas.valorPremioLiquido,
                    valorIof: linhaCoberturas.valorIof,
                    classeAjusteRisco: linhaCoberturas.classeAjusteRisco,
                    valorPremioExtra: linhaCoberturas.valorPremioExtra,
                    duracaoPremioExtra: linhaCoberturas.duracaoPremioExtra,
                    valorRendaMensal: linhaCoberturas.valorRendaMensal,
                }
            )
        })
    }
}

const atualizarJsonConsorcio = (json, linhaJsonRegistros, idFamiliar, idAdministradoraConsorcio, tipoAcessoArquivo) => {

    if (tipoAcessoArquivo === HARDCODE.tipoAcessoArquivoConsorcio && linhaJsonRegistros.numeroConsorcio) {

        let indexConsorcio = -1

        processaRegistrosDefaultIniciaisConsorcio(json, idFamiliar)

        let index = json.consorcio.length - 1

        while (index >= 0) {

            if (
                    json.consorcio[index].familiar === idFamiliar &&
                    json.consorcio[index].numeroConsorcio === linhaJsonRegistros.numeroConsorcio &&
                    json.consorcio[index].administradoraConsorcio === idAdministradoraConsorcio
            ) {
                
                indexConsorcio = index

            } else {

                if (
                        !json.consorcio[index].numeroConsorcio &&
                        !json.consorcio[index].administradoraConsorcio &&
                        !json.consorcio[index].tipoConsorcio &&
                        !json.consorcio[index].grupoConsorcio &&
                        !json.consorcio[index].cotaConsorcio &&
                        !json.consorcio[index].pessoaFisicaJuridica &&
                        !json.consorcio[index].statusGrupoConsorcio &&
                        !json.consorcio[index].dataAssembleia &&
                        !json.consorcio[index].numeroAssembleiaParticipacao &&
                        !json.consorcio[index].valorCreditoConsorcio &&
                        !json.consorcio[index].valorParcelaInicial &&
                        !json.consorcio[index].tipoFormaPagtoConsorcio &&
                        !json.consorcio[index].dataVenctoBoleto &&
                        !json.consorcio[index].prazoTotalGrupoConsorcio &&
                        !json.consorcio[index].numeroTotalParticipantesGrupoConsorcio &&
                        !json.consorcio[index].taxaAdministracaoTotal &&
                        !json.consorcio[index].percFundoReserva &&
                        !json.consorcio[index].percSeguroVida &&
                        !json.consorcio[index].percLanceEmbutido &&
                        !json.consorcio[index].percLanceFixo
                ) {

                    json.consorcio.splice(index, 1)
                }
            }

            index -= 1
        }

        if (indexConsorcio === -1 ) {

            json.consorcio.push(
                {
                    id: obtemContConsorcio(json),
                    familiar: idFamiliar,
                    numeroConsorcio: linhaJsonRegistros.numeroConsorcio,
                    administradoraConsorcio: idAdministradoraConsorcio,
                    tipoConsorcio: linhaJsonRegistros.tipoConsorcio,
                    grupoConsorcio: linhaJsonRegistros.grupoConsorcio,
                    cotaConsorcio: linhaJsonRegistros.cotaConsorcio,
                    pessoaFisicaJuridica: linhaJsonRegistros.pessoaFisicaJuridica,
                    statusGrupoConsorcio: linhaJsonRegistros.statusGrupoConsorcio,
                    dataAssembleia: linhaJsonRegistros.dataAssembleia,
                    numeroAssembleiaParticipacao: linhaJsonRegistros.numeroAssembleiaParticipacao,
                    valorCreditoConsorcio: linhaJsonRegistros.valorCreditoConsorcio,
                    valorParcelaInicial: linhaJsonRegistros.valorParcelaInicial,
                    tipoFormaPagtoConsorcio: linhaJsonRegistros.tipoFormaPagtoConsorcio,
                    dataVenctoBoleto: linhaJsonRegistros.dataVenctoBoleto,
                    prazoTotalGrupoConsorcio: linhaJsonRegistros.prazoTotalGrupoConsorcio,
                    numeroTotalParticipantesGrupoConsorcio: linhaJsonRegistros.numeroTotalParticipantesGrupoConsorcio,
                    taxaAdministracaoTotal: linhaJsonRegistros.taxaAdministracaoTotal,
                    percFundoReserva: linhaJsonRegistros.percFundoReserva,
                    percSeguroVida: linhaJsonRegistros.percSeguroVida,
                    percLanceEmbutido: linhaJsonRegistros.percLanceEmbutido,
                    percLanceFixo: linhaJsonRegistros.percLanceFixo,
                    integracao: 'S',
                    integracaoSeguradora: linhaJsonRegistros.integracaoSeguradora ? 'S' : 'N',
                    producaoPropria: 'S',
                }
            )

        } else {

            json.consorcio[indexConsorcio].tipoConsorcio = linhaJsonRegistros.tipoConsorcio
            json.consorcio[indexConsorcio].grupoConsorcio = linhaJsonRegistros.grupoConsorcio
            json.consorcio[indexConsorcio].cotaConsorcio = linhaJsonRegistros.cotaConsorcio
            json.consorcio[indexConsorcio].pessoaFisicaJuridica = linhaJsonRegistros.pessoaFisicaJuridica
            json.consorcio[indexConsorcio].statusGrupoConsorcio = linhaJsonRegistros.statusGrupoConsorcio
            json.consorcio[indexConsorcio].dataAssembleia = linhaJsonRegistros.dataAssembleia
            json.consorcio[indexConsorcio].numeroAssembleiaParticipacao = linhaJsonRegistros.numeroAssembleiaParticipacao
            json.consorcio[indexConsorcio].valorCreditoConsorcio = linhaJsonRegistros.valorCreditoConsorcio
            json.consorcio[indexConsorcio].valorParcelaInicial = linhaJsonRegistros.valorParcelaInicial
            json.consorcio[indexConsorcio].tipoFormaPagtoConsorcio = linhaJsonRegistros.tipoFormaPagtoConsorcio
            json.consorcio[indexConsorcio].dataVenctoBoleto = linhaJsonRegistros.dataVenctoBoleto
            json.consorcio[indexConsorcio].prazoTotalGrupoConsorcio = linhaJsonRegistros.prazoTotalGrupoConsorcio
            json.consorcio[indexConsorcio].numeroTotalParticipantesGrupoConsorcio =
                linhaJsonRegistros.numeroTotalParticipantesGrupoConsorcio
            json.consorcio[indexConsorcio].taxaAdministracaoTotal = linhaJsonRegistros.taxaAdministracaoTotal
            json.consorcio[indexConsorcio].percFundoReserva = linhaJsonRegistros.percFundoReserva
            json.consorcio[indexConsorcio].percSeguroVida = linhaJsonRegistros.percSeguroVida
            json.consorcio[indexConsorcio].percLanceEmbutido = linhaJsonRegistros.percLanceEmbutido
            json.consorcio[indexConsorcio].percLanceFixo = linhaJsonRegistros.percLanceFixo
            json.consorcio[indexConsorcio].integracao = 'S'
            json.consorcio[indexConsorcio].integracaoSeguradora = linhaJsonRegistros.integracaoSeguradora ? 'S' : 'N'
            json.consorcio[indexConsorcio].producaoPropria = 'S'
        }
    }
}

const incluirAtualizarDadosIdentificadosCliente = async (variaveisGlobais, jsonRegistros, idArquivo, idInstituicaoFinanceira,
    tipoAcessoArquivo, nomeArquivo, erros) => {

    let json

    let qtdeRegistros = 0

    let indexJsonRegistros = 0

    while (indexJsonRegistros < jsonRegistros.length) {

        const linhaJsonRegistros = jsonRegistros[indexJsonRegistros]

        const identificacao = obtemIdentificacao(linhaJsonRegistros, nomeArquivo)

        if (linhaJsonRegistros.qtdeErros === 0 && tipoAcessoArquivo === HARDCODE.tipoAcessoArquivoPosicaoInvestimentos) {
            
            const contaInvestimentos =
                await getContaInvestimentos(variaveisGlobais, linhaJsonRegistros.instituicaoFinanceira, linhaJsonRegistros.numeroConta)

            if (contaInvestimentos && contaInvestimentos.cpf) {

                linhaJsonRegistros.cpfSeguradoCliente = contaInvestimentos.cpf

            } else {

                if (contaInvestimentos.numeroConta) {

                    erros.push(
                        MESSAGE().cpfNaoInformadonumeroContaEncontrado
                            .replace("$identificacao$", identificacao)
                                .replace("$numeroConta$", linhaJsonRegistros.numeroConta)
                    )

                } else {

                    erros.push(
                        MESSAGE().numeroContaNaoEncontrado
                            .replace("$identificacao$", identificacao)
                                .replace("$numeroConta$", linhaJsonRegistros.numeroConta)
                    )
                }

                linhaJsonRegistros.qtdeErros = 1
            }
        }

        if (linhaJsonRegistros.qtdeErros === 0) {

            const usuarioClienteSelecionado = linhaJsonRegistros.usuarioClienteSelecionado

            const usuarioClienteSelecionadoGetFamiliaClienteList = HARDCODE.usuarioClienteSelecionadoGetFamiliaClienteList

            const arquivoArquivoAcessoDTOAssociarClienteNULL = {
                idArquivo: idArquivo,
                numeroPropostaAcesso: tipoAcessoArquivo === HARDCODE.tipoAcessoArquivoProposta ? linhaJsonRegistros.numeroProposta : null,
                numeroApoliceAcesso: tipoAcessoArquivo === HARDCODE.tipoAcessoArquivoApolice ? linhaJsonRegistros.numeroApolice : null,
            }

            const qtdeErrosAnt = erros.length

            let clienteList = await getFamiliaClienteList(variaveisGlobais, usuarioClienteSelecionadoGetFamiliaClienteList,
                null, null, null, linhaJsonRegistros.cpfSeguradoCliente, null, null)

            if (clienteList.length > 1) {

                erros.push(
                    MESSAGE().encontradoMaisDeUmClienteCpfSeguradoCliente
                        .replace("$identificacao$", identificacao)
                            .replace("$cpfSeguradoCliente$", formataCpf(linhaJsonRegistros.cpfSeguradoCliente))
                                .replace("$nomeSeguradoCliente$", linhaJsonRegistros.nomeSeguradoCliente)
                )

            } else {

                if (
                        clienteList.length === 0 &&
                        linhaJsonRegistros.nomeSeguradoCliente &&
                        linhaJsonRegistros.nascimentoSeguradoCliente
                ) {

                    clienteList = await getFamiliaClienteList(variaveisGlobais, usuarioClienteSelecionadoGetFamiliaClienteList,
                        linhaJsonRegistros.nomeSeguradoCliente, linhaJsonRegistros.nascimentoSeguradoCliente, null, null, null, null)
                }

                if (clienteList.length > 1) {

                    erros.push(
                        MESSAGE().encontradoMaisDeUmClienteNomeNascimentoSeguradoCliente
                            .replace("$identificacao$", identificacao)
                                .replace("$nomeSeguradoCliente$", linhaJsonRegistros.nomeSeguradoCliente)
                                    .replace("$nascimentoSeguradoCliente$", linhaJsonRegistros.nascimentoSeguradoCliente)
                    )
    
                } else {

                    if (
                            clienteList.length === 0 &&
                            linhaJsonRegistros.cpfRespPagto &&
                            linhaJsonRegistros.cpfSeguradoCliente !== linhaJsonRegistros.cpfRespPagto
                    ) {

                        let clienteListRespPagto = await getFamiliaClienteList(variaveisGlobais, 
                            usuarioClienteSelecionadoGetFamiliaClienteList, null,
                                null, null, linhaJsonRegistros.cpfRespPagto, null, null)

                        if (clienteListRespPagto.length > 1) {

                            erros.push(
                                MESSAGE().encontradoMaisDeUmClienteCpfRespPagto
                                    .replace("$identificacao$", identificacao)
                                        .replace("$cpfRespPagto$", formataCpf(linhaJsonRegistros.cpfRespPagto))
                                            .replace("$nomeRespPagto$", linhaJsonRegistros.nomeRespPagto)
                            )

                        } else {

                            if (
                                    clienteListRespPagto.length === 0 &&
                                    linhaJsonRegistros.nomeRespPagto &&
                                    linhaJsonRegistros.nascimentoRespPagto
                            ) {
                    
                                clienteListRespPagto = await getFamiliaClienteList(variaveisGlobais,
                                    usuarioClienteSelecionadoGetFamiliaClienteList, linhaJsonRegistros.nomeRespPagto,
                                        linhaJsonRegistros.nascimentoRespPagto, null, null, null, null)
                            }

                            if (clienteListRespPagto.length > 1) {

                                erros.push(
                                    MESSAGE().encontradoMaisDeUmClienteNomeNascimentoRespPagto
                                        .replace("$identificacao$", identificacao)
                                            .replace("$nomeRespPagto$", linhaJsonRegistros.nomeRespPagto)
                                                .replace("$nascimentoRespPagto$", linhaJsonRegistros.nascimentoRespPagto)
                                )
                
                            } else {

                                if (clienteListRespPagto.length === 1) {

                                    json = await obtemJsonClienteAlteracao(variaveisGlobais, clienteListRespPagto[0].idUsuario,
                                        clienteListRespPagto[0].idCliente, clienteListRespPagto[0].ultimaVersao,
                                            clienteListRespPagto[0].jsonComparacaoEstudos)

                                    const familiar = identificaFamiliarSemCpf(json, linhaJsonRegistros.nomeSeguradoCliente,
                                        linhaJsonRegistros.nascimentoSeguradoCliente, linhaJsonRegistros.sexoSeguradoCliente)

                                    if (familiar) {

                                        if (familiar === HARDCODE.idFamiliarCliente) {

                                            json.cpfCliente = linhaJsonRegistros.cpfSeguradoCliente

                                        } else {

                                            if (familiar === HARDCODE.idFamiliarConjuge) {

                                                json.cpfConjuge = linhaJsonRegistros.cpfSeguradoCliente
                                            
                                            } else {

                                                const indexFamiliar = pesquisaIndexFamiliar(json, familiar)

                                                if (indexFamiliar !== -1) {

                                                    json.familiares[indexFamiliar].cpfFamiliar = linhaJsonRegistros.cpfSeguradoCliente
                                                }
                                            }
                                        }

                                        ClienteService.setarClienteSelecionado(variaveisGlobais, clienteListRespPagto[0].idCliente)

                                        const status = await putClienteInfoclient(variaveisGlobais, clienteListRespPagto[0].idUsuario,
                                            json, null, null, arquivoArquivoAcessoDTOAssociarClienteNULL)

                                        if (!status) {

                                            erros.push(
                                                MESSAGE().erroGravacaoCliente
                                                    .replace("$identificacao$", identificacao)
                                                        .replace("$cpfSeguradoCliente$", formataCpf(linhaJsonRegistros.cpfSeguradoCliente))
                                                            .replace("$nomeSeguradoCliente$", linhaJsonRegistros.nomeSeguradoCliente)
                                            )

                                        } else {

                                            clienteList = await getFamiliaClienteList(variaveisGlobais,
                                                usuarioClienteSelecionadoGetFamiliaClienteList,
                                                    null, null, null, linhaJsonRegistros.cpfSeguradoCliente, null, null)

                                            if (clienteList.length !== 1) {

                                                erros.push(
                                                    MESSAGE().inconsistenciaPesquisaCpfSeguradoCliente
                                                        .replace("$identificacao$", identificacao)
                                                            .replace("$cpfSeguradoCliente$",
                                                                formataCpf(linhaJsonRegistros.cpfSeguradoCliente))
                                                                    .replace("$nomeSeguradoCliente$",
                                                                        linhaJsonRegistros.nomeSeguradoCliente)
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (qtdeErrosAnt === erros.length) {

                        if (clienteList.length === 1) {

                            if (!usuarioClienteSelecionado || usuarioClienteSelecionado === clienteList[0].idUsuario) {

                                json = await obtemJsonClienteAlteracao(variaveisGlobais, clienteList[0].idUsuario,
                                    clienteList[0].idCliente, clienteList[0].ultimaVersao, clienteList[0].jsonComparacaoEstudos)

                                atualizarJsonDadosCadastrais(json, clienteList[0].familiar, linhaJsonRegistros)

                                atualizarJsonContatos(json, clienteList[0].familiar, linhaJsonRegistros)

                                await atualizarJsonEndereco(variaveisGlobais, json, HARDCODE.idTipoEnderecoResidencial,
                                    linhaJsonRegistros.cepSeguradoCliente, linhaJsonRegistros.enderecoSeguradoCliente,
                                        linhaJsonRegistros.numeroEnderecoSeguradoCliente,
                                            linhaJsonRegistros.complementoEnderecoSeguradoCliente, linhaJsonRegistros.bairroSeguradoCliente,
                                                linhaJsonRegistros.cidadeSeguradoCliente, linhaJsonRegistros.ufSeguradoCliente,
                                                    linhaJsonRegistros.integracaoSeguradora)

                                atualizarJsonReceitaMensal(json, linhaJsonRegistros, clienteList[0].familiar)

                                atualizarJsonRecursosFinanceiros(json, linhaJsonRegistros, clienteList[0].familiar)

                                atualizarJsonPropostaCoberturas(json, linhaJsonRegistros, clienteList[0].familiar,
                                    idInstituicaoFinanceira, tipoAcessoArquivo)

                                atualizarJsonApoliceCoberturas(json, linhaJsonRegistros, clienteList[0].familiar,
                                    idInstituicaoFinanceira, tipoAcessoArquivo)
                                
                                atualizarJsonConsorcio(json, linhaJsonRegistros, clienteList[0].familiar, idInstituicaoFinanceira,
                                    tipoAcessoArquivo)

                                json = ajustaClienteJson(json, false)

                                ClienteService.setarClienteSelecionado(variaveisGlobais, clienteList[0].idCliente)

                                const status = await putClienteInfoclient(variaveisGlobais, clienteList[0].idUsuario,
                                    json, null, null, arquivoArquivoAcessoDTOAssociarClienteNULL)

                                if (status) {

                                    qtdeRegistros++

                                } else {

                                    erros.push(
                                        MESSAGE().erroGravacaoCliente
                                            .replace("$identificacao$", identificacao)
                                                .replace("$cpfSeguradoCliente$", formataCpf(linhaJsonRegistros.cpfSeguradoCliente))
                                                    .replace("$nomeSeguradoCliente$", linhaJsonRegistros.nomeSeguradoCliente)
                                    )
                                }

                            } else {
                                
                                erros.push(
                                    MESSAGE().clienteEncontradoPertenceOutroUsuario
                                        .replace("$identificacao$", identificacao)
                                            .replace("$cpfSeguradoCliente$", formataCpf(linhaJsonRegistros.cpfSeguradoCliente))
                                                .replace("$nomeSeguradoCliente$", linhaJsonRegistros.nomeSeguradoCliente)
                                )
                            }

                        } else {

                            if (clienteList.length === 0) {

                                if (tipoAcessoArquivo === HARDCODE.tipoAcessoArquivoPosicaoInvestimentos) {

                                    erros.push(
                                        MESSAGE().erroAtualizacaoPosicaoInvestimentos
                                            .replace("$identificacao$", identificacao)
                                                .replace("$cpfSeguradoCliente$", formataCpf(linhaJsonRegistros.cpfSeguradoCliente))
                                                    .replace("$numeroConta$", linhaJsonRegistros.numeroConta)
                                    )

                                } else {

                                    if (
                                            !linhaJsonRegistros.cpfRespPagto ||
                                            (linhaJsonRegistros.cpfSeguradoCliente === linhaJsonRegistros.cpfRespPagto)
                                    ) {

                                        if (!linhaJsonRegistros.sexoSeguradoCliente) {

                                            linhaJsonRegistros.sexoSeguradoCliente =
                                                await getIdentificaSexo(variaveisGlobais, linhaJsonRegistros.nomeSeguradoCliente)
                                        }

                                        let json = {
                                            nomeCliente: linhaJsonRegistros.nomeSeguradoCliente,
                                            cpfCliente: linhaJsonRegistros.cpfSeguradoCliente,
                                            nascimentoCliente: linhaJsonRegistros.nascimentoSeguradoCliente,
                                            sexoCliente: linhaJsonRegistros.sexoSeguradoCliente,
                                            tipoOrigemCliente: HARDCODE.idTipoOrigemClienteIntegracao,
                                        }

                                        atualizarJsonDadosCadastrais(json, HARDCODE.idFamiliarCliente, linhaJsonRegistros)

                                        atualizarJsonContatos(json, HARDCODE.idFamiliarCliente, linhaJsonRegistros)

                                        await atualizarJsonEndereco(variaveisGlobais, json, HARDCODE.idTipoEnderecoResidencial,
                                            linhaJsonRegistros.cepSeguradoCliente, linhaJsonRegistros.enderecoSeguradoCliente,
                                                linhaJsonRegistros.numeroEnderecoSeguradoCliente,
                                                    linhaJsonRegistros.complementoEnderecoSeguradoCliente,
                                                        linhaJsonRegistros.bairroSeguradoCliente,
                                                            linhaJsonRegistros.cidadeSeguradoCliente, linhaJsonRegistros.ufSeguradoCliente,
                                                                linhaJsonRegistros.integracaoSeguradora)

                                        atualizarJsonReceitaMensal(json, linhaJsonRegistros, HARDCODE.idFamiliarCliente)

                                        atualizarJsonPropostaCoberturas(json, linhaJsonRegistros, HARDCODE.idFamiliarCliente,
                                            idInstituicaoFinanceira, tipoAcessoArquivo)

                                        atualizarJsonApoliceCoberturas(json, linhaJsonRegistros, HARDCODE.idFamiliarCliente,
                                            idInstituicaoFinanceira, tipoAcessoArquivo)

                                        atualizarJsonConsorcio(json, linhaJsonRegistros, HARDCODE.idFamiliarCliente,
                                            idInstituicaoFinanceira, tipoAcessoArquivo)

                                        json = ajustaClienteJson(json, true)

                                        ClienteService.setarClienteSelecionado(variaveisGlobais, null)

                                        const status =
                                            await putClienteInfoclient(variaveisGlobais, usuarioClienteSelecionado,
                                                json, null, null, arquivoArquivoAcessoDTOAssociarClienteNULL)

                                        if (status) {

                                            qtdeRegistros++
                                            
                                        } else {

                                            erros.push(
                                                MESSAGE().erroGravacaoCliente
                                                    .replace("$identificacao$", identificacao)
                                                        .replace("$cpfSeguradoCliente$", formataCpf(linhaJsonRegistros.cpfSeguradoCliente))
                                                            .replace("$nomeSeguradoCliente$", linhaJsonRegistros.nomeSeguradoCliente)
                                            )
                                        }

                                    } else {

                                        erros.push(
                                            MESSAGE().respPagtoDiferenteSeguradoCpfNaoEncontrado
                                                .replace("$identificacao$", identificacao)
                                                    .replace("$cpfRespPagto$", formataCpf(linhaJsonRegistros.cpfRespPagto))
                                                        .replace("$nomeRespPagto$", linhaJsonRegistros.nomeRespPagto)
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        indexJsonRegistros++
    }

    return qtdeRegistros
}

const transferirClientes = async (variaveisGlobais, jsonRegistros, erros) => {

    let qtdeRegistros = 0

    if (erros.length === 0) {

        const usuarioClienteSelecionado = AuthService.obterUsuarioSelecionado(variaveisGlobais)[0].id

        let indexJsonRegistros = 0

        while (indexJsonRegistros < jsonRegistros.length) {

            const linhaJsonRegistros = jsonRegistros[indexJsonRegistros]

            let clienteList = await getFamiliaClienteList(variaveisGlobais, usuarioClienteSelecionado, null, null, null,
                linhaJsonRegistros.cpfSeguradoCliente, null, null)

            if (clienteList.length > 1) {

                erros.push(
                    MESSAGE().encontradoMaisDeUmClienteCpfSeguradoClienteTransferencia
                        .replace("$cpfSeguradoCliente$", formataCpf(linhaJsonRegistros.cpfSeguradoCliente))
                )

            } else {

                if (
                        clienteList.length === 0 ||
                        (
                            clienteList.length === 1 &&
                            clienteList[0].familiar !== HARDCODE.idFamiliarCliente
                        )
                ) {

                    erros.push(
                        MESSAGE().naoFoiEncontradoSeguradoClienteTransferencia
                            .replace("$cpfSeguradoCliente$", linhaJsonRegistros.cpfSeguradoCliente)
                    )

                } else {

                    jsonRegistros[indexJsonRegistros].idCliente = clienteList[0].idCliente
                }
            }

            indexJsonRegistros++
        }

        if (erros.length === 0) {

            indexJsonRegistros = 0

            while (indexJsonRegistros < jsonRegistros.length) {

                const linhaJsonRegistros = jsonRegistros[indexJsonRegistros]
       
                const status = await putTransferirCliente(variaveisGlobais, linhaJsonRegistros.idCliente, usuarioClienteSelecionado,
                    linhaJsonRegistros.usuarioClienteSelecionado)

                if (status) {
                    
                    qtdeRegistros++

                } else {

                    erros.push(
                        MESSAGE().ocorreuErroTransferenciaSeguradoClienteTransferencia
                            .replace("$nomeSeguradoCliente$", linhaJsonRegistros.cpfSeguradoCliente)
                    )
                }

                indexJsonRegistros++
            }
        }
    }

    return qtdeRegistros
}

const processaArquivoInstituicaoFinanceira = async (variaveisGlobais, jsonUsuario, equipeList, linhaArquivoList, XLSXPDFTxt, erros) => {

    const linhaInstituicaoFinanceira = pesquisaList(instituicaoFinanceiraList, linhaArquivoList.instituicaoFinanceira)
    
    let registrosProcessados = 0

    let indexIntegracao = 0

    while (indexIntegracao < linhaInstituicaoFinanceira.integracao.length) {

        const linhaIntegracao = linhaInstituicaoFinanceira.integracao[indexIntegracao]

        if (linhaIntegracao.tipoArquivo === linhaArquivoList.tipoArquivo) {

            const jsonRegistros = linhaIntegracao.rotinaIntegracao(XLSXPDFTxt, AuthService.obterUsuarioLogado(variaveisGlobais),
                jsonUsuario, equipeList, linhaIntegracao, linhaArquivoList.instituicaoFinanceira, linhaArquivoList.nomeArquivo, erros)

            if (linhaIntegracao.tipoAtualizacao === HARDCODE.tipoAtualizacaoCliente) {

                registrosProcessados = await incluirAtualizarDadosIdentificadosCliente(variaveisGlobais, jsonRegistros,
                    linhaArquivoList.idArquivo, linhaInstituicaoFinanceira.id, linhaIntegracao.tipoAcessoArquivoNULL,
                        linhaArquivoList.nomeArquivo, erros)

            } else {
                
                if (linhaIntegracao.tipoAtualizacao === HARDCODE.tipoAtualizacaoTransferenciaCliente) {

                    registrosProcessados = await transferirClientes(variaveisGlobais, jsonRegistros, erros)

                } else {

                    if (erros.length === 0) {

                        if (linhaIntegracao.tipoAtualizacao === HARDCODE.tipoAtualizacaoProposta) {
                                
                            putPropostaList(variaveisGlobais, linhaArquivoList.idArquivo,
                                linhaArquivoList.instituicaoFinanceira, jsonRegistros)

                        } else {

                            if (linhaIntegracao.tipoAtualizacao === HARDCODE.tipoAtualizacaoCobranca) {

                                putApoliceCobrancaList(variaveisGlobais, linhaArquivoList.idArquivo,
                                    linhaArquivoList.instituicaoFinanceira, jsonRegistros)

                            } else {

                                if (linhaIntegracao.tipoAtualizacao === HARDCODE.tipoAtualizacaoComissao) {

                                    putApoliceComissaoList(variaveisGlobais, linhaArquivoList.idArquivo,
                                        linhaArquivoList.instituicaoFinanceira, jsonRegistros)
                                }
                            }
                        }

                        registrosProcessados = -1/*await*/ 
                    }
                }
            }

            indexIntegracao = linhaInstituicaoFinanceira.integracao.length
        }

        indexIntegracao++
    }

    return registrosProcessados
}

export const processaArquivos = async (variaveisGlobais, jsonUsuario, arquivoList, equipeList) => {

    let arquivosProcessados = 0

    let arquivosEmProcessamento = 0

    let arquivosComErro = 0

    let qtdeRegistros = 0

    let statusProcessamento



    let arquivoListAux = []

    let indexArquivo = 0

    while (indexArquivo < arquivoList.length) {

        const linhaArquivoList = arquivoList[indexArquivo]

        if (linhaArquivoList.statusProcessamento === HARDCODE.idStatusProcessamentoPendenteProcessamento) {

            arquivoListAux.push(
                {
                    ...linhaArquivoList,
                    ordem: pesquisaList(tipoArquivoList, linhaArquivoList.tipoArquivo).ordem
                }
            )
        }

        indexArquivo++
    }

    arquivoListAux = sortAsc(arquivoListAux)



    indexArquivo = 0

    while (indexArquivo < arquivoListAux.length) {

        const linhaArquivoList = arquivoListAux[indexArquivo]

        const arquivoArquivoAcessoDTO = await getArquivoConteudo(variaveisGlobais,
            ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais), linhaArquivoList.idArquivo)

        let XLSXPDFText

        if (linhaArquivoList.extensaoArquivo === HARDCODE.PDF) {
        
            const { PDFTxt } = await putConverter (variaveisGlobais, arquivoArquivoAcessoDTO.conteudo)

            XLSXPDFText = PDFTxt

        } else {

            const XLSXBlob = getBlobFromBase64(arquivoArquivoAcessoDTO.conteudo)
            
            XLSXPDFText = await readXlsxFile(XLSXBlob)
        }

        let erros = []

        const registrosProcessados =
            await processaArquivoInstituicaoFinanceira(variaveisGlobais, jsonUsuario, equipeList, linhaArquivoList, XLSXPDFText, erros)

        if (registrosProcessados === -1) {

            arquivosEmProcessamento++

        } else {

            qtdeRegistros += registrosProcessados

            let errosProcessamento = ''

            erros.forEach( (linhaErros, indexErros) => {

                errosProcessamento += (errosProcessamento ? '<br>' : '') + linhaErros

            })

            if (erros.length > 0) {

                arquivosComErro++

                statusProcessamento = HARDCODE.idStatusProcessamentoPendenteProcessamento

            } else {

                arquivosProcessados++

                statusProcessamento = HARDCODE.idStatusProcessamentoDadosImportados
            }
            //statusProcessamento = HARDCODE.idStatusProcessamentoPendenteProcessamento

            await putArquivoStatusProcessamento(variaveisGlobais, ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais),
                linhaArquivoList, statusProcessamento, registrosProcessados, errosProcessamento)
        }

        indexArquivo++
    }

    return { arquivosProcessados, arquivosEmProcessamento, arquivosComErro, qtdeRegistros }
}